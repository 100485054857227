// React
import React, {Component, Fragment} from "react";

// Other libs
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import classNames from "classnames";

// MUI
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import IconButtonA11y from "../../uiComponents/Button/IconButtonA11y";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import DoneIcon from "@material-ui/icons/DoneOutlined";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutlined";
import ShareIcon from "@material-ui/icons/ShareOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";

// Redux modules
import {
    updateVersionDescription,
    deleteVersion,
    selectVersionsLoading
} from "redux/modules/versions/versions";
import {openSnackbar} from "redux/modules/snackbar/open";

import DialogComponent from "../../uiComponents/Dialog/DialogComponent";
import Content from "helpers/Content";
import VersionProgressContainer from "components/VersionProgress/VersionProgressContainer";
import TreeInformationContainer from "components/Version/TreeInformationContainer";
import ParametersDialogContent from "components/Version/ParametersDialogContent";
import {ConfigContext} from "../../utils/ConfigProvider";

import FindPermissions from "helpers/FindPermissions";

export function removeTrailingSlash(link) {
    return link.length > 0 && link.substr(-1) === "/"
        ? link.substr(0, link.length - 1)
        : link;
}

export function appendAnchor(link, projectId, version) {
    return `${removeTrailingSlash(
        link
    )}/#/project/${projectId}/versions#version-${version}`;
}

export function appendSavedSetLink(
    link,
    projectId,
    version,
    treeId,
    savedSetId
) {
    return `${removeTrailingSlash(
        link
    )}/#/project/${projectId}/versions/${version}/${treeId}/${savedSetId}`;
}

// Helper to create link to version
export function constructVersionLink(projectId, version, treeId, savedSetId) {
    let link = "";
    link = `${window.location.protocol}//${window.location.host}`;
    if (window.self !== window.top) {
        // Confirms you are in iframe
        link = `${removeTrailingSlash(link)}${parent.Liferay.currentURL}`; // eslint-disable-line no-restricted-globals
    }
    if (treeId && savedSetId) {
        link = appendSavedSetLink(link, projectId, version, treeId, savedSetId);
    } else {
        link = appendAnchor(link, projectId, version);
    }
    return link;
}

const styles = theme => ({
    paper: {
        marginBottom: 30
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 35,
        paddingRight: 35,
        paddingTop: 35
    },
    textSubheading: {
        marginBottom: 30,
        textTransform: "capitalize"
    },
    paddingLeft: {
        paddingLeft: 15
    },
    row: {
        flexBasis: "20%"
    },
    rowMiddle: {
        position: "relative",
        flexBasis: "50%",
        marginLeft: 30,
        marginRight: 30
    },
    alignRight: {
        textAlign: "right"
    },
    containerButton: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexWrap: "wrap",
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 35,
        paddingRight: 35
    },
    infoIcon: {
        fill: theme.status.grey.dark
    },
    editIcon: {
        fontSize: 16,
        display: "none",
        "&:hover": {
            display: "block"
        }
    },
    textSecondary: {
        color: theme.status.midnight[50]
    },
    date: {
        fontWeight: 300
    },
    editButtonContainer: {
        display: "inline-block",
        paddingLeft: 15
    },
    iconButton: {
        padding: 0,
        width: 32,
        height: 32
    },
    iconShareButton: {
        "&:hover": {
            backgroundColor: theme.status.yellow[50]
        }
    },
    updateButton: {
        width: 48,
        height: 48,
        marginRight: 10,
        background: theme.status.blue.dark,
        color: theme.status.grey.white,
        "&:hover": {
            backgroundColor: theme.status.blue.extraDark
        }
    },
    textField: {
        position: "relative",
        top: -20,
        padding: 15,
        boxSizing: "border-box",
        border: "1px solid",
        borderColor: theme.status.grey.white
    },
    textFieldActive: {
        borderColor: theme.status.grey.greydivs,
        "&:hover": {
            borderColor: theme.status.grey.greybox
        }
    },
    errorWarning: {
        marginRight: "auto"
    },
    shareCopy: {
        margin: 40,
        padding: 20,
        backgroundColor: theme.status.clay[10],
        color: theme.status.grey.dark,
        textAlign: "left",
        borderRadius: 10
    }
});

const mapStateToProps = (state, props) => {
    return {
        isLoading: selectVersionsLoading(state)
    };
};

const mapDispatchToProps = {
    updateVersionDescription,
    deleteVersion,
    openSnackbar
};

class Version extends Component {
    static propTypes = {
        version: PropTypes.object.isRequired,
        projectId: PropTypes.string.isRequired,
        owner: PropTypes.string,
        isLoading: PropTypes.bool,
        deleteVersion: PropTypes.func,
        onOpenTree: PropTypes.func,
        updateVersionDescription: PropTypes.func.isRequired
    };

    state = {
        isEditDescription: false,
        isDialogOpen: false,
        isShareDialogOpen: false,
        showEditIcon: false,
        versionLink: "",
        currentDescription: this.props.version.description
    };

    versionRef = React.createRef();

    componentDidMount() {
        window.location.hash = window.decodeURIComponent(window.location.hash);
        const hashParts = window.location.hash.split("#");
        const lastPart = hashParts[hashParts.length - 1];
        const hasVersionAnchor = lastPart.indexOf("version-") === 0;
        if (!hasVersionAnchor) return;
        const versionAnchor = `version-${this.props.version.version}`;
        if (versionAnchor !== lastPart) return;
        const node = this.versionRef.current;
        node && node.scrollIntoView();
    }

    openShareDialog() {
        this.setState({
            isShareDialogOpen: true
        });
    }

    closeShareDialog() {
        this.setState({
            isShareDialogOpen: false
        });
    }

    copyToClipboard = () => {
        const {versionLink} = this.state;
        const {t, openSnackbar} = this.props;
        navigator.clipboard.writeText(versionLink).then(
            () => {
                openSnackbar(t("version.copy_to_clipboard_success", "success"));
            },
            err => {
                console.error(err);
                openSnackbar(t("version.copy_to_clipboard_error", "error"));
            }
        );
        this.closeShareDialog();
    };

    setVersionLink(projectId, version, treeId, savedSetId) {
        const versionLink = constructVersionLink(
            projectId,
            version,
            treeId,
            savedSetId
        );
        this.setState({
            versionLink
        });
    }

    handleClickShare = (projectId, version, treeId, savedSetId) => event => {
        this.setVersionLink(projectId, version, treeId, savedSetId);
        this.openShareDialog();
    };

    handleShareDialogClose = event => {
        this.closeShareDialog();
    };

    handleClickEditDescription = () => {
        this.setState({isEditDescription: true});
        setTimeout(() => {
            this.descriptionEl && this.descriptionEl.focus();
        }, 50);
    };

    handleClickCancelDescription = () => {
        this.setState({
            isEditDescription: false,
            currentDescription: this.props.version.description
        });
    };

    handleClickSaveDescription = () => {
        const {projectId, version, updateVersionDescription} = this.props;
        const {currentDescription: description} = this.state;
        updateVersionDescription(projectId, version.version, {description});
        this.setState({isEditDescription: false});
    };

    handleDescriptionChange = event => {
        this.setState({
            currentDescription: event.target.value
        });
    };

    handleClickDelete = () => {
        const {deleteVersion, projectId, version} = this.props;
        deleteVersion && deleteVersion(projectId, version.version);
    };

    handleDialogOpen = () => {
        this.setState({
            isDialogOpen: true
        });
    };

    handleDialogClose = () => {
        this.setState({isDialogOpen: false});
    };

    render() {
        const {projectId, version, t, classes} = this.props;
        const {
            currentDescription,
            isDialogOpen,
            isShareDialogOpen,
            isEditDescription
        } = this.state;
        const isVersionComplete = version && version.status === "COMMITTED";
        const isProgress = version.jobId && version.status === "IN_PROGRESS";
        const headings = [
            "common.date_created",
            "version.description",
            "version.parameters"
        ];
        return (
            <Paper className={classes.paper}>
                <div
                    ref={this.versionRef}
                    id={`version-${version.version}`}
                    className={classes.container}
                >
                    {headings.map((elem, idx) => (
                        <div
                            key={`projectRow${idx}`}
                            className={classNames(
                                idx === 1 ? classes.rowMiddle : classes.row,
                                idx === 2 ? classes.alignRight : null
                            )}
                        >
                            <Typography
                                variant="subtitle1"
                                className={classNames(
                                    classes.textSubheading,
                                    idx === 1 ? classes.paddingLeft : null
                                )}
                            >
                                {t(elem)}
                                <div className={classes.editButtonContainer}>
                                    {idx === 1 && isEditDescription && (
                                        <Fragment>
                                            <IconButtonA11y
                                                className={classes.iconButton}
                                                ariaLabel={t("version.update")}
                                                onClick={
                                                    this
                                                        .handleClickSaveDescription
                                                }
                                            >
                                                <DoneIcon/>
                                            </IconButtonA11y>
                                            <IconButtonA11y
                                                className={classes.iconButton}
                                                ariaLabel={t("version.cancel")}
                                                onClick={
                                                    this
                                                        .handleClickCancelDescription
                                                }
                                            >
                                                <ClearIcon/>
                                            </IconButtonA11y>
                                        </Fragment>
                                    )}
                                    {idx === 1 && !isEditDescription && (
                                        <IconButtonA11y
                                            className={classes.iconButton}
                                            ariaLabel={t("version.update")}
                                            onClick={
                                                this.handleClickEditDescription
                                            }
                                        >
                                            <EditIcon/>
                                        </IconButtonA11y>
                                    )}
                                </div>
                            </Typography>
                            {idx === 0 && (
                                <Fragment>
                                    <Typography
                                        variant="h2"
                                        className={classes.date}
                                    >
                                        {Content.formatDate(version.createdOn)}
                                    </Typography>
                                    <Typography
                                        className={classes.textSecondary}
                                    >
                                        {Content.formatTime(
                                            version.createdOn,
                                            "hh:mm:ss a"
                                        )}
                                    </Typography>
                                    <Typography
                                        className={classes.textSecondary}
                                    >
                                        {version.createdBy}
                                    </Typography>
                                </Fragment>
                            )}
                            {idx === 1 && (
                                <TextField
                                    id={"versionDescription_" + version.version}
                                    multiline
                                    fullWidth
                                    rows="2"
                                    disabled={!isEditDescription}
                                    autoFocus
                                    spellCheck={false}
                                    value={currentDescription}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    inputRef={el => (this.descriptionEl = el)}
                                    onChange={this.handleDescriptionChange}
                                    className={classNames(
                                        {
                                            [classes.textFieldActive]: isEditDescription
                                        },
                                        classes.textField
                                    )}
                                />
                            )}
                            {idx === 2 && (
                                <IconButtonA11y
                                    ariaLabel={t("common.information")}
                                    onClick={this.handleDialogOpen}
                                >
                                    <InfoIcon className={classes.infoIcon}/>
                                </IconButtonA11y>
                            )}
                        </div>
                    ))}
                </div>
                <div className={classes.containerButton}>
                    {isProgress && (
                        <VersionProgressContainer
                            versionId={version.id}
                            projectId={projectId}
                        />
                    )}

                    <IconButtonA11y
                        ariaLabel={t("common.share")}
                        onClick={this.handleClickShare(
                            projectId,
                            version.version
                        )}
                        className={classes.iconShareButton}
                    >
                        <ShareIcon className={classes.icon}/>
                    </IconButtonA11y>
                    {FindPermissions.isOwnerOfThisProject(
                        version.createdBy,
                        this.context.userInfo.screenName
                    ) && (
                        <IconButtonA11y
                            ariaLabel={t("projects.delete")}
                            className={classes.deleteButton}
                            onClick={this.handleClickDelete}
                        >
                            <DeleteIcon/>
                        </IconButtonA11y>
                    )}


                    {version.status === "ERROR" && (
                        <Fragment>
                            <div className={classes.errorWarning}>
                                <ErrorIcon className={classes.infoIcon}/>
                                <Typography variant="body1">
                                    {t("project_details.version_error")}
                                </Typography>
                            </div>
                            <Button
                                onClick={this.handleClickDelete}
                                variant="outlined"
                                color="primary"
                            >
                                {t("common.delete")}
                            </Button>
                        </Fragment>
                    )}
                </div>
                {isVersionComplete && (
                    <TreeInformationContainer
                        versionNumber={version.version}
                        trees={version.trees}
                        onClickShare={this.handleClickShare}
                    />
                )}
                <DialogComponent
                    isOpen={isDialogOpen}
                    onClose={this.handleDialogClose}
                    fullWidth
                    title={t("version.dialog_title")}
                >
                    <ParametersDialogContent version={version}/>
                </DialogComponent>
                <DialogComponent
                    isOpen={isShareDialogOpen}
                    onClose={this.handleShareDialogClose}
                    canSave
                    onSave={this.copyToClipboard}
                    saveLabel={"common.copy"}
                    maxWidth="sm"
                    title={t("common.share")}
                >
                    <div className={classes.shareCopy}>
                        <Typography variant="body2">
                            {this.state.versionLink}
                        </Typography>
                    </div>
                </DialogComponent>
            </Paper>
        );
    }
}

Version.contextType = ConfigContext;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withStyles(styles)(Version)));
