import React from "react";
import PropTypes from "prop-types";

// Create config context
export const ConfigContext = React.createContext({
    config: {},
    userInfo: {}
});

export default class ConfigProvider extends React.Component {
    render() {
        return (
            <ConfigContext.Provider
                value={{
                    config: this.props.config,
                    userInfo: this.props.userInfo
                }}
            >
                {this.props.children}
            </ConfigContext.Provider>
        );
    }
}

ConfigProvider.propTypes = {
    userInfo: PropTypes.object,
    config: PropTypes.object
};
