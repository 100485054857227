import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { styles as SubcategoryCardBaseStyles } from "components/SubcategoryGroups/SubcategoryCardBase";

let StoreCard = ({ title, count, classes }) => {
    return (
        <div className={classes.wrapper}>
            <div className={classes.card}>
                <div className={classes.container}>
                    <Typography variant="caption" className={classes.cardTitle}>
                        {title}
                    </Typography>
                </div>
                <div className={classes.container}>
                    <Typography variant="caption">{count}</Typography>
                </div>
            </div>
        </div>
    );
};

StoreCard = withStyles(SubcategoryCardBaseStyles)(StoreCard);

const styles = theme => ({
    storeCardContainer: {
        display: "flex",
        flexWrap: "wrap",
        minHeight: 65,
        position: "relative",
        padding: "20px 20px 10px",
        marginBottom: 15,
        borderRadius: 10,
        backgroundColor: theme.status.clay[10]
    },
    card: {
        padding: 35,
        marginBottom: 40,
        textAlign: "left"
    },
    text: {
        display: "inline-block",
        verticalAlign: "middle",
        marginBottom: 15
    },
    button: {
        display: "inline-block",
        verticalAlign: "middle",
        textTransform: "capitalize",
        textDecoration: "underline",
        fontSize: 12,
        fontWeight: 400,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    label: {
        display: "inline"
    },
    storeList: {
        margin: 0,
        padding: 0
    },
    storeListItem: {
        display: "inline-block",
        fontSize: 12,
        fontWeight: 400,
        color: theme.status.grey.dark,
        "&:after": {
            content: "','"
        },
        "&:last-child:after": {
            content: "none"
        }
    }
});

function StoresImportSummary({ stores, classes, t }) {
    const groupedStores = useMemo(() => {
        if (!Array.isArray(stores) || stores.length === 0) {
            return {};
        }
        return stores.reduce((prev, curr) => {
            const region = curr.region || "UNKNOWN";
            if (prev[region]) {
                prev[region].push(curr);
            } else {
                prev[region] = [curr];
            }
            return prev;
        }, {});
    }, [stores]);

    const storeCount = useMemo(
        () => (Array.isArray(stores) ? stores.length : 0),
        [stores]
    );

    return (
        <Card className={classes.card}>
            <Typography variant="h6" className={classes.text}>
                {t("stores.summary", {
                    count: storeCount
                })}
            </Typography>
            <div className={classes.storeCardContainer}>
                {Object.entries(groupedStores).map(([key, value]) => (
                    <StoreCard
                        key={key}
                        title={t("stores.region", { region: key })}
                        count={t("stores.store_count", {
                            count: value.length
                        })}
                    />
                ))}
            </div>
        </Card>
    );
}

StoresImportSummary.propTypes = {
    stores: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(withStyles(styles)(StoresImportSummary));
