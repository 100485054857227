import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    container: {
        display: "flex",
    },
    padding: {
        paddingBottom: 15
    },
    display: {
        fontWeight: 300,
        paddingLeft: 15
    }
});

function ParametersAnalysisLevel(props) {
    const {version, t, classes} = props;
    return (
        <div className={classes.container}>
            <div>
                <Typography variant="h4" className={classes.padding}>
                    {t("subcategories.level_of_analysis_title")}:
                </Typography>
            </div>
            <div>
                <Typography variant="h4" className={classes.display}>
                    {version.versionRequestInfo.analysis_level}
                </Typography>
            </div>
        </div>
    );
}

ParametersAnalysisLevel.propTypes = {
    classes: PropTypes.object.isRequired,
    version: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(ParametersAnalysisLevel));
