import {createSelector} from "reselect";

// redux-saga
import { call, take } from "redux-saga/effects";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Redux saga helper
import { fetchEntity } from "../../store/sagaHelpers";

// Api configuration
import api from "api";

// Reducer name
const reducerName = "collaborators";
const { reducer, actions, actionTypes, selectors } = createFetchRedux(
    reducerName,
    {
        data: []
    }
);

export default reducer;

// Action types
export const FETCH_COLLABORATORS = actionTypes.FETCH;

// Actions
export const fetchCollaborators = actions.fetch;
export const collaborators = actions;

// Selectors
// export const selectCollaborators = selectors.selectReduxData;
export const selectCollaboratorsError = selectors.selectReduxError;
export const selectCollaboratorsLoading = selectors.selectReduxLoading;

export const selectCollaborators = createSelector(
    [selectors.selectReduxData],
    // Sort alphabetically
    data => {
        if (data && data['OrgsUsers'] ) {
            let orgUsers = data['OrgsUsers']
            let result = []
            Object.keys(orgUsers).forEach(org => {
                result = result.concat(orgUsers[org]['users'])
            });
            return result.sort((a, b) => a.name.localeCompare(b.name))
        } else {
            return []
        }
    }
);

// Sagas
export const fetchCollaboratorsEntity = fetchEntity.bind(
    null,
    collaborators,
    api["collaborators.get"]
);

export function* watchCollaborators() {
    yield take(FETCH_COLLABORATORS);
    yield call(fetchCollaboratorsEntity);
}
