import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import { selectAllProducts } from "redux/modules/products/products";
import { selectProjects, runSimulation } from "redux/modules/projects/projects";

import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import HeaderButtonAlt from "../../uiComponents/Button/HeaderButtonAlt";
import DialogComponent from "../../uiComponents/Dialog/DialogComponent";

const styles = theme => ({
    container: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: 30
    },
    buttonContainer: {
        marginTop: 15,
        alignSelf: "flex-end"
    },
    buttonLeft: {
        marginRight: 10
    },
    textField: {
        marginTop: 15,
        marginBottom: 15
    }
});

const mapStateToProps = (state, ownProps) => {
    const allProducts = selectAllProducts(state);
    const projectId = parseInt(ownProps.projectId, 10);
    const project = selectProjects(state).find(el => el.id === projectId);
    const hasDateConstraints = Boolean(
        project && project.startDate && project.endDate
    );
    const isVisible =
        allProducts && allProducts.length > 0 && hasDateConstraints;
    return {
        isVisible
    };
};

const mapDispatchToProps = {
    runSimulation
};

export class RunSimulation extends React.Component {
    state = {
        open: false,
        description: ""
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleAcceptConfirmModal = () => {
        const { projectId, runSimulation, history } = this.props;
        const { description } = this.state;
        this.handleClose();
        runSimulation(projectId, { description }, history);
    };

    handleChangeDescription = event => {
        if (event.target.value.length > this.props.descriptionMaxChars) return;
        this.setState({
            description: event.target.value
        });
    };

    render() {
        const { isVisible, descriptionMaxChars, classes, t } = this.props;
        const { open, description } = this.state;

        return (
            <Fragment>
                {isVisible && (
                    <HeaderButtonAlt
                        className={classes.button}
                        onClick={this.handleClickOpen}
                    >
                        {t("project_details.run_simulation")}
                    </HeaderButtonAlt>
                )}
                <DialogComponent
                    isOpen={open}
                    onClose={this.handleClose}
                    title={t("confirmation.title")}
                >
                    <div className={classes.container}>
                        <Typography>{t("confirmation.message")}</Typography>
                        <TextField
                            label={t("confirmation.add_description")}
                            rows="5"
                            error={
                                description.length > descriptionMaxChars
                                    ? true
                                    : false
                            }
                            multiline
                            variant="outlined"
                            onChange={this.handleChangeDescription}
                            value={description}
                            InputLabelProps={{ shrink: true }}
                            className={classes.textField}
                        />
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.handleClose}
                                className={classes.buttonLeft}
                            >
                                {t("common.cancel")}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleAcceptConfirmModal}
                            >
                                {t("project_details.run_simulation")}
                            </Button>
                        </div>
                    </div>
                </DialogComponent>
            </Fragment>
        );
    }
}

RunSimulation.propTypes = {
    projectId: PropTypes.string.isRequired,
    runSimulation: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isVisible: PropTypes.bool,
    descriptionMaxChars: PropTypes.number
};

RunSimulation.defaultProps = {
    isVisible: false,
    descriptionMaxChars: 250
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withStyles(styles)(RunSimulation)));
