import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: theme.status.midnight[100],
        color: theme.status.yellow.dark,
        borderColor: theme.status.clay[100],
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 20,
        fontWeight: 600,
        textTransform: "uppercase",
        transitionProperty: "border-color, color",
        transitionDuration: "250ms, 250ms",
        transitionTimingFunction: "linear, linear",
        "&:hover": {
            backgroundColor: theme.status.yellow[100],
            borderColor: theme.status.clay[100],
            color: theme.status.midnight[100]
        },
        "&:active": {
            backgroundColor: theme.status.yellow.dark
        }
    }
});

const LabelButton = ({ classes, label }) => (
    <span className={classes.root}>{label}</span>
);

export default withStyles(styles)(LabelButton);
