import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Redux
import {
    selectVersion,
    fetchVersion,
    selectAllVersionsProductsDict,
    selectSavedSetFromTree
} from "redux/modules/versions/versions";
import { selectCurrentVersionNum } from "redux/modules/versions/current";
import {
    selectCurrentTreeId,
    selectCurrentTreeName
} from "redux/modules/tree/current";
import {
    // selectors
    selectSavedSetTree,
    selectSearchPaths,
    selectCurrentTree,
    selectNextNodeToSplit,
    selectProductsPerNode,
    selectSavedSetName,
    selectSavedSetNodeNames,
    selectSavedSetNeedUnits,
    selectSavedSetNeedUnitsWithUserDefined,
    selectAllBrands,
    selectProductDict,
    selectUnsavedChanges,
    selectActiveNodeName,
    selectActiveNodeInfo,
    selectHasUnassigned,
    // actions
    addRemoveNodes,
    updateNodeName,
    updateNeedUnits,
    setActiveNodeName,
    // helpers
    findExpandedNodes,
    findNodeByPaths,
    findStops,
    expandToTarget
} from "../../redux/modules/tree/savedset";

import TreeView from "components/Tree/TreeView";

const mapStateToProps = (state, props) => ({
    versionNumber: selectCurrentVersionNum(state),
    version: selectVersion(state, props),
    treeId: selectCurrentTreeId(state),
    // tree name for display in tree view
    treeName: selectCurrentTreeName(state),
    // saved set name for display in tree view
    savedSetName: selectSavedSetName(state),
    // product data list
    productData: selectProductDict(state, props),
    // more product data
    allVersionProductsDict: selectAllVersionsProductsDict(state, props),
    // source tree top-level children
    currentTree: selectCurrentTree(state),
    // dictionary of nodes and paths to reach them
    searchPaths: selectSearchPaths(state),
    // our new treemap data!
    workingSavedSetData: selectSavedSetTree(state),
    // next node to split (by height)
    nextNodeToSplit: selectNextNodeToSplit(state),
    // product dictionary indexed by node
    productsPerNode: selectProductsPerNode(state),
    // saved set from tree
    savedSetFromTree: selectSavedSetFromTree(state),
    // saved set need unit list
    savedSetNeedUnits: selectSavedSetNeedUnits(state),
    // saved set need unit list including user defined
    savedSetNeedUnitsWithUserDefined: selectSavedSetNeedUnitsWithUserDefined(
        state
    ),
    // node names in working saved set
    savedSetNodeNames: selectSavedSetNodeNames(state),
    // brands from all products in tree
    allBrands: selectAllBrands(state),
    // unsaved changes (comparison between set in tree and working)
    hasUnsavedChanges: selectUnsavedChanges(state),
    // currently selected node
    activeNodeName: selectActiveNodeName(state),
    // node info
    activeNodeInfo: selectActiveNodeInfo(state),
    // boolean for has unassigned nodes
    hasUnassigned: selectHasUnassigned(state)
});

const mapDispatchToProps = {
    fetchVersion,
    addRemoveNodes,
    updateNodeName,
    updateNeedUnits,
    setActiveNodeName
};

class TreeViewContainer extends Component {
    componentDidMount() {
        const { fetchVersion, projectId, versionNumber } = this.props;
        fetchVersion([projectId, versionNumber]);
    }

    findParentNode(node) {
        const { currentTree, searchPaths } = this.props;
        let paths = searchPaths[node].split("|");
        paths = paths.slice(0, paths.length - 1);
        if (paths.length === 0) return;
        return findNodeByPaths(currentTree && currentTree.children, paths);
    }

    findWorkingParentNode(node) {
        const { workingSavedSetData, searchPaths } = this.props;
        let paths = searchPaths[node].split("|");
        paths = paths.slice(0, paths.length - 1);
        if (paths.length === 0) return;
        return findNodeByPaths(workingSavedSetData, paths);
    }

    findCurrentNode(node) {
        const { currentTree, searchPaths } = this.props;
        return findNodeByPaths(
            currentTree && currentTree.children,
            searchPaths[node].split("|")
        );
    }

    findWorkingCurrentNode(node) {
        const { workingSavedSetData, searchPaths } = this.props;
        return findNodeByPaths(
            workingSavedSetData,
            searchPaths[node].split("|")
        );
    }

    findSavedSetProducts(node) {
        const { savedSetFromTree, productsPerNode } = this.props;
        // any in saved set already?
        let savedSetNode = savedSetFromTree.needUnits.find(
            el => el.node === node
        );
        return savedSetNode ? savedSetNode.products : productsPerNode[node];
    }

    findWorkingSavedSetProducts(node) {
        const { savedSetNeedUnits, productsPerNode } = this.props;
        let savedSetNode = savedSetNeedUnits.find(el => el.node === node);
        return savedSetNode ? savedSetNode.products : productsPerNode[node];
    }

    handleChangeNeedUnitNum = event => {
        // find optimal split for this number of need units
        // ..hm
        const needUnitNum = Number(event.target.value);
        const {
            currentTree,
            savedSetNodeNames,
            savedSetNeedUnitsWithUserDefined,
            productsPerNode,
            addRemoveNodes
        } = this.props;
        const addNodes = expandToTarget(currentTree.children, needUnitNum);
        const removeNodes = savedSetNeedUnitsWithUserDefined.map(el => el.node);
        addRemoveNodes(
            addNodes.map(el => ({
                name: savedSetNodeNames[el.name] || el.name,
                node: el.name,
                products: productsPerNode[el.name]
            })),
            removeNodes
        );
    };

    showOptimalSplit = () => {
        const {
            currentTree,
            savedSetNeedUnitsWithUserDefined,
            savedSetNodeNames,
            addRemoveNodes,
            productsPerNode
        } = this.props;
        const stopNodes = findStops(currentTree && currentTree.children);
        const removeNodes = savedSetNeedUnitsWithUserDefined.map(el => el.node);
        addRemoveNodes(
            stopNodes.map(el => ({
                name: savedSetNodeNames[el.name] || el.name,
                node: el.name,
                products: productsPerNode[el.name]
            })),
            removeNodes
        );
    };

    rebuildNeedUnits(nodesAdd, nodesRemove) {
        const {
            savedSetNeedUnitsWithUserDefined,
            savedSetNodeNames
        } = this.props;
        return savedSetNeedUnitsWithUserDefined
            .map(el => el.node)
            .filter(el => nodesRemove.indexOf(el) === -1) // take existing need units minus ones to remove
            .concat(nodesAdd) // add new nodes
            .map(el => ({
                name: savedSetNodeNames[el] || el,
                node: el,
                products: this.findSavedSetProducts(el)
            })); // map with name and products from saved
    }

    // expand node to children
    // node is string name of node
    expandCluster = node => {
        const { updateNeedUnits } = this.props;
        let foundNode = this.findCurrentNode(node);
        // node may not have children to expand?
        if (!foundNode.children) return;
        // NOTE: we are disregarding unsaved reassigned products
        // re-build with saved set products
        let nodesAdd = foundNode.children.map(el => el.name);
        let nodesRemove = [node];
        let nodes = this.rebuildNeedUnits(nodesAdd, nodesRemove);
        updateNeedUnits(nodes); // dispatch action to update nodes in saved set store
    };

    // collapse node by finding parent
    // add parent node, remove parent node children
    collapseCluster = node => {
        const { updateNeedUnits } = this.props;
        let workingNode;
        let currentNode = this.findWorkingCurrentNode(node);
        if (currentNode.children && currentNode.children.length) {
            workingNode = currentNode;
        } else {
            let parentNode = this.findWorkingParentNode(node);
            if (!parentNode) {
                console.warn("Cannot collapse top-level nodes");
                return;
            }
            workingNode = parentNode;
        }
        let nodesAdd = [workingNode.name];
        let nodesRemove = findExpandedNodes(workingNode.children).map(
            el => el.name
        );
        let nodes = this.rebuildNeedUnits(nodesAdd, nodesRemove);
        updateNeedUnits(nodes);
    };

    getProductsByNodeName = node => {
        const {
            productsPerNode,
            productData,
            allVersionProductsDict
        } = this.props;
        let nodeProducts = productsPerNode[node].map(
            productId => allVersionProductsDict[productId]
        );
        return nodeProducts.map(product => ({
            ...product,
            ...productData[product.productId]
        }));
    };

    onFindSibling = node => {
        const { searchPaths, workingSavedSetData } = this.props;
        let siblings = [];
        let foundNode = this.findParentNode(node);
        if (!foundNode) return siblings;
        siblings = foundNode.children.map(el => el.name);
        if (siblings.length === 0) return siblings;
        const expandedNodes = findExpandedNodes(workingSavedSetData);
        return expandedNodes.reduce((prev, curr) => {
            const currNode = curr.name;
            const paths = searchPaths[currNode].split("|");
            const containsParentA =
                paths.indexOf(foundNode.children[0].name) !== -1;
            const containsParentB =
                paths.indexOf(foundNode.children[1].name) !== -1;
            if (containsParentA || containsParentB) {
                prev = prev.concat(currNode);
            }
            return prev;
        }, siblings);
    };

    handleNodeNameChange = (node, nodeName) => {
        const { updateNodeName, setActiveNodeName } = this.props;
        setActiveNodeName(node);
        updateNodeName(node, nodeName);
    };

    onClickCluster = node => {
        const { setActiveNodeName } = this.props;
        setActiveNodeName(node);
    };

    render() {
        const {
            activeNodeName,
            savedSetNodeNames,
            savedSetNeedUnits,
            hasUnassigned
        } = this.props;
        let activeNodeNameUserDefined = activeNodeName;
        const hasUserDefinedNodeName = activeNodeName in savedSetNodeNames;
        if (hasUserDefinedNodeName) {
            activeNodeNameUserDefined = savedSetNodeNames[activeNodeName];
        }
        return (
            this.props.workingSavedSetData && (
                <TreeView
                    version={this.props.version}
                    projectId={this.props.projectId}
                    data={this.props.workingSavedSetData}
                    brands={this.props.allBrands}
                    nextCluster={this.props.nextNodeToSplit}
                    expandCluster={this.expandCluster}
                    collapseCluster={this.collapseCluster}
                    onFindSibling={this.onFindSibling}
                    onClickCluster={this.onClickCluster}
                    updateNodeName={this.handleNodeNameChange}
                    activeNodeNameUserDefined={activeNodeNameUserDefined}
                    activeNodeName={this.props.activeNodeName}
                    activeNodeInfo={this.props.activeNodeInfo}
                    getProductsByNodeName={this.getProductsByNodeName}
                    showOptimalSplit={this.showOptimalSplit}
                    isUnsavedChanges={this.props.hasUnsavedChanges}
                    treeName={this.props.treeName}
                    needUnitLen={savedSetNeedUnits.length}
                    onChangeNeedUnitNum={this.handleChangeNeedUnitNum}
                    savedSetName={
                        this.props.savedSetName === "default"
                            ? ""
                            : this.props.savedSetName
                    }
                    hasUnassigned={hasUnassigned}
                />
            )
        );
    }
}

TreeViewContainer.propTypes = {
    projectId: PropTypes.string.isRequired,
    treeId: PropTypes.number,
    versionNumber: PropTypes.number,
    // derived treemap from savedset data array
    workingSavedSetData: PropTypes.array,
    // saved set node names (dictionary {node: nodename})
    savedSetNodeNames: PropTypes.object.isRequired,
    // saved set from tree
    savedSetFromTree: PropTypes.object.isRequired,
    savedSetName: PropTypes.string,
    treeName: PropTypes.string,
    hasUnsavedChanges: PropTypes.bool.isRequired,
    // current selected node
    activeNodeName: PropTypes.string.isRequired,
    // current info for selected
    activeNodeInfo: PropTypes.object.isRequired,
    // brands from tree
    allBrands: PropTypes.object.isRequired,
    // redux actions
    addRemoveNodes: PropTypes.func.isRequired,
    setActiveNodeName: PropTypes.func.isRequired
};

TreeViewContainer.defaultProps = {
    workingSavedSetData: []
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeViewContainer);
