import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../styles/MuiTheme";
import { LINE_SIZE_PRODUCT } from "constants/static";

const MIN_STROKE_WIDTH = 0.85;
const MAX_STROKE_WIDTH = 30;

const getColourByIndex = (
    salesPc,
    itemsPc,
    lowerDiffIndex,
    upperDiffIndex,
    minDiffIndex,
    maxDiffIndex,
    minOpacity
) => {
    let diff = salesPc / itemsPc;
    let opacity = 1;
    if (diff < lowerDiffIndex || diff > upperDiffIndex) {
        diff = Math.min(Math.max(minDiffIndex, diff), maxDiffIndex);
        if (diff > upperDiffIndex) {
            opacity =
                minOpacity +
                (diff - upperDiffIndex) *
                    ((1 - minOpacity) / (maxDiffIndex - upperDiffIndex));
            return `rgba(127, 197, 133, ${opacity})`;
        } else {
            opacity =
                minOpacity +
                (diff - lowerDiffIndex) *
                    ((1 - minOpacity) / (minDiffIndex - lowerDiffIndex));
            return `rgba(241, 97, 111, ${opacity})`;
        }
    }
    return theme.status.grey.greydivs;
};

const getColourByBrand = (brandCount, brandTotalCount, minOpacity = 0.2) => {
    if (brandCount === 0) return theme.status.grey.greydivs;
    const opacity =
        minOpacity + (1 - minOpacity) * (brandCount / brandTotalCount);
    return `rgba(2, 66, 114, ${opacity})`;
};

function linkPath(d) {
    return (
        "M" +
        d.source.y +
        "," +
        d.source.x +
        "C" +
        (d.source.y + d.target.y) / 2 +
        "," +
        d.source.x +
        " " +
        (d.source.y + d.target.y) / 2 +
        "," +
        d.target.x +
        " " +
        d.target.y +
        "," +
        d.target.x
    );
}

export const getDiagonalPath = (s, d) =>
    `M${d.x},${d.y} C${d.x},${(d.y + s.y) / 2} ${s.x},${(d.y + s.y) / 2} ${
        s.x
    },${s.y}`;

const LinkPath = styled.path`
    fill: none;
    stroke: ${props =>
        props.isColour ? props.colour : theme.status.grey.greydivs};
`;

const HierarchicalLink = ({
    link,
    maxSize,
    maxSales,
    lineOption,
    rootSize,
    rootSales,
    lowerDiffIndex,
    upperDiffIndex,
    minDiffIndex,
    maxDiffIndex,
    minOpacity,
    isColourByIndex,
    highlightBrand,
    getProductsByNodeName,
    isRotate
}) => {
    const node = link.target;
    const isRoot = node.depth === 0;
    const items = isRoot ? rootSize : node.data.size;
    const sales = isRoot ? rootSales : node.data.totalSales;
    const salesPc = sales / rootSales;
    const itemsPc = items / rootSize;
    let colour = isColourByIndex
        ? getColourByIndex(
              salesPc,
              itemsPc,
              lowerDiffIndex,
              upperDiffIndex,
              minDiffIndex,
              maxDiffIndex,
              minOpacity
          )
        : theme.status.grey.greydivs;

    const maxValue = lineOption === LINE_SIZE_PRODUCT ? maxSize : maxSales;
    const targetValue =
        lineOption === LINE_SIZE_PRODUCT
            ? link.target.data.size
            : link.target.data.totalSales;
    const strokeWidth = Math.max(
        MIN_STROKE_WIDTH,
        (MAX_STROKE_WIDTH * targetValue) / maxValue
    );

    if (highlightBrand) {
        const productsByNodeName = getProductsByNodeName(node.data.name);
        const brandCount = productsByNodeName
            ? productsByNodeName.filter(
                  product => product.brand === highlightBrand.name
              ).length
            : 0;
        const brandTotalCount = highlightBrand.value;
        colour = getColourByBrand(brandCount, brandTotalCount);
    }

    return (
        <LinkPath
            isColour={isColourByIndex || highlightBrand}
            colour={colour}
            strokeWidth={strokeWidth}
            d={
                isRotate
                    ? linkPath(link)
                    : getDiagonalPath(link.source, link.target)
            }
        />
    );
};

HierarchicalLink.propTypes = {
    link: PropTypes.object.isRequired,
    maxSize: PropTypes.number.isRequired,
    maxSales: PropTypes.number.isRequired,
    rootSize: PropTypes.number.isRequired,
    rootSales: PropTypes.number.isRequired,
    lineOption: PropTypes.string.isRequired,
    isColourByIndex: PropTypes.bool,
    lowerDiffIndex: PropTypes.number,
    upperDiffIndex: PropTypes.number,
    maxDiffIndex: PropTypes.number,
    minDiffIndex: PropTypes.number,
    minOpacity: PropTypes.number,
    getProductsByNodeName: PropTypes.func.isRequired
};

HierarchicalLink.defaultProps = {
    lowerDiffIndex: 0.85,
    upperDiffIndex: 1.15,
    maxDiffIndex: 2,
    minDiffIndex: 0.5,
    minOpacity: 0.4,
    isColourByIndex: false
};

export default HierarchicalLink;
