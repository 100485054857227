import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function AddIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 11 13">
            <path d="M11 7.233H6.12V13H4.88V7.233H0V5.767h4.88V0h1.24v5.767H11" />
        </SvgIcon>
    );
}
