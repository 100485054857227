import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

// MUI
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "../../styles/icons/add";
import HouseIcon from "../../styles/icons/house";

const styles = {
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 30,
        marginRight: "auto"
    },
    titleContainerNoMargin: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "auto"
    },
    title: {},
    titleEmpty: {
        "&:before": {
            content: "'\\00a0'"
        }
    },
    button: {
        textTransform: "capitalize",
        textDecoration: "underline",
        fontSize: 12,
        fontWeight: 400,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    addIcon: {
        fontSize: 12,
        paddingRight: 10
    },
    houseIcon: {
        fontSize: 20,
        paddingRight: 15
    },
    body1: {
        marginBottom: 15
    },
    alt: {
        position: "absolute",
        top: 54
    }
};

function PageTitle(props) {
    const {
        t,
        alt,
        title,
        subtitle,
        hasHomeIcon,
        addButton,
        noMargin,
        onAddButtonClick,
        classes
    } = props;
    return (
        <Fragment>
            <div
                className={
                    noMargin
                        ? classes.titleContainerNoMargin
                        : classes.titleContainer
                }
            >
                {alt && subtitle && (
                    <Typography className={classes.alt} variant="body2">
                        {subtitle}
                    </Typography>
                )}
                <Typography
                    className={!title ? classes.titleEmpty : classes.title}
                    variant="h3"
                >
                    {hasHomeIcon && <HouseIcon className={classes.houseIcon} />}
                    {t(title)}
                </Typography>
                {addButton && (
                    <Button
                        onClick={onAddButtonClick}
                        className={classes.button}
                    >
                        <AddIcon className={classes.addIcon} />
                        {addButton}
                    </Button>
                )}
            </div>
            {!alt && subtitle && (
                <Typography variant="body2" className={classes.body1}>
                    {subtitle}
                </Typography>
            )}
        </Fragment>
    );
}

PageTitle.propTypes = {
    alt: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.object,
    addButton: PropTypes.string,
    onAddButtonClick: PropTypes.func,
    subtitle: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withTranslation()(PageTitle));
