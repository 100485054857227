import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default withStyles(theme => ({
    root: {
        marginRight: 10,
        backgroundColor: theme.status.midnight[100],
        color: theme.status.clay[100],
        borderColor: theme.status.clay[100],
        borderWidth: 2,
        borderStyle: "solid",
        borderRadius: 20,
        transitionProperty: "border-color, color",
        transitionDuration: "250ms, 250ms",
        transitionTimingFunction: "linear, linear",
        "&:hover": {
            backgroundColor: theme.status.grey.white,
            borderColor: theme.status.grey.white,
            color: theme.status.midnight[100]
        }
    }
}))(Button);
