import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircleIcon from "@material-ui/icons/Brightness1Outlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { roundedRect } from "../../helpers/drawUtil";
import theme from "../../styles/MuiTheme";

export const styles = theme => ({
    wrapper: {
        position: "relative",
        backgroundColor: theme.status.grey.white,
        border: "1px solid",
        borderColor: theme.status.clay[50],
        boxShadow: "0 3px 15px 0 rgba(0, 0, 0, 0.13)",
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 10,
        marginBottom: 10
    },
    card: {
        display: "flex",
        flexDirection: "column"
    },
    container: {
        display: "flex"
    },
    cardTitle: {
        fontSize: 14,
        marginBottom: 15,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    icon: {
        fill: theme.status.clay[20],
        marginLeft: "auto",
        paddingLeft: 15
    },
    iconCheck: {
        fill: theme.status.midnight[100]
    },
    isDragging: {
        backgroundColor: "transparent"
    },
    isOver: {
        backgroundColor: theme.status.yellow[100]
    },
    isDown: {
        opacity: 0.5
    }
});

class SubcategoryCard extends React.Component {
    state = {
        isDown: false
    };

    componentDidMount() {
        this.createDragPreview([]);
    }

    componentDidUpdate(prevProps) {
        const { selectedItems: prevSelectedItems } = prevProps;
        const { selectedItems } = this.props;
        if (selectedItems !== prevSelectedItems) {
            this.createDragPreview(selectedItems);
        }
    }

    createDragPreview(selectedItems) {
        const { connectDragPreview, t } = this.props;
        const canvas = document.createElement("canvas");
        canvas.width = 200;
        canvas.height = 35;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = theme.status.grey.white;
        roundedRect(ctx, 0, 0, 200, 35, 20);
        ctx.fillStyle = theme.status.green[100];
        const movingText =
            selectedItems.length === 0
                ? t("subcategories.moving_one")
                : t("subcategories.moving", {
                      count: selectedItems.length
                  });
        ctx.font = "14px Open Sans";
        ctx.fillText(movingText, 20, 22);
        const url = canvas.toDataURL("image/png");
        const img = new Image();
        img.src = url;
        img.onload = () => {
            connectDragPreview(img);
        };
    }

    handleMouseDown = event => {
        this.setState({
            isDown: true
        });
    };

    handleMouseLeave = event => {
        this.setState({
            isDown: false
        });
    };

    handleMouseUp = event => {
        this.setState({
            isDown: false
        });
    };

    render() {
        const {
            productCount,
            content,
            classes,
            t,
            connectDragSource,
            connectDropTarget,
            isDragging,
            isSelected,
            isOver,
            canDrop,
            handleSelect
        } = this.props;
        const { isDown } = this.state;
        return connectDragSource(
            connectDropTarget(
                <div
                    onClick={handleSelect}
                    onMouseDown={this.handleMouseDown}
                    onMouseUp={this.handleMouseUp}
                    onMouseLeave={this.handleMouseLeave}
                    className={classNames(
                        {
                            [classes.isDragging]: isDragging,
                            [classes.isOver]: isOver && canDrop,
                            [classes.isDown]: isDown
                        },
                        classes.wrapper
                    )}
                >
                    <div className={classes.card}>
                        <div className={classes.container}>
                            <Typography
                                variant="caption"
                                className={classes.cardTitle}
                            >
                                {content}
                            </Typography>
                            {!isSelected && (
                                <CircleIcon className={classes.icon} />
                            )}
                            {isSelected && (
                                <CheckCircleIcon
                                    className={classNames(
                                        classes.icon,
                                        classes.iconCheck
                                    )}
                                />
                            )}
                        </div>
                        <div className={classes.container}>
                            <Typography variant="caption">
                                {t("subcategories.product_count_plural", {
                                    count: productCount
                                })}
                            </Typography>
                        </div>
                    </div>
                </div>
            )
        );
    }
}

SubcategoryCard.propTypes = {
    id: PropTypes.string.isRequired,
    groupId: PropTypes.string,
    selectedItems: PropTypes.array.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    isOver: PropTypes.bool,
    canDrop: PropTypes.bool
};
export default withTranslation()(withStyles(styles)(SubcategoryCard));
