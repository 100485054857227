import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import { selectReassignAvailableProductAttributes } from "redux/modules/reassign/viewAttributes";
import { renderLabel } from "components/AttributeList/AttributeAutocomplete";

const styles = theme => ({
    root: {
        marginTop: 20,
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        padding: 10,
        backgroundColor: theme.status.clay[10]
    }
});

const ChipList = withStyles(styles)(Paper);
const LabelChip = withStyles({
    root: {
        margin: 5
    }
})(Chip);

const mapStateToProps = state => ({
    data: selectReassignAvailableProductAttributes(state)
});

function TreeReassignSortLabelChips({ currentKeys, data, handleClick }) {
    return (
        <ChipList>
            {data.map((option, index) => (
                <LabelChip
                    onClick={() => {
                        handleClick(option);
                    }}
                    disabled={currentKeys.includes(option)}
                    key={index}
                    label={renderLabel(option)}
                />
            ))}
        </ChipList>
    );
}

TreeReassignSortLabelChips.propTypes = {
    data: PropTypes.array,
    currentKeys: PropTypes.array.isRequired,
    handleClick: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(TreeReassignSortLabelChips);
