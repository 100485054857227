import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const styles = theme => ({
    selectFormControl: {
        padding: 30
    }
});

DMTImportOptionSelect.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    listKey: PropTypes.string,
    options: PropTypes.array.isRequired
};

function DMTImportOptionSelect({
    t,
    classes,
    categoryKey = "category_name",
    listKey = "stores",
    onChange,
    selectedOptions,
    options,
    translationSection = "stores"
}) {
    return (
        <FormControl className={classes.selectFormControl} component="fieldset">
            {options.map((el, index) => {
                return (
                    <FormControlLabel
                        key={index}
                        value={el[categoryKey]}
                        control={
                            <Checkbox
                                color="primary"
                                checked={selectedOptions.includes(
                                    el[categoryKey]
                                )}
                                onChange={onChange}
                                value={el[categoryKey]}
                            />
                        }
                        label={t(`${translationSection}.select_label`, {
                            category: el[categoryKey],
                            num: el[listKey].length
                        })}
                    />
                );
            })}
        </FormControl>
    );
}

export default withTranslation()(withStyles(styles)(DMTImportOptionSelect));
