// redux-saga
import { call, take, select, put } from "redux-saga/effects";
// Redux factory
import createFetchRedux from "../createFetchRedux";
// Redux saga helper
import { fetchEntity } from "../../store/sagaHelpers";
// Api configuration
import api from "api";
// Snackbar
import { openSnackbar } from "../snackbar/open";

// Reducer name
const configReducerName = "config";

const { reducer, actions, selectors } = createFetchRedux(configReducerName);

export default reducer;

// Action types
export const FETCH_CONFIG = "config/FETCH_CONFIG";

// Actions
export function fetchConfig() {
    return {
        type: FETCH_CONFIG
    };
}

export const config = actions;

// Selectors
export const selectConfig = selectors.selectReduxData;
export const selectErrorConfig = selectors.selectReduxError;
export const selectLoadingConfig = selectors.selectReduxLoading;

export function selectConfigAdditionalAttributeExclude(state) {
    const config = selectConfig(state);
    return config && Array.isArray(config.additionalAttributeExclude)
        ? config.additionalAttributeExclude
        : [];
}

export function selectConfigAdditionalAttributePairs(state) {
    const config = selectConfig(state);
    return config && Array.isArray(config.additionalAttributePairs)
        ? config.additionalAttributePairs
        : [];
}

export function selectAllowedLevelofAnalysis(state) {
    const config = selectConfig(state);
    return config && Array.isArray(config.allowedLevelOfAnalysis)
        ? config.allowedLevelOfAnalysis
        : [];
}

export function selectDMTIntegrationProducts(state) {
    const config = selectConfig(state);
    if (config && config.dmtIntegration) {
        return config.dmtIntegration.products;
    } else return null;
}

export function selectDMTIntegrationStores(state) {
    const config = selectConfig(state);

    if (config && config.dmtIntegration && config.dmtIntegration.stores) {
        return config.dmtIntegration.stores;
    }

    return {
        hierarchySelectionEnabled: false
    };
}

// Sagas
const fetchConfigEntity = fetchEntity.bind(null, config, api["app.config"]);

export function* watchConfig() {
    yield take(FETCH_CONFIG);
    yield call(fetchConfigEntity);
    const products = yield select(selectDMTIntegrationProducts);
    if (!products) {
        yield put(
            openSnackbar(
                "DMT Integration Config is missing. Please contact administrator",
                "error"
            )
        );
    }
}
