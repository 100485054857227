import { createSelector } from "reselect";
import { selectTrees } from "redux/modules/versions/versions";

import {
    HIERARCHICAL_VIEW,
    TREEMAP_VIEW,
    LINE_SIZE_PRODUCT
} from "constants/static";

// Action types
export const RESET = "modules/tree/RESET";
export const UPDATE_CURRENT_TREE = "modules/tree/UPDATE_CURRENT_TREE";
export const UPDATE_CURRENT_SAVED_SET =
    "modules/tree/saved.set/UPDATE_CURRENT_SAVED_SET";

export const SET_COLOUR_BY_INDEX = "modules/tree/SET_COLOUR_BY_INDEX";
export const SET_VIEW_MODE = "modules/tree/SET_VIEW_MODE";
export const SET_HIERARCHICAL_LINE_OPTION =
    "modules/tree/SET_HIERARCHICAL_LINE_OPTION";
export const SET_HIGHLIGHT_BRAND = "modules/tree/SET_HIGHLIGHT_BRAND";

// Actions
export function resetCurrentTree() {
    return {
        type: RESET
    };
}

export function setHierarchicalView() {
    return {
        type: SET_VIEW_MODE,
        payload: HIERARCHICAL_VIEW
    };
}

export function setTreemapView() {
    return {
        type: SET_VIEW_MODE,
        payload: TREEMAP_VIEW
    };
}

export const setHierarchicalLineOption = lineOption => ({
    type: SET_HIERARCHICAL_LINE_OPTION,
    payload: lineOption
});

export const setColourByIndex = isColourByIndex => ({
    type: SET_COLOUR_BY_INDEX,
    payload: isColourByIndex
});

export const setHighlightBrand = brand => ({
    type: SET_HIGHLIGHT_BRAND,
    payload: brand
});

const initialState = {
    treeId: null,
    savedSetId: null,
    viewMode: HIERARCHICAL_VIEW,
    hierarchicalLineOption: LINE_SIZE_PRODUCT,
    isColourByIndex: false,
    highlightBrand: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RESET:
            return initialState;
        case UPDATE_CURRENT_TREE:
            return {
                ...state,
                treeId: action.payload
            };
        case UPDATE_CURRENT_SAVED_SET:
            return {
                ...state,
                savedSetId: action.payload
            };
        case SET_VIEW_MODE:
            return {
                ...state,
                viewMode: action.payload
            };
        case SET_HIERARCHICAL_LINE_OPTION:
            return {
                ...state,
                hierarchicalLineOption: action.payload
            };
        case SET_COLOUR_BY_INDEX:
            return {
                ...state,
                isColourByIndex: action.payload,
                highlightBrand: null
            };
        case SET_HIGHLIGHT_BRAND:
            return {
                ...state,
                highlightBrand: action.payload,
                isColourByIndex: false
            };
        default:
            return state;
    }
};

export const updateCurrentTree = treeId => ({
    type: UPDATE_CURRENT_TREE,
    payload: treeId
});

export const updateCurrentSavedSet = savedSetId => ({
    type: UPDATE_CURRENT_SAVED_SET,
    payload: savedSetId
});

// selectors
export const selectViewMode = state => state.tree && state.tree.viewMode;
export const selectHierarchicalLineOption = state =>
    state.tree && state.tree.hierarchicalLineOption;
export const selectColourByIndex = state =>
    state.tree && state.tree.isColourByIndex;
export const selectHighlightBrand = state =>
    state.tree && state.tree.highlightBrand;

export const selectCurrentTreeId = state => state.tree && state.tree.treeId;
export const selectCurrentSavedSetId = state =>
    state.tree && state.tree.savedSetId;

export const getTreeId = (state, props) => props.treeId;

export const selectTree = createSelector(
    [selectTrees, getTreeId],
    (trees, treeId) => {
        return trees && trees.filter(tree => tree.id === treeId)[0];
    }
);

export const selectCurrentTree = createSelector(
    [selectTrees, selectCurrentTreeId],
    (trees, currentTreeId) =>
        trees && trees.filter(tree => tree.id === currentTreeId)[0]
);

export const selectFlatTreeList = createSelector(
    [selectTrees, selectCurrentSavedSetId, selectCurrentTreeId],
    (trees, savedSetId, treeId) => {
        const savedSetIdNum = parseInt(savedSetId, 10);
        if (!Array.isArray(trees)) return [];
        let stack = trees.slice(0);
        let result = [];
        while (stack.length > 0) {
            const el = stack[0];
            result = result.concat({
                id: el.id,
                name: el.name,
                active: el.id === treeId && savedSetId === "default"
            });
            if (el.saved_sets) {
                result = result.concat(
                    el.saved_sets
                        .filter(set => set)
                        .map(set => ({
                            id: set.id,
                            parentId: el.id,
                            name: set.name,
                            active: set.id === savedSetIdNum,
                            subitem: true
                        }))
                );
            }
            stack = stack.slice(1);
        }
        return result;
    }
);

export const selectCurrentTreeName = createSelector(
    [selectCurrentTree],
    tree => tree && tree.name
);

export const selectCurrentTreeSavedSets = createSelector(
    [selectCurrentTree],
    tree => (tree ? tree.saved_sets : [])
);

export const selectCurrentSavedSet = createSelector(
    [selectCurrentSavedSetId, selectCurrentTreeSavedSets],
    (savedSetId, savedSets) => {
        const savedSetIdNum = parseInt(savedSetId, 10);
        if (isNaN(savedSetIdNum)) return null;
        return savedSets
            .filter(set => set)
            .filter(savedSet => savedSet.id === savedSetIdNum)[0];
    }
);
