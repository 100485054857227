// redux-saga
import { select, takeEvery, put, call } from "redux-saga/effects";

// Fetch service
import fetch from "services/fetch";

// Api configuration
import api from "api";

// Redux modules
import { openSnackbar } from "../snackbar/open";
import { selectProjects, projectsMany } from "../projects/projects";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "saveStores";
const { reducer, actions, selectors } = createFetchRedux(reducerName);

export const saveActions = actions;

// Selectors
export const selectSaveStoreResult = selectors.selectReduxData;
export const selectSaveStoreLoading = selectors.selectReduxLoading;

// Action types
export const SAVE_STORES = `${reducerName}/SAVE_STORES`;

// Actions
export const resetVerifyStores = actions.reset;

export function saveStores(id, stores) {
    return {
        type: SAVE_STORES,
        payload: {
            id,
            stores
        }
    };
}

// Sagas
export function* fetchSaveStores({ payload }) {
    const { id, stores } = payload;
    const { url, type, successful, error, data } = api["stores.save"](
        id,
        stores
    );
    try {
        const result = yield call(fetch, url, type, data);
        const projects = yield select(selectProjects);
        const newProjects = projects.map(project => {
            const isMatch = String(project.id) === String(id);
            if (isMatch) {
                return {
                    ...project,
                    stores: result
                };
            } else return project;
        });
        yield put(actions.receive(result));
        yield put(projectsMany.receive(newProjects));
        yield put(openSnackbar(successful, "success"));
    } catch (e) {
        yield put(actions.error(error));
        yield put(openSnackbar(error, "error"));
        console.error(e);
    }
}

export function* watchSaveStores() {
    yield takeEvery(SAVE_STORES, fetchSaveStores);
}

export default reducer;
