import { createTheme } from "@material-ui/core/styles";
import * as colors from "./colors";

const theme = createTheme({
    palette: {
        type: "light",
        primary: {
            light: colors.blue[100],
            main: colors.blue.dark,
            dark: colors.blue.extraDark
        },
        secondary: {
            light: colors.cyan[100],
            main: colors.cyan[100],
            dark: colors.cyan.dark
        },
        error: { main: colors.red[100] },
        text: {
            primary: colors.grey.dark
        }
    },
    status: {
        blue: colors.blue,
        grey: colors.grey,
        green: colors.green,
        red: colors.red,
        cyan: colors.cyan,
        mauve: colors.mauve,
        pink: colors.pink,
        orange: colors.orange,
        yellow: colors.yellow,
        teal: colors.teal,
        zest: colors.zest,
        clay: colors.clay,
        midnight: colors.midnight
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: 14,
        h1: {
            fontSize: 28,
            fontWeight: 400,
            lineHeight: "40px",
            color: colors.midnight[100]
        },
        h2: {
            fontSize: 36,
            lineHeight: "50px",
            color: colors.midnight[100]
        },
        h3: {
            fontSize: 28,
            lineHeight: "40px",
            color: colors.midnight[100]
        },
        h4: {
            fontSize: 20,
            lineHeight: "35px",
            color: colors.midnight[100]
        },
        h5: {
            fontSize: 18,
            lineHeight: "30px",
            color: colors.midnight[100]
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: "25px",
            color: colors.midnight[100]
        },
        body1: {
            fontSize: 12,
            lineHeight: "20px",
            fontWeight: 500,
            color: colors.midnight[100]
        },
        body2: {
            fontSize: 14,
            lineHeight: "25px",
            color: colors.midnight[100]
        },
        caption: {
            fontSize: 12,
            lineHeight: "20px",
            color: colors.midnight[100]
        }
    },
    overrides: {
        MuiAutocomplete: {
            option: {
                "&:hover": {
                    backgroundColor: colors.yellow.dark
                },
                '&[data-focus="true"]': {
                    backgroundColor: colors.yellow[100]
                }
            }
        },
        MuiButton: {
            root: {
                fontSize: 14,
                fontWeight: 600,
                padding: "8px 24px",
                borderRadius: 21
            },
            outlined: {
                padding: "8px 24px"
            },
            contained: {
                boxShadow: "none"
            },
            containedPrimary: {
                color: colors.yellow.dark,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: colors.midnight["100"],
                borderRadius: 21,
                "&:hover": {
                    color: colors.grey.dark,
                    backgroundColor: colors.yellow[100]
                },
                "&:active": {
                    backgroundColor: colors.yellow.dark,
                    boxShadow: "none"
                }
            },
            outlinedPrimary: {
                "&:hover": {
                    color: colors.grey.dark,
                    backgroundColor: colors.yellow[100]
                },
                "&:active": {
                    backgroundColor: colors.yellow.dark
                }
            }
        },
        MuiPaper: {
            elevation2: {
                boxShadow: "0 1px 5px 0 rgba(192, 179, 167, 0.5)"
            },
            rounded: {
                borderRadius: 10
            }
        },
        MuiChip: {
            root: {
                fontSize: 12,
                fontWeight: 400,
                textTransform: "capitalize",
                backgroundColor: colors.grey.white,
                boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)"
            },
            outlined: {
                border: `1px solid ${colors.clay[100]}`,
                backgroundColor: colors.clay[10],
                boxShadow: "none",
                "$clickable&:hover": {
                    border: `1px solid ${colors.zest[100]}`,
                    backgroundColor: colors.zest[50]
                }
            }
        },
        MuiTablePagination: {
            selectRoot: {
                color: colors.grey.dark,
                borderBottom: "1px solid",
                borderBottomColor: colors.grey.greydivs,
                fontSize: 12
            },
            select: {
                paddingRight: 24
            }
        },
        MuiCardContent: {
            root: {
                paddingTop: 15,
                paddingRight: 15,
                paddingBottom: 15,
                paddingLeft: 15,
                "&:last-child": {
                    paddingBottom: 15
                },
                "@media (min-width:600px)": {
                    paddingTop: 15,
                    paddingRight: 15,
                    paddingBottom: 15,
                    paddingLeft: 15
                }
            }
        },
        MuiIconButton: {
            colorSecondary: {
                color: colors.grey.dark
            }
        },
        MuiSvgIcon: {
            colorPrimary: {
                color: colors.red[100]
            },
            colorSecondary: {
                color: colors.grey.dark
            },
            colorAction: {
                color: colors.grey.greydivs
            }
        },
        MuiTableCell: {
            paddingNone: {
                "&:last-child": {
                    paddingLeft: 8
                }
            }
        },
        MuiTab: {
            root: {
                textTransform: "capitalize",
                fontSize: 14,
                fontWeight: 400,
                color: colors.midnight[100],
                "&$selected": {
                    color: colors.midnight[100]
                },
                "@media (min-width:600px)": {
                    fontSize: 14
                }
            }
        },
        MuiPrivateTabIndicator: {
            root: {
                height: 3
            }
        },
        MuiTabIndicator: {
            root: {
                height: 3
            }
        },
        MuiRadio: {
            colorSecondary: {
                color: colors.midnight[100],
                "&$checked": {
                    color: colors.midnight[100]
                }
            }
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    }
});

export default theme;
