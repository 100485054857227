import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    select: {
        marginLeft: 10,
        marginRight: 20,
        fontSize: 12,
        fontWeight: 600
    },
    pages: {
        fontWeight: 600,
        marginRight: 10
    },
    button: {
        borderColor: theme.status.midnight[100],
        borderStyle: "solid",
        fontSize: 20,
        minWidth: 40,
        padding: "2px 8px"
    },
    buttonLeft: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 0,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1
    },
    buttonRight: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 2,
        borderLeftWidth: 0,
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1
    }
});

function PaginateControl({
    t,
    classes,
    perPage,
    perPageOptions,
    page,
    pages,
    onPrev,
    onNext,
    onChangePerPage
}) {
    return (
        <div className={classes.root}>
            <Typography variant="caption">
                {t("pagination.results_per_page")}
            </Typography>
            <Select
                className={classes.select}
                value={perPage}
                onChange={onChangePerPage}
            >
                {perPageOptions.map((option, i) => (
                    <MenuItem value={option} key={i}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
            <Typography className={classes.pages} variant="caption">
                {t("pagination.page_of_pages", { page, pages })}
            </Typography>
            <Button
                disabled={page === 1}
                className={classNames(classes.button, classes.buttonLeft)}
                onClick={onPrev}
            >
                <KeyboardArrowLeft />
            </Button>
            <Button
                disabled={page === pages}
                className={classNames(classes.button, classes.buttonRight)}
                onClick={onNext}
            >
                <KeyboardArrowRight />
            </Button>
        </div>
    );
}

export default withTranslation()(withStyles(styles)(PaginateControl));
