// Polyfills
import "./polyfills/index";
//React
import React from "react";
import {render} from "react-dom";
// Redux
import {Provider} from "react-redux";
import store from "redux/store/store";
//Components
import App from "./App";
// 3rd party components
import {I18nextProvider} from "react-i18next";
import i18n from "services/i18n";
// DnD
import {DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
//Material ui
import {MuiThemeProvider} from "@material-ui/core/styles";
import theme from "./styles/MuiTheme";


render(
    <Provider store={store}>
        <DndProvider  backend={HTML5Backend}>
            <I18nextProvider i18n={i18n}>
                <MuiThemeProvider theme={theme}>
                    <App/>
                </MuiThemeProvider>
            </I18nextProvider>
        </DndProvider >
    </Provider>,
    document.querySelector("#root")
);
