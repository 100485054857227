import React, { Component } from "react";

//Third parties libs
import PropTypes from "prop-types";
import numeral from "numeral";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

// MUI
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Components
import TreeInfoPanelChart from "components/Tree/TreeInfoPanelChart";
import TreeInfoPanelTable from "components/Tree/TreeInfoPanelTable";
import TreeInfoPanelCarousel from "components/Tree/TreeInfoPanelCarousel";
import TreeInfoPanelTabs from "components/Tree/TreeInfoPanelTabs";

import {
    PANEL_ANIMATION_TIME,
    TAB_TREEVIEW_UNDERSTAND_FEATURES,
    TAB_TREEVIEW_UNDERSTAND_BRANDS,
    TAB_TREEVIEW_UNDERSTAND_PRODUCTS
} from "constants/static";

// Shared components
import theme from "../../styles/MuiTheme";

const StyledPanel = styled.div`
    width: ${props => (props.isOpenPanel ? "100%" : "20%")};
    box-sizing: border-box;
    background-color: ${theme.status.grey.white};
    border-left: 0.5px solid ${theme.status.grey.greybox};
    border-top: 0.5px solid ${theme.status.grey.greybox};
    margin-left: 12px;
    color: ${theme.status.grey.dark};
    position: absolute;
    height: 100%;
    right: 0px;
    transition: width ${PANEL_ANIMATION_TIME}s ease-out;
`;

const StyledPanelHeader = styled.div`
    text-align: ${props => (props.isOpenPanel ? "center" : "left")};
    font-size: 0.75rem;
    font-weight: 600;
    padding: 10px 6px 0px 6px;
    margin: 14px 0px 14px 0px;
`;

const StyledTab = styled.div`
    position: relative;
    width: 100%;
    padding: 6px 0 6px 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-size: 0.875rem;
`;

const StyledTitle = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 20px;
`;

const StyledList = styled.div`
    width: ${props => (props.isOpenPanel ? "auto" : "calc(100%/3)")};
    padding: ${props =>
        props.isModal ? "0" : props.isOpenPanel ? "20px 60px 0px 60px" : "0px"};
`;

const StyledPanelRow = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    margin: 0px 8px 0px 8px;
    padding: 2px 0;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
`;
const StyledPanelRowCell = styled.div`
    text-align: left;
`;

const StyledProductPanel = styled.div`
    height: 100%;
`;
const StyledScrollableList = styled.div`
    height: calc(100% - 150px);
    overflow-y: auto;
    margin-right: 6px;
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        width: 5px;
        border-radius: 100px;
        background-color: ${theme.status.grey.greydivs};
    }
`;

const StyledBlueLine = styled.div`
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: ${theme.status.blue.dark};
    height: 2px;
`;

const StyledArrow = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    left: ${props => (props.isOpenPanel ? "0px" : "-32px")};
    z-index: 1;
    width: 30px;
    height: 46px;
    margin: 0 1em 1em 0;
    justify-content: flex-end;
    align-items: center;
    background-color: ${theme.status.grey.white};
    border: 1px solid ${theme.status.grey.greybox};
    border-bottom-left-radius: 46px;
    border-top-left-radius: 46px;

    transform: rotate(${props => (props.isOpenPanel ? "180deg" : "0deg")});

    &:hover {
        cursor: pointer;
        background-color: ${theme.status.yellow.dark};
        border-color: ${theme.status.yellow.dark};
    }

    i {
        font-size: 0.785rem;
        margin-left: ${props => (props.isOpenPanel ? "5px" : "0px")};
    }
`;

const orderDictionary = dict => {
    const results = [];
    const ordered = Object.keys(dict).sort(function(a, b) {
        return dict[b] - dict[a];
    });

    ordered.forEach(key => {
        results.push({ name: key, value: dict[key] });
    });
    return results;
};

export class TreeInfoPanel extends Component {
    static propTypes = {
        activeNodeInfo: PropTypes.shape({
            terms: PropTypes.array.isRequired,
            brands: PropTypes.array.isRequired,
            products: PropTypes.array.isRequired,
            additionalAttrs: PropTypes.array.isRequired
        }).isRequired
    };

    state = {
        tab: TAB_TREEVIEW_UNDERSTAND_FEATURES,
        isOpenPanel: this.props.isOpenPanel
    };

    openPanel = e => {
        this.setState({
            isOpenPanel: !this.state.isOpenPanel,
            tab: TAB_TREEVIEW_UNDERSTAND_FEATURES
        });

        if (!this.state.isOpenPanel) {
            setTimeout(this.updatePanel, PANEL_ANIMATION_TIME * 1000);
        }
    };

    updatePanel = () => {
        this.forceUpdate();
    };

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    };

    renderTab(
        t,
        tab,
        tabs,
        activeNodeInfo,
        activeNodeNameUserDefined,
        isOpenPanel,
        isModal
    ) {
        switch (tab) {
            case TAB_TREEVIEW_UNDERSTAND_FEATURES:
                return (
                    <FeatureTable
                        t={t}
                        features={activeNodeInfo.terms}
                        title={activeNodeNameUserDefined}
                        isOpenPanel={isOpenPanel}
                        isModal={isModal}
                    />
                );
            case TAB_TREEVIEW_UNDERSTAND_BRANDS:
                return (
                    <BrandChart
                        t={t}
                        products={activeNodeInfo.products}
                        title={activeNodeNameUserDefined}
                        isOpenPanel={isOpenPanel}
                        isModal={isModal}
                    />
                );
            case TAB_TREEVIEW_UNDERSTAND_PRODUCTS:
                return (
                    <ProductTable
                        t={t}
                        products={activeNodeInfo.products}
                        title={activeNodeNameUserDefined}
                        isOpenPanel={isOpenPanel}
                        isModal={isModal}
                    />
                );
            default:
                return (
                    <AdditionalAttrTable
                        t={t}
                        data={tabs[tab].data}
                        title={activeNodeNameUserDefined}
                        isOpenPanel={isOpenPanel}
                        isModal={isModal}
                    />
                );
        }
    }

    render() {
        const {
            t,
            activeNodeInfo,
            activeNodeNameUserDefined,
            isModal
        } = this.props;
        const { tab, isOpenPanel } = this.state;

        let tabs = [
            {
                name: t("info_panel.features"),
                description: t("info_panel.feature_headline"),
                rows: [
                    [t("info_panel.feature"), t("info_panel.feature_score")],
                    ...activeNodeInfo.terms.map(el => [
                        el.term,
                        numeral(el.tfidf).format("0,0")
                    ])
                ]
            },
            {
                name: t("info_panel.brands"),
                description: t("info_panel.brand_headline"),
                rows: [
                    [t("info_panel.brands"), t("info_panel.skus")],
                    ...orderDictionary(activeNodeInfo.brands).map(el => [
                        el.name,
                        numeral(el.value).format("0,0")
                    ])
                ]
            },
            {
                name: t("info_panel.products"),
                description: t("info_panel.product_headline"),
                rows: [
                    [t("info_panel.product_desc")],
                    ...activeNodeInfo.products.map(el => [el.label])
                ]
            }
        ];

        // Extend tabs with additional attributes
        if (activeNodeInfo.additionalAttrs.length) {
            tabs = tabs.concat(
                activeNodeInfo.additionalAttrs
                    .filter(el => el.values)
                    .map(el => ({
                        name: t(`attributes.${el.name.toLowerCase()}`, {
                            defaultValue: el.name
                        }),
                        description: t("info_panel.attr_headline"),
                        rows: [
                            [t("info_panel.value"), t("info_panel.index")],
                            ...el.values.map(val => [
                                val.name,
                                numeral(val.index).format("0.00")
                            ])
                        ],
                        data: el.values
                    }))
            );
        }

        return (
            <StyledPanel isOpenPanel={isOpenPanel}>
                {!isModal && (
                    <StyledArrow
                        isOpenPanel={isOpenPanel}
                        onClick={this.openPanel}
                    >
                        <ChevronLeftIcon />
                    </StyledArrow>
                )}
                {!isOpenPanel && <StyledBlueLine />}
                {!isOpenPanel && (
                    <TreeInfoPanelCarousel isOpenPanel={isOpenPanel}>
                        {tabs
                            .filter(el => el.name)
                            .map((el, index) => (
                                <MiniTable key={index} data={el} />
                            ))}
                    </TreeInfoPanelCarousel>
                )}

                {isOpenPanel && (
                    <div>
                        <TreeInfoPanelTabs
                            tab={tab}
                            tabs={tabs}
                            handleTabChange={this.handleTabChange}
                        />
                        {this.renderTab(
                            t,
                            tab,
                            tabs,
                            activeNodeInfo,
                            activeNodeNameUserDefined,
                            isOpenPanel,
                            isModal
                        )}
                    </div>
                )}
            </StyledPanel>
        );
    }
}

const BrandChart = ({
    activeNodeName,
    isOpenPanel,
    isModal,
    brands,
    products,
    features,
    t
}) => {
    return (
        <StyledList isModal={isModal} isOpenPanel={isOpenPanel}>
            {!isModal && <StyledTitle>{t("common.selected_group")}{activeNodeName}</StyledTitle>}
            <StyledPanelHeader isOpenPanel={isOpenPanel}>
                {t("info_panel.brand_headline")}
            </StyledPanelHeader>
            <TreeInfoPanelChart
                t={t}
                isOpenPanel={isOpenPanel}
                features={features}
                products={products}
                brands={brands}
            />
        </StyledList>
    );
};

function renderPlainText(value) {
    return value;
}
function renderNumber(format) {
    return function(value) {
        return numeral(value).format(format);
    };
}
let productColumns = [
    { id: "productId", label: "info_panel.sku", render: renderPlainText },
    {
        id: "label",
        label: "info_panel.product_desc",
        render: renderPlainText
    },
    {
        id: "brand",
        label: "info_panel.brand",
        render: renderPlainText
    },
    {
        id: "total_sales",
        label: "info_panel.kpi_sales",
        render: renderNumber("$0a")
    },
    {
        id: "total_units",
        label: "info_panel.kpi_units",
        render: renderNumber("0a")
    },
    {
        id: "product_loyalty",
        label: "info_panel.kpi_loyalty",
        render: renderNumber("0.00%")
    },
    {
        id: "customer_penetration",
        label: "info_panel.kpi_customer_penetration",
        render: renderNumber("0.00%")
    }
];
const ProductTable = ({ title, isModal, isOpenPanel, products, t }) => {
    return (
        <StyledList isModal={isModal} isOpenPanel={isOpenPanel}>
            <StyledProductPanel>
                {!isModal && <StyledTitle>{t("common.selected_group")}{title}</StyledTitle>}
                <TreeInfoPanelTable
                    t={t}
                    data={products}
                    columns={productColumns}
                    initialSortBy="brand"
                />
            </StyledProductPanel>
        </StyledList>
    );
};

let additionalAttrColumns = [
    { id: "name", label: "info_panel.value", render: renderPlainText },
    {
        id: "percentageAll",
        label: "info_panel.percentage_all",
        render: renderNumber("0.00%")
    },
    {
        id: "percentageGroup",
        label: "info_panel.percentage_group",
        render: renderNumber("0.00%")
    },
    {
        id: "index",
        label: "info_panel.index",
        render: renderNumber("0.00")
    }
];

const AdditionalAttrTable = ({ title, isModal, isOpenPanel, data, t }) => (
    <StyledList isModal={isModal} isOpenPanel={isOpenPanel}>
        <StyledProductPanel>
            {!isModal && <StyledTitle>{t("common.selected_group")}{title}</StyledTitle>}
            <TreeInfoPanelTable
                t={t}
                data={data}
                columns={additionalAttrColumns}
                initialSortBy="index"
            />
        </StyledProductPanel>
    </StyledList>
);

let featureColumns = [
    {
        id: "term",
        label: "info_panel.feature",
        render: renderPlainText
    },
    {
        id: "tfidf",
        label: "info_panel.feature_score",
        render: renderNumber("0,0")
    }
];
const FeatureTable = ({ title, isModal, isOpenPanel, features, t }) => {
    return (
        <StyledList isModal={isModal} isOpenPanel={isOpenPanel}>
            <StyledProductPanel>
                {!isModal && <StyledTitle>{t("common.selected_group")}{title}</StyledTitle>}
                <TreeInfoPanelTable
                    t={t}
                    data={features}
                    columns={featureColumns}
                    initialSortBy="term"
                />
            </StyledProductPanel>
        </StyledList>
    );
};

const MiniTable = ({ data }) => (
    <div>
        <StyledTab>{data.name}</StyledTab>
        {data.description && (
            <StyledPanelHeader>{data.description}</StyledPanelHeader>
        )}
        <StyledScrollableList>
            {data.rows.length &&
                data.rows.map((row, index) => (
                    <StyledPanelRow
                        key={index}
                        fontWeight={index > 0 ? "400" : "600"}
                    >
                        {row.map((el, i) => (
                            <StyledPanelRowCell key={i}>
                                {el}
                            </StyledPanelRowCell>
                        ))}
                    </StyledPanelRow>
                ))}
        </StyledScrollableList>
    </div>
);

MiniTable.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        rows: PropTypes.array.isRequired
    })
};

TreeInfoPanel.propTypes = {
    activeNodeName: PropTypes.string,
    activeNodeNameUserDefined: PropTypes.string,
    activeNodeInfo: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(TreeInfoPanel);
