import {call, put, take} from "redux-saga/effects";
import api from "../../../api";
import {fetchDMT} from "../../../services/fetch";
// Reducer name
const reducerName = "dmtAttributeExport";
export const POST_DMT_EXPORT_REQUEST = `${reducerName}/POST_DMT_EXPORT_REQUEST`;
export const POST_DMT_EXPORT_SUCCESS = `${reducerName}/POST_DMT_EXPORT_SUCCESS`;
export const POST_DMT_EXPORT_FAILURE = `${reducerName}/POST_DMT_EXPORT_FAILURE`;
export function postDMTExportRequest(attributeImportValues) {
    return {
        type: POST_DMT_EXPORT_REQUEST,
        payload: attributeImportValues
    };
}

export function postDMTExportRequestSuccess(res) {
    return {
        type: POST_DMT_EXPORT_SUCCESS,
        payload: res.success
    };
}

export function postDMTExportRequestFailure(err) {
    return {
        type: POST_DMT_EXPORT_SUCCESS,
        payload: err
    };
}

const initialState = {
    pending: false,
    success: undefined,
    error: null
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case POST_DMT_EXPORT_REQUEST:
            return {
                ...state,
                pending: true
            };

        case POST_DMT_EXPORT_SUCCESS:
            return {
                ...state,
                success: action.payload,
                pending: false
            };

        case POST_DMT_EXPORT_FAILURE:
            return {
                ...state,
                pending: false,
                success: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}


export default reducer;
export const getSuccessStatus = state => state[reducerName].success;

export const getErrorMessage = state => state[reducerName].error;

export function* watchDMTAttributeExport() {
    while (true) {

        const payload = yield take(POST_DMT_EXPORT_REQUEST);
        const {url, type} = api["dmt.attribute.exportAttributeValues.post"]();
        try {
            const resp = yield call(fetchDMT, url, type, payload);
            yield put(
                postDMTExportRequestSuccess({
                    success: resp
                })
            );
        } catch (e) {
            yield put(
                postDMTExportRequestFailure({
                    error: e.message
                })
            );
        }
    }
}
