import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import StepperItem from "./StepperItem";

const styles = theme => ({
    stepper: {
        position: "relative",
        margin: 0,
        padding: 0,
        display: "block",
        width: "100%",
        whiteSpace: "nowrap",
        border: `1px solid ${theme.status.grey.greydivs}`,
        borderRadius: 4,
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: theme.status.grey.greybox,
        counterReset: "stepper",
        listStyleType: "none"
    },
    stepperDisabled: {
        display: "none"
    },
    spanStepperBefore: {
        position: "absolute",
        left: 0,
        width: "100%",
        height: "100%",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderLeft: `1px solid ${theme.status.grey.greybox}`,
        backgroundColor: theme.status.grey.white,
        transform: "translateX(0)",
        transition: "transform 500ms ease-in-out"
    }
});

const getActiveIndex = steps => {
    return steps.reduce((prev, curr, i) => {
        return curr.active ? i + 1 : prev;
    }, 0);
};

function getStepperStyle(steps) {
    const activeIndex = getActiveIndex(steps);
    const len = steps.length;

    return {
        borderLeft: `${activeIndex ? "1px solid #d8d8d8" : "none"}`,
        transform: `translateX(${(activeIndex / len) * 100}%`
    };
}

const Stepper = ({ classes, steps }) => (
    <ol
        className={classNames(
            {
                [classes.stepperDisabled]: steps.length === 0
            },
            classes.stepper
        )}
    >
        <span
            className={classes.spanStepperBefore}
            style={getStepperStyle(steps)}
        />
        {steps.map((item, index) => (
            <StepperItem
                key={index}
                item={item}
                index={index}
                len={steps.length}
            />
        ))}
    </ol>
);

Stepper.propTypes = {
    steps: PropTypes.array.isRequired
};

export default withStyles(styles)(Stepper);
