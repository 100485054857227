import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DialogComponent from "../../uiComponents/Dialog/DialogComponent";

const SavedSetDialogConfirm = ({ t, isOpen, handleClose, handleAccept }) => (
    <DialogComponent
        onClose={handleClose}
        onSave={handleAccept}
        isOpen={isOpen}
        padding
        maxWidth="sm"
        centre
        title={t("tree.confirm_saved_set_dialog_title")}
    >
        <div>{t("tree.confirm_saved_set_overwrite")}</div>
    </DialogComponent>
);

SavedSetDialogConfirm.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleAccept: PropTypes.func.isRequired
};

SavedSetDialogConfirm.defaultProps = {
    isOpen: false
};

export default withTranslation()(SavedSetDialogConfirm);
