import { call, take, put, select } from "redux-saga/effects";
import i18n from "i18next";

import api from "api";
import { fetchDMT } from "services/fetch";

import { selectDMTIntegrationStores } from "redux/modules/config";
import { openSnackbar } from "../snackbar/open";
import { openAlert } from "../alerts";

const reducerName = "dmtStoreRealization";

const FETCH_REALIZATION = `${reducerName}/FETCH_REALIZATION`;
const REQUEST = `${reducerName}/REQUEST_REALIZATION`;
const RECEIVE = `${reducerName}/RECEIVE_REALIZATION`;
const ERROR = `${reducerName}/ERROR`;

export function fetchRealization(data) {
    return {
        type: FETCH_REALIZATION,
        payload: data
    };
}

export function requestRealization() {
    return {
        type: REQUEST
    };
}

export function receiveRealization(res) {
    return {
        type: RECEIVE,
        payload: res
    };
}

export function errorRealization(err) {
    return {
        type: ERROR,
        payload: err
    };
}

const initialState = {
    loading: false,
    error: null,
    data: null
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST:
            return {
                ...state,
                loading: true
            };
        case RECEIVE:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}

export default reducer;

export const selectRealization = state => state[reducerName].data;
export const selectRealizationLoading = state => state[reducerName].loading;
export const selectRealizationError = state => state[reducerName].error;

const REALIZATION_ERROR = {
    LIMIT_EXCEEDED: "LIMIT_EXCEEDED",
    INVALID_INPUT: "INVALID_INPUT"
};

function* handleResultError(result) {
    const title = i18n.t("product_import.realization_error");
    let message;
    switch (result.errorMessage) {
        case REALIZATION_ERROR.LIMIT_EXCEEDED:
            const dmtStoresConfig = yield select(selectDMTIntegrationStores);
            const configLimit = dmtStoresConfig.limit || 5000;
            message = i18n.t("product_import.realization_limit_exceeded", {
                limit: configLimit
            });
            yield put(openAlert(title, message));
            break;
        case REALIZATION_ERROR.INVALID_INPUT: {
            message = i18n.t("product_import.realization_invalid_input");
            yield put(openAlert(title, message));
            break;
        }
        default:
            message = result.errorMessage;
            yield put(openAlert(title, message));
            break;
    }
}

function getRealizationQuery(payload) {
    return Object.entries(payload).reduce((prev, curr) => {
        if (prev) {
            prev += "&";
        }
        const [key, value] = curr;
        prev = `${prev}${key}=${encodeURI(value)}`;
        return prev;
    }, "");
}

function ensureRegionNames(result) {
    if (!result || !Array.isArray(result.verifiedStores)) return result;
    return {
        ...result,
        verifiedStores: result.verifiedStores.map(el => ({
            ...el,
            region: el.region || "UNKNOWN"
        }))
    };
}

export function* watchDMTStoreRealization() {
    while (true) {
        const { payload } = yield take(FETCH_REALIZATION);
        const query = getRealizationQuery(payload);
        const { url, type, error } = api["dmt.store.realize"]();
        const urlQuery = query ? `${url}?${query}` : url;
        yield put(requestRealization());
        try {
            const result = yield call(fetchDMT, urlQuery, type);
            if (result.status === "ERROR") {
                yield* handleResultError(result);
            }
            yield put(receiveRealization(ensureRegionNames(result)));
        } catch (e) {
            yield put(errorRealization(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}
