// redux-saga
import { call, take, put } from "redux-saga/effects";
// Redux factory
import createFetchRedux from "../createFetchRedux";
// Api configuration
import api from "api";
// Fetch service
import fetch from "services/fetch";
// Redux modules
import { openSnackbar } from "../snackbar/open";

const reducerName = "exportProducts";
const { reducer, actions, selectors } = createFetchRedux(reducerName);
// Reducer
export default reducer;
// Action types
export const EXPORT_PRODUCTS = `${reducerName}/EXPORT_PRODUCTS`;

export function downloadExport(id) {
    return {
        type: EXPORT_PRODUCTS,
        payload: id
    };
}

export function exportProductsFileDownload(res, id) {
    const blob = new Blob([res], {
        type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    const anchorEl = document.createElement("a");
    anchorEl.style = "display: none";
    document.body.appendChild(anchorEl);
    const url = window.URL.createObjectURL(blob);
    anchorEl.href = url;
    anchorEl.download = `ExportProducts-Project-${id}.xlsx`;
    anchorEl.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(anchorEl);
}

// Selectors
export const selectExportProductsResult = selectors.selectReduxData;
export const selectExportProductsLoading = selectors.selectReduxLoading;

// Sagas
export function* watchExportProducts() {
    while (true) {
        const { payload: id } = yield take(EXPORT_PRODUCTS);
        const { url, type, error } = api["products.export.products.get"](id);
        try {
            const result = yield call(
                fetch,
                url,
                type,
                null,
                null,
                null,
                "binary"
            );
            yield put(actions.receive(result));
            yield call(exportProductsFileDownload, result, id);
        } catch (e) {
            yield put(actions.error(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}
