import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../styles/MuiTheme";
import { withTranslation } from "react-i18next";

const StyledButton = styled.button`
    padding: 14px 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
`;

const StyledIconWrapper = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 17px;
    padding: 2px;
    box-shadow: ${theme.shadows[1]};
    background-color: ${theme.status.grey.white};
    &:hover {
        background-color: ${theme.status.yellow.dark};
    }
`;

const FullscreenIcon = styled.div`
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 16px;
`;

const ExpandIcon = styled.i`
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 15px;
    ::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 6px;
        left: 3px;
        top: 7px;
        background-color: ${theme.status.grey.dark};
    }
    ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: 5px solid ${theme.status.grey.dark};
    }
    transform-origin: ${props => (props.rotate ? `6px 11px` : `17px 20px`)};
    transform: ${props => (props.rotate ? `rotate(-135deg)` : `rotate(45deg)`)};
`;

export const TreeViewExpandButton = ({ t, onClickTreeViewExpand }) => (
    <StyledButton
        title={t("tree.go_fullscreen")}
        onClick={onClickTreeViewExpand}
    >
        <StyledIconWrapper>
            <FullscreenIcon>
                <ExpandIcon />
                <ExpandIcon rotate="true" />
            </FullscreenIcon>
        </StyledIconWrapper>
    </StyledButton>
);

TreeViewExpandButton.propTypes = {
    onClickTreeViewExpand: PropTypes.func.isRequired
};

export default withTranslation()(TreeViewExpandButton);
