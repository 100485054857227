// redux-saga
import { call, take } from "redux-saga/effects";

// Redux saga helper
import { fetchEntityData } from "../../store/sagaHelpers";

// Api configuration
import api from "api";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "feedbackAnswers";
const { reducer, actions, selectors } = createFetchRedux(reducerName);

export default reducer;

// Action types
export const FETCH_FEEDBACK_ANSWERS = `${reducerName}/FETCH_FEEDBACK_ANSWERS`;

// Actions
export function fetchSubmitFeedbackAnswers(data) {
    return {
        type: FETCH_FEEDBACK_ANSWERS,
        payload: data
    };
}

export const selectFeedbackAnswersData = selectors.selectReduxData;
export const selectFeedbackAnswersStatus = selectors.selectReduxStatus;

export function* watchFeedbackAnswers() {
    while (true) {
        const { payload } = yield take(FETCH_FEEDBACK_ANSWERS);
        yield call(
            fetchEntityData,
            actions,
            api["feedback.answers.post"],
            payload
        );
    }
}
