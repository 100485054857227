import React, { Component } from "react";
import { connect } from "react-redux";

import {
    // constants
    USER_PREFIX,
    // selectors
    selectSavedSetNodeNames,
    selectSavedSetNeedUnitsWithUserDefined,
    // actions
    updateNeedUnits,
    updateNodeName
} from "../../redux/modules/tree/savedset";

import CreateNeedUnit from "./CreateNeedUnit";

const mapStateToProps = (state, props) => ({
    // saved set need unit list including user defined
    savedSetNeedUnitsWithUserDefined: selectSavedSetNeedUnitsWithUserDefined(
        state
    ),
    // node names in working saved set
    savedSetNodeNames: selectSavedSetNodeNames(state)
});

const mapDispatchToProps = {
    updateNeedUnits,
    updateNodeName
};

class CreateNeedUnitContainer extends Component {
    rebuildNeedUnits(nodesAdd) {
        const { savedSetNeedUnitsWithUserDefined } = this.props;
        return savedSetNeedUnitsWithUserDefined.concat(nodesAdd); // add new node
    }

    handleSave = needUnitName => {
        const node = `${USER_PREFIX}${needUnitName}-${parseInt(
            Math.random() * 999
        )}`;
        const newNeedUnit = {
            name: needUnitName,
            node,
            products: []
        };
        const newNeedUnits = this.rebuildNeedUnits([newNeedUnit]);
        const { updateNeedUnits, updateNodeName } = this.props;
        updateNeedUnits(newNeedUnits);
        updateNodeName(node, needUnitName);
    };

    render() {
        return <CreateNeedUnit handleSave={this.handleSave} />;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateNeedUnitContainer);
