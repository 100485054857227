const STATUS = {
    INIT: "INIT",
    LOADING: "LOADING",
    COMPLETE: "COMPLETE",
    ERROR: "ERROR",
    RESET: "RESET"
};

const TYPE_RESET = "RESET";
const TYPE_FETCH = "FETCH";
const TYPE_FETCH_ONE = "FETCH_ONE";
const TYPE_REQUEST = "REQUEST";
const TYPE_RECEIVE = "RECEIVE";
const TYPE_RECEIVE_ONE = "RECEIVE_ONE";
const TYPE_RECEIVE_EMPTY = "RECEIVE_EMPTY";
const TYPE_ERROR = "ERROR";

const defaultInitialState = {
    id: null,
    loading: false,
    loaded: false,
    data: null,
    error: null,
    status: STATUS.INIT
};

export default function createFetchRedux(reducerName, initialState) {
    // Initial state override
    initialState = {
        ...defaultInitialState,
        ...initialState
    };

    // Action types
    const RESET = `${reducerName}/${TYPE_RESET}`;
    const FETCH = `${reducerName}/${TYPE_FETCH}`;
    const FETCH_ONE = `${reducerName}/${TYPE_FETCH_ONE}`;
    const REQUEST = `${reducerName}/${TYPE_REQUEST}`;
    const RECEIVE = `${reducerName}/${TYPE_RECEIVE}`;
    const RECEIVE_ONE = `${reducerName}/${TYPE_RECEIVE_ONE}`;
    const RECEIVE_EMPTY = `${reducerName}/${TYPE_RECEIVE_EMPTY}`;
    const ERROR = `${reducerName}/${TYPE_ERROR}`;

    const actionTypes = {
        FETCH: FETCH,
        FETCH_ONE: FETCH_ONE,
        REQUEST: REQUEST,
        RECEIVE: RECEIVE,
        RECEIVE_ONE: RECEIVE_ONE,
        RECEIVE_EMPTY: RECEIVE_EMPTY,
        ERROR: ERROR
    };

    // Action creators
    const actions = {
        fetch(payload) {
            return {
                type: FETCH,
                payload
            };
        },
        fetchOne(payload) {
            return {
                type: FETCH_ONE,
                payload
            };
        },
        request() {
            return {
                type: REQUEST
            };
        },
        receive(data) {
            return {
                type: RECEIVE,
                data
            };
        },
        receiveOne(data) {
            return {
                type: RECEIVE_ONE,
                payload: {
                    id: data.id,
                    item: data
                }
            };
        },
        receiveEmpty() {
            return {
                type: RECEIVE_EMPTY
            };
        },
        error(error) {
            return {
                type: ERROR,
                error
            };
        },
        reset() {
            return {
                type: RESET
            };
        }
    };

    function reducer(state = initialState, action) {
        switch (action.type) {
            case REQUEST:
                return {
                    ...state,
                    status: STATUS.LOADING,
                    loading: true
                };
            case RECEIVE:
                return {
                    ...state,
                    data: action.data,
                    status: STATUS.COMPLETE,
                    loaded: true,
                    loading: false
                };
            case RECEIVE_EMPTY: {
                return {
                    ...state,
                    status: STATUS.COMPLETE,
                    loaded: true,
                    loading: false
                };
            }
            case RECEIVE_ONE:
                const { item, id } = action.payload;
                let { data } = state;
                data = Array.isArray(data) ? data : [];
                const hasItem = data.filter(el => el.id === id).length > 0;
                const newData = hasItem
                    ? data.map(el => (el.id === id ? { ...el, ...item } : el))
                    : [...data, item];
                return {
                    ...state,
                    status: STATUS.COMPLETE,
                    loading: false,
                    loaded: true,
                    id,
                    data: newData
                };
            case ERROR:
                return {
                    ...state,
                    status: STATUS.ERROR,
                    loading: false,
                    error: action.error
                };
            case RESET:
                return {
                    ...initialState,
                    status: STATUS.RESET
                };
            default:
                return state;
        }
    }

    // Selectors
    const selectRedux = state => state[reducerName];
    const selectReduxData = state => selectRedux(state).data;
    const selectReduxId = state => selectRedux(state).id;
    const selectReduxLoading = state => selectRedux(state).loading;
    const selectReduxLoaded = state => selectRedux(state).loaded;
    const selectReduxError = state => selectRedux(state).error;
    const selectReduxStatus = state => selectRedux(state).status;

    const selectors = {
        selectReduxData,
        selectReduxId,
        selectReduxLoaded,
        selectReduxLoading,
        selectReduxError,
        selectReduxStatus
    };

    return {
        actionTypes,
        actions,
        reducer,
        selectors
    };
}
