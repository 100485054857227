import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIconOutlined from "@material-ui/icons/InfoOutlined";

const styles = theme => ({
    root: {
        display: "inline-block",
        padding: 4
    },
    tooltip: {
        background: theme.status.grey.white,
        opacity: 1,
        color: theme.status.grey.dark,
        padding: 15,
        boxShadow:
            "0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.1)",
        fontSize: "0.75em",
    },
    icon: {
        color: theme.status.grey.dark,
        fontSize: 16
    }
});

const InfoIcon = ({ classes, tooltip, tooltipPlacement }) => (
    <div className={classes.root}>
        <Tooltip
            title={tooltip}
            placement={tooltipPlacement}
            classes={{ tooltip: classes.tooltip }}
        >
            <InfoIconOutlined
                className={classNames(classes.icon, classes.iconInfo)}
            />
        </Tooltip>
    </div>
);

InfoIcon.propTypes = {
    tooltip: PropTypes.string,
    tooltipPlacement: PropTypes.string
};

export default withStyles(styles)(InfoIcon);
