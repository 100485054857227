import React, {useCallback} from "react";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AttributeAutocomplete from "./AttributeAutocomplete";

const styles = theme => ({
    subheading: {
        marginTop: 30,
        paddingBottom: 15
    },
    paper: {
        position: "relative"
    },
    section: {
        position: "relative",
        padding: 10,
        backgroundColor: theme.status.clay[20]
    },
    buttonRow: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 20
    },
    legend: {
        display: "flex"
    },
    legendItem: {
        position: "relative",
        paddingLeft: 12,
        paddingRight: 16,
        fontSize: 13,
        "&:before": {
            content: "''",
            position: "absolute",
            top: 4,
            left: 0,
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "#59B0E1"
        },
        "&:first-child:before": {
            backgroundColor: "#2643BC"
        }
    }
});

function AttributeLegend({t, classes}) {
    return (
        <div className={classes.legend}>
            <div className={classes.legendItem}>
                {t("product_import.attribute_type_user")}
            </div>
            <div className={classes.legendItem}>
                {t("product_import.attribute_type_retailer")}
            </div>
        </div>
    );
}

export function AttributeList({
                                  t,
                                  classes,
                                  attributeList,
                                  attributeValues,
                                  attributeError,
                                  updateAttributeValues,
                                  userInfo
                              }) {
    const handleChange = useCallback(
        newValues => {
            updateAttributeValues(newValues);
        },
        [updateAttributeValues]
    );

    if (attributeError) {
        return <div>{attributeError}</div>;
    }
    return (
        <Paper elevation={0} className={classes.paper}>
            <Typography
                variant="h3"
                align="center"
                className={classes.subheading}
            >
                {t("product_import.select_your_attributes")}
            </Typography>
            <AttributeAutocomplete
                handleChange={handleChange}
                attributeValues={attributeValues}
                attributeList={attributeList}
                retailer={userInfo.userProfile.retailerShortName}
            />
            <AttributeLegend t={t} classes={classes}/>
        </Paper>
    );
}

export default withTranslation()(withStyles(styles)(AttributeList));
