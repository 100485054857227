// Reselect
import { createSelector } from "reselect";
// redux-saga
import { call, put, take, takeEvery } from "redux-saga/effects";
// Redux factory
import createFetchRedux from "../createFetchRedux";
// Redux saga helper
import { fetchEntity } from "../../store/sagaHelpers";
// Fetch service
import fetch from "services/fetch";
// Api configuration
import api from "api";
// Redux modules
import { openSnackbar } from "../snackbar/open";
import { fetchSubcategoryMetrics } from "../subcategories/metrics";
import { fetchSubcategoryGroups } from "../subcategories/groups";
import { selectAllowedLevelofAnalysis } from "../config";
// Reducer name
const reducerName = "products";
const { reducer, actions, selectors } = createFetchRedux(reducerName);

export default reducer;

export const FETCH_PRODUCTS = `${reducerName}/FETCH_PRODUCTS`;
export const FETCH_UPDATE_PRODUCTS = `${reducerName}/FETCH_UPDATE_PRODUCTS`;

export function fetchProducts(id) {
    return {
        type: FETCH_PRODUCTS,
        payload: id
    };
}

export function fetchUpdateProducts(id, data) {
    return {
        type: FETCH_UPDATE_PRODUCTS,
        payload: {
            id,
            data
        }
    };
}

export const setProjectProducts = actions.receive;

export const products = actions;
export const resetProducts = actions.reset;

// Selectors
export const selectAllProducts = selectors.selectReduxData;
export const selectAllProductsLoading = selectors.selectReduxLoading;
export const selectAllProductsError = selectors.selectReduxError;
export const selectAllProductsDict = createSelector(
    [selectAllProducts],
    allProducts =>
        allProducts &&
        allProducts.reduce((dict, product) => {
            dict[product.productId] = product;
            return dict;
        }, {})
);
export const selectAllProductsStatus = selectors.selectReduxStatus;

export const selectAllowedLevelOfAnalysis = createSelector(
    [selectAllProducts, selectAllowedLevelofAnalysis],
    (allProducts, analysisLevelFromConfig) => {
        if (
            allProducts &&
            Array.isArray(allProducts) &&
            analysisLevelFromConfig &&
            Array.isArray(analysisLevelFromConfig)
        ) {
            const firstProduct = allProducts[0];
            const prodAttrs = Object.keys(firstProduct).concat(
                Object.keys(firstProduct["additionalAttributes"])
            );
            return prodAttrs.filter(value =>
                analysisLevelFromConfig.includes(value)
            );
        } else {
            return [];
        }
    }
);

// Sagas
const fetchProductsEntity = fetchEntity.bind(
    null,
    products,
    api["project.products.get"]
);

export function* watchProducts() {
    yield takeEvery(FETCH_PRODUCTS, fetchProductsEntity);
}

export function* watchUpdateProducts() {
    while (true) {
        const { payload } = yield take(FETCH_UPDATE_PRODUCTS);
        const { id, data: productsData } = payload;
        const { url, type, successful, error, data } = api[
            "project.products.put"
        ](id, productsData);
        yield put(products.request());
        try {
            const result = yield call(fetch, url, type, data);
            yield put(products.receive(result));
            yield put(fetchSubcategoryMetrics(id));
            yield put(fetchSubcategoryGroups(id));
            yield put(openSnackbar(successful, "success"));
        } catch (e) {
            yield put(products.error(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}
