import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import theme from "../../styles/MuiTheme";

const StyledFormControl = styled(FormControl)`
    margin-top: 10px;
    width: 183px;
`;

const StyledInputLabel = styled(InputLabel)`
    && {
        color: ${theme.status.grey.dark} !important;
        font-size: 16px;
        margin-bottom: 4px;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    && {
        font-size: 14px;
        font-weight: 400;
    }
`;
const StyledSelect = styled(Select)`
    && {
        font-size: 14px;
        margin-top: 18px;
    }
`;

export const HierarchicalBrandSelect = ({
    t,
    isOpen,
    handleClose,
    handleOpen,
    handleChange,
    highlightBrand,
    brandList
}) => (
    <div>
        <StyledFormControl>
            <StyledInputLabel shrink={true} htmlFor="brand-select">
                {t("tree.highlight_brands")}
            </StyledInputLabel>
            <StyledSelect
                open={isOpen}
                onClose={handleClose}
                onOpen={handleOpen}
                value={highlightBrand ? highlightBrand.name : ""}
                onChange={handleChange}
                inputProps={{
                    name: "brands",
                    id: "brand-select"
                }}
            >
                <StyledMenuItem key={-1} value={"-1"}>
                    {t("tree.highlight_brands_none")}
                </StyledMenuItem>
                {brandList.map((brand, index) => (
                    <StyledMenuItem key={index} value={brand.name}>
                        {`${brand.name} - ${brand.value} ${t("common.sku")}`}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    </div>
);

HierarchicalBrandSelect.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleOpen: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    highlightBrand: PropTypes.object,
    brandList: PropTypes.array
};

HierarchicalBrandSelect.defaultProps = {
    highlightBrand: {},
    brandList: []
};

export default withTranslation()(HierarchicalBrandSelect);
