// redux-saga
import { takeEvery } from "redux-saga/effects";

// Redux saga helper
import { fetchEntity } from "../../store/sagaHelpers";

// Api configuration
import api from "api";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "feedbackQuestions";
const { reducer, actions, selectors } = createFetchRedux(reducerName);

export default reducer;

// Action types
export const FETCH_FEEDBACK_QUESTIONS = `${reducerName}/FETCH_FEEDBACK_QUESTIONS`;

// Actions
export function fetchFeedbackQuestions() {
    return {
        type: FETCH_FEEDBACK_QUESTIONS
    };
}

export const selectFeedbackQuestionsData = selectors.selectReduxData;
export const selectFeedbackQuestionsStatus = selectors.selectReduxStatus;

export const fetchFeedbackQuestionsEntity = fetchEntity.bind(
    null,
    actions,
    api["feedback.questions.get"]
);

export function* watchFeedbackQuestions() {
    yield takeEvery(FETCH_FEEDBACK_QUESTIONS, fetchFeedbackQuestionsEntity);
}
