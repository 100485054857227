import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import ParametersTimePeriod from "./ParametersTimePeriod";
import ParametersTreeList from "./ParametersTreeList";
import ParametersStoresList from "./ParametersStoresList";
import ParametersAnalysisLevel from "./ParametersAnalysisLevel";

const styles = theme => ({
    tab: {
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: 400,
        color: theme.status.grey.dark
    },
    content: {
        padding: 45
    }
});

export class ParametersDialogContent extends Component {
    static propTypes = {
        params: PropTypes.object,
        t: PropTypes.func,
        close: PropTypes.func
    };

    state = {
        value: 0
    };

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { version, t, classes } = this.props;
        const { value } = this.state;
        const hasStores =
            version &&
            Array.isArray(version.versionRequestInfo.stores) &&
            version.versionRequestInfo.stores.length;

        const hasAnalysisLevel =
            version.versionRequestInfo.analysis_level &&
            version.versionRequestInfo.analysis_level.length > 0;

        return (
            <div>
                <Tabs
                    value={value}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    variant="fullWidth"
                >
                    <Tab label={t("version.attributes_tab_timeperiod")} />
                    <Tab label={t("version.attributes_tab_trees")} />
                    {hasStores && <Tab label={t("common.stores")} />}
                    {hasAnalysisLevel && (
                        <Tab
                            label={t("version.attributes_tab_analysis_level")}
                            value={3}
                        />
                    )}
                </Tabs>
                <div className={classes.content}>
                    {value === 0 && <ParametersTimePeriod version={version} />}
                    {value === 1 && <ParametersTreeList version={version} />}
                    {hasStores && value === 2 && (
                        <ParametersStoresList version={version} />
                    )}
                    {hasAnalysisLevel && value === 3 && (
                        <ParametersAnalysisLevel version={version} />
                    )}
                </div>
            </div>
        );
    }
}

// export const TimePeriodTab = ({ t, version }) => {
//     return (
//         <StyledPanel>
//             {t("version.attributes_timeperiod", {
//                 start_date: Content.formatDate(
//                     version.versionRequestInfo.start_date
//                 ),
//                 end_date: Content.formatDate(
//                     version.versionRequestInfo.end_date
//                 )
//             })}
//         </StyledPanel>
//     );
// };
// const TreesTab = ({ t, version }) => {
//     return (
//         <StyledPanel>
//             {t("version.attributes_created_trees")}
//             <StyledRow>
//                 <StyledRowCell bold={true}>
//                     {t("version.attributes_tree")}
//                 </StyledRowCell>
//                 <StyledRowCell bold={true}>
//                     {t("version.attributes_subcategory")}
//                 </StyledRowCell>
//             </StyledRow>
//             {version.trees.map(tree => {
//                 return (
//                     <StyledRow key={tree.id}>
//                         <StyledRowCell>{tree.name}</StyledRowCell>
//                         <StyledRowCell>
//                             {tree.subcategories.map(subcategory => {
//                                 return (
//                                     <span key={subcategory}>{subcategory}</span>
//                                 );
//                             })}
//                         </StyledRowCell>
//                     </StyledRow>
//                 );
//             })}
//         </StyledPanel>
//     );
// };

export default withTranslation()(withStyles(styles)(ParametersDialogContent));
