import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

// MUI
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// Components
import HierarchicalBrandSelectContainer from "components/Tree/HierarchicalBrandSelectContainer";
import ExportCSVContainer from "./ExportCSVContainer";
import ParametersInfo from "./ParametersInfo";
import ExportToDMTContainer from "./ExportToDMTContainer";

import { LINE_SIZE_PRODUCT, LINE_SIZE_SALES } from "constants/static";

const styles = {
    container: {
        padding: 10
    },
    buttonContainer: {
        display: "flex"
    }
};

let HierarchicalOptionsPanel = ({
    radioValue,
    checkboxValue,
    handleRadioChange,
    handleCheckboxChange,
    brands,
    version,
    t,
    classes,
    exportToDMTNodes,
    data
}) => (
    <div className={classes.container}>
        <FormControl component="fieldset">
            <RadioGroup
                aria-label="Tree View Control"
                name="treeViewControl"
                value={radioValue}
                onChange={handleRadioChange}
            >
                <FormControlLabel
                    value={LINE_SIZE_PRODUCT}
                    control={<Radio color="primary" />}
                    label={t("tree.number_of_products")}
                />
                <FormControlLabel
                    value={LINE_SIZE_SALES}
                    control={<Radio color="primary" />}
                    label={t("tree.volume_of_sales")}
                />
            </RadioGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkboxValue}
                        onChange={handleCheckboxChange("checkboxValue")}
                        value="checkboxValue"
                        color="primary"
                    />
                }
                label={t("tree.colour_by_index")}
            />
        </FormControl>
        <HierarchicalBrandSelectContainer brands={brands} />
        <div className={classes.buttonContainer}>
            <ExportCSVContainer />
            <ParametersInfo version={version} />
        </div>
        <div className={classes.buttonContainer}>
            <ExportToDMTContainer exportToDMTNodes={exportToDMTNodes} data={data}/>
        </div>
    </div>
);

HierarchicalOptionsPanel.propTypes = {
    handleRadioChange: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    radioValue: PropTypes.string.isRequired,
    checkboxValue: PropTypes.bool.isRequired,
    brands: PropTypes.object
};

export default withTranslation()(withStyles(styles)(HierarchicalOptionsPanel));
