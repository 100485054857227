// redux-saga
import { takeEvery, put, call } from "redux-saga/effects";

// Fetch service
import fetch from "services/fetch";

// Api configuration
import api from "api";

// Redux modules
import { openSnackbar } from "../snackbar/open";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "verifyStores";
const { reducer, actions, selectors } = createFetchRedux(reducerName);

// Selectors
export const selectImportStoreResult = selectors.selectReduxData;
export const selectImportStoreLoading = selectors.selectReduxLoading;

// Action types
export const VERIFY_STORE_IMPORT = `${reducerName}/VERIFY_STORE_IMPORT`;

// Actions
export const resetVerifyStores = actions.reset;

export function verifyStoreImport(id, file) {
    return {
        type: VERIFY_STORE_IMPORT,
        payload: {
            id,
            file
        }
    };
}

export function validateImport(result) {
    const hasOneVerifiedStore =
        result &&
        Array.isArray(result.verifiedStores) &&
        result.verifiedStores.length;
    if (!hasOneVerifiedStore) {
        throw new Error("File has no verified stores");
    }
    return true;
}

// Sagas
export function* fetchVerifyStore({ payload }) {
    const { id, file } = payload;
    const { url, type, successful, error, attachment, args } = api[
        "stores.import.from.file"
    ](id, file);
    try {
        const result = yield call(fetch, url, type, null, attachment, args);
        yield call(validateImport, result);
        yield put(actions.receive(result));
        yield put(openSnackbar(successful, "success"));
    } catch (e) {
        yield put(actions.error(error));
        yield put(openSnackbar(error, "error"));
        console.error(e);
    }
}

export function* watchVerifyStore() {
    yield takeEvery(VERIFY_STORE_IMPORT, fetchVerifyStore);
}

export default reducer;
