import { call, take, put } from "redux-saga/effects";
import api from "../../../api";
import fetch from "../../../services/fetch";
// Reducer name
const reducerName = "nodeProducts";
export const GET_NODE_PRODUCTS = `${reducerName}/GET_NODE_PRODUCTS`;
export const GET_NODE_PRODUCTS_SUCCESS = `${reducerName}/GET_NODE_PRODUCTS_SUCCESS`;
export const GET_NODE_PRODUCTS_FAILURE = `${reducerName}/GET_NODE_PRODUCTS_FAILURE`;


export function fetchNodeProducts(projectId, projectVersion, savedSetName, nodes) {
    return {
        type: GET_NODE_PRODUCTS,
        payload: {
            projectId,
            projectVersion,
            savedSetName,
            nodes
        }
    };
}

export function fetchNodeProductsSuccess(res) {
    return {
        type: GET_NODE_PRODUCTS_SUCCESS,
        payload: res
    };
}

export function fetchNodeProductsFailure(err) {
    return {
        type: GET_NODE_PRODUCTS_FAILURE,
        payload: err
    };
}

const initialState = {
    pending: false,
    nodeProducts: [],
    error: null
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_NODE_PRODUCTS:
            return{
                ...state,
                pending: true
            };
        case GET_NODE_PRODUCTS_SUCCESS:
            return{
                ...state,
                nodeProducts: action.payload.nodeProducts,
                pending: false
            };

        case GET_NODE_PRODUCTS_FAILURE:
            return{
                ...state,
                pending: false,
                nodeProducts: [],
                error: action.payload.error
            };

        default:
            return state;
    }
}


export default reducer;
export const getSelectedNodesProducts = state => state[reducerName].nodeProducts;

export const getNodeProductsErrorMessage = state => state[reducerName].error;
export function* watchNodesProducts() {
    while (true) {

        const {payload} = yield take(GET_NODE_PRODUCTS);
        const {projectId, projectVersion, savedSetName, nodes} = payload;
        const { url, type } = api["rangingSet.nodes.products.get"](projectId, projectVersion, savedSetName, String(nodes));
        try {
            const resp = yield call(fetch, url, type, null);
            yield put(
                fetchNodeProductsSuccess({
                    nodeProducts: resp
                })
            );
        } catch (e) {
            yield put(
                fetchNodeProductsFailure({
                    error: e.message
                })
            );
        }
    }
}
