import { selectAttributeValues } from "redux/modules/dmtAttributeList/dmtAttributeList";
import { selectStoreAttributeValues } from "redux/modules/dmtAttributeList/dmtStoreAttributeList";
import { selectDMTProductTreeSelectedNodes } from "redux/modules/dmtProductTree/dmtProductTree";
import { selectDMTStoreTreeSelectedNodes } from "../dmtStoreTree/dmtStoreTree";
import { selectProjectById } from "redux/modules/projects/projects";

export function selectHierarchySelectionForStores(state) {
    const storeAttributeValues = selectStoreAttributeValues(state);
    const selectedStoreNodes = selectDMTStoreTreeSelectedNodes(state);
    const project = selectProjectById(state);
    const productStoreHierarchySelection =
        project && project.productStoreHierarchySelection;
    const products = productStoreHierarchySelection
        ? productStoreHierarchySelection.products
        : [];
    return {
        products,
        stores: {
            hierarchies: selectedStoreNodes.map(el => ({
                id: el.id,
                hierarchyName: el.hierarchyName
            })),
            attributes: storeAttributeValues
        }
    };
}

export function selectHierarchySelectionForProducts(state) {
    const attributeValues = selectAttributeValues(state);
    const selectedNodes = selectDMTProductTreeSelectedNodes(state);
    const project = selectProjectById(state);
    const productStoreHierarchySelection =
        project && project.productStoreHierarchySelection;
    const stores = productStoreHierarchySelection
        ? productStoreHierarchySelection.stores
        : [];
    return {
        products: {
            hierarchies: selectedNodes.map(el => ({
                id: el.id,
                hierarchyName: el.hierarchyName
            })),
            attributes: attributeValues
        },
        stores
    };
}
