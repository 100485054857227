import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import ImportCard from "components/ImportCard";
import StoresImportDialog from "components/StoresImport/StoresImportDialog";
import StoresImportSummary from "components/StoresImport/StoresImportSummary";
import StoreImportHierarchyContainer from "components/StoresImport/StoreImportHierarchyContainer";
import StoreImportMenu from "components/StoresImport/StoreImportMenu";
import DialogComponent from "uiComponents/Dialog/DialogComponent";

import { verifyStoreImport } from "redux/modules/stores/verify";

import { selectDMTIntegrationStores } from "redux/modules/config";
import {
    updateHierarchySelection,
    selectProjectById,
    selectStoresFromProject
} from "redux/modules/projects/projects";
import { selectHierarchySelectionForStores } from "redux/modules/dmtHierarchySelection/dmtHierarchySelection";
import { saveStores } from "redux/modules/stores/save";
import { selectDMTStoreTreeSelectedNodes } from "redux/modules/dmtStoreTree/dmtStoreTree";
import { fetchRealization } from "redux/modules/dmtStoreTree/dmtStoreRealization";
import { selectAdditionalAttributes } from "redux/modules/dmtAttributeList/dmtStoreAttributeList";

function hasHierarchySelection(project) {
    if (!project) return false;
    const { productStoreHierarchySelection } = project;
    return (
        !!productStoreHierarchySelection &&
        !!productStoreHierarchySelection.stores &&
        Object.keys(productStoreHierarchySelection.stores).length > 0
    );
}

export const PAGE_STATE = {
    INIT: "INIT",
    HIERARCHY_IMPORT: "HIERARCHY_IMPORT",
    FILE_IMPORT: "FILE_IMPORT",
    HAS_STORES: "HAS_STORES"
};

class StoreImportContainer extends Component {
    static propTypes = {
        dmtStoresConfig: PropTypes.object,
        fetchRealization: PropTypes.func.isRequired,
        verifyStoreImport: PropTypes.func.isRequired,
        saveStores: PropTypes.func.isRequired,
        hasStores: PropTypes.bool.isRequired,
        additionalAttributes: PropTypes.object.isRequired,
        projectStores: PropTypes.array
    };

    state = {
        pageState: PAGE_STATE.INIT,
        isModalOpen: false,
        isClearModalOpen: false,
        importMode:
            this.props.dmtStoresConfig && this.props.dmtStoresConfig.stores
                ? this.props.dmtStoresConfig.stores.hierarchySelectionEnabled
                    ? "hierarchy"
                    : "file"
                : "file"
    };

    componentDidMount() {
        this.initPage();
    }

    componentDidUpdate(prevProps) {
        const { hasStores: prevStores, project: prevProject } = prevProps;
        const { hasStores, project } = this.props;
        if (hasStores !== prevStores || project !== prevProject) {
            this.initPage();
        }
    }

    getImportPageState() {
        const { dmtStoresConfig } = this.props;
        return dmtStoresConfig.hierarchySelectionEnabled
            ? PAGE_STATE.HIERARCHY_IMPORT
            : PAGE_STATE.FILE_IMPORT;
    }

    initPage() {
        const { project, hasStores } = this.props;
        if (!project) return;
        let pageState = this.state.pageState;
        if (!hasStores) {
            pageState = this.getImportPageState();
        } else {
            pageState = PAGE_STATE.HAS_STORES;
        }
        this.setState({
            pageState
        });
    }

    handleChangeFile = event => {
        const { verifyStoreImport, project } = this.props;
        if (event.target && event.target.files) {
            const fileList = event.target.files;
            verifyStoreImport(project.id, fileList[0]);
        }
        this.setState({ importMode: "file" });
        this.handleOpenModal();
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
    };

    handleOpenModal = () => {
        this.setState({ isModalOpen: true });
    };

    handleHierarchySave = () => {
        const {
            dmtStoresConfig,
            selectedNodes,
            additionalAttributes,
            fetchRealization
        } = this.props;
        const groupByAttribute = dmtStoresConfig.groupByAttributeId;
        const coreAttributes = dmtStoresConfig.generalAttributes;
        fetchRealization({
            nodeIdList: selectedNodes.map(el => el.id).join(","),
            groupByAttribute: JSON.stringify(groupByAttribute),
            coreAttributes: JSON.stringify(coreAttributes),
            additionalAttributes: JSON.stringify(additionalAttributes)
        });
        this.setState({ importMode: "hierarchy" });
        this.handleOpenModal();
    };

    handleOpenClearModal = () => {
        this.setState({ isClearModalOpen: true });
    };

    handleCloseClearModal = () => {
        this.setState({ isClearModalOpen: false });
    };

    handleConfirmClearStores = () => {
        const {
            project,
            saveStores,
            hierarchySelection,
            updateHierarchySelection
        } = this.props;
        saveStores(project.id, []);
        if (hasHierarchySelection(project)) {
            // Clear stores selection in project
            hierarchySelection.stores = {};
            updateHierarchySelection(String(project.id), hierarchySelection);
        }
        this.handleCloseClearModal();
    };

    handleClickEditStores = () => {
        this.setState({
            pageState: this.getImportPageState()
        });
    };

    handleClickGoBack = () => {
        this.setState({
            pageState: PAGE_STATE.HAS_STORES
        });
    };

    render() {
        const {
            t,
            project,
            projectStores,
            hasStores,
            dmtStoresConfig
        } = this.props;
        const {
            pageState,
            isModalOpen,
            isClearModalOpen,
            importMode
        } = this.state;

        return (
            <>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                >
                    <StoreImportMenu
                        hierarchySelectionEnabled={
                            dmtStoresConfig.hierarchySelectionEnabled
                        }
                        hasStores={hasStores}
                        pageState={pageState}
                        project={project}
                        projectHasHierarchySelection={hasHierarchySelection(
                            project
                        )}
                        handleChangeFile={this.handleChangeFile}
                        handleClickHierarchyImport={this.handleClickEditStores}
                        handleDeleteStores={this.handleOpenClearModal}
                    />
                </div>
                {pageState === PAGE_STATE.INIT && (
                    <CircularProgress size={18} />
                )}
                {pageState === PAGE_STATE.FILE_IMPORT && (
                    <>
                        <ImportCard
                            title="stores.import_title"
                            buttonLabel="stores.import_button"
                            onClick={this.handleOpenModal}
                        />
                    </>
                )}
                {pageState === PAGE_STATE.HIERARCHY_IMPORT && (
                    <>
                        <StoreImportHierarchyContainer
                            hasStores={hasStores}
                            handleClickGoBack={this.handleClickGoBack}
                            handleSave={this.handleHierarchySave}
                            projectId={String(project.id)}
                        />
                    </>
                )}
                {pageState === PAGE_STATE.HAS_STORES && (
                    <StoresImportSummary stores={projectStores} />
                )}
                {pageState !== PAGE_STATE.INIT && (
                    <StoresImportDialog
                        projectId={String(project.id)}
                        isOpen={isModalOpen}
                        onClose={this.handleCloseModal}
                        title={t("stores.import_title")}
                        importMode={importMode}
                    />
                )}
                <DialogComponent
                    isOpen={isClearModalOpen}
                    onClose={this.handleCloseClearModal}
                    onSave={this.handleConfirmClearStores}
                    saveLabel={t("stores.clear")}
                    title={t("stores.clear")}
                    padding
                    centre
                >
                    <Typography>{t("stores.confirm_clear")}</Typography>
                </DialogComponent>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const project = selectProjectById(state, ownProps);
    const projectStores = selectStoresFromProject(project);
    const hasStores = Array.isArray(projectStores) && projectStores.length > 0;
    return {
        project,
        projectStores,
        hasStores,
        selectedNodes: selectDMTStoreTreeSelectedNodes(state),
        additionalAttributes: selectAdditionalAttributes(state),
        dmtStoresConfig: selectDMTIntegrationStores(state),
        hierarchySelection: selectHierarchySelectionForStores(state)
    };
};

const mapDispatchToProps = {
    saveStores,
    fetchRealization,
    verifyStoreImport,
    updateHierarchySelection
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(StoreImportContainer)
);
