import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

import {
    TAB_TREEVIEW_UNDERSTAND,
    TAB_TREEVIEW_REASSIGN
} from "constants/static";

// Shared components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    tabsRoot: {
        position: "relative",
        paddingBottom: 40,
    },
    tabRoot: {
        position: "relative",
        backgroundColor: theme.status.clay[20],
        boxShadow: `inset -8px 0px 8px -10px ${theme.status.grey.dark}`,
        textTransform: "none",
        "&:before": {
            content: "''",
            position: "absolute",
            right: 0,
            top: 0,
            width: 1,
            height: "100%",
            backgroundColor: theme.status.grey.greydivs
        },
        "&:after": {
            content: "''",
            position: "absolute",
            left: 0,
            bottom: 0,
            height: 1,
            width: "100%",
            backgroundColor: theme.status.grey.greydivs
        }
    },
    tabRootRight: {
        boxShadow: `inset 8px 0px 8px -10px ${theme.status.grey.dark}`,
        "&:before": {
            left: 0,
            right: "auto"
        }
    },
    tabSelected: {
        boxShadow: "none",
        backgroundColor: theme.status.grey.white,
        "&:after, &:before": {
            content: "none"
        }
    },
    indicator: {
        backgroundColor: "transparent"
    }
});

const TreeViewTabs = ({ classes, t, tab, handleTabChange }) => (
    <Tabs
        classes={{
            root: classes.tabsRoot,
            indicator: classes.indicator
        }}
        value={tab}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
    >
        <Tab
            classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected
            }}
            name={TAB_TREEVIEW_UNDERSTAND}
            label={t("project_details.understand_your_category")}
        />
        <Tab
            classes={{
                root: classNames(classes.tabRoot, classes.tabRootRight),
                selected: classes.tabSelected
            }}
            name={TAB_TREEVIEW_REASSIGN}
            label={t("project_details.reassign_product")}
        />
    </Tabs>
);

TreeViewTabs.propTypes = {
    tab: PropTypes.number.isRequired,
    handleTabChange: PropTypes.func.isRequired
};

export default withTranslation()(withStyles(styles)(TreeViewTabs));
