// redux-saga
import { takeEvery, put, call } from "redux-saga/effects";

// Fetch service
import fetch from "services/fetch";

// Api configuration
import api from "api";

// Redux modules
import { openSnackbar } from "../snackbar/open";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "importFromFile";
const { reducer, actions, actionTypes, selectors } = createFetchRedux(
    reducerName
);

export default reducer;

export const importFromFile = actions;

export const VERIFY_PRODUCT_IMPORT = `${reducerName}/VERIFY_PRODUCT_IMPORT`;
export const FETCH_PRODUCTS_FROM_FILE = actionTypes.FETCH;
export const fetchProductsFromFile = actions.fetch;
export const resetProductsFromFile = actions.reset;
export const selectImportFileLoading = selectors.selectReduxLoading;
export const selectImportFromFileResult = selectors.selectReduxData;

export function verifyProductImport(id, file) {
    return {
        type: VERIFY_PRODUCT_IMPORT,
        payload: {
            id,
            file
        }
    };
}

export function validateImport(result) {
    const hasOneVerifiedProduct =
        result &&
        Array.isArray(result.verifiedProducts) &&
        result.verifiedProducts.length;
    if (!hasOneVerifiedProduct) {
        throw new Error("File has no verified products");
    }
    return true;
}

// Sagas
export function* fetchVerifyProducts({ payload }) {
    const { id, file } = payload;
    const { url, type, error, attachment, args } = api[
        "products.import.from.file"
    ](id, file);
    try {
        const result = yield call(fetch, url, type, null, attachment, args);
        yield call(validateImport, result);
        yield put(importFromFile.receive(result));
    } catch (e) {
        yield put(importFromFile.error(error));
        yield put(openSnackbar(error, "error"));
        console.error(e);
    }
}

export function* watchVerifyProducts() {
    yield takeEvery(VERIFY_PRODUCT_IMPORT, fetchVerifyProducts);
}
