import { call, take, select, put } from "redux-saga/effects";
import { createSelector } from "reselect";

import api from "api";
import { fetchDMT } from "services/fetch";

// import config selectors
import { selectDMTIntegrationStores } from "redux/modules/config";
// import project reducer for project attribute store
import { selectProjects } from "redux/modules/projects/projects";

const reducerName = "dmtStoreAttributeList";

export const FETCH_ATTRIBUTE_LIST = `${reducerName}/FETCH_ATTRIBUTE_LIST`;
const REQUEST = `${reducerName}/REQUEST_ATTRIBUTE_LIST`;
const RECEIVE = `${reducerName}/RECEIVE_ATTRIBUTE_LIST`;
const ERROR = `${reducerName}/ERROR`;
const VALUES = `${reducerName}/VALUES`;
const RESET = `${reducerName}/RESET`;

export function fetchAttributeList(projectId) {
    return {
        type: FETCH_ATTRIBUTE_LIST,
        payload: projectId
    };
}

export function resetDMTAttributeList() {
    return {
        type: RESET
    };
}

export function requestAttributeList() {
    return {
        type: REQUEST
    };
}

export function receiveAttributeList(res) {
    return {
        type: RECEIVE,
        payload: res
    };
}

export function errorAttributeList(err) {
    return {
        type: ERROR,
        payload: err
    };
}

export function updateAttributeValues(values) {
    return {
        type: VALUES,
        payload: values
    };
}

const initialState = {
    list: [],
    values: [],
    error: null
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case RESET:
            return initialState;

        case RECEIVE:
            return {
                ...state,
                list: action.payload
            };
        case ERROR:
            return {
                ...state,
                error: action.payload
            };
        case VALUES:
            return {
                ...state,
                values: action.payload
            };
        default:
            return state;
    }
}

export default reducer;

export const selectAttributeListUnsorted = state => state[reducerName].list;
export const selectStoreAttributeValues = state => state[reducerName].values;
export const selectAttributeListError = state => state[reducerName].error;

export const selectAttributeList = createSelector(
    [selectAttributeListUnsorted],
    attributeList => {
        return attributeList.sort((a, b) => {
            const orgCompare = a.orgName.localeCompare(b.orgName);
            if (orgCompare === 0) {
                return a.attribute.attributeName.localeCompare(
                    b.attribute.attributeName
                );
            }
            return orgCompare;
        });
    }
);

export const selectAdditionalAttributes = createSelector(
    [selectDMTIntegrationStores, selectStoreAttributeValues],
    (dmtIntegrationStores, attributeValues) => {
        if (!dmtIntegrationStores) {
            throw new Error("No config found");
        }
        // get dmtIntegration config
        // remove core attrs from selected
        // update attributes with nameing from config additional attrs
        const configAdditionalAttributes =
            dmtIntegrationStores.additionalAttributes;
        const coreAttributeIds = Object.keys(
            dmtIntegrationStores.generalAttributes
        );
        const attributes = attributeValues
            .map(el => el.attribute)
            .filter(el => {
                // must not be in core attributes
                return !coreAttributeIds.includes(String(el.attributeId));
            })
            .reduce((prev, curr) => {
                // use the name of from config additional attrs
                const configName = configAdditionalAttributes[curr.attributeId];
                prev[curr.attributeId] = configName || curr.attributeName;
                return prev;
            }, {});
        // remaining config additional attributes
        for (const configAttrId in configAdditionalAttributes) {
            if (!attributes.hasOwnProperty(configAttrId)) {
                attributes[configAttrId] =
                    configAdditionalAttributes[configAttrId];
            }
        }
        return attributes;
    }
);

function findProjectAtrributeValues(project) {
    if (
        project &&
        project.productStoreHierarchySelection &&
        project.productStoreHierarchySelection.stores &&
        project.productStoreHierarchySelection.stores.attributes
    ) {
        return project.productStoreHierarchySelection.stores.attributes;
    }
    return null;
}

export function filterAttributeList(list) {
    return list.filter(el => {
        return !(
            el.attribute.attributeName === "ROOT" &&
            el.attribute.attributeSource === "CORE_DB"
        );
    });
}

export function* watchDMTStoreAttributeList() {
    while (true) {
        const { payload: projectId } = yield take(FETCH_ATTRIBUTE_LIST);
        const { url, type, error } = api["dmt.store.attributes.list.get"]();
        yield put(requestAttributeList());
        try {
            const result = yield call(fetchDMT, url, type);
            const filteredResult = filterAttributeList(result);
            yield put(receiveAttributeList(filteredResult));
            // update with project values
            const projects = yield select(selectProjects);
            const project = projects.find(el => String(el.id) === projectId);
            const attributeValues = findProjectAtrributeValues(project);
            if (attributeValues) {
                yield put(updateAttributeValues(attributeValues));
            }
        } catch (e) {
            yield put(errorAttributeList(error));
            console.error(e);
        }
    }
}
