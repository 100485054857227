import TfIdf from "components/Tree/tfidf";

// Helpers
// Additional attributes will be options in the dropdown
export const ADDITIONAL_ATTRS_KEY = "additionalAttributes";
// Product attributes to filter out
const INCLUDE_PRODUCT_ATTRS_KEYS = ["subcategory"];

export const validKeys = obj =>
    Object.keys(obj).filter(key => {
        const value = obj[key];
        return !(
            typeof value === "undefined" ||
            value === null ||
            value === ""
        );
    });

export const productAttrs = (
    nestedObjKey,
    includeKeys,
    addditionalIgnoreKeys
) => product => [
    ...validKeys(product[nestedObjKey]).filter(
        key => addditionalIgnoreKeys.indexOf(key) === -1
    ),
    ...validKeys(product).filter(key => includeKeys.indexOf(key) !== -1)
];

export function getProductValue(product, attr, pairs, additionalAttrsKey) {
    let value = product[additionalAttrsKey][attr];
    value =
        typeof value === "undefined" || value === null ? product[attr] : value;
    const valuePairKey = findPair(attr, pairs);
    let valuePair = null;
    if (valuePairKey) {
        valuePair = product[additionalAttrsKey][valuePairKey];
    }
    return valuePair ? `${value} ${valuePair}` : value;
}

export function findUniqueProductAttrValues(products, attrs, pairs) {
    attrs = Array.isArray(attrs) ? attrs : [attrs];
    return attrs.reduce((prev, attr) => {
        const res = products.reduce((acc, product) => {
            const productValue = getProductValue(
                product,
                attr,
                pairs,
                ADDITIONAL_ATTRS_KEY
            );
            // If the value is empty, return early
            if (productValue === null || typeof productValue === "undefined") {
                return acc;
            }

            if (acc[productValue]) {
                acc[productValue].count = acc[productValue].count + 1;
            } else {
                acc[productValue] = {
                    label: String(productValue),
                    count: 1
                };
            }
            return acc;
        }, {});
        prev[attr] = Object.keys(res).map(key => res[key]);
        return prev;
    }, {});
}

export function findPair(attr, additionalAttributePairs) {
    if (!Array.isArray(additionalAttributePairs)) return null;
    let match = additionalAttributePairs
        .filter(el => el[0] === attr)
        .map(el => el[1]);
    return match && match.length ? match[0] : null;
}

export function findUniqueProductAttrs(
    products,
    additionalAttributeIgnoreKeys,
    pairs
) {
    let additionalIgnoreKeys = additionalAttributeIgnoreKeys.concat(
        pairs.map(el => el[1])
    );
    return [].concat
        .apply(
            [],
            products.map(
                productAttrs(
                    ADDITIONAL_ATTRS_KEY,
                    INCLUDE_PRODUCT_ATTRS_KEYS,
                    additionalIgnoreKeys
                )
            )
        )
        .filter((item, pos, arr) => arr.indexOf(item) === pos);
}

export function countAllAndGroupAttrValues(
    uniqueAttrs,
    nodeProducts,
    allProducts,
    pairs
) {
    // Count attr values across all products
    let allCount = findUniqueProductAttrValues(allProducts, uniqueAttrs, pairs);
    // Count attr values across group products
    let groupCount = findUniqueProductAttrValues(
        nodeProducts,
        uniqueAttrs,
        pairs
    );
    // Count attr values across all products and across group products
    let combinedCount = {};
    Object.keys(groupCount).forEach(attr => {
        let valuesList = groupCount[attr];
        let allValuesList = allCount[attr];
        // note: only attribute values in group are shown
        combinedCount[attr] = valuesList.map(el => {
            const allValue = allValuesList.find(
                item => item.label === el.label
            );
            const percentageGroup = el.count / nodeProducts.length;
            const percentageAll = allValue
                ? allValue.count / allProducts.length
                : 0;
            const index = percentageGroup / percentageAll;
            return {
                name: el.label,
                percentageGroup,
                percentageAll,
                index
            };
        });
    });
    return combinedCount;
}

export const defaultProductAttr = {
    terms: [],
    brands: [],
    products: [],
    additionalAttrs: []
};

// Stop words for tfidf
export const defaultStopwords = {
    with: true,
    of: true,
    in: true,
    and: true,
    from: true,
    the: true
};

export const productTerms = products => products.map(p => p.label).join(" ");

export const productTermsTfidf = (
    terms,
    stopwords = defaultStopwords,
    maxTerms = 15
) => {
    const tfidf = new TfIdf(stopwords);
    tfidf.addDocument(terms);
    return tfidf.listTerms(0).slice(0, maxTerms);
};

export const productTermCount = (products, column) =>
    products.reduce((prev, curr) => {
        const prop = curr[column];
        prev[prop] = Number.isInteger(prev[prop]) ? prev[prop] + 1 : 1;
        return prev;
    }, {});

export const getFirstProduct = (productData, allVersionsProductDict) => {
    const firstId = Object.keys(productData)[0];
    const a = allVersionsProductDict[firstId];
    const b = productData[firstId];
    return {
        ...a,
        ...b
    };
};

export const buildProductList = (
    productIds,
    productData,
    allVersionsProductDict
) =>
    productIds
        .map(id => allVersionsProductDict[id])
        .map(product => ({
            ...product,
            ...productData[product.productId]
        }));

export const buildAdditionalAttrs = (
    products,
    savedSetProductList,
    additionalAttributeExclude,
    additionalAttributePairs
) => {
    // We need all unique attr keys for determining tabs
    // filter out any from config exclusion list
    // filter out any 'paired' attributes
    let uniqueAttrs = findUniqueProductAttrs(
        products,
        additionalAttributeExclude,
        additionalAttributePairs
    );
    // Count attr values across all products and across group products
    let combinedCount = countAllAndGroupAttrValues(
        uniqueAttrs,
        products,
        savedSetProductList,
        additionalAttributePairs
    );
    // Build additional info object for table display
    return uniqueAttrs
        .map(attr => {
            return {
                name: attr,
                values: combinedCount[attr]
            };
        })
        .filter(attr => attr.values.length > 0);
};

export function buildAvailableProductAttrs(
    productData,
    allVersionsProductDict
) {
    const product = getFirstProduct(productData, allVersionsProductDict);
    const excludeAttrs = ["additionalAttributes", "productId", "subcategory"];
    return Object.keys(product).filter(el => !excludeAttrs.includes(el));
}

// products - list of ids
// savedSetProductList - product list from saved set, with combined attrs
// selectConfigAdditionalAttributeExclude,
// selectConfigAdditionalAttributePairs
export function buildProductAttrInfo(
    productIds,
    savedSetProductList,
    additionalAttributeExclude,
    additionalAttributePairs
) {
    const products = savedSetProductList.filter(el =>
        productIds.includes(el.productId)
    );

    return {
        ...defaultProductAttr,
        products: products,
        additionalAttrs: buildAdditionalAttrs(
            products,
            savedSetProductList,
            additionalAttributeExclude,
            additionalAttributePairs
        ),
        brands: productTermCount(products, "brand"),
        terms: productTermsTfidf(productTerms(products))
    };
}
