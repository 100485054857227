import React, { Component } from "react";
import { connect } from "react-redux";

import { selectSnackbar } from "../../redux/modules/snackbar/open";

import SnackbarComponent from "./SnackbarComponent";

const mapStateToProps = state => ({
    message: selectSnackbar(state)
});

class SnackbarTemplate extends Component {
    state = {
        open: false
    };

    componentDidUpdate(prevProps) {
        const { message } = this.props;
        const { message: prevMessage } = prevProps;
        if (message === prevMessage) return;
        if (!message) return;
        this.setState({
            open: true
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { message } = this.props;
        const { open } = this.state;
        if (!message || !message.variant) return null;
        return (
            <SnackbarComponent
                open={open}
                onClose={this.handleClose}
                variant={message.variant}
                message={message.message}
            />
        );
    }
}

export default connect(mapStateToProps)(SnackbarTemplate);
