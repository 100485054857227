// React
import React from "react";

// Other libs
import PropTypes from "prop-types";
import styled from "styled-components";
import numeral from "numeral";
import { withTranslation } from "react-i18next";

// MUI
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddOutlined";
import RemoveIcon from "@material-ui/icons/RemoveOutlined";

// MUI theme
import theme from "../../styles/MuiTheme";

import TreemapOverlayNameInput from "components/Tree/TreemapOverlayNameInput";
import Checkbox from '@material-ui/core/Checkbox';


const overlayWidth = 200;
const iconBarHeight = 49;

const getOffsetTransform = (props, overlayWidth) =>
    `translate(${props.x - overlayWidth / 2}px, ${props.y + iconBarHeight}px)`;

const triangleSize = 16;

const StyledOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: ${props => getOffsetTransform(props, overlayWidth)};
    transition: ${props =>
        props.active && props.prevActive
            ? "transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)"
            : "none"};
    width: ${overlayWidth}px;
    visibility: ${props => (props.active ? "visible" : "hidden")};
    border-radius: 10px;
    box-shadow: ${theme.shadows[4]};
    box-sizing: border-box;
    background-color: ${theme.status.grey.white};
    z-index: 999;
    padding-bottom: 30px;

    :before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: ${(triangleSize * 2 - 1) * -1}px;
        left: ${overlayWidth / 2 - triangleSize}px;
        border-top: ${triangleSize}px solid transparent;
        border-left: ${triangleSize}px solid transparent;
        border-right: ${triangleSize}px solid transparent;
        border-bottom: ${triangleSize}px solid ${theme.status.grey.white};
    }
}
`;

const StyledOverlayHeader = styled.div`
    display: flex;
    padding: 30px 30px 6px 30px;
    justify-content: space-between;

    && {
        button.icon {
        }
    }
`;

const StyledMetricsPanel = styled.div`
    padding: 10px 30px 0px 30px;
    font-size: 12px;
`;

const StyledMetricsRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
`;

const StyledMetricsFigure = styled.div`
    font-weight: 600;
    text-align: right;
`;

export const TreemapOverlay = ({
    t,
    nodeName,
    active,
    prevActive,
    x,
    y,
    isProduct,
    onClickOverlay,
    onClickSplitNode,
    onClickCombineNode,
    onEnterCombineNode,
    onLeaveCombineNode,
    onChangeNodeName,
    onKeyDownNodeName,
    showMetrics,
    size,
    totalSales,
    totalUnits,
    onSelectNode,
    activeNodeName,
    exportToDMTNodes,
    disableCheckbox
}) => {
    return (
        <StyledOverlay
            onClick={onClickOverlay}
            x={isNaN(x) ? 0 : x}
            y={isNaN(y) ? 0 : y}
            prevActive={prevActive}
            active={active}
        >
            <StyledOverlayHeader>
                <Button
                    disabled={isProduct}
                    className="treemap-button__split icon"
                    onClick={onClickSplitNode}
                >
                    <AddIcon />
                </Button>
                <Button
                    className="treemap-button__combine icon"
                    onClick={onClickCombineNode}
                    onMouseEnter={onEnterCombineNode}
                    onMouseLeave={onLeaveCombineNode}
                >
                    <RemoveIcon />
                </Button>
                <Checkbox size="small" title={t("common.export_to_dmt_title")}
                onClick={onSelectNode} checked={exportToDMTNodes.indexOf(activeNodeName) !== -1} disabled={disableCheckbox}/>
            </StyledOverlayHeader>
            <TreemapOverlayNameInput
                value={nodeName}
                onChange={onChangeNodeName}
                onKeyDown={onKeyDownNodeName}
            />
            {showMetrics && (
                <StyledMetricsPanel>
                    <StyledMetricsRow>
                        <div>{t("tree.product_size")}</div>
                        <StyledMetricsFigure>{size}</StyledMetricsFigure>
                    </StyledMetricsRow>
                    <StyledMetricsRow>
                        <div>{t("tree.total_sales")}</div>
                        <StyledMetricsFigure>
                            {numeral(totalSales).format("$0a")}
                        </StyledMetricsFigure>
                    </StyledMetricsRow>
                    <StyledMetricsRow>
                        <div>{t("tree.total_units")}</div>
                        <StyledMetricsFigure>
                            {numeral(totalUnits).format("0a")}
                        </StyledMetricsFigure>
                    </StyledMetricsRow>
                </StyledMetricsPanel>
            )}
        </StyledOverlay>
    );
};

TreemapOverlay.propTypes = {
    nodeName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    onClickOverlay: PropTypes.func.isRequired,
    onClickSplitNode: PropTypes.func.isRequired,
    onClickCombineNode: PropTypes.func.isRequired,
    onEnterCombineNode: PropTypes.func.isRequired,
    onLeaveCombineNode: PropTypes.func.isRequired,
    onChangeNodeName: PropTypes.func.isRequired,
    onKeyDownNodeName: PropTypes.func.isRequired,
    isProduct: PropTypes.bool
};

TreemapOverlay.defaultProps = {
    isProduct: false
};

export default withTranslation()(TreemapOverlay);
