// redux-saga
import {call, take, put} from "redux-saga/effects";
// Redux factory
import createFetchRedux from "../createFetchRedux";
// Api configuration
import api from "api";
// Fetch service
import fetch from "services/fetch";
// Redux modules
import {openSnackbar} from "../snackbar/open";

const {reducer, actions, selectors} = createFetchRedux("importStoreTemplate");
// Reducer
export default reducer;
// Action types
export const EXPORT_STORE_TEMPLATE = "importStoreTemplate/EXPORT_STORE_TEMPLATE";
// Actions
export const fetchExport = actions.fetch;
export const exportData = actions;

export function downloadStoreTemplate() {
    return {
        type: EXPORT_STORE_TEMPLATE,
    };
}

export function exportDownload(res) {
    const blob = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    const anchorEl = document.createElement("a");
    anchorEl.style = "display: none";
    document.body.appendChild(anchorEl);
    const url = window.URL.createObjectURL(blob);
    anchorEl.href = url;
    anchorEl.download = `StoreImportTemplate.xlsx`;
    anchorEl.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(anchorEl);
}

// Selectors
export const selectExportResult = selectors.selectReduxData;
export const selectExportDataLoading = selectors.selectReduxLoading;

// Sagas
export function* watchExportStoreImportTemplate() {
    while (true) {
        yield take(EXPORT_STORE_TEMPLATE);
        const {url, type, error} = api["stores.export.template.get"]();
        try {
            const result = yield call(fetch, url, type, null, null, null, 'binary');
            yield put(exportData.receive(result));
            yield call(exportDownload, result);
        } catch (e) {
            yield put(exportData.error(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}
