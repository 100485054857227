import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function WarningIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20">
            <g fill="none" fill-rule="evenodd">
                <path d="M-1-2h24v24H-1z" />
                <path
                    fill="currentColor"
                    fill-rule="nonzero"
                    d="M1.734 18h18.532L11 1.996 1.734 18zM0 19L11 0l11 19H0zm12-3h-2v-2h2v2zm0-4h-2V7h2v5z"
                />
            </g>
        </SvgIcon>
    );
}
