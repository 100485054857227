import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import FindPermissions from "helpers/FindPermissions";

import { ConfigContext } from "utils/ConfigProvider";
import { downloadExport } from "../../redux/modules/products/exportProducts";
import {
    downloadProductTemplate,
    selectExportDataLoading
} from "../../redux/modules/products/importProductTemplate";

const fade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
      opacity: 0;
  }
`;

const MenuIconButton = styled(IconButton)`
    position: relative;
    margin-left: auto;

    :before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #f8e71c;
        border-radius: 50%;
        opacity: 0;
        animation-name: ${fade};
        animation-duration: 2s;
        animation-iteration-count: 3;
        animation-easing-function: ease-in-out;
        animation-direction: alternate;
        animation-delay: 2s;
    }
`;

const styles = {
    button: {
        float: "right"
    },
    list: {
        padding: 15
    },
    hidden: {
        visibility: "hidden",
        width: 0
    }
};

export class ProjectImportMenu extends Component {
    state = {
        anchorEl: null,
        isNewProdDialogOpen: false
    };

    handlePopoverClick = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleClickHierarchy = () => {
        this.handlePopoverClose();
        this.props.handleClickHierarchyImport();
    };

    handleClickTemplate = () => {
        this.handlePopoverClose();
        this.props.downloadProductTemplate();
    };

    handleClickExport = () => {
        const { downloadExport, project } = this.props;
        this.handlePopoverClose();
        downloadExport(project.id);
    };

    handleClickAssorter = () => {
        this.handlePopoverClose();
        this.props.handleClickImportFromAssorter();
    };

    renderImportFromAssorter() {
        const { t, project } = this.props;
        const isLinkedProj = project && project.source === "FROM_ASSORTER";
        const hasAccessToAssorter = FindPermissions.hasAccessToAssorter(
            this.context.userInfo.userProfile.allowedApps
        );
        if (!isLinkedProj || !hasAccessToAssorter) return null;
        return (
            <ListItem
                button
                dense
                divider
                disabled={!isLinkedProj}
                onClick={this.handleClickAssorter}
            >
                <ListItemText primary={t("product_import.import_assorter")} />
            </ListItem>
        );
    }
    render() {
        const {
            isTemplateLoading,
            hierarchySelectionEnabled,
            isFileLoading,
            handleChangeFile,
            classes,
            hasProducts,
            projectHasHierarchySelection,
            t
        } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <Fragment>
                <MenuIconButton
                    aria-owns={open ? "projectAction-popper" : null}
                    aria-haspopup="true"
                    onClick={this.handlePopoverClick}
                    className={classes.button}
                >
                    <MoreVertIcon />
                </MenuIconButton>
                <Popover
                    id="projectAction-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <List component="nav" className={classes.list}>
                        <Fragment>
                            {hierarchySelectionEnabled && (
                                <>
                                    <ListItem
                                        button
                                        dense
                                        divider
                                        onClick={this.handleClickHierarchy}
                                    >
                                        <ListItemText
                                            primary={
                                                hasProducts &&
                                                projectHasHierarchySelection
                                                    ? t(
                                                          "product_import.hierarchy_reimport"
                                                      )
                                                    : t(
                                                          "product_import.hierarchy"
                                                      )
                                            }
                                        ></ListItemText>
                                    </ListItem>
                                </>
                            )}
                            <ListItem
                                style={{ width: 170 }}
                                button
                                dense
                                divider
                                disabled={isFileLoading}
                            >
                                <input
                                    className={classes.hidden}
                                    id="button-file-selector"
                                    multiple
                                    onChange={handleChangeFile}
                                    type="file"
                                />

                                <label htmlFor="button-file-selector">
                                    <ListItemText
                                        primary={t(
                                            "product_import.import_file"
                                        )}
                                    />
                                </label>
                            </ListItem>
                            {this.renderImportFromAssorter()}
                            <ListItem
                                button
                                dense
                                divider
                                disabled={!hasProducts}
                                onClick={this.handleClickExport}
                            >
                                <ListItemText
                                    primary={t("product_import.export")}
                                />
                            </ListItem>
                            <ListItem
                                button
                                dense
                                onClick={this.handleClickTemplate}
                            >
                                {isTemplateLoading ? (
                                    <CircularProgress size={18} thickness={1} />
                                ) : (
                                    <ListItemText
                                        primary={t(
                                            "product_import.download_template"
                                        )}
                                    />
                                )}
                            </ListItem>
                        </Fragment>
                    </List>
                </Popover>
            </Fragment>
        );
    }
}

ProjectImportMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClickProductImport: PropTypes.func.isRequired,
    hasProducts: PropTypes.bool.isRequired,
    // from redux
    isTemplateLoading: PropTypes.bool,
    downloadProductTemplate: PropTypes.func.isRequired,
    downloadExport: PropTypes.func.isRequired
};

ProjectImportMenu.contextType = ConfigContext;

const mapStateToProps = state => ({
    isTemplateLoading: selectExportDataLoading(state)
});
const mapDispatchToProps = {
    downloadProductTemplate,
    downloadExport
};
export default withTranslation()(
    withStyles(styles)(
        connect(mapStateToProps, mapDispatchToProps)(ProjectImportMenu)
    )
);
