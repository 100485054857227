import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    formControl: {
        position: "relative",
        marginLeft: "auto",
        marginRight: 20,
        display: "flex",
        flexDirection: "row"
    },
    input: {
        width: 40,
        height: 26,
        marginRight: 5,
        fontSize: 14
    },
    label: {
        fontSize: 14,
        fontWeight: 600
    }
});

export function TreeNeedUnitNumberInput({ t, value, classes, onChange }) {
    return (
        <FormControl className={classes.formControl}>
            <Input
                id="need-unit-number"
                value={value}
                onChange={onChange}
                className={classes.input}
                type="number"
            />
            <Typography className={classes.label} variant="body2">
                {t("common.needunits")}
            </Typography>
        </FormControl>
    );
}

export default withStyles(styles)(withTranslation()(TreeNeedUnitNumberInput));
