import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DialogComponent from "uiComponents/Dialog/DialogComponent";
import ProductImport from "./ProductImport";
// Redux modules
import {
    resetProductsFromFile,
    selectImportFileLoading,
    selectImportFromFileResult,
    verifyProductImport
} from "redux/modules/products/importFromFile";
import {
    fetchProductsFromAssorter,
    resetProductsFromAssorter,
    selectImportAssorterData,
    selectImportAssorterLoading
} from "redux/modules/products/importFromAssorter";
import {
    fetchUpdateProducts,
    selectAllProducts,
    setProjectProducts
} from "redux/modules/products/products";
import { resetSubcategoriesAfterLoad } from "redux/modules/subcategories/subcategories";
import { fetchSaveGroups } from "redux/modules/subcategories/save";
import {
    selectAllProductsError,
    selectAllProductsLoading
} from "../../redux/modules/products/products";
import { fetchSubcategoryMetrics } from "../../redux/modules/subcategories/metrics";
import { fetchSubcategoryGroups } from "../../redux/modules/subcategories/groups";
import { downloadProductTemplate } from "../../redux/modules/products/importProductTemplate";
import {
    selectRealizationLoading,
    selectRealizationError,
    selectRealization,
    fetchRealization
} from "../../redux/modules/dmtProductTree/dmtRealization";
import { selectDMTProductTreeSelectedNodes } from "../../redux/modules/dmtProductTree/dmtProductTree";
import { selectAdditionalAttributes } from "redux/modules/dmtAttributeList/dmtAttributeList";
import { selectDMTIntegrationProducts } from "redux/modules/config";
import { selectHierarchySelectionForProducts } from "redux/modules/dmtHierarchySelection/dmtHierarchySelection";
import { updateHierarchySelection } from "redux/modules/projects/projects";

export const ImportPanelState = {
    ADD_IMPORT: 1,
    SELECT_IMPORT: 2,
    CONFIRM_IMPORT: 3,
    LOADING_REALIZATION: 4,
    LOADING_SAVE_PRODUCTS: 5
};

export function getProjectCategoryIds(project) {
    if (
        project &&
        project.productStoreHierarchySelection &&
        project.productStoreHierarchySelection.products &&
        project.productStoreHierarchySelection.products.selectedCategoryIds
    ) {
        return project.productStoreHierarchySelection.products
            .selectedCategoryIds;
    }
    return null;
}

const mapStateToProps = state => {
    const isRealizationLoading = selectRealizationLoading(state);
    const isProductsLoading = selectAllProductsLoading(state);
    const isImportLoading = selectImportAssorterLoading(state);
    const isImportFileLoading = selectImportFileLoading(state);
    const isLoading =
        isProductsLoading ||
        isImportFileLoading ||
        isImportLoading ||
        isRealizationLoading;

    const realizationError = selectRealizationError(state);
    const productsError = selectAllProductsError(state);
    const error = realizationError || productsError;
    const dmtIntegrationProducts = selectDMTIntegrationProducts(state);
    const categoryKey = dmtIntegrationProducts
        ? Object.values(dmtIntegrationProducts.groupByAttributeId)[0]
        : "category_name";
    return {
        isLoading,
        isProductsLoading: isProductsLoading,
        products: selectAllProducts(state),
        error,
        // realization
        selectedNodes: selectDMTProductTreeSelectedNodes(state),
        realizationResult: selectRealization(state),
        additionalAttributes: selectAdditionalAttributes(state),
        dmtIntegrationProducts,
        categoryKey,
        hierarchySelection: selectHierarchySelectionForProducts(state),
        // import from file
        importFromFileResult: selectImportFromFileResult(state),
        // import from assorter
        importFromAssorterResult: selectImportAssorterData(state)
    };
};

const mapDispatchToProps = {
    fetchRealization,
    fetchSubcategoryGroups,
    fetchSubcategoryMetrics,
    fetchProductsFromAssorter,
    resetProductsFromAssorter,
    downloadProductTemplate,
    setProjectProducts,
    fetchUpdateProducts,
    verifyProductImport,
    resetProductsFromFile,
    resetSubcategoriesAfterLoad,
    fetchSaveGroups,
    updateHierarchySelection
};

class ProductImportDialog extends Component {
    static propTypes = {
        verifyProductImport: PropTypes.func.isRequired,
        fetchRealization: PropTypes.func.isRequired,
        fetchUpdateProducts: PropTypes.func.isRequired,
        fetchSubcategoryGroups: PropTypes.func.isRequired,
        fetchSubcategoryMetrics: PropTypes.func.isRequired,
        fetchProductsFromAssorter: PropTypes.func.isRequired,
        resetProductsFromAssorter: PropTypes.func.isRequired,
        resetProductsFromFile: PropTypes.func.isRequired,
        resetSubcategoriesAfterLoad: PropTypes.func.isRequired,
        downloadProductTemplate: PropTypes.func.isRequired,
        project: PropTypes.object,
        projectId: PropTypes.string.isRequired,
        importFromFileResult: PropTypes.object,
        importFromAssorterResult: PropTypes.array,
        importMode: PropTypes.oneOf(["realization", "file"])
    };

    state = {
        step: ImportPanelState.ADD_IMPORT,
        selectedCategoryId: []
    };

    componentDidUpdate(prevProps) {
        const {
            realizationResult,
            importFromFileResult,
            importFromAssorterResult,
            isOpen,
            categoryKey,
            importMode,
            isProductsLoading
        } = this.props;
        const {
            isOpen: prevOpen,
            isProductsLoading: prevProductsLoading
        } = prevProps;
        if (
            !isProductsLoading &&
            prevProductsLoading &&
            this.state.step === ImportPanelState.LOADING_SAVE_PRODUCTS
        ) {
            this.handleClose();
            return;
        }
        if (importMode === "realization") {
            if (isOpen && !prevOpen) {
                this.setState({
                    step: ImportPanelState.LOADING_REALIZATION
                });
                this.loadRealization();
                return;
            }
        }
        const {
            realizationResult: prevRealizationResult,
            importFromFileResult: prevImportFromFileResult,
            importFromAssorterResult: prevImportFromAssorterResult
        } = prevProps;
        if (
            realizationResult === prevRealizationResult &&
            importFromFileResult === prevImportFromFileResult &&
            importFromAssorterResult === prevImportFromAssorterResult
        )
            return;
        if (
            importFromFileResult ||
            importFromAssorterResult ||
            realizationResult
        ) {
            let verifiedProductsLen = 1;
            const isFileResult = importMode === "file" && importFromFileResult;
            const isRealizationResult =
                importMode === "realization" && realizationResult;
            if (isFileResult || isRealizationResult) {
                const result =
                    importMode === "file"
                        ? importFromFileResult
                        : realizationResult;
                const verifiedProducts = result.verifiedProducts;
                if (Array.isArray(verifiedProducts)) {
                    verifiedProductsLen = verifiedProducts.length;
                }
                if (result.status === "ERROR") {
                    this.props.onClose();
                    return;
                }
                if (Array.isArray(verifiedProducts)) {
                    this.setState({
                        selectedCategoryId: verifiedProducts.map(
                            el => el[categoryKey]
                        )
                    });
                }
            }
            this.setState({
                step:
                    verifiedProductsLen > 1
                        ? ImportPanelState.SELECT_IMPORT
                        : ImportPanelState.CONFIRM_IMPORT
            });
        }
    }

    loadRealization = () => {
        const {
            selectedNodes,
            dmtIntegrationProducts,
            additionalAttributes
        } = this.props;
        if (!dmtIntegrationProducts) {
            console.warn("Could not find dmt integration config");
            return;
        }
        const groupByAttribute = dmtIntegrationProducts.groupByAttributeId;
        const coreAttributes = dmtIntegrationProducts.generalAttributes;
        this.props.fetchRealization({
            nodeIdList: selectedNodes.map(el => el.id).join(","),
            groupByAttribute: JSON.stringify(groupByAttribute),
            coreAttributes: JSON.stringify(coreAttributes),
            additionalAttributes: JSON.stringify(additionalAttributes)
        });
    };

    handleClose = () => {
        const {
            onClose,
            resetProductsFromAssorter,
            resetProductsFromFile
        } = this.props;
        this.setState({
            step: ImportPanelState.ADD_IMPORT
        });
        resetProductsFromAssorter();
        resetProductsFromFile();
        onClose();
    };

    handleClickImportFromAssorter = () => {
        const { fetchProductsFromAssorter, projectId } = this.props;
        fetchProductsFromAssorter(projectId);
    };

    handleFileChange = event => {
        const { verifyProductImport, projectId } = this.props;
        if (event.target && event.target.files) {
            const fileList = event.target.files;
            const fileListNum = fileList.length;
            for (let i = 0; i < fileListNum; ++i) {
                verifyProductImport(projectId, fileList[i]);
            }
        }
    };

    handleSave = () => {
        const {
            projectId,
            importMode,
            realizationResult,
            importFromFileResult,
            importFromAssorterResult,
            setProjectProducts,
            fetchUpdateProducts,
            resetSubcategoriesAfterLoad,
            fetchSubcategoryMetrics,
            fetchSubcategoryGroups,
            categoryKey,
            hierarchySelection,
            updateHierarchySelection
        } = this.props;
        const { selectedCategoryId } = this.state;
        const result =
            importMode === "file" ? importFromFileResult : realizationResult;
        if (importMode === "realization" && hierarchySelection) {
            hierarchySelection.products.selectedCategoryIds = selectedCategoryId;
        }
        if (importMode === "file") {
            hierarchySelection.products = {};
        }
        if (result) {
            const selectedProducts = result.verifiedProducts
                .filter(el => selectedCategoryId.includes(el[categoryKey]))
                .reduce((agg, curr) => agg.concat(curr.products), []);
            fetchUpdateProducts(projectId, selectedProducts);
            resetSubcategoriesAfterLoad(selectedProducts);
            updateHierarchySelection(projectId, hierarchySelection);
        } else if (importFromAssorterResult) {
            setProjectProducts(importFromAssorterResult);
            resetSubcategoriesAfterLoad(importFromAssorterResult);
            fetchSubcategoryMetrics(projectId);
            fetchSubcategoryGroups(projectId);
        }
        this.handleLoadingSaveProducts();
    };

    handleLoadingSaveProducts = () => {
        this.setState({
            step: ImportPanelState.LOADING_SAVE_PRODUCTS
        });
    };

    handleSelectConfirm = () => {
        this.setState({
            step: ImportPanelState.CONFIRM_IMPORT
        });
    };

    getSaveHandler = () => {
        const { step } = this.state;
        if (step === ImportPanelState.SELECT_IMPORT) {
            return this.handleSelectConfirm;
        } else if (step === ImportPanelState.CONFIRM_IMPORT) {
            return this.handleSave;
        } else return null;
    };

    handleChangeCategory = event => {
        const category = event.target.value;
        this.setState(state => {
            let newState = state.selectedCategoryId.includes(category)
                ? state.selectedCategoryId.filter(e => e !== category)
                : [...state.selectedCategoryId, category];
            return {
                ...state,
                selectedCategoryId: newState
            };
        });
    };

    render() {
        const {
            categoryKey,
            title,
            isOpen,
            project,
            projectId,
            importFromFileResult,
            importFromAssorterResult,
            realizationResult,
            downloadProductTemplate,
            importMode
        } = this.props;
        const { step, selectedCategoryId } = this.state;
        return (
            <DialogComponent
                isOpen={isOpen}
                onClose={this.handleClose}
                onSave={this.getSaveHandler()}
                title={title}
                disableBackdropClick={true}
            >
                <ProductImport
                    categoryKey={categoryKey}
                    step={step}
                    project={project}
                    projectId={projectId}
                    onClickImportFromAssorter={
                        this.handleClickImportFromAssorter
                    }
                    onChangeImportFromFile={this.handleFileChange}
                    importMode={importMode}
                    realizationResult={realizationResult}
                    importFromFileResult={importFromFileResult}
                    importFromAssorterResult={importFromAssorterResult}
                    selectedCategoryId={selectedCategoryId}
                    onChangeCategory={this.handleChangeCategory}
                    onClickDownloadProductTemplate={downloadProductTemplate}
                />
            </DialogComponent>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductImportDialog);
