import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

/**
 * This component is responsible for rendering the MUI IconButton with a11y.
 * visuallyHidden is standard from HTML5 for screen readers to standardize
 * across different browsers
 */

const styles = {
    visuallyHidden: {
        border: 0,
        height: 1,
        width: 1,
        margin: -1,
        padding: 0,
        overflow: "hidden",
        position: "absolute",
        clip: "rect(0 0 0 0)"
    }
};

const IconButtonA11y = props => {
    let {
        ariaLabel,
        onClick,
        className: classNameProp,
        children,
        overrideClasses,
        disableRipple,
        classes,
        ...other
    } = props;
    return (
        <IconButton
            className={classNameProp}
            onClick={onClick}
            classes={overrideClasses}
            disableRipple={disableRipple ? true : false}
            {...other}
        >
            <span className={classes.visuallyHidden}>{ariaLabel}</span>
            {children}
        </IconButton>
    );
};

IconButtonA11y.propTypes = {
    ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string
};

export default withStyles(styles)(IconButtonA11y);
