import {cancel, delay, fork, take} from "redux-saga/effects";

import fetch, {extendSessionIfRequired} from "../../../services/fetch";

import {ADD_REMOVE_NODES, UPDATE_NEED_UNITS, UPDATE_NODE_NAME, UPDATE_NODE_PRODUCTS} from "../tree/savedset";
import {SET_CLUSTER_SELECTED} from "../reassign/reassign";

// 5 second delay
export const DEBOUNCE_DELAY = 5000;

export function* handleChange() {
    // Debounce
    yield delay(DEBOUNCE_DELAY);
    // After 5000ms extend the session
    const isExtend = extendSessionIfRequired();
    // Make call to keep BE alive
    if (isExtend) {
        fetch("/manage/health", "get")
            .then(() => {
                console.info("Keep-alive");
            })
            .catch(err => {
                console.error("Error: ", err);
            });
    }
}

export function* watchExtendSession() {
    let task;
    while (true) {
        // on split/combine, node name change, reassign actions
        yield take([
            SET_CLUSTER_SELECTED,
            UPDATE_NEED_UNITS,
            UPDATE_NODE_PRODUCTS,
            ADD_REMOVE_NODES,
            UPDATE_NODE_NAME
        ]);
        // Debounce
        if (task) {
            yield cancel(task);
        }
        // handle change
        task = yield fork(handleChange);
    }
}
