import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButtonA11y from "../Button/IconButtonA11y";
import CloseIcon from "../../styles/icons/icon_close_small.svg";
import ErrorIcon from "@material-ui/icons/ErrorOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    paper: {
        margin: 0,
        width: theme.breakpoints.values.md,
        [theme.breakpoints.up("sm")]: {
            margin: 32
        }
    },
    button: {
        position: "absolute",
        top: 22,
        right: 24,
        float: "right",
        cursor: "pointer",
        "&:hover $iconBackground": {
            background: theme.status.clay[50]
        }
    },
    iconBackground: {
        cursor: "pointer",
        width: 25,
        height: 25,
        background: theme.status.clay[20],
        borderRadius: 100,
        position: "absolute"
    },
    icon: {
        fontSize: 9,
        width: 9,
        marginTop: 8,
        display: "block",
        marginLeft: 8
    },
    titleContainer: {
        padding: 10,
        background: theme.status.clay[10],
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
    },
    title: {
        lineHeight: "48px",
        textAlign: "center"
    },
    content: {
        padding: "0 15px 15px 15px",
        [theme.breakpoints.up("sm")]: {
            padding: 30
        }
    },
    contentNoPadding: {
        padding: 0
    },
    centred: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 30
    },
    buttonCancel: {
        width: "45%",
        [theme.breakpoints.up("sm")]: {
            width: 170
        }
    },
    buttonSave: {
        marginLeft: 15,
        width: "45%",
        [theme.breakpoints.up("sm")]: {
            width: 170
        }
    },
    buttonDiscard: {
        marginLeft: 15,
        width: "45%",
        [theme.breakpoints.up("sm")]: {
            width: 170
        }
    },
    error: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    errorText: {
        textAlign: "right",
        paddingRight: 45
    },
    errorIcon: {
        paddingRight: 15,
        color: theme.palette.error.main
    },
    errorButton: {
        backgroundColor: theme.palette.error.main
    }
});

const DialogComponent = props => {
    const {
        maxWidth,
        onClose,
        onSave,
        saveLabel,
        onDiscard,
        discardLabel,
        isOpen,
        isFetching,
        canSave,
        title,
        padding,
        children,
        errorSaving,
        centre,
        classes,
        t,
        disableBackdropClick
    } = props;
    return (
        <Dialog
            maxWidth={maxWidth}
            fullWidth
            open={isOpen}
            onClose={onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            classes={{ paper: classes.paper }}
            disableBackdropClick={disableBackdropClick}
        >
            {title && (
                <div className={classes.titleContainer}>
                    <Typography
                        id="dialog-title"
                        align="center"
                        variant="subtitle1"
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                    <IconButtonA11y
                        onClick={onClose}
                        className={classes.button}
                        ariaLabel={t("common.close")}
                    >
                        <span className={classes.iconBackground}>
                            <img
                                src={CloseIcon}
                                className={classes.icon}
                                alt=""
                            />
                        </span>
                    </IconButtonA11y>
                </div>
            )}
            {children && (
                <DialogContent
                    id="dialog-description"
                    className={classNames({
                        [classes.content]: padding,
                        [classes.contentNoPadding]: !padding,
                        [classes.centred]: centre
                    })}
                >
                    {children}
                </DialogContent>
            )}
            {errorSaving && (
                <div className={classes.error}>
                    <ErrorIcon className={classes.errorIcon} />
                    <Typography className={classes.errorText}>
                        {t("common.errorSaving")}
                    </Typography>
                </div>
            )}
            {(onSave || onDiscard) && (
                <div className={classes.buttonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClose}
                        className={classes.buttonCancel}
                    >
                        {t("common.cancel")}
                    </Button>

                    {onSave && (
                        <Button
                            variant="contained"
                            color={errorSaving ? "inherit" : "primary"}
                            disabled={!canSave || isFetching}
                            onClick={onSave}
                            className={classNames(classes.buttonSave, {
                                [classes.errorButton]: errorSaving
                            })}
                        >
                            {isFetching && (
                                <CircularProgress size={25} thickness={1} />
                            )}
                            {!isFetching && t(saveLabel)}
                        </Button>
                    )}
                    {onDiscard && (
                        <Button
                            variant="contained"
                            onClick={onDiscard}
                            className={classNames(classes.buttonDiscard, {
                                [classes.errorButton]: true
                            })}
                        >
                            {isFetching && (
                                <CircularProgress size={25} thickness={1} />
                            )}
                            {!isFetching && t(discardLabel)}
                        </Button>
                    )}
                </div>
            )}
        </Dialog>
    );
};

DialogComponent.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    canSave: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    padding: PropTypes.bool,
    dialogProps: PropTypes.object,
    maxWidth: PropTypes.string,
    centre: PropTypes.bool,
    disableBackdropClick: PropTypes.bool
};

DialogComponent.defaultProps = {
    canSave: true,
    saveLabel: "common.save",
    dialogProps: {},
    maxWidth: "md",
    centre: false,
    discardLabel: "common.discard",
    disableBackdropClick: false
};

export default withTranslation()(withStyles(styles)(DialogComponent));
