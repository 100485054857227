import React, { Component } from "react";

//Third libs
import classNames from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

//Material UI
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const styles = theme => ({
    container: {
        marginTop: 0
    },
    tableHeader: {
        backgroundColor: theme.status.clay[10]
    },
    tableHeaderCell: {
        fontSize: 14,
        fontWeight: 600
    },
    tableCell: {
        padding: "4px 14px",
        color: theme.status.midnight[100]
    },
    pagination: {
        fontSize: "0.75rem"
    }
});

export class TreeInfoPanelTable extends Component {
    static propTypes = {
        initialSortBy: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                render: PropTypes.func.isRequired
            })
        ).isRequired
    };

    state = {
        sortBy: this.props.initialSortBy,
        order: "desc",
        page: 0,
        rowsPerPage: 5
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleHeaderClick = prop => event => {
        const willChangeProp = prop !== this.state.sortBy;
        const isDescending = this.state.order === "desc";
        const order = !willChangeProp && isDescending ? "asc" : "desc";
        this.sortBy(prop, order);
        event.preventDefault();
    };

    sortBy(prop, order) {
        this.setState({ sortBy: prop, order: order });
    }

    render() {
        let { columns, data, t, classes } = this.props;
        let { sortBy, order, page, rowsPerPage } = this.state;
        return (
            <div className={classes.container}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableRow}>
                            {columns.map(column => (
                                <TableCell
                                    className={classNames(
                                        classes.tableCell,
                                        classes.tableHeaderCell
                                    )}
                                    key={column.id}
                                >
                                    <TableSortLabel
                                        active={sortBy === column.id}
                                        direction={order}
                                        onClick={this.handleHeaderClick(
                                            column.id
                                        )}
                                    >
                                        {t(column.label)}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .sort((a, b) => {
                                let x = order !== "desc" ? a : b;
                                let y = order !== "desc" ? b : a;
                                let xv = parseFloat(x[sortBy]);
                                let yv = parseFloat(y[sortBy]);
                                if (
                                    isNaN(xv) &&
                                    typeof x[sortBy] === "string"
                                ) {
                                    return x[sortBy].localeCompare(y[sortBy]);
                                } else {
                                    return xv - yv;
                                }
                            })
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((product, id) => {
                                return (
                                    <TableRow key={id}>
                                        {columns.map(column => (
                                            <TableCell
                                                key={column.id}
                                                className={classes.tableCell}
                                            >
                                                {column.render(
                                                    product[column.id]
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                {data.length > rowsPerPage && (
                    <TablePagination
                        component="div"
                        className={classes.pagination}
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10]}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "Previous Page"
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next Page"
                        }}
                        onChangePage={this.handleChangePage}
                    />
                )}
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(TreeInfoPanelTable));
