import { createSelector } from "reselect";
import { selectReassignSelectedProducts } from "./reassign";
import {
    selectConfigAdditionalAttributeExclude,
    selectConfigAdditionalAttributePairs
} from "redux/modules/config";
import { selectAllVersionsProductsDict } from "redux/modules/versions/versions";
import { selectProductDict } from "redux/modules/tree/savedset";
import {
    buildProductAttrInfo,
    buildAvailableProductAttrs
} from "redux/modules/tree/productAttributes";
import { selectSavedSetProductList } from "redux/modules/tree/currentSavedSetProducts";

export const selectReassignProductAttributes = createSelector(
    [
        selectReassignSelectedProducts,
        selectSavedSetProductList,
        selectConfigAdditionalAttributeExclude,
        selectConfigAdditionalAttributePairs
    ],
    buildProductAttrInfo
);

export const selectReassignAvailableProductAttributes = createSelector(
    [selectProductDict, selectAllVersionsProductsDict],
    buildAvailableProductAttrs
);
