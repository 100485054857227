import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const AppError = ({ message, t }) => <h3>{t(message)}</h3>;

AppError.propTypes = {
    t: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
};

export default withTranslation()(AppError);
