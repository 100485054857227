import { call, take, put } from "redux-saga/effects";
import { createSelector } from "reselect";

import api from "api";
import { fetchDMT } from "services/fetch";

const reducerName = "dmtUserDefinedAttributeList";

export const FETCH_USER_DEFINED_ATTRIBUTE_LIST = `${reducerName}/FETCH_USER_DEFINED_ATTRIBUTE_LIST`;
const REQUEST = `${reducerName}/REQUEST_USER_DEFINED_ATTRIBUTE_LIST`;
const RECEIVE = `${reducerName}/RECEIVE_USER_DEFINED_ATTRIBUTE_LIST`;
const ERROR = `${reducerName}/ERROR`;
const VALUES = `${reducerName}/VALUES`;
const RESET = `${reducerName}/RESET`;

export function fetchUserDefinedAttributeList() {
    return {
        type: FETCH_USER_DEFINED_ATTRIBUTE_LIST,
    };
}

export function resetUserDefinedDMTAttributeList() {
    return {
        type: RESET
    };
}

export function requestUserDefinedAttributeList() {
    return {
        type: REQUEST
    };
}

export function receiveUserDefinedAttributeList(res) {
    return {
        type: RECEIVE,
        payload: res
    };
}

export function errorUserDefinedAttributeList(err) {
    return {
        type: ERROR,
        payload: err
    };
}

export function updateUserDefinedAttributeList(values) {
    return {
        type: VALUES,
        payload: values
    };
}

const initialState = {
    values: [],
    error: null
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case RESET:
            return initialState;

        case RECEIVE:
            return {
                ...state,
                values: action.payload
            };
        case ERROR:
            return {
                ...state,
                error: action.payload
            };
        case VALUES:
            return {
                ...state,
                values: action.payload
            };
        default:
            return state;
    }
}

export default reducer;

export const selectUserDefinedAttributeValues = state => state[reducerName].values;
export const selectUserDefinedAttributeListError = state => state[reducerName].error;

export const selectUserDefinedAttributeList = createSelector(
    [selectUserDefinedAttributeValues],
    attributeList => {
        return attributeList.sort((a, b) => {
            const orgCompare = a.orgName.localeCompare(b.orgName);
            if (orgCompare === 0) {
                return a.attribute.attributeName.localeCompare(
                    b.attribute.attributeName
                );
            }
            return orgCompare;
        });
    }
);

export function* watchDMTUserDefinedAttributeList() {
    while (true) {
        yield take(FETCH_USER_DEFINED_ATTRIBUTE_LIST);
        const { url, type, error } = api["dmt.attribute.userDefinedList.get"]();
        yield put(requestUserDefinedAttributeList());
        try {
            const result = yield call(fetchDMT, url, type);
            yield put(receiveUserDefinedAttributeList(result));
        } catch (e) {
            yield put(errorUserDefinedAttributeList(error));
            console.error(e);
        }
    }
}
