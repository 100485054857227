export const CONTENT_PROJECT_ADD = "project add view";
export const CONTENT_PROJECT_EDIT = "project edit view";
export const CONTENT_PROJECT_VIEW = "project  view";
export const CONTENT_IMPORT_PRODUCT = "import product";
export const CONTENT_VERSION_PARAMETERS = "version parameters";

export const PANEL_ANIMATION_TIME = 0.7;

export const TAB_TREEVIEW_UNDERSTAND = 0;
export const TAB_TREEVIEW_REASSIGN = 1;

export const TAB_TREEVIEW_UNDERSTAND_FEATURES = 0;
export const TAB_TREEVIEW_UNDERSTAND_BRANDS = 1;
export const TAB_TREEVIEW_UNDERSTAND_PRODUCTS = 2;

// Formats
export const DATE_FORMAT = "YYYY-MM-DD";

// Key for unassigned need units
export const UNASSIGNED = "Unassigned";

// For toggle between tree views
export const HIERARCHICAL_VIEW = "hierarchical.view";
export const TREEMAP_VIEW = "treemap.view";

// Line option for hierarchical views
export const LINE_SIZE_PRODUCT = "line.size.product";
export const LINE_SIZE_SALES = "line.size.sales";
export const COLOUR_BY_INDEX = "colour.by.index";
