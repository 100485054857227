//Static Constants
export const TAB_HOME = "home";
export const TAB_PROJECT = "project";
export const TAB_VERSIONS = "versions";

export const TAB_TREE = "tree";
export const UPDATE_TAB = "modules/tab/UPDATE_TAB";

const initialState = {
    activeTab: TAB_HOME,
    tabs: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_TAB:
            return {
                ...state,
                activeTab: action.payload
            };

        default:
            return state;
    }
};

export const updateTab = name => {
    return {
        type: UPDATE_TAB,
        payload: name
    };
};

export function selectActiveTab(state) {
    return state.tab.activeTab;
}
