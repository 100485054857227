import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import theme from "../../styles/MuiTheme";

import TreemapContentText from "components/Tree/TreemapContentText";

// Fade in animation keyframes
const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
`;

const StyledBackgroundRect = styled.rect`
    z-index: ${props =>
        props.depth ? props.depth + (props.isChildren ? 0 : 1000) : 0};
    opacity: 0;
    pointer-events: none;
    animation-name: ${fade};
    animation-duration: 650ms;
    animation-iteration-count: 4;
    animation-easing-function: ease-in-out;
    animation-direction: alternate;
    animation-delay: 250ms;
`;

const StyledRect = styled.rect`
    z-index: ${props =>
        props.depth ? props.depth + (props.isChildren ? 0 : 1000) : 0};
    opacity: 0.95;
    cursor: pointer;
`;

const minSize = 60;

class TreemapContent extends Component {
    state = {
        isChange: false
    };

    constructor(props) {
        super(props);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.updateActiveOverlay();
    }

    componentDidUpdate(prevProps) {
        const {
            name: prevName,
            newSizeExcludingUnassigned: prevNewSizeExcludingUnassigned
        } = prevProps;
        const { name, newSizeExcludingUnassigned } = this.props;
        // product count has changed externally
        // flag change in state
        // check if is same node as before
        if (
            newSizeExcludingUnassigned !== prevNewSizeExcludingUnassigned &&
            name === prevName
        ) {
            this.setState({ isChange: true });
        }
        // if node changes completely reset state
        if (name !== prevName) {
            this.setState({ isChange: false });
        }
    }

    updateActiveOverlay() {
        if (
            this.props.activeOverlay &&
            this.props.activeClusterName === this.props.name
        )
            this.handleClick(null);
    }

    handleMouseEnter() {
        const { name, onMouseEnter } = this.props;
        onMouseEnter(name);
    }

    handleMouseLeave() {
        const { name, onMouseLeave } = this.props;
        onMouseLeave(name);
    }

    handleClick(event) {
        const { onClick, x, y, width, height } = this.props;

        // we want to display the size, units, sales information in the overlay if
        // we didn't have enough room to display it in the TreeContext
        const isNarrowVertical = height < minSize;
        const isNarrowHorizontal = width < minSize;
        const isNarrow = width < 90;
        const showMetrics = isNarrowHorizontal || isNarrowVertical || isNarrow;

        const clickCluster = {
            showMetrics: showMetrics,
            ...this.props,
            offsetX: x + width / 2,
            offsetY: y + height / 2
        };
        onClick(event, clickCluster);
    }

    getFillColour(isNode, nextToSplit) {
        const { activeClusterName, highlights, hoveringOn, name } = this.props;
        const isHover = name === hoveringOn;
        let fillColour = nextToSplit
            ? "url(#standard-nts)"
            : theme.status.clay[30];

        // highlighted takes precendence. This is used for showing which clusters will collapse back together.
        const isHighlight = highlights.indexOf(name) !== -1;

        // is this the clicked cluster
        const isClicked = activeClusterName && activeClusterName === name;

        if (isNode) {
            if (isHighlight) {
                fillColour = theme.status.green[100];
            } else if (isClicked) {
                fillColour = nextToSplit
                    ? "url(#clicked-nts)"
                    : theme.status.midnight[100];
            } else if (isHover) {
                fillColour = nextToSplit
                    ? "url(#hover-nts)"
                    : theme.status.yellow[50];
            }
        } else {
            fillColour = "none";
        }

        return fillColour;
    }

    getRect(isNode) {
        const { x, y, width, height } = this.props;
        const spacing = 1;
        return {
            x: isNode ? x + spacing : x,
            y: isNode ? y + spacing : y,
            w: isNode ? width - spacing : width,
            h: isNode ? height - spacing : height
        };
    }

    render() {
        const {
            nextCluster,
            depth,
            x,
            y,
            width,
            height,
            name,
            nodeName,
            children,
            size,
            totalSales,
            totalUnits,
            fontSize,
            activeClusterName,
            highlights
        } = this.props;
        const { isChange } = this.state;
        const isProduct = name && name.startsWith("00") ? true : false;
        const isNextCluster = nextCluster === name;
        const isChildren = Boolean(children);
        const isNode = !isChildren && name;
        const fill = this.getFillColour(isNode, isNextCluster);
        const stroke = "#fff";
        const strokeWidth = 0.3;
        const rect = this.getRect(isNode);
        const isNarrowVertical = height < minSize;
        const isNarrowHorizontal = width < minSize;
        const isNarrow = width < 90;
        return (
            <g>
                <StyledRect
                    depth={depth}
                    isChildren={isChildren}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleClick}
                    x={rect.x}
                    y={rect.y}
                    strokeLinejoin="round"
                    width={rect.w}
                    height={rect.h}
                    shapeRendering="crispEdges"
                    fill={fill}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                />
                {isChange && (
                    <StyledBackgroundRect
                        depth={depth}
                        isChildren={isChildren}
                        x={rect.x}
                        y={rect.y}
                        strokeLinejoin="round"
                        width={rect.w}
                        height={rect.h}
                        shapeRendering="crispEdges"
                        fill={theme.status.red[100]}
                        stroke={stroke}
                        strokeWidth={strokeWidth}
                    />
                )}

                {!isNarrowHorizontal && !isNarrowVertical && !isChildren && (
                    <TreemapContentText
                        x={x}
                        y={y}
                        scaledFont={`${fontSize}px`}
                        name={nodeName}
                        size={size}
                        isClicked={
                            activeClusterName && activeClusterName === name
                        }
                        totalSales={totalSales}
                        totalUnits={totalUnits}
                        isProduct={isProduct}
                        isHighlight={highlights.indexOf(name) !== -1}
                        isNarrow={isNarrow}
                    />
                )}
                {isNarrowHorizontal && !isChildren && (
                    <Hamburger
                        x={x}
                        y={y}
                        width={rect.w}
                        height={rect.h}
                        name={nodeName}
                        size={size}
                        isClicked={
                            activeClusterName && activeClusterName === name
                        }
                        totalSales={totalSales}
                        totalUnits={totalUnits}
                        isProduct={isProduct}
                    />
                )}
                {isNarrowVertical && !isNarrowHorizontal && !isChildren && (
                    <HamburgerVert
                        x={x}
                        y={y}
                        width={rect.w}
                        height={rect.h}
                        name={nodeName}
                        size={size}
                        isClicked={
                            activeClusterName && activeClusterName === name
                        }
                        totalSales={totalSales}
                        totalUnits={totalUnits}
                        isProduct={isProduct}
                    />
                )}
            </g>
        );
    }
}

const HamburgerVert = ({ x, y, width, height, isClicked }) => {
    const fill = isClicked ? theme.status.grey.white : theme.status.grey.dark;
    return (
        <g pointerEvents="none">
            <circle
                cx={6 + x + width / 2}
                cy={y + height / 2}
                r="2"
                stroke="none"
                fill={fill}
            />
            <circle
                cx={x + width / 2}
                cy={y + height / 2}
                r="2"
                stroke="none"
                fill={fill}
            />
            <circle
                cx={x - 6 + width / 2}
                cy={y + height / 2}
                r="2"
                stroke="none"
                fill={fill}
            />
        </g>
    );
};

const Hamburger = ({ x, y, width, height, isClicked }) => {
    const fill = isClicked ? theme.status.grey.white : theme.status.grey.dark;
    return (
        <g pointerEvents="none">
            <circle
                cx={x + width / 2}
                cy={6 + y + height / 2}
                r="2"
                stroke="none"
                fill={fill}
            />
            <circle
                cx={x + width / 2}
                cy={y + height / 2}
                r="2"
                stroke="none"
                fill={fill}
            />
            <circle
                cx={x + width / 2}
                cy={y - 6 + height / 2}
                r="2"
                stroke="none"
                fill={fill}
            />
        </g>
    );
};

TreemapContent.propTypes = {
    fontSize: PropTypes.number
};

TreemapContent.defaultProps = {
    fontSize: 12
};

export default TreemapContent;
