import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
// MUI
import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const StyledDialogInputWrapper = styled.div`
    width: 300px;
`;

const styles = {
    formLabel: {
        marginBottom: 15
    }
};

const SavedSetDialogInput = ({
    t,
    classes,
    savedSetName,
    onSavedSetNameChange
}) => (
    <StyledDialogInputWrapper>
        <FormControl>
            <FormLabel className={classes.formLabel}>
                {t("tree.enter_saved_set_name")}
            </FormLabel>
            <TextField
                required
                id="saved-set-name"
                label={t("tree.saved_set_name_label")}
                autoFocus
                value={savedSetName}
                InputLabelProps={{
                    shrink: true
                }}
                onChange={onSavedSetNameChange}
            />
        </FormControl>
    </StyledDialogInputWrapper>
);

export default withTranslation()(withStyles(styles)(SavedSetDialogInput));
