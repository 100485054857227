import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const styles = theme => ({
    selectFormControl: {
        padding: 30
    }
});

ProductImportSelectCategory.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onChangeCategory: PropTypes.func.isRequired,
    selectedCategoryId: PropTypes.array.isRequired,
    verifiedProducts: PropTypes.array.isRequired
};

function ProductImportSelectCategory({
    t,
    classes,
    categoryKey = "category_name",
    onChangeCategory,
    selectedCategoryId,
    verifiedProducts
}) {
    return (
        <FormControl className={classes.selectFormControl} component="fieldset">
            {verifiedProducts.map((el, index) => {
                return (
                    <FormControlLabel
                        key={index}
                        value={el[categoryKey]}
                        control={
                            <Checkbox
                                color="primary"
                                checked={selectedCategoryId.includes(
                                    el[categoryKey]
                                )}
                                onChange={onChangeCategory}
                                value={el[categoryKey]}
                            />
                        }
                        label={t("product_import.select_label", {
                            category: el[categoryKey],
                            num: el.products.length
                        })}
                    />
                );
            })}
        </FormControl>
    );
}

export default withTranslation()(
    withStyles(styles)(ProductImportSelectCategory)
);
