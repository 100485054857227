import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    selectHighlightBrand,
    setHighlightBrand
} from "redux/modules/tree/current";

import HierarchicalBrandSelect from "components/Tree/HierarchicalBrandSelect";

const mapStateToProps = state => ({
    highlightBrand: selectHighlightBrand(state)
});

const mapDispatchToProps = {
    setHighlightBrand
};

export class HierarchicalBrandSelectContainer extends React.Component {
    static propTypes = {
        setHighlightBrand: PropTypes.func.isRequired,
        highlightBrand: PropTypes.object,
        brands: PropTypes.object
    };

    static defaultProps = {
        highlightBrand: {},
        brands: {}
    };

    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleOpen() {
        this.setState({ isOpen: true });
    }

    handleClose() {
        this.setState({ isOpen: false });
    }

    handleChange(event) {
        const brandName = event.target.value;
        const { setHighlightBrand, brands } = this.props;
        const brandValue = brands[brandName];
        setHighlightBrand({
            name: brandName,
            value: brandValue
        });
    }

    render() {
        const { highlightBrand, brands } = this.props;
        const { isOpen } = this.state;
        const brandList = Object.keys(brands).map(brandName => ({
            name: brandName,
            value: brands[brandName]
        }));
        return (
            <HierarchicalBrandSelect
                isOpen={isOpen}
                handleChange={this.handleChange}
                handleOpen={this.handleOpen}
                handleClose={this.handleClose}
                highlightBrand={highlightBrand}
                brandList={brandList}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HierarchicalBrandSelectContainer);
