import styled from "styled-components";
import theme from "../../styles/MuiTheme";

const TreemapIcon = styled.i`
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 15px;
    border: 1px solid ${theme.status.grey.dark};
    opacity: ${props => (props.active ? 1 : 0.4)};
    ::before {
        content: "";
        position: absolute;
        right: 0;
        width: 10px;
        height: 9px;
        border-bottom: 1px solid ${theme.status.grey.dark};
    }
    ::after {
        content: "";
        position: absolute;
        right: 0;
        width: 10px;
        height: 15px;
        border-left: 1px solid ${theme.status.grey.dark};
    }
`;

export default TreemapIcon;
