import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
    selectAttributeList,
    selectAttributeListError,
    selectAttributeValues,
    fetchAttributeList,
    updateAttributeValues
} from "../../redux/modules/dmtAttributeList/dmtAttributeList";
import AttributeList from "./AttributeList";
import { selectUserInfo} from "redux/modules/user/get";

const AttributeListContainer = ({
                                    fetchAttributeList,
                                    projectId,
                                    ...remainingProps
                                }) => {
    useEffect(() => {
        fetchAttributeList(projectId);
    }, [fetchAttributeList, projectId]);
    return <AttributeList {...remainingProps} />;
};

const mapStateToProps = state => ({
    attributeList: selectAttributeList(state),
    attributeValues: selectAttributeValues(state),
    attributeListError: selectAttributeListError(state),
    userInfo: selectUserInfo(state)
});

const mapDispatchToProps = {
    fetchAttributeList,
    updateAttributeValues
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttributeListContainer);
