import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DialogComponent from "../../uiComponents/Dialog/DialogComponent";

const SavedSetDialogCheck = ({
    isOpen,
    isNameWarning,
    isUnassignedWarning,
    handleClose,
    handleAccept,
    t
}) => (
    <DialogComponent
        onClose={handleClose}
        onSave={handleAccept}
        isOpen={isOpen}
        padding
        maxWidth="sm"
        centre
        title={t("tree.save_saved_set_dialog_title")}
    >
        <div>
            {isNameWarning && <div>{t("tree.saved_set_name_warning")}</div>}
            {isUnassignedWarning && (
                <div>{t("tree.saved_set_unassigned_warning")}</div>
            )}
        </div>
    </DialogComponent>
);

SavedSetDialogCheck.propTypes = {
    isOpen: PropTypes.bool,
    isNameWarning: PropTypes.bool,
    isUnassignedWarning: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleAccept: PropTypes.func.isRequired
};

SavedSetDialogCheck.defaultProps = {
    isOpen: false,
    isNameWarning: false,
    isUnassignedWarning: false
};

export default withTranslation()(SavedSetDialogCheck);
