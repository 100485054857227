import {
    put,
    take,
    takeLatest,
    all,
    select,
    cancel,
    fork,
    delay
} from "redux-saga/effects";
import _ from "lodash";

import {
    RECEIVE_SUBCATEGORY_METRICS,
    selectSubcategoryMetrics
} from "redux/modules/subcategories/metrics";
import {
    RECEIVE_SUBCATEGORY_GROUPS,
    selectSubcategoryGroups
} from "redux/modules/subcategories/groups";
import { fetchSaveGroups } from "./save";
import { selectProjectId } from "../projects/projects";

import { groupSubcategoriesFromProducts } from "helpers/groups";
import { ItemIds } from "../../../components/SubcategoryGroups/SubcategoryDragConstants";

const SET_SAVING = "subcategories/SET_SAVING";
export const SAVE_GROUPS = "subcategories/SAVE_GROUP";
const DELETE_GROUP = "subcategories/DELETE_GROUP";
const RESET_SUBCATEGORIES = "subcategories/RESET";
const RESET_SUBCATEGORIES_AFTER_LOAD = "subcategories/RESET_AFTER_LOAD";
const UPDATE_GROUP_NAME = "subcategories/UPDATE_GROUP_NAME";
const UPDATE_SUBCATEGORY_USER_GROUPS =
    "subcategories/UPDATE_SUBCATEGORY_USER_GROUPS";
const SUBCATEGORY_DRAG_END = "subcategories/SUBCATEGORY_DRAG_END";
const SUBCATEGORY_DRAG_START = "subcategories/SUBCATEGORY_DRAG_START";
const HAS_DRAGGED = "subcategories/HAS_DRAGGED";
const SET_SELECTED_ITEMS = "subcategories/SET_SELECTED_ITEMS";

// Actions
export function resetSubcategoriesAfterLoad(products) {
    const res = groupSubcategoriesFromProducts(products);
    return {
        type: RESET_SUBCATEGORIES_AFTER_LOAD,
        payload: res
    };
}

export function setHasDragged() {
    return {
        type: HAS_DRAGGED
    };
}

export function resetSubcategories() {
    return {
        type: RESET_SUBCATEGORIES
    };
}

export function subcategoryDragStart(id) {
    return {
        type: SUBCATEGORY_DRAG_START,
        payload: {
            id
        }
    };
}

export function subcategoryDragEnd(id, sourceGroupId, targetId, targetGroupId) {
    return {
        type: SUBCATEGORY_DRAG_END,
        payload: {
            id,
            sourceGroupId,
            targetId,
            targetGroupId
        }
    };
}

export function deleteGroup(id) {
    return {
        type: DELETE_GROUP,
        payload: id
    };
}

const initialState = {
    // list of groups with subcategories
    // first group is designated 'ungrouped'
    groups: [],
    selectedItems: [],
    isSaving: false,
    hasDragged: false
};

export function setSaving(payload) {
    return {
        type: SET_SAVING,
        payload
    };
}

export function setSelectedItems(payload) {
    return {
        type: SET_SELECTED_ITEMS,
        payload
    };
}

export function updateGroupName(groupId, value) {
    return {
        type: UPDATE_GROUP_NAME,
        payload: {
            groupId,
            value
        }
    };
}

export function saveGroups() {
    return {
        type: SAVE_GROUPS
    };
}

function updateSubcategoryUserGroups(payload) {
    return {
        type: UPDATE_SUBCATEGORY_USER_GROUPS,
        payload
    };
}

let subCatId = 0;
let groupId = 0;

function getSubCatId() {
    return `subcat-${subCatId++}`;
}

function getGroupId() {
    return `group-${groupId++}`;
}

function getGroupedItems(groups, metrics) {
    return groups.map(item => ({
        id: getGroupId(),
        name: item.name,
        subcategories: item.subcategories.map(subcat => ({
            id: getSubCatId(),
            content: subcat,
            count: metrics.reduce(
                (n, curr) =>
                    curr.subcategory === subcat ? curr.productCount : n,
                0
            )
        }))
    }));
}

function getUngroupedItems(metrics) {
    return {
        id: ItemIds.UNGROUPED_GROUP_ID,
        name: "ungrouped",
        subcategories: metrics.map(item => ({
            id: getSubCatId(),
            count: item.productCount,
            content: item.subcategory
        }))
    };
}

function createNewGroup(subcategories) {
    return {
        id: getGroupId(),
        name: subcategories
            .map(el => el.content)
            .join("_")
            .substring(0, 250),
        subcategories
    };
}

function updateGroupItemName(items, { groupId, value }) {
    return items.map(item =>
        item.id === groupId
            ? {
                  ...item,
                  name: value
              }
            : item
    );
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case RESET_SUBCATEGORIES: {
            return initialState;
        }
        case SET_SAVING: {
            return {
                ...state,
                isSaving: action.payload
            };
        }
        case HAS_DRAGGED: {
            return {
                ...state,
                hasDragged: true
            };
        }
        case UPDATE_GROUP_NAME: {
            return {
                ...state,
                groups: updateGroupItemName(state.groups, action.payload)
            };
        }
        case UPDATE_SUBCATEGORY_USER_GROUPS:
            return {
                ...state,
                groups: action.payload
            };
        case SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: action.payload
            };
        default:
            return state;
    }
}

export function selectSubcategoriesRedux(state) {
    return state.subcategories;
}

export function selectSubcategoryUserGroups(state) {
    return selectSubcategoriesRedux(state).groups;
}

export function selectUngroupedItems(state) {
    return selectSubcategoryUserGroups(state).find(
        el => el.id === ItemIds.UNGROUPED_GROUP_ID
    );
}

export function selectGroupedItems(state) {
    return selectSubcategoryUserGroups(state).filter(
        el => el.id !== ItemIds.UNGROUPED_GROUP_ID
    );
}

export function selectHasDragged(state) {
    return selectSubcategoriesRedux(state).hasDragged;
}

export function selectSaving(state) {
    return selectSubcategoriesRedux(state).isSaving;
}

export function selectSelectedItems(state) {
    return selectSubcategoriesRedux(state).selectedItems;
}

export function* watchSubcategoryGroupsLoad() {
    while (true) {
        // Wait for both groups and metrics to be received
        yield all([
            take(RECEIVE_SUBCATEGORY_GROUPS),
            take(RECEIVE_SUBCATEGORY_METRICS)
        ]);
        // Select groups and metrics from store
        let groups = yield select(selectSubcategoryGroups);
        const metrics = yield select(selectSubcategoryMetrics);
        if (groups && metrics) {
            const projectId = yield select(selectProjectId);
            // If groups is empty and metrics is not
            // group everything..
            const allEmpty = groups.length === 0 && metrics.length !== 0;
            // Check groups have valid subcategories
            // Available subcategories
            const availableSubcategoryNames = metrics.map(el => el.subcategory);
            const isValid = groups.every(item => {
                return item.subcategories.every(subcatName =>
                    availableSubcategoryNames.includes(subcatName)
                );
            });
            const nonEmptyAndInvalid = !isValid && groups.length > 0;
            if (allEmpty || nonEmptyAndInvalid) {
                const ungroupedItems = getUngroupedItems(metrics);
                let newGroup = createNewGroup(ungroupedItems.subcategories);
                newGroup = {
                    name: newGroup.name,
                    subcategories: newGroup.subcategories.map(el => el.content)
                };
                // override loaded groups
                groups = [newGroup];
                // Flag as saving
                yield put(setSaving(true));
                yield put(fetchSaveGroups(projectId, groups));
                yield put(setSaving(false));
            }
            // Remove from metrics where exists in group
            // First, get all grouped subcategories in flat list
            let allGroupedSubcategories = groups.map(item => {
                return item.subcategories;
            });
            if (allGroupedSubcategories.length > 0) {
                allGroupedSubcategories = [].concat.apply(
                    ...allGroupedSubcategories
                );
            }
            // Filter metrics leaving only ungrouped subcats
            const ungroupedMetrics = metrics.filter(
                item => allGroupedSubcategories.indexOf(item.subcategory) === -1
            );
            // Convert to drag and drop structure
            const subcategoryUserGroups = [
                getUngroupedItems(ungroupedMetrics),
                ...getGroupedItems(groups, metrics)
            ];
            // Dispatch actions to update store subcategory groups
            yield put(updateSubcategoryUserGroups(subcategoryUserGroups));
        }
    }
}

export const DEBOUNCE_DELAY = 1000;

export function* handleGroupChange(groups) {
    // Debounce
    yield delay(DEBOUNCE_DELAY);
    // After 1000ms save the groups
    const id = yield select(selectProjectId);
    yield put(fetchSaveGroups(id, groups));
    // Flag as done saving
    yield put(setSaving(false));
}

export function* watchSubcategoryGroupsChange() {
    let task;
    while (true) {
        // Either updated groups, group name, or created a group
        yield take([SAVE_GROUPS]);
        // Debounce
        if (task) {
            yield cancel(task);
        }
        // Select groups from store
        let groups = yield select(selectGroupedItems);
        const storeGroups = yield select(selectSubcategoryGroups);
        groups = groups.map(group => ({
            name: group.name,
            subcategories: group.subcategories.map(subcat => subcat.content)
        }));
        if (!_.isEqual(groups, storeGroups)) {
            // Flag as saving
            yield put(setSaving(true));
            task = yield fork(handleGroupChange, groups);
        }
    }
}

export function* watchSubcategoriesResetAfterLoad() {
    while (true) {
        yield take(RESET_SUBCATEGORIES_AFTER_LOAD);
        yield put(updateSubcategoryUserGroups([]));
    }
}

function* updateAfterDragStart({ payload }) {
    const { id } = payload;
    const selectedItems = yield select(selectSelectedItems);
    const selected = selectedItems.some(el => el === id);
    // if dragging an item that is not selected - unselect all items and replace with current id
    yield put(setHasDragged());
    if (!selected) yield put(setSelectedItems([id]));
}

export function* watchSubcategoryDragStart() {
    yield takeLatest(SUBCATEGORY_DRAG_START, updateAfterDragStart);
}

function* updateAfterDragEnd({ payload }) {
    const { targetGroupId } = payload;
    // add the selected items to the target group
    const selectedItems = yield select(selectSelectedItems);
    // current groups
    let userGroups = yield select(selectSubcategoryUserGroups);
    let selectedItemsToAdd = [];
    userGroups.forEach(userGroup => {
        selectedItemsToAdd = selectedItemsToAdd.concat(
            userGroup.subcategories.filter(el => selectedItems.includes(el.id))
        );
    });
    // remove source items from their source
    userGroups = userGroups.map(userGroup => ({
        ...userGroup,
        subcategories: userGroup.subcategories.filter(
            el => !selectedItems.includes(el.id)
        )
    }));
    // add to their targets
    if (targetGroupId === ItemIds.EMPTY_GROUP_ID) {
        // create a new group if dropped on empty area
        userGroups = userGroups.concat(createNewGroup(selectedItemsToAdd));
    } else {
        // update existing group
        userGroups = userGroups.map(userGroup => ({
            ...userGroup,
            subcategories:
                userGroup.id === targetGroupId
                    ? userGroup.subcategories.concat(selectedItemsToAdd)
                    : userGroup.subcategories
        }));
    }
    // filter out empty groups
    userGroups = userGroups.filter(
        userGroup =>
            userGroup.id === ItemIds.UNGROUPED_GROUP_ID ||
            userGroup.subcategories.length
    );
    yield put(updateSubcategoryUserGroups(userGroups));
    yield put(setSelectedItems([]));
}

export function* watchSubcategoryDragEnd() {
    yield takeLatest(SUBCATEGORY_DRAG_END, updateAfterDragEnd);
}

function* handleDeleteGroup({ payload }) {
    const groupId = payload;
    let userGroups = yield select(selectSubcategoryUserGroups);
    let deletedItems = [];
    userGroups.forEach(userGroup => {
        if (userGroup.id === groupId) {
            deletedItems = userGroup.subcategories;
        }
    });
    userGroups = userGroups
        .map(userGroup => {
            if (userGroup.id === groupId) {
                return null;
            }
            if (userGroup.id === ItemIds.UNGROUPED_GROUP_ID) {
                userGroup.subcategories = userGroup.subcategories.concat(
                    deletedItems
                );
            }
            return userGroup;
        })
        .filter(Boolean);
    yield put(updateSubcategoryUserGroups(userGroups));
    yield put(setSelectedItems([]));
}

export function* watchSubcategoryDelete() {
    yield takeLatest(DELETE_GROUP, handleDeleteGroup);
}
