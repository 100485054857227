//React
import React, {Component} from "react";
//Third parties libs
import PropTypes from "prop-types";
import numeral from "numeral";
import getNumeralLocale from "helpers/numeralLocales";
import fetch from "services/fetch";
//Redux
import {connect} from "react-redux";
//Redux Modules
import {fetchUserInfo, selectErrorUserInfo, selectLoadingUserInfo, selectUserInfo} from "redux/modules/user/get";
import {fetchConfig, selectConfig, selectErrorConfig, selectLoadingConfig} from "redux/modules/config";
//React Components
import AppError from "components/AppError";
import EmnosHeader from "components/EmnosHeader";
import EmnosFooter from "components/EmnosFooter";
// import CaptureUserFeedback from "components/CaptureUserFeedback/CaptureUserFeedback";
import Main from "routes/main";
// Services
import i18n from "services/i18n";
import ConfigProvider from "utils/ConfigProvider";
//Calendar
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import "moment/locale/fr";
import "moment/locale/ru";
import "moment/locale/pt";
import "moment/locale/en-gb";
import moment from "moment";

function setNumeralLocale(locale, currencySymbol) {
    if (!numeral.locales[locale]) {
        numeral.register(
            "locale",
            locale,
            getNumeralLocale(locale, currencySymbol)
        );
    }
    numeral.locale(locale);
}

const mapStateToProps = state => ({
    userInfo: selectUserInfo(state),
    errorUserInfo: selectErrorUserInfo(state),
    isLoadingUserInfo: selectLoadingUserInfo(state),
    config: selectConfig(state),
    errorConfig: selectErrorConfig(state),
    isLoadingConfig: selectLoadingConfig(state)
});

const mapDispatchToProps = {
    fetchUserInfo,
    fetchConfig
};

export class App extends Component {
    static propTypes = {
        config: PropTypes.object,
        isLoadingConfig: PropTypes.bool,
        errorConfig: PropTypes.string,
        userInfo: PropTypes.object,
        isLoadingUserInfo: PropTypes.bool,
        errorUserInfo: PropTypes.string,
        fetchUserInfo: PropTypes.func.isRequired,
        fetchConfig: PropTypes.func.isRequired
    };

    static localeMap = {
        fr_FR: "fr",
        en_FR: "fr",
        en_GB: "en-gb",
        en_PT: "pt",
        pd_PT: "pt",
        en_US: "en"
    };

    componentDidMount() {
        this.props.fetchUserInfo();
        this.props.fetchConfig();
    }

    componentDidUpdate(prevProps) {
        const {userInfo, config} = this.props;
        const {userInfo: prevUserInfo, config: prevConfig} = prevProps;
        if (userInfo && userInfo !== prevUserInfo) {
            this.changeLanguage();
        }

        if (config && config !== prevConfig) {
            this.changeNumericalLocale()
        }
    }

    changeNumericalLocale() {
        const {config} = this.props;
        const currencySymbol =
            config && config.global ? config.global.currency : "$";
        switch (currencySymbol) {
            case "€":
                setNumeralLocale("de", currencySymbol);
                break;
            case "₽":
                setNumeralLocale("ru", currencySymbol);
                break;
            case "zł":
                setNumeralLocale("pl", currencySymbol);
                break;
            default:
                setNumeralLocale("en", currencySymbol);
        }
    }

    changeLanguage() {
        const {userInfo} = this.props;
        const locale = userInfo.userProfile.locale;
        i18n.changeLanguage(locale);
        let localeToFetch = locale;
        if (!i18n.hasResourceBundle(locale, "translation")) {
            localeToFetch = i18n.languages[0];
        }
        fetch(`/config/${localeToFetch}`, "get")
            .then(resource => {
                i18n.addResourceBundle(
                    locale,
                    "translation",
                    resource,
                    true,
                    true
                );
            })
            .catch(err => {
                console.warn("Error fetching translations", err);
            });
    }

    render() {
        const {
            config,
            userInfo,
            isLoadingConfig,
            isLoadingUserInfo,
            errorConfig,
            errorUserInfo
        } = this.props;

        if (isLoadingConfig || isLoadingUserInfo) {
            return <h1>Loading</h1>;
        }
        if (config && userInfo) {
            const locale = App.localeMap[userInfo.userProfile.locale];
            moment.locale(locale);
            return (
                <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={locale}
                >
                    <ConfigProvider config={config} userInfo={userInfo}>
                        <EmnosHeader userInfo={userInfo}/>
                        {/*<CaptureUserFeedback/>*/}
                        <Main/>
                        <EmnosFooter/>
                    </ConfigProvider>
                </MuiPickersUtilsProvider>
            );
        }

        if (errorConfig) {
            return <AppError message={errorConfig}/>;
        }

        if (errorUserInfo) {
            return <AppError message={errorUserInfo}/>;
        }

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
