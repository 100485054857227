import React from "react";

import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({
    root: {
        position: "relative",
        marginBottom: 10
    },
    selectedText: {
        fontStyle: "italic"
    },
    chipColorPrimary: {
        backgroundColor: "#59B0E1"
    },
    chipColorSecondary: {
        backgroundColor: "#2643BC",
        color: "#ffffff"
    },
    optionRetailer: {
        position: "relative",
        paddingLeft: 12,
        "&:before": {
            content: "''",
            position: "absolute",
            top: 6,
            left: 0,
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "#59B0E1"
        }
    },
    optionUser: {
        position: "relative",
        paddingLeft: 12,
        "&:before": {
            content: "''",
            position: "absolute",
            top: 6,
            left: 0,
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "#2643BC"
        }
    }
});

export function renderLabel(label) {
    // replace _ with space
    let res = label.replace(/_/g, " ").toLowerCase();
    return res.replace(/\b\w/g, x => x.charAt(0).toUpperCase() + x.substr(1));
}

export function renderAttributeLabel(t, retailer, label) {
    let key = "attribute." + retailer + "." + label;
    let lookupValue = t(key)
    if (key === lookupValue)
        return renderLabel(label)
    else
        return lookupValue
}

export function isRetailer(option) {
    return option.attribute.attributeSource === "CORE_DB";
}

let AttributeAutocomplete = ({
                                 t,
                                 attributeList,
                                 attributeValues,
                                 handleChange,
                                 classes,
                                 retailer
                             }) => {
    return (
        <div className={classes.root}>
            <Autocomplete
                multiple
                id="attribute-list"
                options={attributeList}
                value={attributeValues}
                onChange={(event, newValue) => {
                    handleChange(newValue);
                }}
                groupBy={option => option.orgName}
                getOptionLabel={option =>
                    renderAttributeLabel(t, retailer, option.attribute.attributeName)
                }
                renderOption={(option, {selected}) => (
                    <div
                        className={
                            isRetailer(option)
                                ? classes.optionRetailer
                                : classes.optionUser
                        }
                    >
                        <div
                            className={
                                selected ? classes.selectedText : classes.text
                            }
                        >
                            {renderAttributeLabel(t, retailer, option.attribute.attributeName)}
                        </div>
                    </div>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            color={isRetailer(option) ? "primary" : "secondary"}
                            classes={{
                                colorPrimary: classes.chipColorPrimary,
                                colorSecondary: classes.chipColorSecondary
                            }}
                            label={
                                option.orgName +
                                "-" +
                                renderAttributeLabel(t, retailer, option.attribute.attributeName)
                            }
                            {...getTagProps({index})}
                        />
                    ))
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={t("product_import.start_typing")}
                        placeholder="Attribute"
                    />
                )}
            />
        </div>
    );
};

AttributeAutocomplete = withTranslation()(
    withStyles(styles)(AttributeAutocomplete)
);

export default AttributeAutocomplete;
