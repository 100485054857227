export const blue = {
    10: "#f9fcfd",
    20: "#f3f9fc",
    40: "#c4e2f0",
    100: "#6cb6d9",
    dark: "#323a51",
    extradark: "#024272"
};

export const cyan = {
    10: "#f4fcfe",
    20: "#ebfafe",
    40: "#99e4f9",
    80: "#33c9f3",
    100: "#00bcf0",
    dark: "#0291b9"
};

export const green = {
    10: "#f2f8f2",
    20: "#e5f2e7",
    40: "#cce6ce",
    80: "#99cd9d",
    100: "#7fc085",
    dark: "#4d8a53"
};

export const grey = {
    white: "#ffffff",
    greybG: "#fcfcfc",
    greybox: "#f8f8f8",
    greydivs: "#d8d8d8",
    100: "#929292",
    dark: "#404040"
};

export const mauve = {
    10: "#f6f4f8",
    20: "#eeeaf2",
    40: "#dcd6e5",
    80: "#b9adca",
    100: "#a898bd",
    dark: "#796395"
};

export const orange = {
    10: "#fdf2ee",
    20: "#fde6df",
    40: "#fbccbe",
    80: "#f6997d",
    100: "#f4805d",
    dark: "#bb5739"
};

export const pink = {
    10: "#faeef3",
    20: "#f5dde7",
    40: "#ecbcd0",
    80: "#d979a1",
    100: "#cf5789",
    dark: "#91375d"
};

export const red = {
    10: "#fdeff0",
    20: "#fcdfe2",
    40: "#f9c0c5",
    80: "#f4818c",
    100: "#f1616f",
    dark: "#bc3441"
};

export const teal = {
    10: "#eef9f9",
    20: "#def3f3",
    40: "#bce8e7",
    80: "#79d1d0",
    100: "#58c5c4",
    dark: "#34908f"
};

export const yellow = {
    10: "#fefaf3",
    20: "#fff6e7",
    40: "#ffeccf",
    50: "#fbf38d",
    80: "#fdd9a0",
    100: "#fcf7ba",
    dark: "#f8e71c"
};

export const zest = {
    50: "#f8f38d",
    60: "#fbf177",
    70: "#f9ee60",
    80: "#f9ec49",
    90: "#f8e932",
    100: "#f8e71c"
};

export const clay = {
    10: "#F8F7F6",
    20: "#f2f0ed",
    30: "#ece8e4",
    40: "#e6e1dc",
    50: "#dfd9d3",
    60: "#d9d1ca",
    70: "#d2c9c1",
    80: "#cdc2b9",
    90: "#c6baaf",
    100: "#c0b3a7"
};

export const midnight = {
    50: "#989ca8",
    60: "#838997",
    70: "#6e7585",
    80: "#5a6174 ",
    90: "#454d62",
    100: "#313a51"
};
