import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    TAB_VERSIONS,
    selectActiveTab,
    updateTab
} from "redux/modules/tab/change";
import { fetchProject, selectProjectId } from "redux/modules/projects/projects";
import { fetchVersions, selectVersions } from "redux/modules/versions/versions";

import VersionList from "components/Version/VersionList";
import PageTitle from "components/PageTitle/PageTitle";

const mapStateToProps = state => ({
    lastProjectId: String(selectProjectId(state)),
    versions: selectVersions(state),
    activeTab: selectActiveTab(state)
});

const mapDispatchToProps = {
    updateTab,
    fetchProject,
    fetchVersions
};

// component of Route
export class VersionListPage extends Component {
    static propTypes = {
        lastProjectId: PropTypes.string,
        versions: PropTypes.array.isRequired,
        activeTab: PropTypes.string.isRequired,
        // actions
        updateTab: PropTypes.func.isRequired,
        fetchProject: PropTypes.func.isRequired,
        fetchVersions: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.shouldFetch() && this.fetchData();
        this.shouldUpdateTab() && this.updateTab();
    }

    updateTab() {
        this.props.updateTab(TAB_VERSIONS);
    }

    shouldUpdateTab() {
        const { activeTab } = this.props;
        return activeTab !== TAB_VERSIONS;
    }

    fetchData() {
        const { fetchProject, fetchVersions } = this.props;
        const { projectId } = this.props.match.params;
        fetchProject(projectId);
        fetchVersions(projectId);
    }

    shouldFetch() {
        const { lastProjectId } = this.props;
        const { projectId } = this.props.match.params;
        const hasNeverFetched =
            typeof lastProjectId === "undefined" || lastProjectId === null;
        const hasChangedProjectId = projectId !== lastProjectId;
        return hasNeverFetched || hasChangedProjectId;
    }

    render() {
        const { versions } = this.props;
        const { projectId } = this.props.match.params;
        return (
            <div>
                <PageTitle title={"project_details.version_title"} />
                <VersionList versions={versions} projectId={projectId} />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VersionListPage);
