import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

// redux
import { saveSavedSet, updateSavedSet } from "redux/modules/versions/versions";
import { selectCurrentVersionNum } from "redux/modules/versions/current";
import {
    selectCurrentTreeId,
    selectCurrentSavedSetId
} from "redux/modules/tree/current";
import {
    selectSavedSetName,
    selectSavedSetNodeNames,
    selectSavedSetNeedUnits,
    selectSavedSetNeedUnitsWithUserDefined,
    selectSavedSetTree,
    findExpandedNodes
} from "redux/modules/tree/savedset";

import SavedSetSaveButton from "components/Tree/SavedSetSaveButton";
import SavedSetDialogConfirm from "components/Tree/SavedSetDialogConfirm";
import SavedSetDialogSave from "components/Tree/SavedSetDialogSave";
import SavedSetDialogCheck from "components/Tree/SavedSetDialogCheck";
import { USER_PREFIX } from "../../redux/modules/tree/savedset";

const mapStateToProps = state => ({
    isFetching: false,
    versionNumber: selectCurrentVersionNum(state),
    treeId: selectCurrentTreeId(state),
    currentSavedSetId: selectCurrentSavedSetId(state),
    currentSavedSetName: selectSavedSetName(state),
    savedSetNeedUnitsUserDefined: selectSavedSetNeedUnitsWithUserDefined(state),
    savedSetNeedUnits: selectSavedSetNeedUnits(state),
    savedSetNodeNames: selectSavedSetNodeNames(state),
    workingSavedSetData: selectSavedSetTree(state)
});

const mapDispatchToProps = {
    saveSavedSet,
    updateSavedSet
};

const StyledButtonsWrapper = styled.div`
    position: absolute;
    top: 60px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    button:last-child {
        margin-left: 30px;
    }
`;

export function getSavedSetPostData(
    savedSetName,
    savedSetNeedUnits,
    savedSetNodeNames
) {
    return {
        name: savedSetName,
        need_units: savedSetNeedUnits.map(el => ({
            need_unit: savedSetNodeNames[el.node] || el.node,
            node: el.node,
            products: el.products
        })),
        node_names: savedSetNodeNames
    };
}

export const hasNamedAllNeedUnits = (needUnits, nodeNames) => {
    return needUnits.every(n => nodeNames.hasOwnProperty(n.node));
};

export const ensureNeedUnitsAreExpandedNodes = (needUnits, data) => {
    const expandedNodes = findExpandedNodes(data).map(el => el.name);
    return needUnits.filter(item => {
        return expandedNodes.indexOf(item.node) !== -1;
    });
};

export class SavedSetSaveButtonContainer extends Component {
    static propTypes = {
        // props
        projectId: PropTypes.string.isRequired,
        isUnassignedProducts: PropTypes.bool.isRequired,
        // redux
        versionNumber: PropTypes.number.isRequired,
        treeId: PropTypes.number.isRequired,
        currentSavedSetId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        savedSetNeedUnits: PropTypes.array.isRequired,
        savedSetNodeNames: PropTypes.object.isRequired
    };

    state = {
        isHover: false,
        isSaveModalOpen: false,
        isConfirmModalOpen: false,
        isCheckModalOpen: false,
        isNameWarning: false,
        isUnassignedWarning: false,
        savedSetName: ""
    };

    constructor(props) {
        super(props);
        this.onClickSaveSet = this.onClickSaveSet.bind(this);
        this.onCancelSaveModal = this.onCancelSaveModal.bind(this);
        this.onAcceptSaveModal = this.onAcceptSaveModal.bind(this);
        this.onCancelConfirmModal = this.onCancelConfirmModal.bind(this);
        this.onAcceptConfirmModal = this.onAcceptConfirmModal.bind(this);
        this.onCancelCheckModal = this.onCancelCheckModal.bind(this);
        this.onAcceptCheckModal = this.onAcceptCheckModal.bind(this);
        this.onSavedSetNameChange = this.onSavedSetNameChange.bind(this);
    }

    submitSavedSet() {
        const {
            savedSetNeedUnitsUserDefined,
            savedSetNeedUnits,
            savedSetNodeNames,
            projectId,
            versionNumber,
            treeId: rangingSetId,
            saveSavedSet,
            updateSavedSet,
            currentSavedSetId,
            workingSavedSetData
        } = this.props;
        const { savedSetName, action } = this.state;
        // Only save need units that are expanded
        const expandedSaveSetNeedUnits = ensureNeedUnitsAreExpandedNodes(
            savedSetNeedUnits,
            workingSavedSetData
        );
        // Add any user defined need units
        const userDefined = savedSetNeedUnitsUserDefined.filter(el => el.node.indexOf(USER_PREFIX) !== -1);
        const expandedWithUserDefined = expandedSaveSetNeedUnits.concat(userDefined);
        const savedSetData = getSavedSetPostData(
            savedSetName,
            expandedWithUserDefined,
            savedSetNodeNames
        );
        if (action === "Save") {
            saveSavedSet(projectId, versionNumber, rangingSetId, savedSetData);
            this.setState({
                savedSetName: ""
            });
        }
        if (action === "Update") {
            updateSavedSet(
                parseInt(currentSavedSetId, 10),
                rangingSetId,
                savedSetData
            );
        }
    }

    openConfirmModal() {
        this.setState({ isConfirmModalOpen: true });
    }

    closeConfirmModal() {
        this.setState({ isConfirmModalOpen: false });
    }

    closeSaveModal() {
        this.setState({ isSaveModalOpen: false });
    }

    openSaveModal() {
        this.setState({ isSaveModalOpen: true });
    }

    closeCheckModal() {
        this.setState({ isCheckModalOpen: false });
    }

    openCheckModal(isNameWarning, isUnassignedWarning) {
        this.setState({
            isCheckModalOpen: true,
            isNameWarning,
            isUnassignedWarning
        });
    }

    onClickSaveSet(event, action) {
        const {
            savedSetNeedUnits,
            savedSetNodeNames,
            isUnassignedProducts,
            currentSavedSetName
        } = this.props;
        const isNameWarning = !hasNamedAllNeedUnits(
            savedSetNeedUnits,
            savedSetNodeNames
        );
        const isUnassignedWarning = isUnassignedProducts;
        this.setState({ action: action, savedSetName: currentSavedSetName });
        if (isNameWarning || isUnassignedWarning) {
            this.openCheckModal(isNameWarning, isUnassignedWarning);
        } else {
            this.openSaveModal();
        }
    }

    onCancelCheckModal() {
        this.closeCheckModal();
    }

    onAcceptCheckModal() {
        this.closeCheckModal();
        this.openSaveModal();
    }

    onCancelConfirmModal() {
        this.closeConfirmModal();
    }

    onAcceptConfirmModal() {
        this.submitSavedSet();
        this.closeConfirmModal();
    }

    onCancelSaveModal() {
        this.closeSaveModal();
    }

    onAcceptSaveModal() {
        this.closeSaveModal();
        this.submitSavedSet();
    }

    onSavedSetNameChange(event) {
        this.setState({
            savedSetName: event.target.value
        });
    }

    handleMouseEnter = event => {
        this.setState({
            isHover: true
        });
    };

    handleMouseLeave = event => {
        this.setState({
            isHover: false
        });
    };

    render() {
        const { isFetching, currentSavedSetId } = this.props;
        const isDefaultSavedSet = isNaN(currentSavedSetId);
        const {
            isHover,
            isSaveModalOpen,
            isConfirmModalOpen,
            isCheckModalOpen,
            isNameWarning,
            isUnassignedWarning,
            savedSetName
        } = this.state;
        return (
            <React.Fragment>
                <StyledButtonsWrapper>
                    <div
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        {!isDefaultSavedSet && (
                            <SavedSetSaveButton
                                isHover={isHover}
                                isFetching={isFetching}
                                onClickSave={event =>
                                    this.onClickSaveSet(event, "Save")
                                }
                                label={"common.saveAs"}
                                isSecondary
                            />
                        )}
                        <SavedSetSaveButton
                            isFetching={isFetching}
                            onClickSave={event =>
                                this.onClickSaveSet(
                                    event,
                                    isDefaultSavedSet ? "Save" : "Update"
                                )
                            }
                            label={"common.save"}
                        />
                    </div>
                </StyledButtonsWrapper>
                <SavedSetDialogCheck
                    handleClose={this.onCancelCheckModal}
                    handleAccept={this.onAcceptCheckModal}
                    isNameWarning={isNameWarning}
                    isUnassignedWarning={isUnassignedWarning}
                    isOpen={isCheckModalOpen}
                />
                <SavedSetDialogSave
                    onClose={this.onCancelSaveModal}
                    onSave={this.onAcceptSaveModal}
                    isOpen={isSaveModalOpen}
                    savedSetName={savedSetName}
                    handleSavedSetNameChange={this.onSavedSetNameChange}
                />
                <SavedSetDialogConfirm
                    handleClose={this.onCancelConfirmModal}
                    handleAccept={this.onAcceptConfirmModal}
                    isOpen={isConfirmModalOpen}
                />
            </React.Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavedSetSaveButtonContainer);
