import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import IconButtonA11y from "../../uiComponents/Button/IconButtonA11y";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import Dot from "./Dot";

const styles = theme => ({
    container: {
        padding: 35
    },
    icon: {
        marginTop: -8
    },
    trackFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    trackContainer: {
        overflow: "hidden",
        margin: 0,
        width: "100%"
    },
    track: {
        position: "relative",
        display: "flex",
        transition: "transform 1s ease-in-out"
    },
    dotsContainer: {
        paddingTop: 35,
        paddingBottom: 35,
        textAlign: "center"
    },
    arrowButton: {
        background: theme.status.grey.white,
        borderRadius: "50%",
        width: 30,
        height: 30,
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.15)",
        zIndex: 100
    }
});

const trackWidth = (len, perPage) => (len / perPage) * 100;

const xOffset = (offset = 0, len = 1) => {
    const x = (offset / len) * 100 * -1;
    return `translateX(${x}%)`;
};

class Carousel extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        numItems: PropTypes.number.isRequired
    };

    static defaultProps = {
        perPage: 3
    };

    state = {
        offset: 0
    };

    clampOffset = offset => {
        const { numItems, perPage } = this.props;
        const maxOffset = numItems - perPage;
        if (offset < 0) return 0;
        if (offset >= maxOffset) return maxOffset;
        return offset;
    };

    updateOffset = newOffset => {
        const offset = this.clampOffset(newOffset);
        this.setState({ offset });
    };

    go = dir => {
        const { perPage } = this.props;
        const { offset } = this.state;
        this.updateOffset(offset + perPage * dir);
    };

    goLeft = () => {
        this.go(-1);
    };

    goRight = () => {
        this.go(1);
    };

    goTo = index => {
        this.updateOffset(index * this.props.perPage);
    };

    render() {
        const { numItems, perPage, children, classes, t } = this.props;
        const { offset } = this.state;
        const isCarousel = numItems > perPage;
        return (
            <div className={classes.container}>
                <div className={classes.trackFlex}>
                    {isCarousel && (
                        <IconButtonA11y
                            ariaLabel={t("common.left")}
                            className={classes.arrowButton}
                            onClick={this.goLeft}
                            disabled={offset === 0}
                        >
                            <ArrowLeftIcon className={classes.icon} />
                        </IconButtonA11y>
                    )}
                    <div className={classes.trackContainer}>
                        <div
                            className={classes.track}
                            style={{
                                width: `${trackWidth(numItems, perPage)}%`,
                                transform: xOffset(offset, numItems)
                            }}
                        >
                            {children}
                        </div>
                    </div>
                    {isCarousel && (
                        <IconButtonA11y
                            ariaLabel={t("common.right")}
                            className={classes.arrowButton}
                            onClick={this.goRight}
                            disabled={offset === numItems - perPage}
                        >
                            <ArrowRightIcon className={classes.icon} />
                        </IconButtonA11y>
                    )}
                </div>
                {isCarousel && (
                    <div className={classes.dotsContainer}>
                        {Array(Math.ceil(numItems / perPage))
                            .fill()
                            .map((elem, idx) => (
                                <Dot
                                    key={idx}
                                    className={classes.dot}
                                    active={Math.ceil(offset / perPage) === idx}
                                    onClick={this.goTo}
                                    index={idx}
                                />
                            ))}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(Carousel));
