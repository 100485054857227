import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";

import Stepper from "uiComponents/Stepper/Stepper";

const styles = {
    stepperContainer: {
        width: "100%"
    },
    container: {
        margin: "20px 0"
    }
};

export class VersionProgress extends Component {
    static propTypes = {
        inProgress: PropTypes.bool,
        steps: PropTypes.array,
        switchInterval: PropTypes.number
    };

    static defaultProps = {
        inProgress: false,
        steps: [],
        switchInterval: 2000
    };

    state = {
        displaySteps: []
    };

    constructor(props) {
        super(props);
        this.useStatus = true;
    }

    componentDidMount() {
        this.setState({
            displaySteps: this.getDisplaySteps(this.props.steps)
        });
        this.startSwitchTimeout();
    }

    componentWillUnmount() {
        this.clearSwitchTimeout();
    }

    getDisplaySteps = (steps, useStatus) => {
        const { t } = this.props;
        return Array.isArray(steps)
            ? steps.map((step, index) => {
                  const showStatus =
                      useStatus &&
                      step.status &&
                      step.status !== "project_details.progress_5";
                  return {
                      label: `${t(step.label)} ${index + 1}`,
                      value: showStatus ? t(step.status) : t(step.value),
                      active: step.active
                  };
              })
            : [];
    };

    startSwitchTimeout = () => {
        this.clearSwitchTimeout();
        this.switchTimeout = setTimeout(() => {
            // switch steps shown.
            this.setState({
                displaySteps: this.getDisplaySteps(
                    this.props.steps,
                    this.useStatus
                )
            });
            this.useStatus = !this.useStatus;
            this.startSwitchTimeout();
        }, this.props.switchInterval);
    };

    clearSwitchTimeout = () => {
        if (this.switchTimeout) {
            clearTimeout(this.switchTimeout);
            this.switchTimeout = null;
        }
    };

    render() {
        const { inProgress, classes } = this.props;
        const { displaySteps } = this.state;
        return (
            <div className={classes.stepperContainer}>
                {inProgress && (
                    <div className={classes.container}>
                        <Stepper steps={displaySteps} />
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(VersionProgress));
