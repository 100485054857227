const en = currency => {
    return {
        delimiters: {
            thousands: ",",
            decimal: "."
        },
        abbreviations: {
            thousand: "k",
            million: "m",
            billion: "b",
            trillion: "t"
        },
        currency: {
            symbol: currency
        }
    };
};

const ru = currency => {
    return {
        delimiters: {
            thousands: " ",
            decimal: ","
        },
        abbreviations: {
            thousand: "тыс.",
            million: "млн.",
            billion: "млрд.",
            trillion: "трлн."
        },
        ordinal: () => {
            // not ideal, but since in Russian it can taken on
            // different forms (masculine, feminine, neuter)
            // this is all we can do
            return ".";
        },
        currency: {
            symbol: currency
        }
    };
};

const de = currency => {
    return {
        delimiters: {
            thousands: " ",
            decimal: ","
        },
        abbreviations: {
            thousand: "k",
            million: "m",
            billion: "b",
            trillion: "t"
        },
        ordinal: () => {
            return ".";
        },
        currency: {
            symbol: currency
        }
    };
};

const pl = currency => {
    return {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'tys.',
            million: 'mln',
            billion: 'mld',
            trillion: 'bln'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: {
            symbol: currency
        }
    };
};

const locales = {
    en,
    de,
    ru,
    pl
};

export default function (locale, symbol) {
    return locales[locale](symbol);
}
