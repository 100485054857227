// react
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

//Third parties libs
import { withTranslation } from "react-i18next";
import styled from "styled-components";

// Redux modules
import {
    addProject,
    editProject,
    selectProjectsLoading
} from "redux/modules/projects/projects";
import {
    fetchCollaborators,
    selectCollaborators,
    selectCollaboratorsLoading,
    selectCollaboratorsError
} from "redux/modules/collaborators/collaborators";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

// Components
import CollaboratorChip from "./CollaboratorChip";

// Shared components
import theme from "../../styles/MuiTheme";

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    background-color: ${theme.status.grey.greybG};
    padding: 40px 35px 50px 35px;
`;

const StyledTagsWrapper = styled.div`
    .tag {
        margin-right: ${theme.spacing(2)}px;
    }
`;

const StyledButtonWrapper = styled.div`
    text-align: right;
    padding-top: ${theme.spacing(8)}px;
`;

const StyledDesc = styled.div`
    font-size: 0.8125rem;
    font-weight: 400;
`;

const styles = theme => ({
    textField: {
        width: "40%",
        marginBottom: 15
    },
    textArea: {
        marginBottom: 15
    },
    textFieldRoot: {
        fontSize: 14,
        padding: 15
    },
    inputMultiline: {
        minHeight: 50
    },
    helperText: {
        textAlign: "right"
    }
});

const mapStateToProps = state => ({
    isLoading: selectProjectsLoading(state),
    collaborators: selectCollaborators(state),
    isLoadingCollaborators: selectCollaboratorsLoading(state),
    isErrorCollaborators: selectCollaboratorsError(state)
});

const mapDispatchToProps = {
    addProject,
    editProject,
    fetchCollaborators
};

const propNames = {
    projectName: {
        maxChars: 50
    },
    description: {
        maxChars: 250
    }
};

class ProjectAddEdit extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        isEdit: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            source: PropTypes.string,
            sourceId: PropTypes.number,
            collaborators: PropTypes.array,
            byProject: PropTypes.bool
        }),
        collaborators: PropTypes.array,
        isErrorCollaborators: PropTypes.any,
        addProject: PropTypes.func.isRequired,
        editProject: PropTypes.func.isRequired
    };

    static defaultProps = {
        collaborators: []
    };

    state = {
        projectName: this.props.project ? this.props.project.name : "",
        description: this.props.project ? this.props.project.description : "",
        collaborators: this.props.project
            ? this.props.project.collaborators
            : []
    };

    componentDidMount() {
        this.props.fetchCollaborators();
    }

    componentDidUpdate(prevProps) {
        const { isLoading: prevIsLoading } = prevProps;
        const { isLoading, onClose } = this.props;
        if (!isLoading && prevIsLoading) {
            onClose();
        }
    }

    handleChange = propName => event => {
        let value = event.target.value;
        value =
            value.length > propNames[propName].maxChars
                ? this.state[propName]
                : value;
        this.setState({
            [propName]: value
        });
    };

    handleSubmitForm = event => {
        const {
            addProject,
            editProject,
            isEdit,
            project,
            collaborators: sourceCollaborators
        } = this.props;
        const { projectName, description, collaborators } = this.state;
        const updatedProject = {
            name: projectName,
            description,
            collaborators: sourceCollaborators
                .filter(el => collaborators.indexOf(el.userName) !== -1)
                .map(el => el.userName)
        };
        if (isEdit) {
            editProject({
                ...project,
                ...updatedProject
            });
        } else {
            addProject(updatedProject);
        }
        event.preventDefault();
    };

    handleClickCollaborator = item => event => {
        const isCollaborator =
            this.state.collaborators.filter(el => el === item.userName)
                .length > 0;
        if (isCollaborator) {
            this.setState({
                collaborators: this.state.collaborators.filter(
                    el => el !== item.userName
                )
            });
        } else {
            this.setState({
                collaborators: [item.userName, ...this.state.collaborators]
            });
        }
    };

    render() {
        const {
            t,
            classes,
            collaborators,
            isCollaboratorsError,
            isCollaboratorsLoading,
            isLoading: isProjectsLoading
        } = this.props;
        const {
            projectName,
            description,
            collaborators: projectCollaborators
        } = this.state;
        const isLoading = isCollaboratorsLoading || isProjectsLoading;
        return (
            <div>
                <StyledForm onSubmit={this.handleSubmitForm}>
                    <FormControl>
                        <TextField
                            label={t("project_edit_add.project_name")}
                            autoFocus={true}
                            spellCheck={false}
                            id="project-name"
                            value={projectName}
                            className={classes.textField}
                            onChange={this.handleChange("projectName")}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            label={t("project_edit_add.project_desc")}
                            rows="4"
                            rowsMax="4"
                            margin="normal"
                            spellCheck={false}
                            variant="outlined"
                            helperText={`${
                                description ? description.length : "0"
                            } / ${propNames.description.maxChars}`}
                            FormHelperTextProps={{
                                classes: { root: classes.helperText }
                            }}
                            InputProps={{
                                className: classes.textFieldRoot,
                                classes: {
                                    inputMultiline: classes.inputMultiline
                                }
                            }}
                            className={classes.textArea}
                            onChange={this.handleChange("description")}
                            value={description}
                        />
                    </FormControl>
                    <FormControl>
                        <StyledDesc>
                            {t("project_edit_add.project_participants_title")}
                        </StyledDesc>
                        <StyledTagsWrapper>
                            {collaborators.map((item, index) => (
                                <CollaboratorChip
                                    isActive={
                                        projectCollaborators.filter(
                                            el => el === item.userName
                                        ).length > 0
                                    }
                                    onClick={this.handleClickCollaborator(item)}
                                    key={index}
                                    label={item.name}
                                />
                            ))}
                            {isCollaboratorsError && (
                                <span>{isCollaboratorsError}</span>
                            )}
                        </StyledTagsWrapper>

                        <StyledButtonWrapper>
                            <Button
                                disabled={isLoading}
                                onClick={this.handleSubmitForm}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                {this.props.isEdit &&
                                    t("project_edit_add.project_update")}
                                {!this.props.isEdit &&
                                    t("project_edit_add.project_create")}
                            </Button>
                        </StyledButtonWrapper>
                    </FormControl>
                </StyledForm>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withStyles(styles)(ProjectAddEdit)));
