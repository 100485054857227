 import React, {Component} from "react";
import {connect} from "react-redux";
import DialogComponent from "../../uiComponents/Dialog/DialogComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import theme from "../../styles/MuiTheme";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import {
    errorUserDefinedAttributeList, fetchUserDefinedAttributeList, selectUserDefinedAttributeList
}
    from "../../redux/modules/dmtAttributeList/dmtUserDefinedAttributeList";

import {
    getErrorMessage,
    getSuccessStatus,
    postDMTExportRequest,
} from "../../redux/modules/dmtAttributeExport/dmtAttributeExport"

import {
    getSelectedNodesProducts,
    fetchNodeProducts
} from "../../redux/modules/products/nodeProducts"

import {
    selectSavedSetName,
} from "redux/modules/tree/savedset";

import PropTypes from "prop-types";
import {selectProjectId} from "../../redux/modules/projects/projects";
import {selectCurrentVersionNum} from "../../redux/modules/versions/current";
import {selectCurrentSavedSetId} from "../../redux/modules/tree/current";

import { selectIsSavedSetDirty} from "../../redux/modules/tree/savedset";

const styles = {
    root: {
        paddingLeft: 0,
        borderRadius: 0,
        width: "100%"
    },
    label: {
        justifyContent: "flex-start"
    },
    content: {
        padding: 45
    },
    groupContainer: {
        paddingTop: 20,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 30,
        marginLeft: 35,
        marginRight: 35,
    },
    selectedAttributeContainer: {
        display: "flex"
    },
    formControl: {
        paddingLeft: 30,
        minWidth: 240,

    },
    paper: {
        position: "relative",
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 30,
        marginBottom: 40
    },
   cardContainer: {
       display: "flex",
       padding: "20px 20px 10px",
       position: "relative",
       borderRadius: "10px",
       backgroundColor: "#F8F7F6",
    },
    nodesContainer: {
        paddingTop: "20px"
    },
    buttonContainer: {
        paddingTop: "20px",
        display: "flex",
        flexDirection: "row-reverse",
    },
    wrapper: {
        position: "relative",
        backgroundColor: theme.status.grey.white,
        border: "1px solid",
        borderColor: theme.status.clay[50],
        boxShadow: "0 3px 15px 0 rgba(0, 0, 0, 0.13)",
        borderRadius: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 10,
        marginBottom: 10
    },
    card: {
        border: "1px solid",
        position: "relative",
        boxShadow: "0 3px 15px 0 rgba(0, 0, 0, 0.13)",
        paddingTop: "10px",
        borderColor: "#dfd9d3",
        marginRight: "10px",
        paddingLeft: "15px",
        borderRadius: "10px",
        marginBottom: "10px",
        paddingRight: "15px",
        paddingBottom: "10px",
        backgroundColor:"#ffffff",
        width: "150px",
        flexWrap: "wrap"
    },
    container: {
        display: "flex"
    },
    cardTitle: {
        fontSize: 14,
        marginBottom: 15,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    icon: {
        fill: theme.status.clay[20],
        marginLeft: "auto",
        paddingLeft: 15
    },
    iconCheck: {
        fill: theme.status.midnight[100]
    },
    isDragging: {
        backgroundColor: "transparent"
    },
    isOver: {
        backgroundColor: theme.status.yellow[100]
    },
    isDown: {
        opacity: 0.5
    }
};

const StyledSelect = styled(Select)`
    && {
        font-size: 14px;
    }

,
MuiInputBase-input: {
    padding: 0px 7px;
}
`;


// Redux props
const mapStateToProps = state => {
    return {
        userDefinedAttributeList: selectUserDefinedAttributeList(state),
        attributeListError: errorUserDefinedAttributeList(state),
        exportSuccessful: getSuccessStatus(state),
        exportError: getErrorMessage(state),
        nodeProducts: getSelectedNodesProducts(state),
        projectId: selectProjectId(state),
        version: selectCurrentVersionNum(state),
        savedSetName: selectSavedSetName(state),
        isDisabled: selectCurrentSavedSetId(state) === "default",
        isSavedSetDirty: selectIsSavedSetDirty(state)
    };
}

const initState = {
    dialogOpen: false,
    selectedAttribute: null,
    exportTriggered: false,
}


class ExportToDMTContainer extends Component {
    handleChangeOfSelectedAttribute = event => {
        this.setState({selectedAttribute: event.target.value})
    }

    handleExportAttributeValues = (attributeValuePairList, event) => {
        //prepare attribute pair list
        this.props.postDMTExportRequest({
            "attributeName": this.state.selectedAttribute,
            "attributeValuePairList": attributeValuePairList
        });
        this.setState({exportTriggered: true});
    }

    static propTypes = {
        fetchUserDefinedAttributeList: PropTypes.func.isRequired,
        postDMTExportRequest: PropTypes.func.isRequired,
        fetchNodeProducts: PropTypes.func.isRequired,
    };


    state = {
        ...initState
    }

    initComponent() {
        const {userDefinedAttributeList, exportToDMTNodes, projectId, version, savedSetName, data} = this.props;

        if (userDefinedAttributeList && userDefinedAttributeList.length === 0) {
            this.props.fetchUserDefinedAttributeList();
        }

        if(exportToDMTNodes && exportToDMTNodes.length !== 0 ){
            const nodeMap = data && data.length > 0 ? this.getNodeMap(data): {};
            this.props.fetchNodeProducts(projectId, version, savedSetName, exportToDMTNodes.map((node) => {return nodeMap.get(node)}));
        }
    }

    getSelectionMenuItems = userDefinedAttributeList => {
        let tempOrgList = [];
        let selectionMenuItems = [];
    
        if (userDefinedAttributeList) {
            userDefinedAttributeList.forEach(opt => {
                if (tempOrgList.indexOf(opt.orgName) === -1) {
                    tempOrgList.push(opt.orgName);
                    selectionMenuItems.push(
                        <MenuItem key={"readonly_item_" + opt.orgName} value={"readonly_item_" + opt.orgName} disabled>
                            {opt.orgName}
                        </MenuItem>
                    );
                }
                selectionMenuItems.push(
                    <MenuItem key={opt.attribute.attributeName} value={opt.attribute.attributeName}>
                        {opt.attribute.attributeName}
                    </MenuItem>
                );
            });
        }
    
        return selectionMenuItems;
    }

    getNodeMap = data => {
        //iterate over data children to get all node maps
        let nodeMap = new Map();
        data.forEach((dataElement) => {
            nodeMap.set(dataElement.name, dataElement.nodeName);
            if(dataElement.children.length > 0){
                const tmpNodeMap = this.getNodeMap(dataElement.children);
                nodeMap = new Map([...nodeMap, ...tmpNodeMap]);
            }
        })
        return nodeMap;
    }

    getCardList = (nodeProducts, nodeMap) => {
        /*let cards = new Map();
        nodeProducts && nodeProducts.length !== 0 && nodeProducts.forEach((nodeElement) => {
            const key = nodeMap.get(nodeElement.nodeName);
            if(cards.has(key)){
                cards.set(key, cards.get(key).concat(nodeElement.products));
            }else{
                cards.set(key, nodeElement.products);
            }
        });
        return cards;*/
        let cards = new Map();
        nodeProducts && nodeProducts.length !== 0 && nodeProducts.forEach((nodeElement) => {
            cards.set(nodeElement.nodeName, nodeElement.products);
        });
        return cards;
    }

    getCardItems = (cards) => {
        const { t } = this.props;
        let cardItems = [];
        cards && cards.forEach(function(value, key){
            cardItems.push(<div style={{...styles.card}} key={'card_'+key}>
                <div style={{...styles.container}}>
                    <Typography
                        variant="caption"
                        style={styles.cardTitle}
                    >
                        {key}
                    </Typography>
                </div>
                <div style={styles.container}>
                    <Typography variant="caption">
                        {t("subcategories.product_count_plural", {
                            count: value.length
                        })}
                    </Typography>
                </div>
            </div>);
        });
        return cardItems;
    }

    getAttributeValuePairList = cards => {
        let attributeValuePairList = [];
        cards && cards.forEach(function(value, key){
            value.forEach((product) =>{
                attributeValuePairList.push({
                    "productId" : product,
                    "attributeValue": key
                });
            });
        });
        return attributeValuePairList;
    }

    reset = () => {
        this.setState({...initState});
    }

    render() {
        const {isLoading, classes, t, exportToDMTNodes, userDefinedAttributeList,
            exportSuccessful, exportError, data, nodeProducts, isDisabled, isSavedSetDirty} = this.props;
        const {dialogOpen, selectedAttribute, exportTriggered} = this.state;
        if (userDefinedAttributeList && userDefinedAttributeList.length !== 0 && !selectedAttribute) {
            this.setState({selectedAttribute: userDefinedAttributeList[0].attribute.attributeName});
        }
        let selectionMenuItems = this.getSelectionMenuItems(userDefinedAttributeList);
        //response contains, original node name and not the user defined name
        // and data object contains node name and user defined name

        //data contains original node name and custom name
        //pass in project id, version id and original selected nods to get list of products
        //prepare two maps one to display in card view and another format to be used while exporting to dmt
        const nodeMap = data && data.length > 0 ? this.getNodeMap(data): {};
        const cards = this.getCardList(nodeProducts, nodeMap);
        const attributeValuePairList = this.getAttributeValuePairList(cards);
        let cardItems = this.getCardItems(cards);
        let exportRespMessage = '';
        if(exportTriggered === true) {
            exportRespMessage = exportSuccessful !== undefined ? ((exportSuccessful === 'true' || exportSuccessful === true) ?
                t("common.export_to_dmt_request_created") : t("common.export_to_dmt_request_failed") + (exportError !== null ? exportError : '')) : '';
        }
        return (
            <>
                <Tooltip disableHoverListener={!(isDisabled || exportToDMTNodes.length === 0 || isSavedSetDirty === true)}
                         title={<p>{t("common.export_to_dmt_info_1")}<br/><ol>
                             <li>{t("common.export_to_dmt_info_2")}</li>
                             <li>{t("common.export_to_dmt_info_3")}</li>
                             <li>{t("common.export_to_dmt_info_4")}</li></ol></p>}>
                    <span>
                        <Button
                            classes={{
                                root: classes.root,
                                label: classes.label
                            }}
                            disabled={isDisabled || exportToDMTNodes.length === 0 || isSavedSetDirty === true}
                            onClick={() => {this.setState({dialogOpen: true}); this.initComponent();}}
                        >
                            {!isLoading && t("common.export_to_dmt")}
                            {isLoading && <CircularProgress size={12} thickness={1}/>}
                        </Button>
                    </span>
                </Tooltip>

                <DialogComponent
                    isOpen={dialogOpen}
                    onClose={() => this.reset()}
                    fullWidth
                    title={t("common.export_to_dmt")}
                >

                    <div style={{...styles.groupContainer}}>
                        <div style={{...styles.selectedAttributeContainer}}>
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                align={"left"}
                            >
                                {t("common.export_to_dmt_select_attribute")} :
                            </Typography>
                            {selectionMenuItems.length > 0 && <FormControl style={{...styles.formControl}}>
                                <StyledSelect
                                    value={selectedAttribute? selectedAttribute: ''}
                                    onChange={this.handleChangeOfSelectedAttribute}
                                    name="selectedAttribute"
                                >
                                    {selectionMenuItems}
                                </StyledSelect>
                            </FormControl>}
                        </div>
                        <div style={{...styles.nodesContainer}}>
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                align={"left"}
                            >
                                {t("common.export_to_dmt_selected_nodes")} :
                            </Typography>
                            <Card>
                                <CardContent style={{...styles.cardContainer}}>
                                    {cardItems}
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{...styles.buttonContainer}}>
                            <Button
                                onClick={event => this.handleExportAttributeValues(attributeValuePairList, event)}
                                variant="contained"
                                color="primary"
                                disabled={cardItems.length === 0}
                            >
                                {t("common.export")}
                            </Button>
                        </div>
                        {exportRespMessage !== '' && <div style={{...styles.nodesContainer}}>
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                align={"left"}
                            >
                                {exportRespMessage}
                            </Typography>
                        </div>}
                    </div>
                </DialogComponent>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    {
        fetchUserDefinedAttributeList,
        postDMTExportRequest,
        fetchNodeProducts,
    }
)(withTranslation()(withStyles(styles)(ExportToDMTContainer)));
