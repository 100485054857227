import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    header: {
        width: 600,
        height: 70,
        backgroundColor: theme.status.grey.greybox,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 30px"
    },
    headerText: {
        fontWeight: 600,
        textAlign: "center",
        width: "100%"
    },
    addImportContainer: {
        display: "flex",
        flexDirection: "column"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-around",
        padding: 30
    },
    unlinked: {
        padding: "0 0px 30px 30px",
        fontSize: 14,
        textAlign: "left"
    },
    hidden: {
        visibility: "hidden",
        width: 0
    },
    importSummary: {
        padding: 30,
        textAlign: "center"
    },
    importSummaryHeader: {
        fontWeight: 600
    },
    importRow: {
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${theme.status.grey.greydivs}`
    },
    importRowMissing: {
        textAlign: "left",
        borderBottom: "none"
    },
    importColumn: {
        display: "inline-block",
        width: "50%",
        padding: 12,
        boxSizing: "border-box"
    },
    missingTitle: {
        padding: "12px 0 0"
    },
    missingList: {
        margin: 0,
        padding: "8px 0 0",
        listStyle: "none",
        whiteSpace: "normal"
    },
    missingListItem: {
        display: "inline-block",
        margin: 0,
        padding: "0 4px 0 0",
        fontSize: 14,
        color: theme.status.grey.dark,
        lineHeight: 1.6
    }
});

function getSummary(
    selectedCategoryId,
    verifiedProducts,
    missingProductsIds,
    assorterResult,
    categoryKey = "category_name",
    subcategoryCountKey = "subcategoryCount"
) {
    const result = {
        productCount: 0,
        subcategoryCount: 0
    };
    if (!assorterResult) {
        if (Array.isArray(verifiedProducts) && verifiedProducts.length) {
            const selectedCategoryProducts = verifiedProducts.filter(el =>
                selectedCategoryId.includes(el[categoryKey])
            );
            selectedCategoryProducts.reduce((agg, curr) => {
                agg.productCount += curr.metrics.productCount;
                agg[subcategoryCountKey] += curr.metrics[subcategoryCountKey];
                return agg;
            }, result);
        }
        return {
            ...result,
            missingIds: missingProductsIds
        };
    } else if (Array.isArray(assorterResult)) {
        const subcategoryDict = {};
        return assorterResult.reduce((count, curr) => {
            ++count.productCount;
            if (!subcategoryDict[curr.subcategory]) {
                subcategoryDict[curr.subcategory] = true;
                ++count.subcategoryCount;
            }
            return count;
        }, result);
    }
    return result;
}

function ProductImportConfirm({
    t,
    classes,
    selectedCategoryId,
    verifiedProducts,
    missingProductsIds,
    assorterResult,
    categoryKey = "category_name",
    subcategoryCountKey = "subcategoryCount"
}) {
    const { subcategoryCount, productCount, missingIds } = getSummary(
        selectedCategoryId,
        verifiedProducts,
        missingProductsIds,
        assorterResult,
        categoryKey,
        subcategoryCountKey
    );

    return (
        <div className={classes.importSummary}>
            <div
                className={classNames(
                    classes.importRow,
                    classes.importSummaryHeader
                )}
            >
                <div className={classes.importColumn}>
                    <Typography variant="body1">
                        {t("product_import.subcategories")}
                    </Typography>
                </div>
                <div className={classes.importColumn}>
                    <Typography variant="body1">
                        {t("product_import.products")}
                    </Typography>
                </div>
            </div>
            <div className={classes.importRow}>
                <div className={classes.importColumn}>{subcategoryCount}</div>
                <div className={classes.importColumn}>{productCount}</div>
            </div>
            {missingIds && missingIds.length > 0 && (
                <div
                    className={classNames(
                        classes.importRow,
                        classes.importRowMissing
                    )}
                >
                    <Typography
                        variant="subtitle1"
                        className={classes.missingTitle}
                    >
                        {t("product_import.products_missing", {
                            count: missingProductsIds.length
                        })}
                    </Typography>
                    <ul className={classes.missingList}>
                        {missingIds.map((missingId, index) => (
                            <li className={classes.missingListItem} key={index}>
                                {missingId}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default withTranslation()(withStyles(styles)(ProductImportConfirm));
