import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import DialogComponent from "../../uiComponents/Dialog/DialogComponent";
import SavedSetDialogInput from "components/Tree/SavedSetDialogInput";

function SavedSetDialogSave(props) {
    const {
        savedSetName,
        handleSavedSetNameChange,
        onClose,
        onSave,
        isOpen,
        t
    } = props;
    return (
        <DialogComponent
            isOpen={isOpen}
            onClose={onClose}
            onSave={onSave}
            canSave={savedSetName !== ""}
            padding
            maxWidth="sm"
            centre
            title={t("tree.save_saved_set_dialog_title")}
        >
            <SavedSetDialogInput
                savedSetName={savedSetName}
                onSavedSetNameChange={handleSavedSetNameChange}
            />
        </DialogComponent>
    );
}

SavedSetDialogSave.propTypes = {
    savedSetName: PropTypes.string,
    handleSavedSetNameChange: PropTypes.func.isRequired
};

SavedSetDialogSave.defaultProps = {
    savedSetName: ""
};

export default withTranslation()(SavedSetDialogSave);
