// redux-saga
import { take, put, call } from "redux-saga/effects";

// Fetch service
import fetch from "services/fetch";

// Api configuration
import api from "api";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Redux modules
import { openSnackbar } from "../snackbar/open";

// Reducer name
const reducerName = "jobs";
const { reducer, actions, actionTypes, selectors } = createFetchRedux(
    reducerName
);

export default reducer;

export const jobs = actions;

export const FETCH_JOBS = actionTypes.FETCH;
export const RECEIVE = actionTypes.RECEIVE;

export const fetchJobs = actions.fetch;

const startStep = {
    label: "project_details.step",
    value: "project_details.progress_start",
    active: true
};

const endStep = {
    label: "project_details.step",
    value: "project_details.progress_complete",
    active: false
};

function getSteps(job, rangingSets = []) {
    const steps = rangingSets.map(set => ({
        label: "project_details.step",
        name: set.name,
        status: set.name,
        value: set.name,
        activeTime: 0,
        active: false
    }));
    if (job) {
        steps.map(step => {
            if (Array.isArray(job)) {
                if (job.some(el => el === step.name)) {
                    step.activeTime = Date.now();
                    step.value = step.name;
                    step.active = true;
                }
            } else {
                const keys = Object.keys(job);
                keys.forEach(key => {
                    if (key === step.name && !step.active) {
                        step.status = `project_details.progress_${job[key]}`;
                        step.activeTime = new Date();
                        step.value = step.name;
                        step.active = true;
                    }
                });
            }
            return step;
        });
    }
    return [startStep]
        .concat(
            steps.sort((a, b) => {
                if (a.active && b.active) {
                    return a.activeTime - b.activeTime;
                }
                return b.active - a.active;
            })
        )
        .concat([endStep]);
}

export const selectJobsData = selectors.selectReduxData;
export const getStepsByVersion = (state, version) => {
    const jobs = selectJobsData(state);
    return (
        version &&
        getSteps(
            jobs && jobs[version.jobId],
            version.versionRequestInfo && version.versionRequestInfo.rangingSets
        )
    );
};

// Sagas

export function* watchJobs() {
    while (true) {
        const { payload } = yield take(FETCH_JOBS);
        const { url, type, error, data } = api["jobs.get"](payload);
        yield put(jobs.request());
        try {
            const result = yield call(fetch, url, type, data);
            yield put(jobs.receive(result));
        } catch (e) {
            yield put(jobs.error(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}

/*
export function getStepsByVersion(state, version) {
    return (
        version &&
        state &&
        getSteps(
            state.jobs && state.jobs[version.jobId],
            version.versionRequestInfo &&
                version.versionRequestInfo.rangingSets
        )
    );
}

export function getStepsByJobId(state, jobId) {
    return getSteps(state.jobs && state.jobs[jobId]);
}

*/
