// redux-saga
import { take, call } from "redux-saga/effects";

// Api configuration
import api from "api";

// Redux saga helper
import { fetchEntityList } from "../../store/sagaHelpers";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "subcategoryGroups";
const { reducer, actions, actionTypes, selectors } = createFetchRedux(
    reducerName
);

export default reducer;

// Action types
export const FETCH_SUBCATEGORY_GROUPS = `${reducerName}/FETCH_SUBCATEGORY_GROUPS`;
export const RECEIVE_SUBCATEGORY_GROUPS = actionTypes.RECEIVE;

// Actions
export const resetGroups = actions.reset;
export function fetchSubcategoryGroups(projectId) {
    return {
        type: FETCH_SUBCATEGORY_GROUPS,
        payload: projectId
    };
}

export const subcategoryGroups = actions;

// Selectors
export const selectSubcategoryGroups = state =>
    selectors.selectReduxData(state) || [];

// Sagas
export function* watchSubcategoryGroups() {
    while (true) {
        const { payload: id } = yield take(FETCH_SUBCATEGORY_GROUPS);
        yield call(
            fetchEntityList,
            subcategoryGroups,
            api["project.subcategories.group.get"](id)
        );
    }
}
