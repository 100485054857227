import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    closeAlert,
    selectAlertMessage,
    selectAlertOpen,
    selectAlertTitle
} from "redux/modules/alerts";

import AlertDialog from "./AlertDialog";

function AlertContainer({ closeAlert, open, message, title }) {
    return (
        <AlertDialog
            open={open}
            title={title}
            message={message}
            handleClose={closeAlert}
        />
    );
}

AlertContainer.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    closeAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    title: selectAlertTitle(state),
    message: selectAlertMessage(state),
    open: selectAlertOpen(state)
});

const mapDispatchToProps = {
    closeAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
