import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import i18next from "i18next";
import Popover from "@material-ui/core/Popover";
import { TimePeriod } from "@emnos-legacy/ordering-wizard-components";

import {
    updateConstraints,
    selectProjectsLoading
} from "redux/modules/projects/projects";
import {
    fetchRetailerDates,
    selectRetailerDates,
    selectMinRetailerDate,
    selectMaxRetailerDate,
    selectRetailerDatesLoading
} from "redux/modules/retailer/getDates";
import { openSnackbar } from "redux/modules/snackbar/open";

import SetConstraintsButton from "components/SetConstraints/SetConstraintsButton";

const mapStateToProps = state => ({
    retailerDates: selectRetailerDates(state),
    minDate: selectMinRetailerDate(state),
    maxDate: selectMaxRetailerDate(state),
    isRetailerDatesLoading: selectRetailerDatesLoading(state),
    isProjectLoading: selectProjectsLoading(state)
});

const mapDispatchToProps = {
    fetchRetailerDates,
    updateConstraints,
    openSnackbar
};

const StyledDateRangerWrapper = styled.div`
    position: relative;
`;

class SetConstraintsContainer extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        project: PropTypes.object,
        retailerDates: PropTypes.object,
        fetchRetailerDates: PropTypes.func.isRequired,
        isRetailerDatesLoading: PropTypes.bool.isRequired,
        openSnackbar: PropTypes.func.isRequired
    };

    state = {
        isOpen: false,
        selectedStartDate: null,
        selectedEndDate: null,
        popoverEl: null
    };

    componentDidMount() {
        if (!this.props.retailerDates && !this.props.isRetailerDatesLoading) {
            this.props.fetchRetailerDates();
        }
    }

    handleClosePopover = () => {
        this.setState({
            popoverEl: null,
            selectedStartDate: null,
            selectedEndDate: null
        });
    };

    handleClickConstraints = event => {
        this.setState({
            popoverEl: event.currentTarget
        });
    };

    onSaveTimePeriod = newTimePeriod => {
        const { startDate: newStartDate, endDate: newEndDate } = newTimePeriod;

        if (newStartDate && newEndDate) {
            const { projectId, updateConstraints } = this.props;
            updateConstraints(projectId, {
                start: newStartDate,
                end: newEndDate
            });
            this.handleClosePopover();
        } else {
            this.props.openSnackbar(
                true,
                i18next.t("project_details.constraints_warning")
            );
        }
    };

    render() {
        const { project, minDate, maxDate, isProjectLoading } = this.props;
        const { popoverEl } = this.state;
        const hasMinMaxDates = Boolean(minDate && maxDate);
        const hasProjectDates = Boolean(
            project && project.startDate && project.endDate
        );
        let warningMessage;
        if (!hasProjectDates) {
            warningMessage = i18next.t(
                "project_details.constraints_save_warning"
            );
        }

        return (
            <StyledDateRangerWrapper>
                <SetConstraintsButton
                    openDateRange={this.handleClickConstraints}
                    hasConstraints={hasProjectDates}
                />
                <Popover
                    id="constraintsPopover"
                    open={popoverEl !== null}
                    anchorEl={popoverEl}
                    onClose={this.handleClosePopover}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    {hasMinMaxDates && project && (
                        <TimePeriod
                            warningMessage={warningMessage}
                            minDate={minDate}
                            maxDate={maxDate}
                            initStartDate={project.startDate}
                            initEndDate={project.endDate}
                            onCancelTimePeriod={this.handleClosePopover}
                            onSaveTimePeriod={this.onSaveTimePeriod}
                            saveLabel={i18next.t("common.save")}
                            cancelLabel={i18next.t("common.cancel")}
                            isSaving={isProjectLoading}
                        />
                    )}
                </Popover>
            </StyledDateRangerWrapper>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetConstraintsContainer);
