import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchJobs, selectJobsData } from "redux/modules/jobs/jobs";
import { fetchVersions } from "redux/modules/versions/versions";

import Version from "./Version";
import Paginate from "../../helpers/Paginate";

const mapStateToProps = state => ({
    jobs: selectJobsData(state)
});

const mapDispatchToProps = {
    fetchJobs,
    fetchVersions
};

function getJobIds(versions) {
    return (
        versions &&
        versions.filter(el => el.status === "IN_PROGRESS").map(el => el.jobId)
    );
}

function hasDoneOrError(str) {
    return str.indexOf("DONE") !== -1 || str.indexOf("ERROR") !== -1;
}

export function hasAnyDoneOrErrorJobs(jobs) {
    const doneJobs = Object.keys(jobs).filter(key => {
        const job = jobs[key];
        if (Array.isArray(job)) {
            return job && hasDoneOrError(job[0]);
        } else {
            const keys = Object.keys(job);
            return keys.some(key => hasDoneOrError(key));
        }
    });
    return doneJobs.length !== 0;
}

export class VersionList extends Component {
    static propTypes = {
        versions: PropTypes.array.isRequired,
        pollInterval: PropTypes.number,
        jobs: PropTypes.object,
        fetchJobs: PropTypes.func.isRequired,
        fetchVersions: PropTypes.func.isRequired
    };

    static defaultProps = {
        jobs: {},
        versions: [],
        pollInterval: 10000
    };

    componentDidMount() {
        this.startProgressPoll(1000);
    }

    componentDidUpdate(prevProps) {
        const { jobs: prevJobs, versions: prevVersions } = prevProps;
        const { jobs, versions, fetchVersions, projectId } = this.props;

        if (prevJobs !== jobs) {
            // criteria to load list:
            // - jobs list has changed
            // - there are no jobs in new list
            // - at least one job has reached 'done'
            if (!jobs || hasAnyDoneOrErrorJobs(jobs)) {
                fetchVersions(projectId);
            }
        }

        if (versions !== prevVersions && !this.pollRunning) {
            this.startProgressPoll(1000);
        }
    }

    componentWillUnmount() {
        this.clearProgressPoll();
    }

    startProgressPoll = interval => {
        const { pollInterval, fetchJobs, versions } = this.props;
        this.clearProgressPoll();
        this.pollRunning = true;
        this.pollTimeout = setTimeout(() => {
            this.pollRunning = false;
            const ids = getJobIds(versions);
            if (ids && ids.length) {
                fetchJobs(ids);
                // Continue to poll if jobs are still in progress
                this.startProgressPoll();
            }
        }, interval || pollInterval);
    };

    clearProgressPoll = () => {
        if (this.pollTimeout) {
            clearTimeout(this.pollTimeout);
            this.pollTimeout = null;
        }
    };

    render() {
        const { versions, projectId } = this.props;
        return (
            <Paginate
                list={versions}
                render={paginatedList =>
                    paginatedList.map(version => (
                        <Version
                            key={version.id}
                            projectId={projectId}
                            version={version}
                        />
                    ))
                }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VersionList);
