import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

// MUI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
    root: {
        marginTop: 20,
        width: 120
    },
    secondary: {
        position: "absolute",
        right: 0,
        top: 0,
        transition: "transform 400ms cubic-bezier(0.19, 1, 0.22, 1)"
    },
    hover: {
        transform: "translateX(-90px)"
    },
    label: {},
    secondaryLabel: {
        textAlign: "left",
        justifyContent: "flex-start"
    }
};

export const SavedSetSaveButton = ({
    t,
    classes,
    isHover,
    onClickSave,
    label,
    isSecondary
}) => (
    <Button
        classes={{
            root: classNames(classes.root, {
                [classes.secondary]: isSecondary,
                [classes.hover]: isHover
            }),
            label: classNames(classes.label, {
                [classes.secondaryLabel]: isSecondary
            })
        }}
        variant={isSecondary ? "outlined" : "contained"}
        color="primary"
        onClick={onClickSave}
    >
        {t(label)}
    </Button>
);

SavedSetSaveButton.propTypes = {
    onClickSave: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    label: PropTypes.string
};

export default withTranslation()(withStyles(styles)(SavedSetSaveButton));
