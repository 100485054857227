import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    fetchSubcategoryGroups,
    selectSubcategoryGroups
} from "redux/modules/subcategories/groups";
import {
    fetchSubcategoryMetrics,
    selectSubcategoryMetrics
} from "redux/modules/subcategories/metrics";
import {
    selectSubcategoryUserGroups,
    selectUngroupedItems,
    selectGroupedItems,
    selectHasDragged,
    selectSelectedItems,
    updateGroupName,
    setSelectedItems,
    selectSaving,
    saveGroups,
    deleteGroup
} from "redux/modules/subcategories/subcategories";
import { selectSaveGroupsLoading } from "../../redux/modules/subcategories/save";
import { selectAllowedLevelOfAnalysis } from "../../redux/modules/products/products";
import { selectAnalysisLevel, selectProjectById , updateAnalysisLevel } from "../../redux/modules/projects/projects"

import SubcategoryGroupPage from "./SubcategoryGroupsPage";
import {selectAllowedLevelofAnalysis} from "../../redux/modules/config";
import { selectUserInfo} from "redux/modules/user/get";

const mapStateToProps = state => ({
    userGroups: selectSubcategoryUserGroups(state),
    subcategoryMetrics: selectSubcategoryMetrics(state),
    subcategoryGroups: selectSubcategoryGroups(state),
    selectedItems: selectSelectedItems(state),
    ungroupedItems: selectUngroupedItems(state),
    groupedItems: selectGroupedItems(state),
    isLoading: selectSaveGroupsLoading(state),
    hasDragged: selectHasDragged(state),
    isSaving: selectSaving(state),
    allowedLevelOfAnalysis: selectAllowedLevelOfAnalysis(state),
    analysisLevel: selectAnalysisLevel(state),
    currentProject: selectProjectById(state),
    allowedLevelOfAnalysisInConfig : selectAllowedLevelofAnalysis(state),
    retailer : selectUserInfo(state).userProfile.retailerShortName
});

const mapDispatchToProps = {
    fetchSubcategoryMetrics,
    fetchSubcategoryGroups,
    updateGroupName,
    setSelectedItems,
    saveGroups,
    deleteGroup,
    updateAnalysisLevel
};

class SubcategoryGroupPageContainer extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        projectId: PropTypes.string.isRequired,
        subcategoryMetrics: PropTypes.array.isRequired,
        subcategoryGroups: PropTypes.array.isRequired,
        userGroups: PropTypes.array.isRequired,
        selectedItems: PropTypes.array.isRequired,
        fetchSubcategoryMetrics: PropTypes.func.isRequired,
        fetchSubcategoryGroups: PropTypes.func.isRequired,
        updateGroupName: PropTypes.func.isRequired,
        hasDragged: PropTypes.bool.isRequired,
        setSelectedItems: PropTypes.func.isRequired,
        saveGroups: PropTypes.func.isRequired,
        deleteGroup: PropTypes.func.isRequired,
        isSaving: PropTypes.bool.isRequired,
        updateAnalysisLevel: PropTypes.func.isRequired
    };

    componentDidMount() {
        const {
            projectId,
            fetchSubcategoryGroups,
            fetchSubcategoryMetrics
        } = this.props;
        fetchSubcategoryMetrics(projectId);
        fetchSubcategoryGroups(projectId);
    }

    render() {
        return <SubcategoryGroupPage {...this.props} />;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubcategoryGroupPageContainer);
