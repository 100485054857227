import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
    root: {
        marginRight: "auto"
    },
    switchBase: {
        "&$switchChecked": {
            color: theme.palette.common.white,
            "& + $switchTrack": {
                backgroundColor: theme.status.midnight[50]
            }
        },
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp
        })
    },
    switchTrack: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: "none",
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.status.grey.greybox,
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
        boxShadow: "inset 0 1px 4px 0 rgba(0, 0, 0, 0.1)"
    },
    switchThumb: {
        width: 24,
        height: 24,
        backgroundColor: theme.status.clay[20]
    },
    switchChecked: {
        transform: "translateX(15px)",
        color: theme.status.midnight[100],
        "& + $switchTrack": {
            opacity: 1
        }
    },
    label: {
        fontWeight: 600
    },
    button: {
        padding: "4px 24px",
        minHeight: 33,
        borderRadius: 2,
        borderColor: theme.status.grey.greydivs,
        borderWidth: 1,
        borderStyle: "solid",
        "&:hover": {
            borderColor: theme.status.yellow.dark,
            backgroundColor: theme.status.yellow.dark
        }
    }
});

export const TreeOptimalSplitButton = ({
    handleChangeOptimalSplit,
    isOptimal,
    isDisabled,
    t,
    classes
}) => (
    <div className={classes.root}>
        <FormControlLabel
            control={
                <Switch
                    disabled={isDisabled}
                    checked={isOptimal}
                    onChange={handleChangeOptimalSplit}
                    value={"optimal-split"}
                    color="primary"
                    disableRipple
                />
            }
            label={t("tree.optimal_level_button")}
            classes={{
                label: classes.label
            }}
        />
    </div>
);

TreeOptimalSplitButton.propTypes = {
    handleChangeOptimalSplit: PropTypes.func.isRequired,
    isOptimal: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool
};

TreeOptimalSplitButton.defaultProps = {
    isDisabled: false
};

export default withTranslation()(withStyles(styles)(TreeOptimalSplitButton));
