export const UPDATE_CURRENT_VERSION = "modules/versions/UPDATE_CURRENT_VERSION";
export const RESET = "modules/versions/RESET";

const initialState = {
    versionNum: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RESET:
            return initialState;
        case UPDATE_CURRENT_VERSION:
            return {
                ...state,
                versionNum: action.payload
            };

        default:
            return state;
    }
};

export function resetCurrentVersion() {
    return {
        type: RESET
    };
}

export const updateCurrentVersion = versionNum => ({
    type: UPDATE_CURRENT_VERSION,
    payload: versionNum
});

export const selectCurrentVersionNum = state =>
    state.versionCurrent && state.versionCurrent.versionNum;
