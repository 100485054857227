import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import ChevronRightSVG from "../../static/img/double-chevron-right.svg";
import AttributesSVG from "../../static/img/attributes.svg";
import DialogComponent from "uiComponents/Dialog/DialogComponent";
import TreeReassignProductAttributesContainer from "./TreeReassignProductAttributesContainer";

const styles = theme => ({
    root: {
        position: "sticky",
        top: 0,
        width: "100%",
        paddingLeft: 40,
        paddingRight: 40,
        boxSizing: "border-box",
        zIndex: 1,
        backgroundColor: theme.status.clay[10],
        borderBottom: `1px solid ${theme.status.clay[100]}`
    },
    toolbar: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "70%"
    },
    targetButton: {
        marginLeft: 20
    },
    viewButton: {
        marginLeft: "auto",
        fontSize: 14,
        fontWeight: 400,
        textTransform: "none"
    },
    label: {
        paddingLeft: 10
    },
    formControl: {
        minWidth: 120,
        paddingRight: 40,
        marginTop: 10,
        marginBottom: 10
    },
    select: {
        fontSize: 14
    }
});

function TreeReassignToolbar({
                                 t,
                                 classes,
                                 options,
                                 targetDisabled,
                                 onReassignToTarget,
                                 clusterSelected
                             }) {
    const [value, setValue] = useState("");
    const [isDialogOpen, setDialogOpen] = useState(false);
    if (value === clusterSelected) setValue("")
    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <Button
                    disabled={targetDisabled}
                    className={classes.viewButton}
                    onClick={() => setDialogOpen(true)}
                >
                    <img src={AttributesSVG} alt=""/>
                    <span className={classes.label}>
                        {t("tree.view_attributes")}
                    </span>
                </Button>
                <Button
                    className={classes.targetButton}
                    disabled={value === "" || targetDisabled}
                    onClick={() => onReassignToTarget(value)}
                >
                    <img src={ChevronRightSVG} alt=""/>
                </Button>
                <FormControl className={classes.formControl}>
                    <Select
                        className={classes.select}
                        value={value}
                        disabled={targetDisabled}
                        displayEmpty
                        onChange={event => setValue(event.target.value)}
                        inputProps={{
                            name: "need-unit",
                            id: "target-need-unit"
                        }}
                    >
                        <MenuItem value="">{t("tree.select_nu")}</MenuItem>
                        {options.map((opt, i) => (
                            <MenuItem key={i} value={opt.node}
                                      disabled={(opt.node === clusterSelected) ? true : false}>
                                {opt.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <DialogComponent
                isOpen={isDialogOpen}
                onClose={() => setDialogOpen(false)}
                title={t("tree.product_attributes")}
            >
                <TreeReassignProductAttributesContainer/>
            </DialogComponent>
        </div>
    );
}

export default withTranslation()(withStyles(styles)(TreeReassignToolbar));
