import React from "react";

import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import classNames from "classnames";

import {withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SubcategoryCardArea from "./SubcategoryCardArea";
import SubcategoryCard from "./SubcategoryCard";

import DragDownIcon from "./DragDownIcon";

import {ItemIds} from "./SubcategoryDragConstants";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InfoIcon from "../../uiComponents/Common/InfoIcon";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    subheading: {
        paddingBottom: 15
    },
    paper: {
        position: "relative",
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 30,
        marginBottom: 40
    },
    dragHint: {
        display: "flex",
        width: "100%",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center"
    },
    dragHintHide: {
        opacity: 0
    },
    loader: {
        position: "absolute",
        top: 0,
        left: 0,
        padding: 20
    },
    sectionTitleContainer: {
        paddingLeft: 35,
        paddingRight: 35
    },
    sectionContainer: {
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 0,
        marginRight: 0
    },
    buttonContainer: {
        paddingTop: 30,
        paddingRight: 35,
        paddingLeft: 35,
        paddingBottom: 30,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    saveWarning: {
        marginRight: 20
    },
    groupContainer: {
        paddingTop: 20,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 30,
        marginLeft: 35,
        marginRight: 35,
        borderTop: `1px dashed ${theme.status.clay[100]}`
    },
    groupContainerUngrouped: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 35,
        marginRight: 35,
        paddingBottom: 0
    },
    ungroupDraggingOver: {
        "&:before": {
            opacity: 1
        }
    },
    groupDraggingOver: {
        backgroundColor: theme.status.grey[100]
    },
    cardTitle: {
        fontSize: 14,
        marginBottom: 15
    },
    textContainer: {
        width: "100%"
    },
    textField: {
        marginBottom: 15,
        width: 250
    },
    inputRoot: {
        borderBottom: "1px solid",
        borderBottomColor: theme.status.grey.greydivs,
        "&:hover": {
            borderBottomColor: theme.status.grey[100]
        }
    },
    inputInput: {
        fontSize: 14,
        width: "100%"
    },
    grouped_header: {
        paddingTop: 35
    },
    levelOfAnalysisContainer: {
        display: "flex"
    },
    formControl: {
        paddingLeft: 30,
        minWidth: 120
    },
    warningIcon: {
        color: theme.status.red[100],
        height: 20,
        width: 20
    },
    warningMessage: {
        display: "flex",
        alignItems: "center",
        color: theme.status.orange.dark,
        fontSize: 14
    },
    warningText: {
        paddingLeft: 4
    }
});

function validateGroups(userGroups) {
    let isValid = true;
    userGroups.forEach(userGroup => {
        if (userGroup.id !== ItemIds.UNGROUPED_GROUP_ID) {
            if (userGroup.subcategories.length <= 1) isValid = false;
        }
    });
    return isValid;
}

class SubcategoryGroupPage extends React.Component {
    static propTypes = {
        userGroups: PropTypes.array.isRequired,
        selectedItems: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        updateGroupName: PropTypes.func.isRequired,
        hasDragged: PropTypes.bool.isRequired,
        setSelectedItems: PropTypes.func.isRequired,
        saveGroups: PropTypes.func.isRequired,
        deleteGroup: PropTypes.func.isRequired,
        allowedLevelOfAnalysis: PropTypes.array.isRequired,
        analysisLevel: PropTypes.string,
        updateAnalysisLevel: PropTypes.func.isRequired,
        retailer: PropTypes.string
    };

    state = {
        currentAnalysisLevel: ""
    };

    componentDidMount() {
        this.setState({
            currentAnalysisLevel: this.props.analysisLevel
        });
    }

    componentDidUpdate(prevProps) {
        const {analysisLevel: prevAnalysisLevel} = prevProps;
        const {analysisLevel} = this.props;
        if (analysisLevel !== prevAnalysisLevel) {
            this.setState({
                currentAnalysisLevel: analysisLevel
            });
        }
    }

    handleChangeGroupName = id => event => {
        const {updateGroupName} = this.props;
        updateGroupName(id, event.target.value);
    };

    handleSelect = id => event => {
        const {selectedItems, setSelectedItems} = this.props;
        let newSelectedItems = selectedItems.slice(0);
        const index = newSelectedItems.indexOf(id);
        if (index !== -1) {
            newSelectedItems[index] =
                newSelectedItems[selectedItems.length - 1];
            newSelectedItems.pop();
        } else {
            newSelectedItems.push(id);
        }
        setSelectedItems(newSelectedItems);
    };

    handleClickSave = () => {
        this.props.saveGroups();
        if (this.props.analysisLevel !== this.state.currentAnalysisLevel) {
            this.props.updateAnalysisLevel({
                ...this.props.currentProject,
                ...{analysisLevel: this.state.currentAnalysisLevel}
            });
        }
    };

    handleClickDelete = id => () => {
        this.props.deleteGroup(id);
    };

    handleChangeOfAnalysisLevel = event => {
        this.setState({
            currentAnalysisLevel: event.target.value
        });
    };

    render() {
        const {
            classes,
            t,
            selectedItems,
            userGroups,
            isLoading,
            isSaving,
            hasDragged,
            allowedLevelOfAnalysis,
            allowedLevelOfAnalysisInConfig,
            retailer
        } = this.props;
        const isValid = validateGroups(userGroups);
        const wrongAnalysisLevel = (
            <Tooltip title={t("subcategories.wrong_analysis_level")}>
                <WarningIcon className={classes.warningIcon}/>
            </Tooltip>
        );
        const currentAnalysisLevel = this.state.currentAnalysisLevel;
        const showNonSKUAnalysisWarning =
            typeof currentAnalysisLevel !== "undefined" &&
            currentAnalysisLevel !== null &&
            currentAnalysisLevel !== "";

        const showAnalysisLevelWarning =
            allowedLevelOfAnalysis &&
            this.state.currentAnalysisLevel &&
            !allowedLevelOfAnalysis.includes(this.state.currentAnalysisLevel);
        return (
            <Paper className={classes.paper}>
                <Typography
                    variant="subtitle1"
                    align="center"
                    className={classes.subheading}
                >
                    {t("subcategories.title")}
                </Typography>
                <div
                    className={classNames(
                        {
                            [classes.dragHintHide]: hasDragged
                        },
                        classes.dragHint
                    )}
                >
                    <DragDownIcon/>
                </div>
                <section className={classes.sectionContainer}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.sectionTitleContainer}
                    >
                        {t("subcategories.group_title")}
                    </Typography>
                    {userGroups.map(item => (
                        <div
                            key={item.id}
                            className={classNames(
                                {
                                    [classes.groupContainerUngrouped]:
                                    item.id === ItemIds.UNGROUPED_GROUP_ID
                                },
                                classes.groupContainer
                            )}
                        >
                            {item.id !== ItemIds.UNGROUPED_GROUP_ID && (
                                <div className={classes.textContainer}>
                                    <TextField
                                        id="name"
                                        placeholder={t(
                                            "subcategories.group_name_input"
                                        )}
                                        margin="none"
                                        value={item.name}
                                        onChange={this.handleChangeGroupName(
                                            item.id
                                        )}
                                        className={classes.textField}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classes.inputRoot,
                                                input: classes.inputInput
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            <SubcategoryCardArea
                                handleClickDelete={this.handleClickDelete(
                                    item.id
                                )}
                                id={item.id}
                            >
                                {item.subcategories.map((cat, catIndex) => (
                                    <SubcategoryCard
                                        key={catIndex}
                                        id={cat.id}
                                        selectedItems={selectedItems}
                                        isSelected={selectedItems.includes(
                                            cat.id
                                        )}
                                        handleSelect={this.handleSelect(cat.id)}
                                        groupId={item.id}
                                        canCombine={
                                            item.subcategories.length > 2
                                        }
                                        productCount={cat.count}
                                        content={cat.content}
                                    />
                                ))}
                            </SubcategoryCardArea>
                            {item.id === ItemIds.UNGROUPED_GROUP_ID && (
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.grouped_header}
                                >
                                    {t("subcategories.grouped_section_title")}
                                </Typography>
                            )}
                        </div>
                    ))}
                    <div className={classes.groupContainer}>
                        <SubcategoryCardArea id={ItemIds.EMPTY_GROUP_ID}/>
                    </div>

                    <div className={classes.groupContainer}>
                        <div className={classes.levelOfAnalysisContainer}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                align={"left"}
                            >
                                {t("subcategories.level_of_analysis_title")} :
                            </Typography>

                            <FormControl className={classes.formControl}>
                                <Select
                                    value={this.state.currentAnalysisLevel}
                                    onChange={this.handleChangeOfAnalysisLevel}
                                    displayEmpty
                                    name="age"
                                    className={classes.selectEmpty}
                                >
                                    <MenuItem value="">
                                        <em>{t("attribute." + retailer + ".SKU")} </em>
                                    </MenuItem>
                                    {allowedLevelOfAnalysis.map((opt, i) => (
                                        <MenuItem key={i} value={opt}>
                                            {opt}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {showAnalysisLevelWarning && wrongAnalysisLevel}

                            <InfoIcon
                                tooltip={t(
                                    "subcategories.allowed_analysis_level",
                                    {
                                        allowed_list: allowedLevelOfAnalysisInConfig
                                    }
                                )}
                                tooltipPlacement="right-end"
                            />
                        </div>
                        {showNonSKUAnalysisWarning && (
                            <div className={classes.warningMessage}>
                                <WarningIcon/>
                                <span className={classes.warningText}>
                                    {t("subcategories.warning_analysis")}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={classes.buttonContainer}>
                        {!isValid && (
                            <Typography
                                className={classes.saveWarning}
                                variant="body2"
                            >
                                {t("subcategories.save_warning")}
                            </Typography>
                        )}
                        <Button
                            onClick={this.handleClickSave}
                            disabled={isSaving || isLoading || !isValid}
                            variant="contained"
                            color="primary"
                        >
                            {t("common.save")}
                        </Button>
                    </div>
                </section>

                {isLoading && (
                    <div className={classes.loader}>
                        <CircularProgress size={18} thickness={1}/>
                    </div>
                )}
            </Paper>
        );
    }
}

export default withTranslation()(withStyles(styles)(SubcategoryGroupPage));
