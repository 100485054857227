import React, { Component } from "react";

//Third parties libs
import numeral from "numeral";
import PropTypes from "prop-types";

import Highcharts from "highcharts";
import {
    HighchartsChart,
    withHighcharts,
    Chart,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    ColumnSeries
} from "react-jsx-highcharts";

const calculateBrandChartInfo = products => {
    let totalUnits = 0,
        totalSales = 0,
        totalSKUs = 0,
        sales = [],
        categories = [],
        units = [],
        skus = [];

    const brands = products.reduce((prevValue, product) => {
        if (product.brand in prevValue) {
            prevValue[product.brand] = {
                totalSales:
                    product.total_sales + prevValue[product.brand].totalSales,
                totalUnits:
                    product.total_units + prevValue[product.brand].totalUnits,
                SKUs: prevValue[product.brand].SKUs + 1
            };
        } else {
            prevValue[product.brand] = {
                totalSales: product.total_sales,
                totalUnits: product.total_units,
                SKUs: 1
            };
        }
        totalSales = totalSales + product.total_sales;
        totalUnits = totalUnits + product.total_units;
        totalSKUs = totalSKUs + 1;
        return prevValue;
    }, {});

    Object.keys(brands).forEach(brand => {
        categories.push(brand);

        sales.push({
            y: Math.ceil(brands[brand].totalSales / totalSales * 10000) / 100,
            totalSales: numeral(brands[brand].totalSales).format("0"),
            totalUnits: numeral(brands[brand].totalUnits).format("0"),
            totalSKUs: numeral(brands[brand].SKUs).format("0")
        });
        units.push({
            y: Math.ceil(brands[brand].totalUnits / totalUnits * 10000) / 100,
            totalSales: numeral(brands[brand].totalSales).format("0"),
            totalUnits: numeral(brands[brand].totalUnits).format("0"),
            totalSKUs: numeral(brands[brand].SKUs).format("0")
        });
        skus.push({
            y: Math.ceil(brands[brand].SKUs / totalSKUs * 10000) / 100,
            totalSales: numeral(brands[brand].totalSales).format("0"),
            totalUnits: numeral(brands[brand].totalUnits).format("0"),
            totalSKUs: numeral(brands[brand].SKUs).format("0")
        });
    });

    return {
        categories: categories,
        sales: sales,
        skus: skus,
        units: units,
        brands: brands
    };
};

export class TreeInfoPanelChart extends Component {
    render() {
        const chartData = calculateBrandChartInfo(this.props.products);

        const series = [
            {
                name: "Sales",
                data: chartData.sales,
                color: "#e376a3",
                tooltip: {
                    valueSuffix: "%",
                    headerFormat: "<b>{point.key}</b><br/>",
                    pointFormat:
                        "<span style='color:{point.color}'>\u25CF</span> {series.name}:<b>{point.y}</b>, Total: <b>{point.totalSales}</b> items<br/>"
                }
            },
            {
                name: "Units",
                color: "#fed088",
                data: chartData.units,
                tooltip: {
                    valueSuffix: "%",
                    headerFormat: "<b>{point.key}</b><br />",
                    pointFormat:
                        "<span style='color:{point.color}'>\u25CF</span> {series.name}:<b>{point.y}</b>, Total: <b>{point.totalUnits}</b> units<br/>"
                }
            },
            {
                name: "SKU's",
                color: "#30c8f2",
                data: chartData.skus,
                tooltip: {
                    valueSuffix: "%",
                    headerFormat: "<b>{point.key}</b><br />",
                    pointFormat:
                        "<span style='color:{point.color}'>\u25CF</span> {series.name}:<b>{point.y}</b>, Total: <b>{point.totalSKUs}</b> products<br/>"
                }
            }
        ];

        return (
            <HighchartsChart>
                <Chart />
                <Legend />
                <Tooltip shared={true} />

                <XAxis categories={chartData.categories} />

                <YAxis
                    labels={{
                        format: "{value}%"
                    }}
                >
                    {series.map(column => {
                        return (
                            <ColumnSeries
                                key={column.name}
                                name={column.name}
                                data={column.data}
                                brands={column.brands}
                                color={column.color}
                                tooltip={column.tooltip}
                            />
                        );
                    })}
                </YAxis>
            </HighchartsChart>
        );
    }
}

TreeInfoPanelChart.propTypes = {
    products: PropTypes.array,
    t: PropTypes.func.isRequired
};

export default withHighcharts(TreeInfoPanelChart, Highcharts);
