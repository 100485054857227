import React from "react";
import TreeInfoPanel from "./TreeInfoPanel";
import { withStyles } from "@material-ui/core/styles";
const styles = {
    root: {
        position: "relative",
        width: "100%",
        height: 600
    }
};
function TreeReassignProductAttributes({ classes, data }) {
    return (
        <div className={classes.root}>
            <TreeInfoPanel
                isModal={true}
                isOpenPanel={true}
                activeNodeInfo={data}
            />
        </div>
    );
}

export default withStyles(styles)(TreeReassignProductAttributes);
