import React from "react";
import { withTranslation } from "react-i18next";

import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
    root: {
        paddingLeft: 0,
        borderRadius: 0,
        width: "100%"
    },
    label: {
        justifyContent: "flex-start"
    }
};

function ExportCSV({ t, classes, isLoading, isDisabled, handleClick }) {
    return (
        <Button
            classes={{
                root: classes.root,
                label: classes.label
            }}
            disabled={isDisabled}
            onClick={handleClick}
        >
            {!isLoading && t("common.export")}
            {isLoading && <CircularProgress size={12} thickness={1} />}
        </Button>
    );
}

export default withTranslation()(withStyles(styles)(ExportCSV));
