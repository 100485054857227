import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import domtoimage from "dom-to-image";

import {
    HIERARCHICAL_VIEW,
    TAB_TREEVIEW_REASSIGN,
    TAB_TREEVIEW_UNDERSTAND
} from "constants/static";

import theme from "../../styles/MuiTheme";

import TreeViewTabs from "components/Tree/TreeViewTabs";
import TreeMap from "components/Tree/Treemap";
import TreeReassign from "components/Tree/TreeReassign";
import TreemapOverlay from "components/Tree/TreemapOverlay";
import TreeInfoPanel from "components/Tree/TreeInfoPanel";
import TreeIconBar from "components/Tree/TreeIconBar";
import HierarchicalTreeContainer from "components/Tree/HierarchicalTreeContainer";
import PageTitle from "../PageTitle/PageTitle";
import SavedSetSaveButtonContainer from "components/Tree/SavedSetSaveButtonContainer";

import { selectViewMode } from "redux/modules/tree/current";
import { openSnackbar } from "redux/modules/snackbar/open";

import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import { TREEMAP_VIEW } from "constants/static";

const StyledTreeView = styled.div`
    width: 100%;
`;

const StyledContentWrapper = styled(Paper)`
    padding: 0;
    && {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
`;

const StyledDialogToolbar = styled(Toolbar)`
    min-height: 70px;
`;

const StyledDialogAppBar = styled(AppBar)`
    && {
        background-color: ${theme.status.grey.greybox};
        box-shadow: none;
        align-items: flex-end;
    }
`;

const StyledTreeMapWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const StyledTreeMapWrapperDialog = styled.div`
    position: relative;
    width: 100%;
    padding-top: 15px;
`;

const StyledTreeViewLayout = styled.div`
    display: flex;
`;

const StyledTreeMapPanel = styled.div`
    position: relative;
    width: 80%;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const findActiveNode = (nodes, nodeName, foundNode = {}) =>
    nodes.reduce(
        (prev, curr) =>
            curr.name === nodeName
                ? curr
                : curr.children
                ? findActiveNode(curr.children, nodeName, prev)
                : prev,
        foundNode
    );

const downloadBlob = (res, fileName) => {
    const blob = new Blob([res], {
        type: "image/png"
    });
    const anchorEl = document.createElement("a");
    anchorEl.style = "display: none";
    document.body.appendChild(anchorEl);
    const url = window.URL.createObjectURL(blob);
    anchorEl.href = url;
    anchorEl.download = `${fileName}.png`;
    anchorEl.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(anchorEl);
};

const mapStateToProps = state => ({
    viewMode: selectViewMode(state)
});
const mapDispatchToProps = {
    openSnackbar
};

class TreeView extends Component {
    static propTypes = {
        activeNodeName: PropTypes.string,
        nodeNames: PropTypes.object,
        nextCluster: PropTypes.string,
        onFindSibling: PropTypes.func.isRequired,
        treeName: PropTypes.string,
        savedSetName: PropTypes.string,
        hasUnassigned: PropTypes.bool.isRequired,
        openSnackbar: PropTypes.func.isRequired
    };

    static defaultProps = {
        activeNodeName: "",
        nextCluster: "",
        nodeNames: {}
    };

    state = {
        activeClusterX: 0,
        activeClusterY: 0,
        activeOverlay: false,
        prevActiveOverlay: false,
        activeClusterIsProduct: false,
        activeClusterCanCombine: false,
        activeClusterName: null,
        sidePanelVisible: false,
        optimal: true,
        highlights: [],
        tab: TAB_TREEVIEW_UNDERSTAND,
        isDialogOpen: false,
        hierarchicalZoom: 0,
        isFitToScreen: false,
        isRotate: false,
        hierarchicalLayoutMethod: "tree",
        exportToDMTNodes: [],
        parentNodeSelectedForDmtExport: false
    };

    constructor(props) {
        super(props);

        this.treemapRef = React.createRef();
        this.hierarchyRef = React.createRef();

        this.isOverTreemap = false;
        this.isGlobalClickListener = false;

        this.onClickCluster = this.onClickCluster.bind(this);
        this.splitCluster = this.splitCluster.bind(this);
        this.collapseCluster = this.collapseCluster.bind(this);
        this.toggleOptimal = this.toggleOptimal.bind(this);
        this.toggleSidePanel = this.toggleSidePanel.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onEnterCombineNode = this.onEnterCombineNode.bind(this);
        this.onLeaveCombineNode = this.onLeaveCombineNode.bind(this);
        this.onChangeNodeName = this.onChangeNodeName.bind(this);
        this.onKeyDownNodeName = this.onKeyDownNodeName.bind(this);
        this.onClickWindow = this.onClickWindow.bind(this);
        this.onMouseEnterTreemap = this.onMouseEnterTreemap.bind(this);
        this.onMouseLeaveTreemap = this.onMouseLeaveTreemap.bind(this);
        this.onConfirmOptimalSplit = this.onConfirmOptimalSplit.bind(this);
        this.onClickMapView = this.onClickMapView.bind(this);
        this.onClickHierarchicalView = this.onClickHierarchicalView.bind(this);
        this.onClickOverlay = this.onClickOverlay.bind(this);
        this.onSelectNode = this.onSelectNode.bind(this);
    }

    componentWillUnmount() {
        this.removeGlobalClickListener();
    }

    componentDidUpdate(prevProps) {
        const { viewMode: prevViewMode } = prevProps;
        const { viewMode, data } = this.props;
        const { activeOverlay, activeClusterName } = this.state;
        if (viewMode !== prevViewMode) {
            if (viewMode !== HIERARCHICAL_VIEW && activeOverlay) {
                if (Array.isArray(data)) {
                    const node = findActiveNode(data, activeClusterName);
                    // Close overlay if not end of line node
                    if (node && node.children && node.children.length) {
                        this.setState({
                            activeOverlay: false,
                            activeClusterName: null
                        });
                    }
                }
            }
        }
    }

    handleDialogOpen = () => {
        this.setState({ isDialogOpen: true });
    };

    handleDialogClose = () => {
        this.setState({ isDialogOpen: false });
    };

    handleResetZoom = () => {
        this.setState({
            hierarchicalZoom: 0
        });
    };

    handleUpdateZoom = newZoom => {
        this.setState({
            hierarchicalZoom: newZoom
        });
    };

    handleFitToScreen = () => {
        this.handleSetFitToScreen(true);
    };

    handleToggleHierarchicalLayoutMethod = () => {
        this.setState({
            hierarchicalLayoutMethod:
                this.state.hierarchicalLayoutMethod === "tree"
                    ? "cluster"
                    : "tree"
        });
    };

    handleSetFitToScreen = newFitToScreen => {
        this.setState({
            isFitToScreen: newFitToScreen
        });
    };

    handleClickRotate = () => {
        this.setState({
            isRotate: !this.state.isRotate,
            isFitToScreen: true
        });
    };

    handleTreemapScreenshot = () => {
        const viewRef =
            this.props.viewMode === TREEMAP_VIEW
                ? this.treemapRef
                : this.hierarchyRef;
        if (viewRef && viewRef.current) {
            const node = viewRef.current;

            let treeName = this.props.treeName.replace(/\s/g, "-");
            let savedSetName =
                this.props.savedSetName &&
                this.props.savedSetName.replace(/\s/g, "-");
            let fileName = `${treeName}_${this.props.projectId}_${this.props.version.version}`;
            if (savedSetName) {
                fileName = `${savedSetName}_${fileName}`;
            }
            const scale = 3;
            const style = {
                transform: "scale(" + scale + ")",
                transformOrigin: "top left",
                width: node.offsetWidth + "px",
                height: node.offsetHeight + "px"
            };
            const param = {
                height: node.offsetHeight * scale,
                width: node.offsetWidth * scale,
                quality: 1,
                style
            };
            domtoimage
                .toBlob(node, param)
                .then(res => {
                    downloadBlob(res, fileName);
                })
                .catch(err => {
                    console.error(err);
                    this.props.openSnackbar(
                        true,
                        "Error attempting to download image"
                    );
                });
        }
    };

    onClickWindow(event) {
        //if (!this.isOverTreemap) {
        this.updateActiveOverlay(false);
        //}
    }

    addGlobalClickListener() {
        if (!this.isGlobalClickListener) {
            window.addEventListener("click", this.onClickWindow);
            this.isGlobalClickListener = true;
        }
    }

    removeGlobalClickListener() {
        if (this.isGlobalClickListener) {
            window.removeEventListener("click", this.onClickWindow);
            this.isGlobalClickListener = false;
        }
    }

    onClickCluster(event, cluster) {
        event && event.stopPropagation();
        if (cluster && cluster.name) {
            this.setActiveClusterName(cluster.name);
            this.updateActiveClusterOffset(cluster.offsetX, cluster.offsetY);
            this.updateActiveClusterMetrics(cluster);
            this.updateActiveOverlay(true);
            this.updateActiveClusterIsProduct(Boolean(cluster.product));
            this.props.onClickCluster(cluster.name);
            // In case of clicking outside, we want to close the overlay
            this.addGlobalClickListener();
        }
    }

    updateActiveOverlay(activeOverlay) {
        this.setState({ prevActiveOverlay: this.state.activeOverlay });
        this.setState({ activeOverlay });
    }

    updateActiveClusterMetrics(cluster) {
        this.setState({
            showMetrics: cluster.showMetrics,
            size: cluster.size,
            totalUnits: cluster.totalUnits,
            totalSales: cluster.totalSales
        });
    }

    updateActiveClusterOffset(activeClusterX, activeClusterY) {
        this.setState({
            activeClusterX,
            activeClusterY
        });
    }

    updateActiveClusterIsProduct(activeClusterIsProduct) {
        this.setState({ activeClusterIsProduct });
    }

    setActiveClusterName(activeClusterName) {
        this.setState({ activeClusterName });
    }

    splitCluster() {
        const { activeClusterName } = this.state;
        const { expandCluster } = this.props;
        if (activeClusterName) {
            expandCluster(activeClusterName);
            // close overlay on split
            this.updateActiveOverlay(false);
        }
    }

    collapseCluster() {
        const { activeClusterName } = this.state;
        const { collapseCluster } = this.props;
        if (activeClusterName) {
            collapseCluster(activeClusterName);
            this.updateActiveOverlay(false);
        }
    }

    toggleOptimal() {
        this.props.toggleOptimal(!this.state.optimal);
        this.setState({ optimal: !this.state.optimal });
    }

    toggleSidePanel() {
        this.setState({ sidePanelVisible: !this.state.sidePanelVisible });
    }

    highlightSibling(clusterName) {
        const { onFindSibling } = this.props;
        const highlights = clusterName ? onFindSibling(clusterName) : [];
        this.setState({ highlights });
    }

    clearHighlightSiblings() {
        this.setState({ highlights: [] });
    }

    onEnterCombineNode() {
        this.highlightSibling(this.state.activeClusterName);
    }

    onLeaveCombineNode() {
        this.clearHighlightSiblings();
    }

    onMouseEnterTreemap() {
        this.isOverTreemap = true;
    }

    onMouseLeaveTreemap() {
        this.isOverTreemap = false;
    }

    handleTabChange(event, tab) {
        this.setState({ tab });
    }

    onChangeNodeName(event) {
        const { activeNodeName, updateNodeName } = this.props;
        updateNodeName(activeNodeName, event.target.value);
    }

    onKeyDownNodeName(event) {
        const enterKeyCode = 13;
        if (event.keyCode === enterKeyCode) {
            this.updateActiveOverlay(false);
            event.preventDefault();
        }
    }

    onConfirmOptimalSplit() {
        const { showOptimalSplit } = this.props;
        showOptimalSplit();
    }

    onClickMapView(event) {
        event.stopPropagation();
        this.setTreemapView();
    }

    onClickHierarchicalView(event) {
        event.stopPropagation();
        this.setHierarchicalView();
    }

    onClickOverlay(event) {
        event.stopPropagation();
    }

    onSelectNode(event){
        const { activeNodeName} = this.props;
        let exportToDMTNodes = this.state.exportToDMTNodes.slice()
        if(event.target.checked && exportToDMTNodes.indexOf(activeNodeName) === -1) {
            exportToDMTNodes.push(activeNodeName);
        }else if(!event.target.checked) {
            const index = exportToDMTNodes.indexOf(activeNodeName);
            exportToDMTNodes.splice(index, 1);
        }
        this.setState({exportToDMTNodes: exportToDMTNodes});
    }

    updateParentNodeSelectedForDmtExport = (parentNodeSelected) => {
        this.setState({parentNodeSelectedForDmtExport: parentNodeSelected});
    }

    renderView() {
        const {
            viewMode,
            data,
            products,
            activeNodeNameUserDefined,
            nextCluster,
            treeName,
            activeNodeName,
        } = this.props;

        const {
            activeClusterX,
            activeClusterY,
            activeOverlay,
            prevActiveOverlay,
            activeClusterIsProduct,
            activeClusterName,
            highlights,
            showMetrics,
            size,
            totalSales,
            totalUnits,
            hierarchicalZoom,
            exportToDMTNodes,
            parentNodeSelectedForDmtExport
        } = this.state;

        return (
            <React.Fragment>
                {viewMode === HIERARCHICAL_VIEW ? (
                    <HierarchicalTreeContainer
                        ref={this.hierarchyRef}
                        categoryTitle={treeName}
                        data={data}
                        products={products}
                        getProductsByNodeName={this.props.getProductsByNodeName}
                        onClickCluster={this.onClickCluster}
                        activeOverlay={activeOverlay}
                        activeClusterName={activeClusterName}
                        nextCluster={nextCluster}
                        zoom={hierarchicalZoom}
                        handleUpdateZoom={this.handleUpdateZoom}
                        isFitToScreen={this.state.isFitToScreen}
                        handleSetFitToScreen={this.handleSetFitToScreen}
                        isRotate={this.state.isRotate}
                        handleClickRotate={this.handleClickRotate}
                        hierarchicalLayoutMethod={
                            this.state.hierarchicalLayoutMethod
                        }
                        exportToDMTNodes={exportToDMTNodes}
                        updateParentNodeSelectedForDmtExport={this.updateParentNodeSelectedForDmtExport}
                    />
                ) : (
                    <TreeMap
                        ref={this.treemapRef}
                        activeClusterName={activeClusterName}
                        activeOverlay={activeOverlay}
                        highlights={highlights}
                        onClick={this.onClickCluster}
                        onSplitCluster={this.splitCluster}
                        {...this.props}
                    />
                )}
                <TreemapOverlay
                    nodeName={activeNodeNameUserDefined}
                    active={activeOverlay}
                    prevActive={prevActiveOverlay}
                    x={activeClusterX}
                    y={activeClusterY}
                    isProduct={activeClusterIsProduct}
                    onClickOverlay={this.onClickOverlay}
                    onClickSplitNode={this.splitCluster}
                    onClickCombineNode={this.collapseCluster}
                    onEnterCombineNode={this.onEnterCombineNode}
                    onLeaveCombineNode={this.onLeaveCombineNode}
                    onChangeNodeName={this.onChangeNodeName}
                    onKeyDownNodeName={this.onKeyDownNodeName}
                    showMetrics={showMetrics}
                    totalSales={totalSales}
                    size={size}
                    totalUnits={totalUnits}
                    onSelectNode={this.onSelectNode}
                    exportToDMTNodes={exportToDMTNodes}
                    activeNodeName={activeNodeName}
                    disableCheckbox={parentNodeSelectedForDmtExport}
                />
            </React.Fragment>
        );
    }

    render() {
        const {
            activeNodeInfo,
            isUnsavedChanges,
            activeNodeName,
            activeNodeNameUserDefined,
            brands,
            treeName,
            needUnitLen,
            savedSetName,
            hasUnassigned,
            onChangeNeedUnitNum,
            data
        } = this.props;

        const { activeClusterName, tab, isDialogOpen, exportToDMTNodes } = this.state;
        return (
            <StyledTreeView>
                <PageTitle
                    title={treeName}
                    alt={true}
                    subtitle={savedSetName}
                />
                <SavedSetSaveButtonContainer
                    projectId={this.props.projectId}
                    isUnassignedProducts={hasUnassigned}
                />
                <StyledContentWrapper elevation={4}>
                    <TreeViewTabs
                        tab={tab}
                        handleTabChange={this.handleTabChange}
                    />
                    {tab === TAB_TREEVIEW_UNDERSTAND && (
                        <React.Fragment>
                            <StyledTreeMapWrapper
                                onMouseEnter={this.onMouseEnterTreemap}
                                onMouseLeave={this.onMouseLeaveTreemap}
                            >
                                <StyledTreeViewLayout>
                                    <StyledTreeMapPanel>
                                        <TreeIconBar
                                            version={this.props.version}
                                            savedSet={this.props.savedSet}
                                            isUnsavedChanges={isUnsavedChanges}
                                            onConfirmOptimalSplit={
                                                this.onConfirmOptimalSplit
                                            }
                                            onChangeNeedUnitNum={
                                                onChangeNeedUnitNum
                                            }
                                            needUnitLen={needUnitLen}
                                            brands={brands}
                                            onClickTreeViewExpand={
                                                this.handleDialogOpen
                                            }
                                            onClickResetZoom={
                                                this.handleResetZoom
                                            }
                                            onClickFitToScreen={
                                                this.handleFitToScreen
                                            }
                                            onClickRotate={
                                                this.handleClickRotate
                                            }
                                            onClickToggleHierarchicalLayout={
                                                this
                                                    .handleToggleHierarchicalLayoutMethod
                                            }
                                            onClickTakeScreenshot={
                                                this.handleTreemapScreenshot
                                            }
                                            exportToDMTNodes={exportToDMTNodes}
                                            data={data}
                                        />
                                        {this.renderView()}
                                    </StyledTreeMapPanel>
                                    <TreeInfoPanel
                                        activeNodeInfo={activeNodeInfo}
                                        activeNodeName={activeNodeName}
                                        activeNodeNameUserDefined={
                                            activeNodeNameUserDefined
                                        }
                                    />
                                </StyledTreeViewLayout>
                            </StyledTreeMapWrapper>
                            <Dialog
                                fullScreen
                                open={isDialogOpen}
                                onClose={this.handleDialogClose}
                                TransitionComponent={Transition}
                            >
                                <StyledDialogAppBar
                                    position="static"
                                    color="inherit"
                                >
                                    <StyledDialogToolbar>
                                        <Fab
                                            className="icon-sm grey-bg"
                                            onClick={this.handleDialogClose}
                                        >
                                            <CloseIcon />
                                        </Fab>
                                    </StyledDialogToolbar>
                                </StyledDialogAppBar>
                                <StyledTreeMapWrapperDialog
                                    onMouseEnter={this.onMouseEnterTreemap}
                                    onMouseLeave={this.onMouseLeaveTreemap}
                                >
                                    <StyledTreeViewLayout>
                                        <StyledTreeMapPanel>
                                            <TreeIconBar
                                                savedSet={
                                                    this.props.savedSetName
                                                }
                                                needUnitLen={needUnitLen}
                                                isUnsavedChanges={
                                                    isUnsavedChanges
                                                }
                                                onChangeNeedUnitNum={
                                                    onChangeNeedUnitNum
                                                }
                                                onConfirmOptimalSplit={
                                                    this.onConfirmOptimalSplit
                                                }
                                                onClickResetZoom={
                                                    this.handleResetZoom
                                                }
                                                onClickFitToScreen={
                                                    this.handleFitToScreen
                                                }
                                                onClickRotate={
                                                    this.handleClickRotate
                                                }
                                                onClickToggleHierarchicalLayout={
                                                    this
                                                        .handleToggleHierarchicalLayoutMethod
                                                }
                                                onClickTakeScreenshot={
                                                    this.handleTreemapScreenshot
                                                }
                                                brands={brands}
                                                data={data}
                                            />
                                            {this.renderView()}
                                        </StyledTreeMapPanel>
                                        <TreeInfoPanel
                                            activeNodeInfo={activeNodeInfo}
                                            activeClusterName={
                                                activeClusterName
                                            }
                                            activeNodeName={activeNodeName}
                                        />
                                    </StyledTreeViewLayout>
                                </StyledTreeMapWrapperDialog>
                            </Dialog>
                        </React.Fragment>
                    )}
                    {tab === TAB_TREEVIEW_REASSIGN && (
                        <TreeReassign projectId={this.props.projectId} />
                    )}
                </StyledContentWrapper>
            </StyledTreeView>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeView);
