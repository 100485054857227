import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = theme => ({
    tableHead: {
        background: theme.status.grey.greybox,
        fontWeight: 400
    }
});

function ParametersTimePeriod(props) {
    const { version, t, classes } = props;

    return (
        <Table>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell>{t("version.attributes_tree")}</TableCell>
                    <TableCell>
                        {t("version.attributes_subcategories")}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {version.trees.map((tree, idx) => (
                    <Fragment key={idx}>
                        {tree.subcategories.map((elem, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {index === 0 && tree.name}
                                    </TableCell>
                                    <TableCell>{elem}</TableCell>
                                </TableRow>
                            );
                        })}
                    </Fragment>
                ))}
            </TableBody>
        </Table>
    );
}

ParametersTimePeriod.propTypes = {
    classes: PropTypes.object.isRequired,
    version: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(ParametersTimePeriod));
