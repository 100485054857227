// redux-saga
import { fork, all } from "redux-saga/effects";

// Redux modules
import { watchUserInfo } from "../modules/user/get";
import { watchConfig } from "../modules/config";
import {
    watchProjects,
    watchProject,
    watchDeleteProject,
    watchProjectUpdateConstraints,
    watchProjectHierarchySelection,
    watchAddEditProject,
    watchProjectRunSimulation
} from "../modules/projects/projects";
import { watchCollaborators } from "../modules/collaborators/collaborators";
import { watchRetailerDates } from "../modules/retailer/getDates";
import {
    watchProducts,
    watchUpdateProducts
} from "../modules/products/products";
import {
    watchDeleteVersion,
    watchUpdateVersion,
    watchVersion,
    watchVersions,
    watchUpdateSavedSet,
    watchSaveSavedSet,
    watchSavedSet
} from "../modules/versions/versions";
import { watchSubcategoryGroups } from "../modules/subcategories/groups";
import { watchSubcategoryMetrics } from "../modules/subcategories/metrics";
import { watchSaveSubcategoryGroups } from "../modules/subcategories/save";
import {
    watchSubcategoryGroupsLoad,
    watchSubcategoryGroupsChange,
    watchSubcategoriesResetAfterLoad,
    watchSubcategoryDragStart,
    watchSubcategoryDragEnd,
    watchSubcategoryDelete
} from "../modules/subcategories/subcategories";
import { watchJobs } from "../modules/jobs/jobs";
import { watchVerifyProducts } from "../modules/products/importFromFile";
import { watchImportFromAssorter } from "../modules/products/importFromAssorter";
import {
    watchReassignDragEnd,
    watchReassignDragStart,
    watchReassignToTarget
} from "../modules/reassign/reassign";
import { watchExtendSession } from "../modules/liferay";
import { watchExport } from "../modules/tree/exportData";
import { watchVerifyStore } from "../modules/stores/verify";
import { watchSaveStores } from "../modules/stores/save";
import { watchExportProductImportTemplate } from "../modules/products/importProductTemplate";
import { watchExportStoreImportTemplate } from "../modules/stores/importStoreTemplate";
import { watchDeleteSavedSet } from "../modules/tree/savedset";
import { watchDMTProduct } from "../modules/dmtProductTree/dmtProductTree";
import { watchDMTStore } from "../modules/dmtStoreTree/dmtStoreTree";
import { watchDMTAttributeList } from "../modules/dmtAttributeList/dmtAttributeList";
import { watchDMTStoreAttributeList } from "../modules/dmtAttributeList/dmtStoreAttributeList";
import { watchDMTRealization } from "../modules/dmtProductTree/dmtRealization";
import { watchDMTStoreRealization } from "../modules/dmtStoreTree/dmtStoreRealization";
import { watchExportProducts } from "../modules/products/exportProducts";

import { watchFeedbackQuestions } from "../modules/feedbackQuestions";
import { watchFeedbackAnswers } from "../modules/feedbackAnswers";
import { watchDMTUserDefinedAttributeList } from "../modules/dmtAttributeList/dmtUserDefinedAttributeList";
import { watchDMTAttributeExport } from "../modules/dmtAttributeExport/dmtAttributeExport";
import { watchNodesProducts } from "../modules/products/nodeProducts";

export default function* rootSaga() {
    // Create a fork for each action watcher
    yield all([
        fork(watchConfig),
        fork(watchUserInfo),
        fork(watchProjects),
        fork(watchCollaborators),
        fork(watchAddEditProject),
        fork(watchRetailerDates),
        fork(watchProject),
        fork(watchDeleteProject),
        fork(watchProjectUpdateConstraints),
        fork(watchProjectHierarchySelection),
        fork(watchProjectRunSimulation),
        fork(watchProducts),
        fork(watchVerifyProducts),
        fork(watchUpdateProducts),
        fork(watchImportFromAssorter),
        fork(watchVersions),
        fork(watchVersion),
        fork(watchUpdateVersion),
        fork(watchDeleteVersion),
        fork(watchJobs),
        fork(watchUpdateSavedSet),
        fork(watchSaveSavedSet),
        fork(watchSavedSet),
        fork(watchSubcategoryGroups),
        fork(watchSubcategoryMetrics),
        fork(watchSaveSubcategoryGroups),
        fork(watchReassignDragStart),
        fork(watchReassignDragEnd),
        fork(watchReassignToTarget),
        fork(watchSubcategoryGroupsLoad),
        fork(watchSubcategoryGroupsChange),
        fork(watchSubcategoriesResetAfterLoad),
        fork(watchSubcategoryDragStart),
        fork(watchSubcategoryDragEnd),
        fork(watchSubcategoryDelete),
        fork(watchExtendSession),
        fork(watchExport),
        fork(watchVerifyStore),
        fork(watchSaveStores),
        fork(watchExportProductImportTemplate),
        fork(watchExportStoreImportTemplate),
        fork(watchDeleteSavedSet),
        fork(watchDMTProduct),
        fork(watchDMTAttributeList),
        fork(watchDMTStoreAttributeList),
        fork(watchDMTRealization),
        fork(watchExportProducts),
        fork(watchDMTStore),
        fork(watchDMTStoreRealization),
        fork(watchFeedbackQuestions),
        fork(watchFeedbackAnswers),
        fork(watchDMTUserDefinedAttributeList),
        fork(watchDMTAttributeExport),
        fork(watchNodesProducts),
    ]);
}
