import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        marginTop: 20,
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        padding: 10,
        backgroundColor: theme.status.clay[10]
    },
    chip: {
        margin: 5
    }
});

let ChipList = ({ items, labelKey = "name", handleDelete, classes = {} }) => (
    <Paper className={classes.root}>
        {items.map(el => (
            <Chip
                className={classes.chip}
                key={el.id}
                label={el[labelKey]}
                onDelete={() => handleDelete(el.id)}
            />
        ))}
    </Paper>
);

ChipList.propTypes = {
    items: PropTypes.array.isRequired,
    labelKey: PropTypes.string,
    handleDelete: PropTypes.func.isRequired,
    classes: PropTypes.object
};

ChipList = withStyles(styles)(ChipList);

export default ChipList;
