import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import theme from "../../styles/MuiTheme";

const StyledOverlayNameInput = styled.div`
    padding: 10px 30px 0px 30px;
`;

const StyledTextField = styled(TextField)`
    && {
        label {
            color: ${theme.status.grey.dark};
            opacity: 0.5;
            font-size: 0.75rem;
            font-weight: 600;
        }
        width: 140px;
    }
`;

export class TreemapOverlayNameInput extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.focus = this.focus.bind(this);
    }

    focus() {
        this.textInput.current.focus();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value !== prevProps.value){
            this.focus();
        }
    }

    render() {
        let {t, value, onChange, onKeyDown} = this.props;

        return (
            <StyledOverlayNameInput>
                <StyledTextField
                    required={false}
                    id="treemap-rename"
                    label={t("tree.enter_name")}
                    margin="normal"
                    autoFocus={true}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    InputLabelProps={{shrink: true}}
                    inputRef={this.textInput}
                />
            </StyledOverlayNameInput>
        );
    }
}

TreemapOverlayNameInput.propTypes = {
    t: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired
};

export default withTranslation()(TreemapOverlayNameInput);
