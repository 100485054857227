import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../styles/MuiTheme";
import { withTranslation } from "react-i18next";

import TreemapIcon from "components/Tree/TreemapIcon";

const StyledButton = styled.button`
    padding: 14px 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
`;

const StyledIconWrapper = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 17px;
    padding: 2px;
    background-color: ${theme.status.grey.white};
    box-shadow: ${theme.shadows[1]};
    &:hover {
        background-color: ${theme.status.yellow.dark};
    }
`;

export const TreeMapViewButton = ({ t, active, onClickMapView }) => (
    <StyledButton title={t("tree.show_treemap_view")} onClick={onClickMapView}>
        <StyledIconWrapper>
            <TreemapIcon active={active} />
        </StyledIconWrapper>
    </StyledButton>
);

TreeMapViewButton.propTypes = {
    active: PropTypes.bool.isRequired,
    onClickMapView: PropTypes.func.isRequired
};

export default withTranslation()(TreeMapViewButton);
