// React
import React, { Fragment } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

// components
import Header from "components/Header/Header";
import AlertContainer from "components/AlertContainer";

// pages
import ProjectListPage from "pages/ProjectListPage";
import ProjectPage from "pages/ProjectPage";
import VersionListPage from "pages/VersionListPage";
import TreeViewPage from "pages/TreeViewPage";

import Snackbar from "components/Snackbar/Snackbar";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
        position: "relative",
        backgroundColor: theme.status.clay[10]
    },
    container: {
        position: "relative",
        paddingTop: 80,
        paddingBottom: 100,
        maxWidth: 1140,
        width: "80%",
        margin: "0 auto"
    }
});

let PageWrapper = ({ classes, children }) => {
    return (
        <div className={classes.root}>
            <div className={classes.container}>{children}</div>
        </div>
    );
};

PageWrapper = withStyles(styles)(PageWrapper);

const Main = () => (
    <HashRouter>
        <Fragment>
            <Snackbar />
            <Header />
            <PageWrapper>
                <Switch>
                    <Route exact path="/" component={ProjectListPage} />
                    <Route
                        exact
                        path="/project/:projectId"
                        component={ProjectPage}
                    />
                    <Route
                        exact
                        path="/project/:projectId/versions"
                        component={VersionListPage}
                    />
                    <Route
                        exact
                        path="/project/:projectId/versions/:version/:treeId/:savedSetId"
                        component={TreeViewPage}
                    />
                </Switch>
            </PageWrapper>
            <AlertContainer />
        </Fragment>
    </HashRouter>
);

export default Main;
