import React from "react";

// MUI
import { withStyles } from "@material-ui/core/styles";

import DragDownSVG from "../../static/img/drag-down.svg";

const styles = {
    "@keyframes dragAnimation": {
        "0%": { transform: "translateY(0px)", opacity: 0 },
        "50%": { opacity: 1 },
        "100%": { transform: "translateY(50px)", opacity: 0 }
    },
    root: {
        transform: "translateY(0px)",
        opacity: 0,
        animationName: "$dragAnimation",
        animationDuration: "2s",
        animationTimingFunction: "cubic-bezier(0.645, 0.045, 0.355, 1)",
        animationIterationCount: "infinite"
    }
};
export function DragDownIcon({ classes }) {
    return <img className={classes.root} src={DragDownSVG} alt="" />;
}

export default withStyles(styles)(DragDownIcon);
