import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const styles = theme => ({
    dot: {
        display: "inline-block",
        margin: "0 5px",
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: theme.status.grey.white,
        border: "solid 1px",
        borderColor: theme.status.grey.greydivs,
        cursor: "pointer"
    },
    dotActive: {
        backgroundColor: theme.status.grey.greydivs,
        borderColor: theme.status.grey[100]
    }
});

class Dot extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        active: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired
    };

    handleClick = () => {
        this.props.onClick(this.props.index);
    };

    render() {
        const { active, classes } = this.props;

        return (
            <div
                className={classnames(
                    classes.dot,
                    active ? classes.dotActive : null
                )}
                onClick={this.handleClick}
            />
        );
    }
}

export default withStyles(styles)(Dot);
