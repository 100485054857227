export function groupSubcategoriesFromProducts(products) {
    return products.reduce((prev, curr) => {
        const hasSubcategory =
            prev.filter(item => item.subcategory === curr.subcategory).length >
            0;

        if (hasSubcategory) {
            prev = prev.map(item =>
                item.subcategory === curr.subcategory
                    ? {
                          ...item,
                          productCount: item.productCount + 1
                      }
                    : item
            );
        } else {
            prev = [
                ...prev,
                {
                    subcategory: curr.subcategory,
                    productCount: 1
                }
            ];
        }
        return prev;
    }, []);
}
