import { connect } from "react-redux";
import { DragSource, DropTarget } from "react-dnd";

import SubcategoryCardBase from "./SubcategoryCardBase";
import { ItemTypes } from "./SubcategoryDragConstants";

// Redux
import {
    subcategoryDragStart,
    subcategoryDragEnd
} from "../../redux/modules/subcategories/subcategories";

// The DragSource higher-order component accepts three parameters: type, spec, and collect

// DnD source specification
const cardSourceSpec = {
    beginDrag(props) {
        const { subcategoryDragStart, id, groupId } = props;
        subcategoryDragStart(id);
        return { id, groupId };
    },
    endDrag(props, monitor) {
        if (!monitor.didDrop()) {
            return;
        }
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();

        if (!item || !dropResult) {
            console.warn("Item or drop result missing", item, dropResult);
            return;
        }
        const { subcategoryDragEnd } = props;
        subcategoryDragEnd(
            item.id,
            item.groupId,
            dropResult.id,
            dropResult.groupId
        );
    }
};

// DnD drop specification for subcat card
const dropTarget = {
    canDrop(props, monitor) {
        const item = monitor.getItem();
        // prevent dropping on to self
        const isSelf = item && item.id === props.id;
        // groups of 2 can not re-combine
        return !isSelf && props.canCombine;
    },
    drop(props) {
        return {
            id: props.id,
            groupId: props.groupId
        };
    }
};

// DnD drop collect function
function dropCollect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}

// DnD collect function
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging()
    };
}

// Wrap subcategory card component in drag higher-order component
let SubcategoryCard = DragSource(
    ItemTypes.SUBCAT_CARD,
    cardSourceSpec,
    collect
)(SubcategoryCardBase);

// wrap in dnd drop higher-order component also
SubcategoryCard = DropTarget(ItemTypes.SUBCAT_CARD, dropTarget, dropCollect)(
    SubcategoryCard
);

// Connect Subcategory card to redux
const mapDispatchToProps = {
    subcategoryDragStart,
    subcategoryDragEnd
};

export default connect(
    null,
    mapDispatchToProps
)(SubcategoryCard);
