const api = {
    "app.config": {
        url: "/config/app",
        type: "get",
        successful: "snack_bar_messages.app_config_successful",
        error: "snack_bar_messages.app_config_error",
        data: null
    },
    "retailer.transactions.date.get": {
        url: "/retailer/transactionDates",
        type: "get",
        successful:
            "snack_bar_messages.retailer_transactions_date_get_successful",
        error: "snack_bar_messages.retailer_transactions_date_get_error",
        data: null
    },
    "user.info.get": {
        url: "/user/current",
        type: "get",
        successful: "snack_bar_messages.user_info_get_successful",
        error: "snack_bar_messages.user_info_get_error",
        data: null
    },
    "collaborators.get": {
        url: "/user/collaborators",
        type: "get",
        successful: "snack_bar_messages.collaborators_get_successful",
        error: "snack_bar_messages.collaborators_get_error",
        data: null
    },
    "projects.get.all": {
        url: "/project",
        type: "get",
        successful: "snack_bar_messages.projects_get_all_successful.",
        error: "snack_bar_messages.projects_get_all_error",
        data: null
    },
    "project.get.id": projectId => {
        return {
            url: `/project/${projectId}`,
            type: "get",
            successful: "snack_bar_messages.project_get_id_successful",
            error: "snack_bar_messages.project_get_id_error",
            data: {
                status: "ACTIVE",
                source: "STANDARD"
            }
        };
    },
    "project.add": {
        url: "/project",
        type: "post",
        successful: "snack_bar_messages.project_add_successful",
        error: "snack_bar_messages.project_add_error",
        data: {
            status: "ACTIVE",
            source: "STANDARD"
        }
    },
    "project.delete": projectId => {
        return {
            url: `/project/${projectId}`,
            type: "delete",
            successful: "snack_bar_messages.project_delete_successful",
            error: "snack_bar_messages.project_delete_error"
        };
    },
    "project.edit": projectId => {
        return {
            url: `/project/${projectId}`,
            type: "put",
            successful: "snack_bar_messages.project_edit_successful",
            error: "snack_bar_messages.project_edit_error",
            data: {
                status: "ACTIVE",
                source: "STANDARD"
            }
        };
    },
    "project.submit": projectId => {
        return {
            url: `/project/${projectId}/submit`,
            type: "post",
            successful: "snack_bar_messages.project_submit_successful",
            error: "snack_bar_messages.project_submit_error",
            data: {
                description: "initial ranging"
            }
        };
    },
    "project.products.get": projectId => {
        return {
            url: `/${projectId}/products`,
            type: "get",
            successful: "snack_bar_messages.project_products_get_successful",
            error: "snack_bar_messages.project_products_get_error"
        };
    },
    "project.products.put": (projectId, data) => {
        return {
            url: `/${projectId}/products`,
            type: "put",
            successful: "snack_bar_messages.project_products_put_successful",
            error: "snack_bar_messages.project_products_put_error",
            data: data
        };
    },
    "project.subcategories.ungroup.get": projectId => {
        return {
            url: `/project/${projectId}/subcategoryMetrics`,
            type: "get",
            successful:
                "snack_bar_messages.project_subcategories_ungroup_get_successful",
            error: "snack_bar_messages.project_subcategories_ungroup_get_error"
        };
    },
    "project.subcategories.group.get": projectId => {
        return {
            url: `/project/${projectId}/subcategoryGroups`,
            type: "get",
            successful:
                "snack_bar_messages.project_subcategories_group_get_successful",
            error: "snack_bar_messages.project_subcategories_group_get_error"
        };
    },
    "project.subcategories.group.save": projectId => {
        return {
            url: `/project/${projectId}/subcategoryGroups`,
            type: "post",
            successful:
                "snack_bar_messages.project_subcategories_group_save_successful",
            error: "snack_bar_messages.project_subcategories_group_save_error"
        };
    },
    "products.import.from.assorter": projectId => {
        return {
            url: `/${projectId}/importProductsFromAssorter`,
            type: "put",
            successful:
                "snack_bar_messages.products_import_from_assorter_successful",
            error: "snack_bar_messages.products_import_from_assorter_error"
        };
    },
    "products.import.from.file": (projectId, file) => {
        return {
            url: `/project/${projectId}/verifyProductImport`,
            type: "post",
            successful:
                "snack_bar_messages.products_import_from_file_successful",
            error: "snack_bar_messages.products_import_from_file_error",
            data: {},
            attachment: { file },
            args: {
                fileFieldName: "productFile"
            }
        };
    },
    "stores.import.from.file": (projectId, file) => {
        return {
            url: `/project/${projectId}/verifyStoreImport`,
            type: "post",
            successful: "snack_bar_messages.stores_import_from_file_successful",
            error: "snack_bar_messages.stores_import_from_file_error",
            data: {},
            attachment: { file },
            args: {
                fileFieldName: "storeFile"
            }
        };
    },
    "stores.save": (projectId, stores) => {
        return {
            url: `/${projectId}/stores`,
            type: "put",
            successful: "snack_bar_messages.stores_save_successful",
            error: "snack_bar_messages.stores_save_error",
            data: stores
        };
    },
    "project.constraints": projectId => {
        return {
            url: `/project/${projectId}/constraints`,
            type: "put",
            successful: "snack_bar_messages.project_constraints_successful",
            error: "snack_bar_messages.project_constraints_error"
        };
    },
    "project.productStoreHierarchySelection": projectId => {
        return {
            url: `/project/${projectId}/productStoreHierarchySelection`,
            type: "put",
            successful: "snack_bar_messages.project_hierarchy_successful",
            error: "snack_bar_messages.project_hierarchy_error"
        };
    },
    "project.versions.get": projectId => {
        return {
            url: `/project/${projectId}/versions`,
            type: "get",
            successful: "snack_bar_messages.project_versions_get_successful",
            error: "snack_bar_messages.project_versions_get_error"
        };
    },
    "version.delete": (projectId, versionNumber) => {
        return {
            url: `/project/${projectId}/${versionNumber}`,
            type: "delete",
            successful: "snack_bar_messages.version_delete_successful",
            error: "snack_bar_messages.version_delete_error"
        };
    },
    "version.get": (projectId, versionNumber) => {
        return {
            url: `/project/${projectId}/${versionNumber}`,
            type: "get",
            successful: "snack_bar_messages.version_get_successful",
            error: "snack_bar_messages.version_get_error"
        };
    },
    "version.edit.description": (projectId, versionNumber) => {
        return {
            url: `/project/${projectId}/${versionNumber}`,
            type: "put",
            successful:
                "snack_bar_messages.version_edit_description_successful",
            error: "snack_bar_messages.version_edit_description_error",
            data: {}
        };
    },
    "savedset.save": (projectId, versionNumber, rangingSetId) => {
        return {
            url: `/project/${projectId}/${versionNumber}/${rangingSetId}/savedSet`,
            type: "post",
            successful: "savedset_save_successful",
            error: "savedset_save_error",
            data: {}
        };
    },
    "savedset.update": savedSetId => {
        return {
            url: `/project/${savedSetId}/savedSet`,
            type: "put",
            successful: "snack_bar_messages.savedset_update_successful",
            error: "snack_bar_messages.savedset_update_error",
            data: {}
        };
    },
    "savedset.delete": savedSetId => {
        return {
            url: `/project/${savedSetId}/savedSet`,
            type: "delete",
            successful: "snack_bar_messages.savedset_delete_successful",
            error: "snack_bar_messages.savedset_delete_error",
            data: {}
        };
    },
    "jobs.get": jobIds => {
        return {
            url: "/job/progress",
            type: "post",
            successful: "Project updated successfully.",
            error: "There was an error retrieving job progress",
            data: jobIds
        };
    },
    "export.get": (projectId, projectVersion, savedSetName) => {
        return {
            url: `/project/${projectId}/${projectVersion}/${savedSetName}/exportData`,
            type: "get",
            successful: "snack_bar_messages.export_get_successful",
            error: "snack_bar_messages.export_get_error"
        };
    },
    "rangingSet.nodes.products.get": (projectId, projectVersion, savedSetName, nodes) => {
        return {
            url: `/project/${projectId}/${projectVersion}/${savedSetName}/nodesProducts?nodes=${nodes}`,
            type: "get",
            successful: "success",
            error: "error",
            data: {}
        };
    },
    "products.export.template.get": () => {
        return {
            url: `/project/exportProductImportTemplate`,
            type: "get",
            successful:
                "snack_bar_messages.products_export_template_get_successful",
            error: "snack_bar_messages.products_export_template_get_error"
        };
    },
    "stores.export.template.get": () => {
        return {
            url: `/project/exportStoreImportTemplate`,
            type: "get",
            successful:
                "snack_bar_messages.stores_export_template_get_successful",
            error: "snack_bar_messages.stores_export_template_get_error"
        };
    },
    "dmt.stores.tree.get": () => {
        return {
            url: "/store/tree",
            type: "get",
            successful: "success",
            error: "error"
        };
    },
    "dmt.stores.tree.node.get": id => {
        return {
            url: `/store/tree/${id}`,
            type: "get",
            successful: "successful",
            error: "error"
        };
    },
    "dmt.product.tree.get": () => {
        return {
            url: "/product/tree",
            type: "get",
            successful: "success",
            error: "error"
        };
    },
    "dmt.product.tree.node.get": id => {
        return {
            url: `/product/tree/${id}`,
            type: "get",
            successful: "successful",
            error: "error"
        };
    },
    "dmt.attribute.list.get": () => {
        return {
            url: "/product/attributes",
            type: "get",
            successful: "successful",
            error: "error"
        };
    },
    "dmt.attribute.userDefinedList.get": () => {
        return {
            url: "/product/userDefinedAttributes",
            type: "get",
            successful: "successful",
            error: "error"
        };
    },
    "dmt.attribute.exportAttributeValues.post": () => {
        return {
            url: "/product/importAttributeValues",
            type: "post",
            successful: "export_successful",
            error: "export_error"
        };
    },
    "dmt.store.attributes.list.get": () => {
        return {
            url: "/store/attributes",
            type: "get",
            successful: "successful",
            error: "error"
        };
    },
    "dmt.product.realize": data => {
        return {
            url: "/product/realize",
            type: "get",
            successful: "successful",
            error: "Error in DMT realization",
            data
        };
    },
    "dmt.store.realize": data => {
        return {
            url: "/store/realize",
            type: "get",
            succesful: "successful",
            error: "error"
        };
    },
    "products.export.products.get": id => {
        return {
            url: `/project/${id}/exportProducts`,
            type: "get",
            successful: "snack_bar_messages.products_export_successful",
            error: "snack_bar_messages.products_export_error"
        };
    },
    "feedback.questions.get": () => {
        return {
            url: "/feedback/questions",
            type: "get",
            successful: "success",
            error: "error"
        };
    },
    "feedback.questions.post": () => {
        return {
            url: "/feedback/answers",
            type: "post",
            successful: "Feedback received successfully",
            error: "Error attempting to submit feedback"
        };
    }
};

export default api;
