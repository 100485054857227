// moment date lib
import moment from "moment";

// reselect
import { createSelector } from "reselect";

// redux-saga
import { takeEvery } from "redux-saga/effects";

// Redux saga helper
import { fetchEntity } from "../../store/sagaHelpers";

// Api configuration
import api from "api";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "retailerDates";
const { reducer, actions, selectors } = createFetchRedux(reducerName);

export default reducer;

// Action types
export const FETCH_RETAILER_DATES = `${reducerName}/FETCH_RETAILER_DATES`;

// Actions
export function fetchRetailerDates() {
    return {
        type: FETCH_RETAILER_DATES
    };
}

export const retailerDates = actions;

// Select retailer dates
export const selectRetailerDates = selectors.selectReduxData;
export const selectRetailerDatesLoading = selectors.selectReduxLoading;

export const selectMinRetailerDate = createSelector(
    [selectRetailerDates],
    dates => (dates && dates.start_date ? moment(dates.start_date) : null)
);

export const selectMaxRetailerDate = createSelector(
    [selectRetailerDates],
    dates => (dates && dates.end_date ? moment(dates.end_date) : null)
);

// Sagas
export const fetchRetailerDatesEntity = fetchEntity.bind(
    null,
    retailerDates,
    api["retailer.transactions.date.get"]
);

export function* watchRetailerDates() {
    yield takeEvery(FETCH_RETAILER_DATES, fetchRetailerDatesEntity);
}
