import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = {
    card: {
        padding: 35,
        marginTop: 30,
        marginBottom: 40,
        textAlign: "center"
    },
    text: {
        marginBottom: 45
    }
};

function ImportCard({ onClick, title, buttonLabel, classes, t, children }) {
    return (
        <Card className={classes.card}>
            <Typography variant="h5" className={classes.text}>
                {t(title)}
            </Typography>
            {!children && (
                <Button onClick={onClick} variant="contained" color="primary">
                    {t(buttonLabel)}
                </Button>
            )}
            {children}
        </Card>
    );
}

ImportCard.propTypes = {
    onClick: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired
};

export default withTranslation()(withStyles(styles)(ImportCard));
