import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import CenterFocusWeak from "@material-ui/icons/CenterFocusWeak";
import RotateLeft from "@material-ui/icons/RotateLeft";
import AccountTree from "@material-ui/icons/AccountTree";
import Camera from "@material-ui/icons/PhotoCamera";

import { HIERARCHICAL_VIEW, TREEMAP_VIEW } from "constants/static";

import {
    selectViewMode,
    setHierarchicalView,
    setTreemapView
} from "redux/modules/tree/current";

import TreeOptimalSplitButtonContainer from "components/Tree/TreeOptimalSplitButtonContainer";
import TreeMapViewButton from "components/Tree/TreeMapViewButton";
import TreeHierarchicalViewButton from "components/Tree/TreeHierarchicalViewButton";
import TreeViewExpandButton from "components/Tree/TreeViewExpandButton";

import TreeIconButton from "components/Tree/TreeIconButton";

import TreeIconDots from "components/Tree/TreeIconDots";
import TreeNeedUnitNumberInput from "components/Tree/TreeNeedUnitNumberInput";
import {
    selectIsOptimal,
    selectSavedSetCanUndo,
    undoSavedSetChange
} from "../../redux/modules/tree/savedset";

const ResetIcon = styled(CenterFocusWeak)`
    width: 0.8em;
    height: 0.8em;
`;

const RotateIcon = styled(RotateLeft)`
    width: 0.8em;
    height: 0.8em;
`;

const AccountTreeIcon = styled(AccountTree)`
    width: 0.8em;
    height: 0.8em;
`;

const CameraIcon = styled(Camera)`
    width: 0.8em;
    height: 0.8em;
`;

const StyledTreeViewIcons = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 49px;
`;

const mapStateToProps = state => ({
    viewMode: selectViewMode(state),
    isOptimal: selectIsOptimal(state),
    canUndo: selectSavedSetCanUndo(state)
});

const mapDispatchToProps = dispatch => {
    return {
        onClickTreemapView(event) {
            event.stopPropagation();
            dispatch(setTreemapView());
        },
        onClickHierarchicalView(event) {
            event.stopPropagation();
            dispatch(setHierarchicalView());
        },
        onUndoOptimalSplit(event) {
            dispatch(undoSavedSetChange());
        }
    };
};

export const TreeIconBar = ({
    viewMode,
    onConfirmOptimalSplit,
    onUndoOptimalSplit,
    onClickHierarchicalView,
    onClickTreemapView,
    onClickTreeViewExpand,
    onClickFitToScreen,
    onClickResetZoom,
    onClickToggleHierarchicalLayout,
    onClickTakeScreenshot,
    onChangeNeedUnitNum,
    onClickRotate,
    isUnsavedChanges,
    isOptimal,
    canUndo,
    brands,
    needUnitLen,
    savedSet,
    version,
    exportToDMTNodes,
    data
}) => (
    <StyledTreeViewIcons>
        <div>
            {onClickTreeViewExpand && (
                <TreeViewExpandButton
                    onClickTreeViewExpand={onClickTreeViewExpand}
                />
            )}
            <TreeHierarchicalViewButton
                active={viewMode === HIERARCHICAL_VIEW}
                onClickHierarchicalView={onClickHierarchicalView}
            />
            <TreeMapViewButton
                active={viewMode === TREEMAP_VIEW}
                onClickMapView={onClickTreemapView}
            />
            <TreeIconButton
                title={"tree.reset_viewport"}
                icon={<ResetIcon />}
                disabled={viewMode === TREEMAP_VIEW}
                onClick={onClickFitToScreen}
            />
            <TreeIconButton
                title={"tree.rotate_viewport"}
                icon={<RotateIcon />}
                disabled={viewMode === TREEMAP_VIEW}
                onClick={onClickRotate}
            />
            <TreeIconButton
                title={"tree.toggle_layout_method"}
                icon={<AccountTreeIcon />}
                disabled={viewMode === TREEMAP_VIEW}
                onClick={onClickToggleHierarchicalLayout}
            />
            <TreeIconButton
                title={"tree.take_screenshot"}
                icon={<CameraIcon />}
                onClick={onClickTakeScreenshot}
            />
        </div>
        <TreeNeedUnitNumberInput
            value={needUnitLen}
            onChange={onChangeNeedUnitNum}
        />
        <TreeOptimalSplitButtonContainer
            onConfirmOptimalSplit={onConfirmOptimalSplit}
            onUndoOptimalSplit={onUndoOptimalSplit}
            isUnsavedChanges={isUnsavedChanges}
            canUndo={canUndo}
            isOptimal={isOptimal}
            savedSet={savedSet}
        />
        <TreeIconDots version={version} brands={brands}
                      exportToDMTNodes={exportToDMTNodes} data={data}/>
    </StyledTreeViewIcons>
);

TreeIconBar.propTypes = {
    viewMode: PropTypes.string.isRequired,
    onConfirmOptimalSplit: PropTypes.func.isRequired,
    onUndoOptimalSplit: PropTypes.func.isRequired,
    onClickTreemapView: PropTypes.func.isRequired,
    onClickHierarchicalView: PropTypes.func.isRequired,
    onClickTakeScreenshot: PropTypes.func.isRequired,
    isUnsavedChanges: PropTypes.bool.isRequired,
    brands: PropTypes.object,
    savedSet: PropTypes.any,
    isOptimal: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeIconBar);
