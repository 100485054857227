import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DialogComponent from "../../uiComponents/Dialog/DialogComponent";

export const TreeOptimalSplitDialogConfirm = ({
    handleClose,
    handleAccept,
    isOpen,
    t
}) => (
    <DialogComponent
        onClose={handleClose}
        onSave={handleAccept}
        isOpen={isOpen}
        padding
        saveLabel={t("common.continue")}
        title={t("tree.confirm_optimal_split_title")}
    >
        <div style={{ textAlign: "center" }}>
            {t("tree.confirm_optimal_split")}
        </div>
    </DialogComponent>
);

TreeOptimalSplitDialogConfirm.propTypes = {
    handleAccept: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default withTranslation()(TreeOptimalSplitDialogConfirm);
