import React, { useState } from "react";
import PropTypes from "prop-types";
import PaginateControl from "./PaginateControl";

function Paginate({ render, list, perPageOptions }) {
    const len = list.length;
    const [perPage, setPerPage] = useState(perPageOptions[0]);
    const [index, setIndex] = useState(0);
    const paginatedList = list.slice(index, Math.min(len, index + perPage));
    const pages = Math.ceil(len / perPage);
    const page = Math.round(index / perPage) + 1;
    return (
        <div>
            {render(paginatedList)}
            {len > perPageOptions[0] && (
                <PaginateControl
                    perPage={perPage}
                    perPageOptions={perPageOptions}
                    page={page}
                    pages={pages}
                    onPrev={() => setIndex(Math.max(0, index - perPage))}
                    onNext={() => setIndex(Math.min(len - 1, index + perPage))}
                    onChangePerPage={event => setPerPage(event.target.value)}
                />
            )}
        </div>
    );
}

Paginate.propTypes = {
    perPageOptions: PropTypes.array,
    list: PropTypes.array.isRequired,
    render: PropTypes.func.isRequired
};

Paginate.defaultProps = {
    perPageOptions: [5, 20, 30]
};

export default Paginate;
