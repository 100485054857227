import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ShareIcon from "@material-ui/icons/ShareOutlined";
import IconButtonA11y from "../../uiComponents/Button/IconButtonA11y";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutlined";

import { TAB_TREE, updateTab } from "redux/modules/tab/change";
import {
    updateCurrentSavedSet,
    updateCurrentTree
} from "redux/modules/tree/current";
import { updateCurrentVersion } from "redux/modules/versions/current";
import { deleteSavedSet, resetSavedSet } from "redux/modules/tree/savedset";
import { selectProjectId } from "redux/modules/projects/projects";

const styles = theme => ({
    container: {
        paddingLeft: 45,
        paddingRight: 45,
        width: `${100 / 2}%`,
        overflow: "hidden"
    },
    border: {
        borderLeft: "1px solid",
        borderColor: theme.status.clay[50]
    },
    flex: {
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    flexItem: {
        flexBasis: "30%"
    },
    savedSetActionContainer: {
        display: "flex",
        alignItems: "center",
        paddingTop: 30
    },
    errorSectionContainer: {
        display: "flex",
        paddingTop: 10
    },
    treeName: {
        paddingBottom: 15,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    bold: {
        fontWeight: 500
    },
    contained: {
        boxShadow: "none"
    },
    iconButton: {
        opacity: 0,
        pointerEvents: "none",
        transition: "opacity 200ms linear",
        "&:hover": {
            backgroundColor: theme.status.yellow[50]
        }
    },
    iconButtonActive: {
        opacity: 1,
        pointerEvents: "auto"
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 15
    },
    formControl: {
        width: "100%"
    },
    group: {
        width: "100%"
    },
    formControlLabelRoot: {
        width: "100%"
    },
    formControlLabel: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
});

const mapStateToProps = state => ({
    projectId: String(selectProjectId(state))
});

const mapDispatchToProps = {
    updateTab,
    resetSavedSet,
    updateCurrentTree,
    updateCurrentVersion,
    updateCurrentSavedSet,
    deleteSavedSet
};

export class TreeInformation extends Component {
    static propTypes = {
        versionNumber: PropTypes.number.isRequired,
        tree: PropTypes.object.isRequired,
        isHeightLimit: PropTypes.bool.isRequired,
        onToggleExpand: PropTypes.func.isRequired,
        onClickShare: PropTypes.func.isRequired,
        // redux
        updateTab: PropTypes.func.isRequired,
        updateCurrentTree: PropTypes.func,
        updateCurrentVersion: PropTypes.func,
        updateCurrentSavedSet: PropTypes.func.isRequired,
        // current selected state setter
        setSelectedSavedSet: PropTypes.func.isRequired,
        setSelectedTree: PropTypes.func.isRequired,
        resetSavedSet: PropTypes.func.isRequired,
        limit: PropTypes.number
    };

    static defaultProps = {
        limit: 3
    };

    handleClickOpen = () => {
        const {
            updateCurrentTree,
            tree,
            versionNumber,
            updateCurrentVersion,
            updateCurrentSavedSet,
            selectedSavedSetId,
            resetSavedSet
        } = this.props;
        resetSavedSet();
        updateCurrentTree && updateCurrentTree(tree.id);
        updateCurrentVersion && updateCurrentVersion(versionNumber);
        updateCurrentSavedSet && updateCurrentSavedSet(selectedSavedSetId);
    };

    handleChange = event => {
        const { tree, setSelectedSavedSet, setSelectedTree } = this.props;
        setSelectedSavedSet(event.target.value);
        setSelectedTree(tree.id);
    };

    handleSavedSetDelete = (projectId, savedSetId) => event => {
        const { deleteSavedSet } = this.props;
        deleteSavedSet(projectId, savedSetId);
    };

    render() {
        const {
            tree,
            isFirst,
            classes,
            isHeightLimit,
            limit,
            onToggleExpand,
            onClickShare,
            t,
            versionNumber,
            selectedSavedSetId,
            selectedTreeId,
            projectId
        } = this.props;
        const isSelected =
            Boolean(selectedSavedSetId) && selectedTreeId === tree.id;

        // Get saved sets (limit to 3 or all if expanded)
        let savedSets = tree.saved_sets
            .slice(0)
            .filter(a => a)
            .sort((a, b) => {
                if (a.updated_at < b.updated_at) return 1;
                if (a.updated_at > b.updated_at) return -1;
                return 0;
            });

        savedSets = isHeightLimit ? savedSets.slice(0, limit) : savedSets;

        // Should we display expand toggle icon?
        const isOverLimit = isHeightLimit && tree.saved_sets.length > limit;

        return (
            <div
                className={classnames(
                    classes.container,
                    isFirst ? null : classes.border
                )}
            >
                <div className={classes.flex}>
                    <div className={classes.flexItem}>
                        <Typography
                            variant="subtitle1"
                            className={classes.treeName}
                        >
                            {tree.name}
                        </Typography>
                        {!tree.is_error && (
                            <Fragment>
                                <Typography className={classes.bold}>
                                    {t("project_details.saved_sets")}
                                </Typography>
                                <div>
                                    <FormControl
                                        component="fieldset"
                                        className={classes.formControl}
                                    >
                                        <RadioGroup
                                            aria-label={t(
                                                "project_details.saved_sets"
                                            )}
                                            name={t(
                                                "project_details.saved_sets"
                                            )}
                                            className={classes.group}
                                            value={
                                                isSelected
                                                    ? selectedSavedSetId
                                                    : ""
                                            }
                                            onChange={this.handleChange}
                                        >
                                            <FormControlLabel
                                                value="default"
                                                control={<Radio />}
                                                label={t(
                                                    "project_details.initial_saved_set"
                                                )}
                                            />

                                            {savedSets
                                                .filter(el => el)
                                                .map(elem => (
                                                    <FormControlLabel
                                                        key={elem.id}
                                                        classes={{
                                                            root:
                                                                classes.formControlLabelRoot,
                                                            label:
                                                                classes.formControlLabel
                                                        }}
                                                        title={elem.name}
                                                        value={String(elem.id)}
                                                        control={<Radio />}
                                                        label={
                                                            elem.name
                                                                ? elem.name
                                                                : t(
                                                                      "project_details.empty_name_saved_set"
                                                                  )
                                                        }
                                                    />
                                                ))}
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                {isOverLimit && (
                                    <IconButton
                                        aria-label="More"
                                        onClick={onToggleExpand}
                                    >
                                        <MoreHorizIcon />
                                    </IconButton>
                                )}
                            </Fragment>
                        )}
                    </div>
                    {tree.is_error ? (
                        <div className={classes.errorSectionContainer}>
                            <span
                                id="error-message"
                                className={classes.message}
                            >
                                <ErrorIcon
                                    className={classnames(
                                        classes.icon,
                                        classes.iconVariant
                                    )}
                                />
                                {tree.error_message &&
                                tree.error_message.exception
                                    ? t(
                                          `version.${tree.error_message.exception.type}`,
                                          `${tree.error_message.exception.value}`
                                      )
                                    : t("version.error")}
                            </span>
                        </div>
                    ) : (
                        <div className={classes.savedSetActionContainer}>
                            <Link
                                onClick={event => {
                                    isSelected
                                        ? updateTab(TAB_TREE)
                                        : event.preventDefault();
                                }}
                                to={`/project/${projectId}/versions/${versionNumber}/${tree.id}/${selectedSavedSetId}`}
                            >
                                <Button
                                    variant={
                                        isSelected ? "contained" : "outlined"
                                    }
                                    color="primary"
                                    onClick={this.handleClickOpen}
                                    className={classnames(
                                        classes.button,
                                        isSelected ? classes.contained : null
                                    )}
                                >
                                    {t("common.open")}
                                </Button>
                            </Link>
                            <IconButtonA11y
                                ariaLabel={t("common.share")}
                                onClick={onClickShare(
                                    projectId,
                                    versionNumber,
                                    tree.id,
                                    selectedSavedSetId
                                )}
                                className={classnames(
                                    classes.iconButton,
                                    isSelected ? classes.iconButtonActive : null
                                )}
                            >
                                <ShareIcon className={classes.icon} />
                            </IconButtonA11y>

                            <IconButtonA11y
                                ariaLabel={t("projects.delete")}
                                onClick={this.handleSavedSetDelete(
                                    projectId,
                                    selectedSavedSetId
                                )}
                                className={classnames(
                                    classes.iconButton,
                                    isSelected &&
                                        selectedSavedSetId !== "default"
                                        ? classes.iconButtonActive
                                        : null
                                )}
                            >
                                <DeleteIcon />
                            </IconButtonA11y>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withStyles(styles)(TreeInformation)));
