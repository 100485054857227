import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
// MUI
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";

import {
    resetVerifyStores,
    selectImportStoreLoading,
    selectImportStoreResult,
    verifyStoreImport
} from "redux/modules/stores/verify";
import { saveStores } from "redux/modules/stores/save";
import DialogComponent from "uiComponents/Dialog/DialogComponent";
import { downloadStoreTemplate } from "../../redux/modules/stores/importStoreTemplate";
import { selectRealization } from "redux/modules/dmtStoreTree/dmtStoreRealization";
import { selectHierarchySelectionForStores } from "redux/modules/dmtHierarchySelection/dmtHierarchySelection";
import {
    updateHierarchySelection,
    selectProjectById
} from "redux/modules/projects/projects";

import DMTImportOptionSelect from "components/DMTImport/DMTImportOptionSelect";

const styles = theme => ({
    hidden: {
        display: "none"
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 30
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        padding: 30
    },
    importSummary: {
        padding: 30,
        textAlign: "center"
    },
    importSummaryHeader: {
        fontWeight: 600
    },
    importRow: {
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${theme.status.grey.greydivs}`
    },
    importRowMissing: {
        textAlign: "left",
        borderBottom: "none"
    },
    importColumn: {
        display: "inline-block",
        width: "50%",
        padding: 12,
        boxSizing: "border-box"
    },
    missingTitle: {
        padding: "12px 0 0"
    },
    missingList: {
        margin: 0,
        padding: "8px 0 0",
        listStyle: "none",
        whiteSpace: "normal"
    },
    missingListItem: {
        display: "inline-block",
        margin: 0,
        padding: "0 4px 0 0",
        fontSize: 14,
        color: theme.status.grey.dark,
        lineHeight: 1.6
    }
});

export function getFlatStoreList(verifiedStores) {
    const result = [];
    const len = verifiedStores.length;
    for (let i = 0; i < len; ++i) {
        const item = verifiedStores[i];
        const region = item.region;
        const storesLen = item.stores.length;
        for (let j = 0; j < storesLen; ++j) {
            const storeItem = item.stores[j];
            result.push({
                ...storeItem,
                region: region
            });
        }
    }
    return result;
}

export function getProjectSelectedRegions(project) {
    if (
        project &&
        project.productStoreHierarchySelection &&
        project.productStoreHierarchySelection.stores &&
        project.productStoreHierarchySelection.stores.selectedRegions
    ) {
        return project.productStoreHierarchySelection.stores.selectedRegions;
    }
    return null;
}

export function getInitSelectedRegions(project, currResult) {
    let selectedRegions = getProjectSelectedRegions(project);
    if (selectedRegions) {
        // filter out any selected regions that dont exist
        const availableRegions = currResult.verifiedStores.map(el => el.region);
        selectedRegions = selectedRegions.filter(el =>
            availableRegions.includes(el)
        );
    }
    if (!selectedRegions || selectedRegions.length === 0) {
        selectedRegions = currResult.verifiedStores.map(el => el.region);
    }
    return selectedRegions;
}

const IMPORT_STEP = {
    INIT: "INIT",
    LOADING: "LOADING",
    SELECT_REGION: "SELECT_REGION",
    SUMMARY: "SUMMARY"
};

const mapStateToProps = state => ({
    importStoreResult: selectImportStoreResult(state),
    importStoreRealizationResult: selectRealization(state),
    isLoading: selectImportStoreLoading(state),
    hierarchySelection: selectHierarchySelectionForStores(state),
    project: selectProjectById(state)
});

const mapDispatchToProps = {
    verifyStoreImport,
    resetVerifyStores,
    saveStores,
    downloadStoreTemplate,
    updateHierarchySelection
};

export class StoresImportDialog extends Component {
    static propTypes = {
        project: PropTypes.object,
        importMode: PropTypes.oneOf(["hierarchy", "file"])
    };

    state = {
        selectedRegions: [],
        importStep: IMPORT_STEP.INIT
    };

    componentDidUpdate(prevProps) {
        const { project } = this.props;
        const { importStep } = this.state;
        if (
            importStep === IMPORT_STEP.INIT ||
            importStep === IMPORT_STEP.LOADING
        ) {
            // check whether we have a new result
            const prevResult = this.getStoreResult(prevProps);
            const currResult = this.getStoreResult(this.props);

            if (currResult !== prevResult) {
                if (currResult) {
                    // Check for multiple regions
                    const numRegions = currResult.verifiedStores.length;
                    if (numRegions > 1) {
                        // Set any previously selected rgeions
                        const selectedRegions = getInitSelectedRegions(
                            project,
                            currResult
                        );
                        this.setState({
                            importStep: IMPORT_STEP.SELECT_REGION,
                            selectedRegions
                        });
                    } else {
                        this.setState({
                            importStep: IMPORT_STEP.SUMMARY
                        });
                    }
                } else {
                    this.setState({
                        importStep: IMPORT_STEP.LOADING
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.resetVerifyStores();
    }

    closeDialog() {
        this.props.resetVerifyStores();
        this.props.onClose();
        this.setState({
            importStep: IMPORT_STEP.INIT,
            selectedRegions: []
        });
    }

    handleClose = () => {
        this.closeDialog();
    };

    handleSave = () => {
        const {
            projectId,
            saveStores,
            updateHierarchySelection,
            hierarchySelection,
            importMode
        } = this.props;

        const { selectedRegions } = this.state;
        const importResult = this.getStoreResult(this.props);
        const storesToSave = getFlatStoreList(importResult.verifiedStores);

        if (storesToSave) {
            // Check we have selected the region
            // If no regions selected include all
            const selectedStoresToSave = storesToSave.filter(
                el =>
                    selectedRegions.length === 0 ||
                    selectedRegions.includes(el.region)
            );
            saveStores(projectId, selectedStoresToSave);
            if (importMode === "hierarchy") {
                hierarchySelection.stores.selectedRegions = selectedRegions;
            } else {
                hierarchySelection.stores = {};
            }
            updateHierarchySelection(String(projectId), hierarchySelection);
        } else {
            console.warn("No stores to save!");
        }
        this.closeDialog();
    };

    getSaveHandler = () => {
        const { importStep } = this.state;
        let result;
        switch (importStep) {
            case IMPORT_STEP.INIT:
            case IMPORT_STEP.LOADING:
                result = null;
                break;
            case IMPORT_STEP.SELECT_REGION:
                result = this.handleSelectRegionContinue;
                break;
            case IMPORT_STEP.SUMMARY:
                result = this.handleSave;
                break;
            default:
                result = null;
        }
        return result;
    };

    handleChangeFile = event => {
        const { verifyStoreImport, projectId } = this.props;
        if (event.target && event.target.files) {
            const fileList = event.target.files;
            verifyStoreImport(projectId, fileList[0]);
        }
    };

    handleSelectRegionContinue = () => {
        this.setState({
            importStep: IMPORT_STEP.SUMMARY
        });
    };

    renderStoreResult(result) {
        const { t, classes } = this.props;
        const { selectedRegions } = this.state;
        const { missingStoresIds } = result;
        const selectedVerifiedStores = result.verifiedStores.filter(
            el =>
                selectedRegions.length === 0 ||
                selectedRegions.includes(el.region)
        );
        return (
            <div className={classes.importSummary}>
                <div
                    className={classNames(
                        classes.importRow,
                        classes.importSummaryHeader
                    )}
                >
                    <div className={classes.importColumn}>
                        <Typography variant="body1">
                            {t("stores.regions")}
                        </Typography>
                    </div>
                    <div className={classes.importColumn}>
                        <Typography variant="body1">
                            {t("stores.stores")}
                        </Typography>
                    </div>
                </div>
                {selectedVerifiedStores.map(el => (
                    <div key={el.region} className={classes.importRow}>
                        <div className={classes.importColumn}>{el.region}</div>
                        <div className={classes.importColumn}>
                            {el.metrics.storeCount}
                        </div>
                    </div>
                ))}
                {missingStoresIds && missingStoresIds.length > 0 && (
                    <div
                        className={classNames(
                            classes.importRow,
                            classes.importRowMissing
                        )}
                    >
                        <Typography
                            variant="subtitle1"
                            className={classes.missingTitle}
                        >
                            {t("stores.stores_missing", {
                                count: missingStoresIds.length
                            })}
                        </Typography>
                        <ul className={classes.missingList}>
                            {missingStoresIds.map((missingId, index) => (
                                <li
                                    className={classes.missingListItem}
                                    key={index}
                                >
                                    {missingId}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    }

    handleChangeRegion = event => {
        const newValue = event.target.value;
        this.setState(state => {
            let newState = state.selectedRegions.includes(newValue)
                ? state.selectedRegions.filter(e => e !== newValue)
                : [...state.selectedRegions, newValue];
            return {
                ...state,
                selectedRegions: newState
            };
        });
    };

    getStoreResult(props) {
        const {
            importMode,
            importStoreRealizationResult,
            importStoreResult
        } = props;
        // determine result we are working with
        let importResult;
        if (importMode === "hierarchy" && importStoreRealizationResult) {
            importResult = importStoreRealizationResult;
        } else if (importMode === "file" && importStoreResult) {
            importResult = importStoreResult;
        }
        return importResult;
    }

    renderLoading() {
        const { classes } = this.props;
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress size={18} />
            </div>
        );
    }

    renderSelectRegion() {
        const { selectedRegions } = this.state;
        const result = this.getStoreResult(this.props);
        return (
            <div>
                <DMTImportOptionSelect
                    categoryKey="region"
                    listKey="stores"
                    selectedOptions={selectedRegions}
                    options={result.verifiedStores}
                    onChange={this.handleChangeRegion}
                />
            </div>
        );
    }

    renderSummary() {
        return this.renderStoreResult(this.getStoreResult(this.props));
    }

    renderContent() {
        const { importStep } = this.state;
        let result;
        switch (importStep) {
            case IMPORT_STEP.INIT:
            case IMPORT_STEP.LOADING:
                result = this.renderLoading();
                break;
            case IMPORT_STEP.SELECT_REGION:
                result = this.renderSelectRegion();
                break;
            case IMPORT_STEP.SUMMARY:
                result = this.renderSummary();
                break;
            default:
                result = null;
        }
        return result;
    }

    render() {
        const { title, isOpen } = this.props;
        return (
            <DialogComponent
                isOpen={isOpen}
                onClose={this.handleClose}
                onSave={this.getSaveHandler()}
                canSave={Boolean(this.getSaveHandler())}
                title={title}
            >
                {this.renderContent()}
            </DialogComponent>
        );
    }
}

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation()(StoresImportDialog))
);
