// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import history from "routes/history";
import { connect } from "react-redux";

// Redux modules
import {
    selectImportFileLoading,
    verifyProductImport
} from "redux/modules/products/importFromFile";
import { selectDMTIntegrationProducts } from "redux/modules/config";
import {
    fetchProject,
    selectProjectById,
    selectProjectId
} from "redux/modules/projects/projects";
import { fetchVersions, selectVersions } from "redux/modules/versions/versions";
import {
    fetchProducts,
    selectAllProducts,
    selectAllProductsLoading,
    selectAllProductsStatus
} from "redux/modules/products/products";
import {
    TAB_PROJECT,
    updateTab,
    selectActiveTab
} from "redux/modules/tab/change";
import { openSnackbar } from "redux/modules/snackbar/open";
import {
    updateCurrentTree,
    updateCurrentSavedSet,
    selectCurrentTreeId,
    selectCurrentTreeName
} from "redux/modules/tree/current";
import { selectTrees } from "redux/modules/versions/versions";
import { fetchProductsFromAssorter } from "redux/modules/products/importFromAssorter";
import {
    selectDMTProductTreeSelectedNodes,
    initDMTProductTreeFromProject
} from "redux/modules/dmtProductTree/dmtProductTree";

// Components
import ProjectImportMenu from "../components/ProductImport/ProjectImportMenu";
import DMTProductTreeContainer from "../components/DMTProductTree/DMTProductTreeContainer";
import AttributeListContainer from "../components/AttributeList/AttributeListContainer";
import ImportCard from "components/ImportCard";
import PageTitle from "../components/PageTitle/PageTitle";
import ProjectAddEdit from "components/ProjectAddEdit/ProjectAddEdit";
import ProductImportDialog from "components/ProductImport/ProductImportDialog";
import DialogComponent from "../uiComponents/Dialog/DialogComponent";
import LabelButton from "../uiComponents/Button/LabelButton";
import SubcategoryGroupPageContainer from "components/SubcategoryGroups/SubcategoryGroupsPageContainer";
import Divider from "@material-ui/core/Divider";
import StoreImportContainer from "components/StoresImport/StoreImportContainer";

function hasHierarchySelection(project) {
    if (!project) return false;
    const { productStoreHierarchySelection } = project;
    return (
        !!productStoreHierarchySelection &&
        !!productStoreHierarchySelection.products &&
        Object.keys(productStoreHierarchySelection.products).length > 0
    );
}

const PAGE_STATE = {
    HIERARCHY_IMPORT: "hierarchy_import",
    FILE_IMPORT: "file_import",
    HAS_PRODUCTS: "has_products",
    INIT: "init"
};

const styles = theme => ({
    loadingOverlay: {
        position: "absolute",
        width: "100%",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    paperHierarchy: {
        padding: 30,
        marginBottom: 30
    },
    hidden: {
        visibility: "hidden",
        width: 0
    },
    button: {
        textTransform: "capitalize",
        textDecoration: "underline",
        fontSize: 12,
        fontWeight: 400,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    dmtButton: {
        display: "flex",
        justifyContent: "flex-end"
    },
    tabContainer: {
        alignSelf: "flex-end",
        display: "flex",
        marginBottom: -1
    },
    buttonContainer: {
        display: "flex"
    },
    buttonContainerPadding: {
        marginRight: 15
    },
    addIcon: {
        fontSize: 12,
        paddingRight: 10
    },
    houseIcon: {
        fontSize: 20,
        paddingRight: 15
    },
    moreVertIcon: {
        fontSize: 18,
        color: theme.status.grey.dark
    }
});

// Redux props
const mapStateToProps = (state, ownProps) => {
    return {
        selectedNodes: selectDMTProductTreeSelectedNodes(state),
        trees: selectTrees(state),
        currentTreeId: selectCurrentTreeId(state),
        currentTreeName: selectCurrentTreeName(state),
        tab: selectActiveTab(state),
        versions: selectVersions(state),
        products: selectAllProducts(state),
        project: selectProjectById(state, ownProps),
        lastProjectId: String(selectProjectId(state)),
        isLoadingProducts: selectAllProductsLoading(state),
        productsStatus: selectAllProductsStatus(state),
        isFileLoading: selectImportFileLoading(state),
        dmtProductsConfig: selectDMTIntegrationProducts(state)
    };
};

// Redux action
const mapDispatchToProps = {
    fetchProject,
    fetchProducts,
    fetchVersions,
    fetchProductsFromAssorter,
    updateCurrentTree,
    updateCurrentSavedSet,
    openSnackbar,
    updateTab,
    verifyProductImport,
    initDMTProductTreeFromProject
};

class ProjectPage extends Component {
    state = {
        isEditModalOpen: false,
        isProductImportModalOpen: false,
        isRealizationModalOpen: false,
        openTreeList: false,
        pageState: PAGE_STATE.INIT
    };

    static propTypes = {
        productsStatus: PropTypes.string.isRequired,
        isLoadingProducts: PropTypes.bool.isRequired,
        lastProjectId: PropTypes.string,
        tab: PropTypes.string,
        products: PropTypes.array,
        versions: PropTypes.array,
        project: PropTypes.object,
        fetchProject: PropTypes.func.isRequired,
        fetchProducts: PropTypes.func.isRequired,
        fetchVersions: PropTypes.func.isRequired,
        openSnackbar: PropTypes.func.isRequired,
        updateTab: PropTypes.func.isRequired,
        dmtProductsConfig: PropTypes.object,
        initDMTProductTreeFromProject: PropTypes.func.isRequired
    };

    componentDidMount() {
        if (this.shouldFetch()) {
            this.fetchData();
        } else {
            this.initPage();
        }
    }

    componentDidUpdate(prevProps) {
        const { products, dmtProductsConfig } = this.props;
        const {
            products: prevProducts,
            dmtProductsConfig: prevConfig
        } = prevProps;
        const isProductsChange = products !== prevProducts;
        const isConfigChange = dmtProductsConfig !== prevConfig;
        if (isProductsChange || isConfigChange) {
            // products loaded in or removed
            this.initPage();
        }
    }

    initPage() {
        const { dmtProductsConfig, products } = this.props;
        let pageState = this.state.pageState;
        const hasProducts = Boolean(Array.isArray(products) && products.length);
        if (!hasProducts) {
            // show import screens
            // can view hierarchy?
            if (
                dmtProductsConfig &&
                dmtProductsConfig.hierarchySelectionEnabled
            ) {
                pageState = PAGE_STATE.HIERARCHY_IMPORT;
            } else {
                pageState = PAGE_STATE.FILE_IMPORT;
            }
        } else {
            pageState = PAGE_STATE.HAS_PRODUCTS;
        }
        this.setState({
            pageState
        });
    }

    shouldFetch() {
        const { match, lastProjectId, productsStatus } = this.props;
        const { projectId } = match.params;
        const hasNeverFetched =
            typeof lastProjectId === "undefined" || lastProjectId === null;
        const hasChangedProjectId = projectId !== lastProjectId;
        const shouldFetchProducts =
            productsStatus === "INIT" || productsStatus === "RESET";
        return hasNeverFetched || hasChangedProjectId || shouldFetchProducts;
    }

    fetchData() {
        const {
            tab,
            updateTab,
            match,
            fetchProject,
            fetchProducts,
            fetchVersions
        } = this.props;
        const { params } = match;
        const { projectId } = params;
        fetchProject(projectId);
        fetchProducts(projectId);
        fetchVersions(projectId);

        // Set tab if page refreshed
        if (tab !== TAB_PROJECT) {
            updateTab(TAB_PROJECT);
        }
    }

    handleChangeFile = event => {
        this.openImportModal();
        const { verifyProductImport } = this.props;
        const projectId = this.props.match.params.projectId;
        if (event.target && event.target.files) {
            const fileList = event.target.files;
            const fileListNum = fileList.length;
            for (let i = 0; i < fileListNum; ++i) {
                verifyProductImport(projectId, fileList[i]);
            }
        }
    };

    handleTabChange = value => {
        if (value === "home") {
            this.props.updateTab(TAB_PROJECT);
            history.goBack("/");
        } else if (value !== this.props.tab) {
            this.props.updateTab(value);
        }
    };

    hasVersions = () => {
        const { versions } = this.props;
        return Boolean(versions && versions.length);
    };

    hasCurrentTree = () => {
        const { currentTreeId } = this.props;
        return Boolean(currentTreeId);
    };

    onClickTreeSavedSet = (id, idChild) => {
        this.props.updateCurrentTree(parseInt(id, 10));
        this.props.updateCurrentSavedSet(idChild ? idChild : "default");
    };

    handleCloseEditModal = event => {
        this.setState({ isEditModalOpen: false });
    };

    handleOpenEditModal = event => {
        this.setState({ isEditModalOpen: true });
    };

    handleTreeTabDropdown = event => {
        this.setState({ openTreeList: event.currentTarget });
    };

    handleTreeTabMenuClose = () => {
        this.setState({ openTreeList: false });
    };

    openImportModal = () => {
        this.setState({
            isProductImportModalOpen: true,
            isRealizationImport: false
        });
    };

    handleClickProductImport = () => {
        this.setState({
            isProductImportModalOpen: true,
            isRealizationImport: false
        });
    };

    handleCloseProductImport = () => {
        this.setState({
            isProductImportModalOpen: false
        });
    };

    handleClickHierarchyImport = () => {
        const { products, match, initDMTProductTreeFromProject } = this.props;
        const hasProducts = Boolean(Array.isArray(products) && products.length);
        const projectId = match.params.projectId;

        if (hasProducts) {
            initDMTProductTreeFromProject(projectId);
        }

        this.setState({
            pageState: PAGE_STATE.HIERARCHY_IMPORT
        });
    };

    handleClickGoBack = () => {
        this.setState({
            pageState: PAGE_STATE.HAS_PRODUCTS
        });
    };

    handleHierarchySelectionContinue = () => {
        this.setState({
            isProductImportModalOpen: true,
            isRealizationImport: true
        });
    };

    handleHierarchySelectionCancel = () => {
        this.setState({
            isProductImportModalOpen: false,
            isRealizationImport: false
        });
    };

    handleClickImportFromAssorter = () => {
        const { fetchProductsFromAssorter, match } = this.props;
        this.openImportModal();
        const projectId = match.params.projectId;
        fetchProductsFromAssorter(projectId);
    };

    render() {
        const {
            classes,
            match,
            products,
            project,
            selectedNodes,
            dmtProductsConfig,
            t
        } = this.props;
        const {
            isEditModalOpen,
            isProductImportModalOpen,
            isRealizationImport,
            pageState
        } = this.state;
        const projectId = match.params.projectId;
        const hasProducts = Boolean(Array.isArray(products) && products.length);

        return (
            <div>
                <div
                    style={{
                        width: "100%",
                        display: "flex"
                    }}
                >
                    {pageState === PAGE_STATE.HAS_PRODUCTS && (
                        <PageTitle noMargin title={"subcategories.title"} />
                    )}
                    <ProjectImportMenu
                        hierarchySelectionEnabled={
                            dmtProductsConfig.hierarchySelectionEnabled
                        }
                        hasProducts={hasProducts}
                        project={project}
                        projectHasHierarchySelection={hasHierarchySelection(
                            project
                        )}
                        handleClickImportFromAssorter={
                            this.handleClickImportFromAssorter
                        }
                        handleChangeFile={this.handleChangeFile}
                        handleClickHierarchyImport={
                            this.handleClickHierarchyImport
                        }
                        handleClickProductImport={this.handleClickProductImport}
                    />
                </div>
                {pageState === PAGE_STATE.HIERARCHY_IMPORT && (
                    <>
                        {hasProducts && (
                            <Button
                                onClick={this.handleClickGoBack}
                                className={classes.button}
                            >
                                {t("product_import.go_back")}
                            </Button>
                        )}
                        <Paper className={classes.paperHierarchy}>
                            <DMTProductTreeContainer projectId={projectId} />
                            <Divider />
                            <AttributeListContainer projectId={projectId} />
                            <div className={classes.dmtButton}>
                                <Button
                                    disabled={
                                        !selectedNodes ||
                                        selectedNodes.length === 0
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        this.handleHierarchySelectionContinue
                                    }
                                >
                                    Continue
                                </Button>
                            </div>
                        </Paper>
                    </>
                )}
                {pageState === PAGE_STATE.FILE_IMPORT && (
                    <ImportCard
                        title="project_details.description"
                        buttonLabel="project_details.add_products"
                        onClick={this.handleClickProductImport}
                    >
                        <input
                            className={classes.hidden}
                            id="card-file-selector"
                            multiple
                            onChange={this.handleChangeFile}
                            type="file"
                        />

                        <label htmlFor="card-file-selector">
                            <LabelButton
                                label={t("project_details.add_products")}
                            />
                        </label>
                    </ImportCard>
                )}
                {pageState === PAGE_STATE.HAS_PRODUCTS && (
                    <SubcategoryGroupPageContainer projectId={projectId} />
                )}
                <StoreImportContainer />
                <DialogComponent
                    isOpen={isEditModalOpen}
                    onClose={this.handleCloseEditModal}
                    title={t("project_edit_add.edit_title")}
                >
                    <ProjectAddEdit
                        isEdit={true}
                        project={project}
                        onClose={this.handleCloseEditModal}
                    />
                </DialogComponent>
                <ProductImportDialog
                    project={project}
                    projectId={projectId}
                    isOpen={isProductImportModalOpen}
                    importMode={isRealizationImport ? "realization" : "file"}
                    onClose={this.handleCloseProductImport}
                    title={t("project_details.add_products")}
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withStyles(styles)(ProjectPage)));
