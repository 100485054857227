import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/ErrorOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import WarningIcon from "../../styles/icons/warning";

const styles = theme => ({
    success: {
        background: theme.status.green[100]
    },
    error: {
        background: theme.status.red[100]
    },
    warning: {
        background: theme.status.yellow[100]
    },
    info: {
        background: theme.status.blue[100]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 15
    },
    message: {
        display: "flex",
        alignItems: "center"
    }
});

const variantIcon = {
    success: CheckIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

function SnackbarComponent(props) {
    const { open, message, onClose, variant, classes, className, t } = props;
    const Icon = variantIcon[variant];
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
        >
            <SnackbarContent
                className={classNames(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={classNames(
                                classes.icon,
                                classes.iconVariant
                            )}
                        />
                        {t(message)}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label={t("common.close")}
                        color="inherit"
                        className={classes.close}
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
}

SnackbarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

export default withTranslation()(withStyles(styles)(SnackbarComponent));
