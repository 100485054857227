import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../styles/MuiTheme";
import { withTranslation } from "react-i18next";

const StyledButton = styled.button`
    padding: 14px 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
    opacity: ${props => (props.disabled ? "0.2" : "1")};
`;

const StyledIconWrapper = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 17px;
    padding: 2px;
    background-color: ${theme.status.grey.white};
    box-shadow: ${theme.shadows[1]};
    &:hover {
        background-color: ${theme.status.yellow.dark};
    }
`;

export const TreeIconButton = ({ t, title, icon, disabled, onClick }) => (
    <StyledButton title={t(title)} disabled={disabled} onClick={onClick}>
        <StyledIconWrapper>{icon}</StyledIconWrapper>
    </StyledButton>
);

TreeIconButton.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

export default withTranslation()(TreeIconButton);
