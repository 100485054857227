// Tree view displays a saved set
// Saved set will derive from tree in version request
// Plus the saved set information if it exists

// saved_set in tree
// id: Number - saved set id
// name: String
// need_units: Array of SavedSetNeedUnit
// node_names: Object
// saved_at: Date
// saved_by: String
// updated_at: Date

// savedSetPost
// name: String
// node_names: Object
// need_units: Array of SavedSetNeedUnit

// SavedSetNeedUnit
// id - Number
// name|need_unit - String - user-defined nu name
// node - String - node name
// products: Array of product ids
import { createSelector } from "reselect";
import undo from "./undo";
import { buildProductAttrInfo } from "./productAttributes";
import {
    selectConfigAdditionalAttributeExclude,
    selectConfigAdditionalAttributePairs
} from "redux/modules/config";

import {
    FETCH_VERSIONS,
    selectEnhancedProductsDict,
    selectSavedSetFromTree,
    selectTrees
} from "../versions/versions";
import { UNASSIGNED } from "constants/static";
import { openSnackbar } from "../snackbar/open";
// redux-saga
import { call, delay, put, take } from "redux-saga/effects";

import fetch from "services/fetch";
// Api configuration
import api from "api";

// Prefix for user-defined need units
export const USER_PREFIX = "user-";

// Traverse tree to find all nodes true for `compareFn`
export const traverseNodes = (nodes, compareFn, foundNodes = []) =>
    Array.isArray(nodes)
        ? nodes.reduce(
              (prev, node) =>
                  compareFn(node)
                      ? prev.concat([node])
                      : traverseNodes(node.children, compareFn, prev),
              foundNodes
          )
        : foundNodes;

// Check if node exists and has children
export const hasChildren = node =>
    node && node.children && node.children.length;

export function expandToTarget(nodes, target) {
    let stack = nodes.slice(0);
    let next = null;
    let temp = null;
    let i = 0;
    let nextIndex = 0;
    let dist = 0;
    while (stack.length < target) {
        // get next to split
        dist = 0;
        temp = null;
        i = stack.length;
        while (i--) {
            temp = stack[i];
            if (temp.dist > dist) {
                next = temp;
                nextIndex = i;
                dist = temp.dist;
            }
        }
        if (hasChildren(next)) {
            // remove next from stack
            stack[nextIndex] = stack[stack.length - 1];
            stack.pop();
            // append next children
            stack.push(...next.children.slice(0));
        } else break;
    }
    return stack;
}

// creates a dictionary containing the number of unique terms it finds in the key 'column'
// and returns the number of occurances
export function productTermCount(products, column) {
    const results = {};
    products.forEach(product => {
        if (product[column] in results) {
            results[product[column]] = results[product[column]] + 1;
        } else {
            results[product[column]] = 1;
        }
    });
    return results;
}

// helper to sum total of kpis
function sumKPI(products, productData, kpi) {
    if (!Array.isArray(products)) return 0;
    return products.reduce((prev, curr) => {
        let product = productData[curr];
        let kpiValue = product ? product[kpi] : 0;
        return prev + kpiValue;
    }, 0);
}

export const findExpandedNodes = nodes => {
    const hasNoChildren = node => {
        return !hasChildren(node);
    };
    return traverseNodes(nodes, hasNoChildren);
};

export const findStops = nodes =>
    traverseNodes(nodes, node => node && node.stop);

// helper function to search for a node by paths
export const findNodeByPaths = (nodes = [], paths = []) => {
    let stack = paths.slice(0);
    let match = null;
    while (stack.length > 0) {
        const path = stack[0];
        match = nodes.filter(n => n && n.name === path)[0];
        nodes = match ? match.children : [];
        stack = stack.slice(1);
    }
    return match;
};

// helper function to build height dictionary
function buildHeightTree(children, dict = {}) {
    if (
        typeof children === "undefined" ||
        children === null ||
        !Array.isArray(children)
    )
        return dict;
    return children.reduce((prev, node) => {
        if (typeof prev === "undefined" || prev === null) {
            return prev;
        }
        prev[node.name] = node.dist;
        return buildHeightTree(node.children, prev);
    }, dict);
}

// helper function to build a search tree to create a path that we can traverse to
// find a node quickly. We do this to avoid a brute force seach when looking for the location
// of a cluster.
function buildSearchPaths(children, dict = {}, path = "") {
    if (
        typeof children === "undefined" ||
        children === null ||
        !Array.isArray(children)
    )
        return dict;
    return children.reduce((prev, node) => {
        let currPath = `${path}${path.length ? "|" : ""}${node.name}`;
        prev[node.name] = currPath;
        return buildSearchPaths(node.children, prev, currPath);
    }, dict);
}

const reduceProductList = (list, curr) => {
    if (curr.children) {
        return curr.children.reduce(reduceProductList, list);
    } else {
        list = list.concat([curr.name]);
        return list;
    }
};
const buildProductList = node => {
    node = Array.isArray(node) ? node : [node];
    return node.reduce(reduceProductList, []);
};

function buildProductListDict(children, dict = {}) {
    if (!Array.isArray(children)) return dict;
    return children.reduce((prev, node) => {
        let productList = buildProductList(node);
        prev[node.name] = productList;
        return buildProductListDict(node.children, prev);
    }, dict);
}

export const selectProductsPerNode = createSelector([selectCurrentTree], tree =>
    buildProductListDict(tree && tree.children)
);

// Selectors
function selectCurrentTreeId(state) {
    return state.tree.treeId;
}

export function selectCurrentTree(state) {
    const treeId = selectCurrentTreeId(state);
    const trees = selectTrees(state);
    const currentTree =
        Array.isArray(trees) && trees.find(el => el.id === treeId);
    return currentTree ? currentTree.tree : null;
}

export function selectUnassignedData(state) {
    const treeId = selectCurrentTreeId(state);
    const trees = selectTrees(state);
    const currentTree =
        Array.isArray(trees) && trees.find(el => el.id === treeId);
    return currentTree && currentTree.not_qualified_products;
}

export const selectUnassignedProducts = createSelector(
    [selectUnassignedData],
    unassignedData => {
        if (!Array.isArray(unassignedData)) return [];
        let unassignedProducts = unassignedData.map(el => el.productIds);
        if (unassignedProducts.length > 0) {
            unassignedProducts = [].concat.apply([], unassignedProducts);
        }
        return unassignedProducts;
    }
);

const selectProductKpis = function(state) {
    const treeId = selectCurrentTreeId(state);
    const trees = selectTrees(state);
    const currentTree =
        Array.isArray(trees) && trees.find(el => el.id === treeId);
    return currentTree ? currentTree.productkpi : null;
};

export const selectProductDict = createSelector([selectProductKpis], kpis => {
    let res =
        Array.isArray(kpis) &&
        kpis.reduce((prev, curr) => {
            prev[curr.productId] = curr;
            return prev;
        }, {});

    return res;
});

const buildHeightTreeSelector = tree => buildHeightTree(tree && tree.children);
export const selectHeightTree = createSelector(
    [selectCurrentTree],
    buildHeightTreeSelector
);

export const buildSearchPathsSelector = tree =>
    buildSearchPaths(tree && tree.children);
export const selectSearchPaths = createSelector(
    [selectCurrentTree],
    buildSearchPathsSelector
);

function selectSavedSet(state) {
    return state.savedset.current;
}

export function selectSavedSetNeedUnitsWithUserDefined(state) {
    return selectSavedSet(state).needUnits;
}

export function selectSavedSetNeedUnits(state) {
    return selectSavedSet(state).needUnits.filter(
        el => el.node.indexOf(USER_PREFIX) === -1
    );
}

export function selectSavedSetNodeNames(state) {
    return selectSavedSet(state).nodeNames;
}

export function selectSavedSetName(state) {
    return selectSavedSet(state).name;
}

export function selectIsSavedSetDirty(state) {
    return selectSavedSet(state).isDirty;
}

export function selectSavedSetCanUndo(state) {
    const hasPrevious = Boolean(state.savedset.previous.length);
    if (!hasPrevious) return false;
    const previousSavedSet =
        state.savedset.previous[state.savedset.previous.length - 1];
    if (previousSavedSet.name !== selectSavedSetName(state)) return false;
    const prevNeedUnits = previousSavedSet.needUnits;
    const currNeedUnits = selectSavedSetNeedUnitsWithUserDefined(state);
    for (let i = 0; i < currNeedUnits.length; ++i) {
        const curr = currNeedUnits[i];
        const prev = prevNeedUnits.find(prev => prev.node === curr.node);
        if (!prev) {
            return true;
        }
        if (curr.products.length !== prev.products.length) {
            return true;
        }
        if (!curr.products.every(id => prev.products.indexOf(id) !== -1)) {
            return true;
        }
    }
    return false;
}

export function selectUnsavedChanges(state) {
    const savedSetFromTree = selectSavedSetFromTree(state);
    const needUnits = selectSavedSetNeedUnits(state);
    const nodeNames = selectSavedSetNodeNames(state);
    const savedSetName = selectSavedSetName(state);

    if (savedSetFromTree.name !== savedSetName) return true;
    if (
        Object.keys(nodeNames).length !==
        Object.keys(savedSetFromTree.nodeNames).length
    )
        return true;
    if (savedSetFromTree.id === "default") {
        // this is an initial saved set
        if (needUnits.length > 2) return true;
    } else {
        let isMatchLength =
            needUnits.length === savedSetFromTree.needUnits.length;
        if (!isMatchLength) return true;
        let isMatchingNames = needUnits.every(el => {
            return (
                savedSetFromTree.needUnits
                    .map(el => el.name)
                    .indexOf(el.name) !== -1
            );
        });
        if (!isMatchingNames) return true;
    }
    return false;
}

export function selectIsOptimal(state) {
    const currentTree = selectCurrentTree(state);
    const stops = findStops(currentTree && currentTree.children);
    const needUnits = selectSavedSetNeedUnitsWithUserDefined(state);
    const productsPerNode = selectProductsPerNode(state);
    if (stops.length === 0 || needUnits.length === 0) {
        return false;
    }
    if (stops.length !== needUnits.length) {
        return false;
    }
    return stops.every(stop => {
        const hasName = needUnits
            .map(item => item.node)
            .indexOf(stop.name !== -1);
        const nodeProducts = productsPerNode[stop.name];
        const nodeNeedUnit = needUnits.find(item => item.node === stop.name);
        let isMatchingProducts = false;
        if (nodeNeedUnit && nodeNeedUnit.products) {
            isMatchingProducts = nodeNeedUnit.products.every(
                productId => nodeProducts.indexOf(productId) !== -1
            );
        }
        return isMatchingProducts && hasName;
    });
}

// We want to derive the treemap data from:
// a) the version tree
// b) the version saved_set (if exists)
// c) the current state of treemap
export function savedSetDataSelector(
    tree,
    searchPaths,
    needUnits,
    nodeNames,
    productData,
    productsPerNode,
    unassignedProducts
) {
    const treeChildren = tree && tree.children;
    if (!treeChildren || !searchPaths) return null;
    // TODO: cache this
    let heightTree = buildHeightTree(treeChildren);

    // map fn - get what's needed for treemap display
    const mapNode = el => {
        let nu = needUnits.filter(item => item.node === el.name)[0];
        let products =
            nu && nu.products ? nu.products : productsPerNode[el.name];
        // without unassigned, what is new size?
        let newSizeExcludingUnassigned = products.filter(
            el => unassignedProducts.indexOf(el) === -1
        ).length;
        return {
            name: el.name,
            nodeName: nodeNames[el.name] || el.name,
            children: [],
            size: products.length,
            newSizeExcludingUnassigned,
            height: heightTree[el.name],
            totalSales: sumKPI(products, productData, "total_sales"),
            totalUnits: sumKPI(products, productData, "total_units")
        };
    };
    // filter fn for finding node by path
    const filterNode = path => el => el && el.name === path;
    // the node tree for treemap display
    let sourceNodes = null;
    let nodes = null;
    // get top-level source nodes from version tree
    // save top-level dest nodes for display
    sourceNodes = treeChildren.slice(0);
    nodes = sourceNodes.map(mapNode);
    // parent tree nodes to build from
    const nodeA = nodes[0];
    const nodeB = nodes[1];
    // loop through each and build node tree
    needUnits.forEach(nu => {
        // start at top-level
        sourceNodes = treeChildren.slice(0);
        nodes = [nodeA, nodeB];
        // this needunit node is ?
        const node = nu.node;
        // path list to this node is?
        const paths = searchPaths[node].split("|");
        // loop through paths
        let path = "";
        let stack = paths.slice(0, paths.length - 1);
        let match = null;
        let sourceMatch = null;
        while (stack.length > 0) {
            // path to search for
            path = stack[0];
            // find parent path in source nodes
            sourceMatch = sourceNodes.filter(filterNode(path))[0];
            // move to children source nodes for next iteration
            sourceNodes = sourceMatch ? sourceMatch.children : [];
            // match current path in the treemap nodes
            match = nodes.filter(filterNode(path))[0];
            // move treemap nodes to children for next iteration
            if (match && match.children.length) {
                nodes = match.children;
            } else {
                nodes = match.children = sourceNodes.map(mapNode);
            }
            // next path
            stack = stack.slice(1);
        }
    });
    return [nodeA, nodeB];
}

export const selectSavedSetTree = createSelector(
    [
        selectCurrentTree,
        selectSearchPaths,
        selectSavedSetNeedUnits,
        selectSavedSetNodeNames,
        selectProductDict,
        selectProductsPerNode,
        selectUnassignedProducts
    ],
    savedSetDataSelector
);

export const selectNextNodeToSplit = createSelector(
    [selectSavedSetTree],
    nodes => {
        let expandedNodes = findExpandedNodes(nodes);
        let res = expandedNodes.reduce(
            (prev, curr) => {
                let currHeight = parseFloat(curr.height);
                let prevHeight = parseFloat(prev.height);
                if (prevHeight < currHeight) {
                    prev = curr;
                }
                return prev;
            },
            { name: "", height: 0 }
        );
        return res.name;
    }
);

export const selectAllBrands = createSelector(
    [selectProductsPerNode, selectEnhancedProductsDict],
    (productsPerNode, allVersionsProductDict) => {
        let brands = {};
        if (typeof productsPerNode === "undefined" || productsPerNode === null)
            return brands;
        if (
            typeof allVersionsProductDict === "undefined" ||
            allVersionsProductDict === null
        )
            return brands;

        let productIds = Object.keys(productsPerNode).map(
            id => productsPerNode[id]
        );
        // flatten
        productIds = [].concat.apply([], productIds);
        // get data for products
        let products = productIds.map(id => allVersionsProductDict[id]);
        // get brand occurences
        brands = productTermCount(products, "brand");
        return brands;
    }
);

export function selectActiveNodeName(state) {
    return selectSavedSet(state).activeNodeName;
}

export const selectActiveNodeInfo = createSelector(
    [
        selectActiveNodeName,
        selectSavedSetNeedUnits,
        selectProductsPerNode,
        selectProductDict,
        selectEnhancedProductsDict,
        selectConfigAdditionalAttributeExclude,
        selectConfigAdditionalAttributePairs
    ],
    (
        node,
        savedSetNeedUnits,
        productsPerNode,
        productData,
        allVersionsProductDict,
        additionalAttributeExclude,
        additionalAttributePairs
    ) => {
        // Return early if nothing selected
        if (node === "")
            return { terms: [], brands: [], products: [], additionalAttrs: [] };

        let savedSetNode = savedSetNeedUnits.find(el => el.node === node);
        let nodeProducts = savedSetNode
            ? savedSetNode.products
            : productsPerNode[node];
        const allProductList = Object.values(productData).map(el => ({
            ...el,
            ...allVersionsProductDict[el.productId]
        }));
        return buildProductAttrInfo(
            nodeProducts,
            allProductList,
            additionalAttributeExclude,
            additionalAttributePairs
        );
    }
);

export function getUnassignedNode(unassignedData, savedSetNeedUnits) {
    let node = {
        name: UNASSIGNED,
        node: UNASSIGNED,
        products: []
    };
    if (!Array.isArray(unassignedData)) return node;
    // flattenedd unassigned products
    let unassignedProducts = unassignedData.map(el => el.productIds);
    if (unassignedProducts.length > 0) {
        unassignedProducts = [].concat.apply([], unassignedProducts);
    }
    // flattened saved products
    let savedSetProducts = savedSetNeedUnits.map(el => el.products);
    if (savedSetProducts.length > 0) {
        savedSetProducts = [].concat.apply([], savedSetProducts);
    }
    // filter out any saved
    let remainingUnassignedProducts = unassignedProducts.filter(
        el => savedSetProducts.indexOf(el) === -1
    );
    node.products = remainingUnassignedProducts;
    return node;
}

export const selectHasUnassigned = createSelector(
    [selectUnassignedData, selectSavedSetNeedUnits],
    (unassignedData, savedSetNeedUnits) => {
        const node = getUnassignedNode(unassignedData, savedSetNeedUnits);
        return node.products.length > 0;
    }
);

// Action types
export const RESET_SAVED_SET = "savedset/RESET_SAVED_SET";
const INIT_SAVED_SET = "savedset/INIT_SAVED_SET";
export const ADD_REMOVE_NODES = "savedset/ADD_REMOVE_NODES";
export const UPDATE_NODE_NAME = "savedset/UPDATE_NODE_NAME";
export const UPDATE_NODE_PRODUCTS = "savedset/UPDATE_NODE_PRODUCTS";
export const UPDATE_NEED_UNITS = "savedset/UPDATE_NEED_UNITS";
const SET_ACTIVE_NODE_NAME = "savedset/SET_ACTIVE_NODE_NAME";
const DELETE_SAVED_SET = "savedset/DELETE_SAVED_SET";

// Actions
export function initSavedSet(savedSet) {
    return {
        type: INIT_SAVED_SET,
        payload: savedSet
    };
}

export function resetSavedSet() {
    return {
        type: RESET_SAVED_SET
    };
}

export function undoSavedSetChange() {
    return {
        type: "savedset/UNDO"
    };
}

// Append nodes to saved set need units
// Parent node will be removed from need units
// nodesAdd array of object {name, products}
// nodesRemove array of node names
export function addRemoveNodes(nodesAdd, nodesRemove) {
    return {
        type: ADD_REMOVE_NODES,
        payload: {
            nodesAdd,
            nodesRemove
        }
    };
}

// update node name dictionary
export function updateNodeName(node, nodeName) {
    return {
        type: UPDATE_NODE_NAME,
        payload: {
            [node]: nodeName
        }
    };
}

// update node products
// payload is dictionary of products indexed by node
// { [node]: products }
export function updateNodeProducts(payload) {
    return {
        type: UPDATE_NODE_PRODUCTS,
        payload
    };
}

// update all need units
export function updateNeedUnits(payload) {
    return {
        type: UPDATE_NEED_UNITS,
        payload
    };
}

export function setActiveNodeName(payload) {
    return {
        type: SET_ACTIVE_NODE_NAME,
        payload
    };
}

export function deleteSavedSet(projectId, savedsetId) {
    return {
        type: DELETE_SAVED_SET,
        payload: {
            projectId,
            savedsetId
        }
    };
}

// Store shape
const initialState = {
    name: "",
    needUnits: [],
    nodeNames: {},
    activeNodeName: "",
    isDirty: false
};

// Reducer
function savedSetReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_SAVED_SET:
            return initialState;
        case INIT_SAVED_SET:
            const newState = {
                ...state,
                activeNodeName: "",
                ...action.payload,
                isDirty: false
            };
            return newState;
        case SET_ACTIVE_NODE_NAME:
            return {
                ...state,
                activeNodeName: action.payload
            };
        case UPDATE_NODE_NAME:
            return {
                ...state,
                nodeNames: {
                    ...state.nodeNames,
                    ...action.payload
                },
                needUnits: state.needUnits.map(el => {
                    if (action.payload[el.node]) {
                        return {
                            ...el,
                            name: action.payload[el.node]
                        };
                    }
                    return el;
                }),
                isDirty: true
            };
        case UPDATE_NODE_PRODUCTS:
            return {
                ...state,
                needUnits: state.needUnits.map(el => {
                    if (action.payload[el.node]) {
                        return {
                            ...el,
                            products: action.payload[el.node]
                        };
                    }
                    return el;
                }),
                isDirty: true
            };
        case UPDATE_NEED_UNITS:
            return {
                ...state,
                needUnits: action.payload,
                isDirty: true
            };
        case ADD_REMOVE_NODES:
            const { nodesAdd, nodesRemove } = action.payload;
            let newNeedUnits = state.needUnits.filter(el => {
                let needUnitNode = el.node;
                let res = nodesRemove.indexOf(needUnitNode);
                return res === -1;
            });
            newNeedUnits = newNeedUnits.concat(nodesAdd);
            return {
                ...state,
                needUnits: newNeedUnits,
                isDirty: true
            };
        default:
            return state;
    }
}

export function* watchDeleteSavedSet() {
    while (true) {
        const { payload } = yield take(DELETE_SAVED_SET);
        const { url, type, error } = api["savedset.delete"](payload.savedsetId);
        try {
            yield call(fetch, url, type);
            yield delay(1000);
            yield put({ type: FETCH_VERSIONS, payload: payload.projectId });
        } catch (e) {
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}

export default undo(savedSetReducer, "savedset", [
    RESET_SAVED_SET,
    INIT_SAVED_SET
]);
