// DnD type constants
export const ItemTypes = {
    SUBCAT_CARD: "subcat_card"
};

export const ItemIds = {
    UNGROUPED_GROUP_ID: "ungrouped_group_id",
    EMPTY_GROUP_ID: "empty_group_id",
    AREA_ID: "area_id"
};
