import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    stepperItem: {
        position: "relative",
        display: " inline-block",
        width: "100%",
        padding: "6px 0 6px 10px",
        textAlign: "left",
        boxSizing: "border-box",
        counterIncrement: "stepper",
        "&:before": {
            content: "counter(stepper) '.'",
            position: "absolute",
            top: "50%",
            left: 0,
            padding: "2px 0px 0px 9px",
            fontFamily: "Open Sans",
            fontSize: 12,
            lineHeight: 0,
            opacity: 0,
            display: "none"
        },
        "&:nth-child(2)::after": {
            content: "none"
        },
        "&:last-child": {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4
        },
        "&:after": {
            content: "''",
            position: "absolute",
            top: "50%",
            left: 0,
            width: 1,
            height: "50%",
            backgroundColor: theme.status.grey.greydivs,
            opacity: 1,
            transform: "translateY(-50%) scale(1, 1)"
        },
        "&$stepperItemActive + &$stepperItemInactive": {
            "&:after": {
                content: "''",
                position: "absolute",
                top: "50%",
                left: 0,
                width: 1,
                height: "50%",
                opacity: 0,
                backgroundColor: theme.status.grey.greydivs,
                transform: "translateY(-50%) scale(1, 0)",
                transition: "transform 500ms linear, opacity 500ms linear"
            }
        }
    },
    stepperItemInactive: {
        backgroundColor: "transparent"
    },
    stepperItemActive: {
        backgroundColor: "transparent"
    },
    spanStepperItemLabel: {
        color: theme.status.grey.dark,
        opacity: 0.5,
        textAlign: "right"
    },
    spanStepperItemValue: {
        color: theme.status.grey.dark,
        opacity: 1,
        textAlign: "left",
        textOverflow: "ellipsis"
    },
    spanStepperItem: {
        position: "relative",
        verticalAlign: "middle",
        fontFamily: "Open Sans",
        fontSize: " 0.75rem",
        padding: "0 20px",
        boxSizing: "border-box",
        overflow: "hidden",
        userSelect: "none",
        pointerEvents: "auto"
    },
    stepperTooltip: {
        display: "block",
        width: "100%",
        backgroundColor: theme.status.grey.white,
        pointerEvents: "none",
        boxSizing: "border-box",
        border: `1px solid ${theme.status.grey.greybox}`,
        borderLeft: "none",
        overflow: "hidden",
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4
    },
    stepperTooltipValue: {
        position: "relative",
        display: "block",
        fontFamily: "Open Sans",
        fontSize: "0.75rem",
        color: theme.status.grey.black,
        padding: "0 20px 0 0",
        boxSizing: "border-box"
    },

    stepperItemContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    }
});

function getItemStyle(len) {
    return {
        width: `${100 / len}%`
    };
}

export const getElementOverflow = el =>
    el ? el.offsetWidth < el.scrollWidth : false;

class StepperItem extends Component {
    render() {
        const { classes, len, item } = this.props;
        const { label, active, value } = item;

        return (
            <li
                className={classNames(
                    {
                        [classes.stepperItemActive]: active,
                        [classes.stepperItemInactive]: !active
                    },
                    classes.stepperItem
                )}
                style={getItemStyle(len)}
            >
                <span className={classes.stepperItemContent}>
                    <span
                        className={classNames(
                            classes.spanStepperItem,
                            classes.spanStepperItemLabel
                        )}
                    >
                        {label}
                    </span>
                    <Tooltip title={value} placement="top">
                        <span
                            className={classNames(
                                classes.spanStepperItem,
                                classes.spanStepperItemValue
                            )}
                        >
                            {value}
                        </span>
                    </Tooltip>
                </span>
            </li>
        );
    }
}

StepperItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        active: PropTypes.bool
    }).isRequired
};

export default withStyles(styles)(StepperItem);
