import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function HouseIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 19 19">
            <path d="M18.895 8.67L9.5 0 .105 8.67a.325.325 0 0 0-.017.46.33.33 0 0 0 .462.017l1.088-1.003V19h15.724V8.143l1.087 1.004a.327.327 0 0 0 .463-.017.324.324 0 0 0-.017-.46zm-11.36 9.679V13.14c0-1.077.88-1.953 1.965-1.953 1.084 0 1.965.876 1.965 1.953v5.209h-3.93zm9.172 0H12.12V13.14A2.616 2.616 0 0 0 9.5 10.536a2.616 2.616 0 0 0-2.62 2.604v5.209H2.292V7.539L9.5.889l7.207 6.65v10.81z" />
        </SvgIcon>
    );
}
