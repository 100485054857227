import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = theme => ({
    tableHead: {
        background: theme.status.grey.greybox,
        fontWeight: 400
    },
    store: {
        display: "inline-block",
        paddingRight: 2,
        "&:after": {
            content: "','"
        },
        "&:last-child:after": {
            content: "none"
        }
    }
});

function ParametersStoresList(props) {
    const { version, t, classes } = props;

    return (
        <Table>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell>{t("stores.stores")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell component="th" scope="row">
                        {version.versionRequestInfo.stores.map(
                            (store, index) => (
                                <span key={index} className={classes.store}>
                                    {store}
                                </span>
                            )
                        )}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

ParametersStoresList.propTypes = {
    classes: PropTypes.object.isRequired,
    version: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(ParametersStoresList));
