import React from "react";
import classNames from "classnames";

// MUI
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import IconButtonA11y from "../../uiComponents/Button/IconButtonA11y";

// DnD
import { DropTarget } from "react-dnd";

// DnD type
import { ItemTypes, ItemIds } from "./SubcategoryDragConstants";

// DnD drop specification
const dropTarget = {
    canDrop(props, monitor) {
        const item = monitor.getItem();
        // Only differing group ids can work
        return item.groupId !== props.id;
    },
    drop(props, monitor) {
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
            return;
        }
        return {
            groupId: props.id
        };
    }
};

// DnD drop collect function
function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop()
    };
}

const styles = theme => ({
    cardList: {
        display: "flex",
        flexWrap: "wrap",
        minHeight: 65
    },
    group: {
        position: "relative",
        padding: "20px 20px 10px",
        borderRadius: 10,
        display: "flex",
        backgroundColor: theme.status.clay[10],
        "&:hover $deleteButton": {
            opacity: 1,
            pointerEvents: "auto"
        }
    },
    groupBorder: {
        boxShadow: `inset 0 2px 7px 0 ${theme.status.green[80]}`
    },
    ungroup: {
        position: "relative",
        padding: 20,
        borderRadius: 10,
        border: "1px dashed",
        borderColor: theme.status.grey.greybox,
        "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme.status.grey.greybG,
            opacity: 0,
            transition: "opacity 300ms linear"
        }
    },
    cardListOver: {
        backgroundColor: theme.status.yellow[100]
    },
    deleteButton: {
        position: "absolute",
        right: 10,
        top: 10,
        opacity: 0,
        transition: "opacity 200ms linear",
        pointerEvents: "none"
    }
});

class SubcategoryCardArea extends React.Component {
    render() {
        const {
            id,
            classes,
            connectDropTarget,
            isOver,
            isOverCurrent,
            canDrop,
            handleClickDelete,
            children
        } = this.props;
        return connectDropTarget(
            <div
                className={classNames(
                    {
                        [classes.cardListOver]:
                            isOver && isOverCurrent && canDrop,
                        [classes.groupBorder]: id !== ItemIds.UNGROUPED_GROUP_ID
                    },
                    classes.cardList,
                    classes.group
                )}
            >
                {children}
                {id !== ItemIds.UNGROUPED_GROUP_ID &&
                    id !== ItemIds.EMPTY_GROUP_ID && (
                        <IconButtonA11y
                            className={classes.deleteButton}
                            ariaLabel={"delete"}
                            onClick={handleClickDelete}
                        >
                            <CloseIcon className={classes.icon} />
                        </IconButtonA11y>
                    )}
            </div>
        );
    }
}

export default DropTarget(
    ItemTypes.SUBCAT_CARD,
    dropTarget,
    collect
)(withStyles(styles)(SubcategoryCardArea));
