// redux-saga
import { take, call } from "redux-saga/effects";

// Api configuration
import api from "api";

// Redux saga helper
import { fetchEntityList } from "../../store/sagaHelpers";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "subcategoryMetrics";
const { reducer, actions, actionTypes, selectors } = createFetchRedux(
    reducerName
);

export default reducer;

// Action types
export const FETCH_SUBCATEGORY_METRICS = `${reducerName}/FETCH_SUBCATEGORY_METRICS`;
export const RECEIVE_SUBCATEGORY_METRICS = actionTypes.RECEIVE;

// Actions
export const resetMetrics = actions.reset;

export function fetchSubcategoryMetrics(projectId) {
    return {
        type: FETCH_SUBCATEGORY_METRICS,
        payload: projectId
    };
}

export const subcategoryMetrics = actions;

// Selectors
export const selectSubcategoryMetrics = state =>
    selectors.selectReduxData(state) || [];

// Sagas
export function* watchSubcategoryMetrics() {
    while (true) {
        const { payload: id } = yield take(FETCH_SUBCATEGORY_METRICS);
        yield call(
            fetchEntityList,
            subcategoryMetrics,
            api["project.subcategories.ungroup.get"](id)
        );
    }
}
