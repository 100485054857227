import React from "react";
import { Header } from "@emnos-legacy/portal-assets";

export function getNavigationFromUser(user) {
    let navigation = [];
    if (
        user &&
        user.userProfile &&
        Array.isArray(user.userProfile.allowedApps)
    ) {
        navigation = user.userProfile.allowedApps.map(app => ({
            name: app.name,
            rank: app.rank,
            applicationUrl: app.applicationUrl
        }));
    }
    return navigation;
}

export function getUserNameFromUser(user) {
    return user && user.userProfile && user.userProfile.userName
        ? user.userProfile.userName
        : "";
}

export function getNameFromUser(user) {
    return user && user.userProfile && user.userProfile.name
        ? user.userProfile.name
        : "";
}

export function getComEmailFromUser(user) {
    return user && user.userProfile && user.userProfile.comEmail
        ? user.userProfile.comEmail
        : "";
}

export function getHeaderDataFromUser(user) {
    return {
        userName: getNameFromUser(user),
        comEmail: getComEmailFromUser(user),
        navigation: getNavigationFromUser(user),
        navigationSortKey: 'rank'
    };
}

export function EmnosHeader({ userInfo }) {
    return <Header appName="Ranger"
                   emnosPortalAssets={{
                       actionHandlers: {
                           onUserLogout: function() {
                               window.location.href = "/logout";
                           }
                       }
                   }}
                   data={getHeaderDataFromUser(userInfo)} />;
}

export default EmnosHeader;
