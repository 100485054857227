import React, { Component } from "react";
import { connect } from "react-redux";

import {
    exportCSV,
    selectExportDataLoading
} from "../../redux/modules/tree/exportData";
import { selectProjectId } from "redux/modules/projects/projects";
import { selectCurrentVersionNum } from "redux/modules/versions/current";
import { selectSavedSetName } from "redux/modules/tree/savedset";
import { selectCurrentSavedSetId } from "redux/modules/tree/current";

import ExportCSV from "./ExportCSV";

const mapStateToProps = state => ({
    projectId: selectProjectId(state),
    version: selectCurrentVersionNum(state),
    savedSetName: selectSavedSetName(state),
    isDisabled: selectCurrentSavedSetId(state) === "default",
    isLoading: selectExportDataLoading(state)
});

class ExportCSVContainer extends Component {
    handleClickExport = event => {
        const { projectId, version, savedSetName, exportCSV } = this.props;
        exportCSV(projectId, version, savedSetName);
    };

    render() {
        const { isLoading, isDisabled } = this.props;
        return (
            <ExportCSV
                isLoading={isLoading}
                isDisabled={isDisabled}
                handleClick={this.handleClickExport}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    { exportCSV }
)(ExportCSVContainer);
