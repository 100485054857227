import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../styles/MuiTheme";
import { withTranslation } from "react-i18next";

const StyledButton = styled.button`
    padding: 14px 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
`;

const StyledIconWrapper = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 17px;
    padding: 2px;
    background-color: ${theme.status.grey.white};
    box-shadow: ${theme.shadows[1]};
    &:hover {
        background-color: ${theme.status.yellow.dark};
    }
`;

const StyledHierarchicalIcon = styled.i`
    position: absolute;
    top: 10px;
    left: 2px;
    width: 20px;
    height: 9px;
    border-left: 1px solid ${theme.status.grey.dark};
    border-right: 1px solid ${theme.status.grey.dark};
    opacity: ${props => (props.active ? 1 : 0.4)};
    ::before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 20px;
        height: 1px;
        border-bottom: 1px solid ${theme.status.grey.dark};
    }
    ::after {
        content: "";
        position: absolute;
        top: -9px;
        right: 0;
        width: 10px;
        height: 18px;
        border-left: 1px solid ${theme.status.grey.dark};
    }
`;

export const TreeHierarchicalViewButton = ({
    t,
    active,
    onClickHierarchicalView
}) => (
    <StyledButton
        title={t("tree.show_hierarchical_view")}
        onClick={onClickHierarchicalView}
    >
        <StyledIconWrapper>
            <StyledHierarchicalIcon active={active} />
        </StyledIconWrapper>
    </StyledButton>
);

TreeHierarchicalViewButton.propTypes = {
    active: PropTypes.bool.isRequired,
    onClickHierarchicalView: PropTypes.func.isRequired
};

export default withTranslation()(TreeHierarchicalViewButton);
