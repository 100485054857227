// React
import React, { Component } from "react";

//Third parties libs
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

// @material-ui/core
import { withStyles } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

//Components
import ReassignProduct from "./ReassignProduct";
import ReassignNeedUnit from "./ReassignNeedUnit";
import ReassignNeedUnitEditOptions from "./ReassignNeedUnitEditOptions";
import CreateNeedUnitContainer from "./CreateNeedUnitContainer";
import DialogComponent from "uiComponents/Dialog/DialogComponent";
import TreeReassignToolbar from "./TreeReassignToolbar";
import TreeReassignEditSortLabelButton from "./TreeReassignEditSortLabelButton";
import TreeReassignSortLabelChips from "./TreeReassignSortLabelChips";
import { UNASSIGNED } from "constants/static";
import { renderLabel } from "components/AttributeList/AttributeAutocomplete";

import theme from "../../styles/MuiTheme";
import { roundedRect } from "../../helpers/drawUtil";

import {
    setOrderBy,
    setOrderDirection,
    setSelectedProducts,
    setLastSelectedIndex,
    setClusterSelected,
    selectClusterSelected,
    selectOrderDirection,
    selectOrderBy,
    selectReassignSelectedProducts,
    selectDraggingTaskId,
    selectLastSelectedIndex,
    selectHasDragged,
    reassignDragEnd,
    reassignDragStart,
    reassignToTarget
} from "../../redux/modules/reassign/reassign";
import {
    selectSavedSetNeedUnitsWithUserDefined,
    selectSavedSetNeedUnits,
    selectSavedSetNodeNames,
    selectProductDict,
    selectUnassignedData,
    updateNodeName,
    updateNeedUnits,
    USER_PREFIX
} from "../../redux/modules/tree/savedset";
import { selectAllVersionsProductsDict } from "redux/modules/versions/versions";
import { selectProductsPerNode } from "redux/modules/tree/savedset";
import {
    selectCurrentProductDict,
    selectAllNeedUnits
} from "redux/modules/tree/currentSavedSetProducts";

const StyledTableSortLabel = withStyles({
    root: {
        position: "relative",
        whiteSpace: "normal"
    },
    icon: {
        marginLeft: 0
    }
})(TableSortLabel);

const styles = theme => ({
    root: {
        position: "relative"
    },
    titleInfo: {
        fontWeight: 400,
        marginBottom: 40,
        paddingLeft: 40
    },
    reassignWrapper: {
        fontSize: 0,
        whiteSpace: "nowrap"
    },
    reassignProductListContainer: {
        position: "relative",
        display: "inline-block",
        width: "70%",
        paddingRight: 80,
        verticalAlign: "top",
        boxSizing: "border-box",
        "&:after": {
            content: "''",
            position: "absolute",
            width: 1,
            top: 70,
            right: 36,
            height: "calc(100% - 70px)",
            borderRight: `1px dashed ${theme.status.clay[50]}`
        }
    },
    reassignNeedUnitListContainer: {
        position: "relative",
        display: "inline-block",
        width: "30%",
        verticalAlign: "top"
    },
    productSeparate: {
        display: "none",
        justifyContent: "center"
    },
    reassignContainer: {
        backgroundColor: theme.status.clay[10],
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 80,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    },
    list: {
        position: "relative",
        margin: 0,
        padding: 0,
        borderRadius: 20,
        listStyle: "none"
    },
    listDraggingOver: {
        "& $listItem": {
            border: `1px solid ${theme.status.green[100]}`
        }
    },
    listItem: {
        position: "relative",
        marginBottom: "10px",
        borderRadius: "17px",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "#ffffff",
        border: "none",
        boxShadow: `0 5px 10px 0 ${theme.status.clay[100]}`,
        fontSize: 14,
        "&:hover $editNeedUnitIcon": {
            opacity: 1
        },
        "&:hover": {
            backgroundColor: theme.status.yellow[50],
            color: theme.status.grey.dark
        }
    },
    listItemNeedUnit: {
        cursor: "default",
        fontSize: 13,
        boxShadow: `inset 0 3px 5px 0 ${theme.status.clay[100]}`
    },
    listItemSelected: {
        color: theme.status.grey.white,
        backgroundColor: theme.status.midnight[100],
        boxShadow: "none"
    },
    listItemTarget: {
        cursor: "pointer"
    },
    listItemOver: {
        backgroundColor: theme.status.green[100]
    },
    listItemUserDefined: {
        "&:before": {
            content: "''",
            position: "absolute",
            top: 14,
            left: 10,
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: theme.status.orange[100]
        },
        "&:hover": {
            backgroundColor: theme.status.yellow[10]
        }
    },
    editNeedUnit: {},
    editNeedUnitIcon: {
        padding: 0,
        opacity: 0,
        transition: "opacity 200ms linear",
        pointerEvents: "none",
        visibility: "hidden"
    },
    editNeedUnitIconActive: {
        visibility: "visible",
        pointerEvents: "auto"
    },
    productGridContainer: {
        position: "relative",
        fontSize: 0
    },
    productGridContainerGhost: {
        opacity: 0.5
    },
    productGridItem: {
        position: "relative",
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        fontSize: 14,
        boxSizing: "border-box",
        paddingTop: 6,
        paddingBottom: 6,
        color: theme.status.grey.dark
    },
    productGridItemDragging: {
        color: theme.status.grey.white
    },
    productGridItemLast: {
        position: "relative",
        overflow: "visible"
    },
    productGridItemUserAssigned: {
        width: "5%"
    },
    hidden: {
        visibility: "hidden"
    },
    icon: {
        position: "absolute",
        right: 3,
        top: -6,
        fill: theme.status.clay[50]
    },
    personIcon: {
        top: 2,
        right: 5,
        fill: theme.status.orange[100]
    },
    iconCheck: {
        fill: theme.status.midnight[100]
    },
    number: {
        position: "absolute",
        top: 0,
        right: 0,
        backgroundColor: theme.status.grey.greydivs,
        color: theme.status.grey.white
    },
    needUnitName: {
        marginRight: "auto"
    },
    productCount: {
        width: 16
    },
    textAlignRight: {
        textAlign: "right"
    },
    overflow: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    tableHead: {
        padding: 0,
        marginTop: 20,
        marginBottom: 10
    },
    tableCell: {
        position: "relative",
        display: "inline-block",
        verticalAlign: "middle",
        height: 40,
        fontSize: 13,
        fontWeight: 600,
        "&:last-child": {
            textAlign: "right"
        }
    },
    verticalLine: {
        position: "relative",
        top: 40,
        border: `1px dashed ${theme.status.clay[50]}`
    },
    column: {
        display: "inline-block",
        verticalAlign: "middle",
        width: "80%",
        paddingTop: 6,
        paddingBottom: 6,
        boxSizing: "border-box"
    },
    columnFirst: { paddingLeft: 24 },
    columnSm: {
        width: "10%",
        paddingRight: 10
    }
});

const mapStateToProps = state => ({
    orderBy: selectOrderBy(state),
    order: selectOrderDirection(state),
    selectedProducts: selectReassignSelectedProducts(state),
    lastSelectedIndex: selectLastSelectedIndex(state),
    savedSetNeedUnits: selectSavedSetNeedUnits(state),
    savedSetNeedUnitsWithUserDefined: selectSavedSetNeedUnitsWithUserDefined(
        state
    ),
    draggingTaskId: selectDraggingTaskId(state),
    clusterSelected: selectClusterSelected(state),
    productData: selectProductDict(state),
    productsPerNode: selectProductsPerNode(state),
    allVersionProductsDict: selectAllVersionsProductsDict(state),
    unassignedData: selectUnassignedData(state),
    savedSetNodeNames: selectSavedSetNodeNames(state),
    hasDragged: selectHasDragged(state),
    currentProductDict: selectCurrentProductDict(state),
    allNeedUnits: selectAllNeedUnits(state)
});

const mapDispatchToProps = {
    setOrderBy,
    setOrderDirection,
    setSelectedProducts,
    setLastSelectedIndex,
    setClusterSelected,
    reassignDragEnd,
    reassignDragStart,
    reassignToTarget,
    updateNodeName,
    updateNeedUnits
};

export function shiftSelectRows(
    selectedIndex,
    lastSelectedIndex,
    currentProducts
) {
    let startIndex =
        selectedIndex > lastSelectedIndex ? lastSelectedIndex : selectedIndex;
    let endIndex =
        selectedIndex > lastSelectedIndex
            ? selectedIndex + 1
            : lastSelectedIndex + 1;
    return currentProducts.slice(startIndex, endIndex).map(el => el.productId);
}

export function getUserAssigned(id, list, clusterSelected) {
    const hasTreeProducts = Array.isArray(list);
    const isUserDefinedNeedUnit =
        clusterSelected !== UNASSIGNED && !hasTreeProducts;
    const isUserAssigned = hasTreeProducts && list.indexOf(id) === -1;
    return isUserDefinedNeedUnit || isUserAssigned;
}

export class TreeReassign extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        savedSetNeedUnits: PropTypes.array.isRequired,
        savedSetNeedUnitsWithUserDefined: PropTypes.array.isRequired,
        savedSetNodeNames: PropTypes.object.isRequired,
        productData: PropTypes.object.isRequired,
        productsPerNode: PropTypes.object.isRequired,
        unassignedData: PropTypes.any,
        // From redux state
        orderBy: PropTypes.string.isRequired,
        order: PropTypes.string.isRequired,
        clusterSelected: PropTypes.string,
        lastSelectedIndex: PropTypes.number.isRequired,
        hasDragged: PropTypes.bool.isRequired,
        selectedProducts: PropTypes.array.isRequired,
        currentProductDict: PropTypes.object.isRequired,
        allNeedUnits: PropTypes.array.isRequired,
        // Redux actions
        setOrderBy: PropTypes.func.isRequired,
        setOrderDirection: PropTypes.func.isRequired,
        setClusterSelected: PropTypes.func.isRequired,
        setSelectedProducts: PropTypes.func.isRequired,
        setLastSelectedIndex: PropTypes.func.isRequired,
        reassignDragStart: PropTypes.func.isRequired,
        reassignDragEnd: PropTypes.func.isRequired,
        reassignToTarget: PropTypes.func.isRequired
    };

    state = {
        previewImg: null,
        anchorEl: null,
        // need unit currently being edited
        currentEditNode: null,
        // need unit editable name
        currentEditNodeName: "",
        // edit name dialog
        isEditNameDialogOpen: false,
        // edit sort label dialog
        isEditSortLabelDialogOpen: false,
        // sort label currently being edited
        currentSortLabel: null,
        // selected sort label from dialog
        selectedSortLabel: null,
        // table header column head
        headerData: [
            {
                id: "label",
                width: "60%",
                sorting: true,
                label: "product"
            },
            {
                id: "productId",
                width: "15%",
                sorting: true,
                label: "productid"
            },
            {
                id: "brand",
                width: "15%",
                sorting: true,
                label: "brand"
            },
            {
                id: "selected",
                width: "10%",
                sorting: false,
                label: "selected"
            }
        ]
    };

    componentDidMount() {
        this.props.setClusterSelected(UNASSIGNED);
        this.props.setSelectedProducts([]);
    }

    componentDidUpdate(prevProps) {
        const { selectedProducts: prevSelectedProducts } = prevProps;
        const { selectedProducts } = this.props;
        if (selectedProducts !== prevSelectedProducts) {
            this.updateDragPreview(selectedProducts);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.enterTimer);
    }

    openEditDialog() {
        const { currentEditNode } = this.state;
        const { savedSetNodeNames } = this.props;
        this.setState({
            currentEditNodeName:
                savedSetNodeNames[currentEditNode] || currentEditNode,
            isEditNameDialogOpen: true
        });
    }

    closeEditDialog() {
        this.setState({
            currentEditNodeName: "",
            isEditNameDialogOpen: false
        });
    }

    clearItemSelection = () => {
        const { setSelectedProducts } = this.props;
        setSelectedProducts([]);
    };

    selectProduct = (id, shiftKey) => {
        const {
            selectedProducts,
            setSelectedProducts,
            lastSelectedIndex,
            setLastSelectedIndex,
            currentProductDict,
            clusterSelected
        } = this.props;
        let newSelectedProducts = [];
        let currentProducts = currentProductDict[clusterSelected];
        let selectedIndex = currentProducts.findIndex(
            el => el.productId === id
        );
        if (shiftKey) {
            // select all between current index and lastSelectedIndex
            newSelectedProducts = shiftSelectRows(
                selectedIndex,
                lastSelectedIndex,
                currentProducts
            )
                .concat(selectedProducts)
                .filter((el, index, list) => {
                    return list.indexOf(el) === index;
                });
        } else {
            const isSelected = selectedProducts.some(el => el === id);
            if (isSelected) {
                // If already selected, we will unselect, so reset the last selected index
                // for the next time a click+Shift selection is made
                selectedIndex = 0;
            }
            newSelectedProducts = isSelected
                ? selectedProducts.filter(el => el !== id)
                : selectedProducts.concat([id]);
        }
        setSelectedProducts(newSelectedProducts);
        setLastSelectedIndex(selectedIndex);
    };

    updateDragPreview(selectedProducts) {
        const { t } = this.props;
        const canvas = document.createElement("canvas");
        canvas.width = 200;
        canvas.height = 35;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = theme.status.green[100];
        roundedRect(ctx, 0, 0, 200, 35, 20);
        ctx.fillStyle = theme.status.grey.white;
        const count = selectedProducts.length;
        const movingText =
            count < 2 ? t("tree.moving_one") : t("tree.moving", { count });
        ctx.font = "14px Open Sans";
        ctx.fillText(movingText, 20, 22);
        const url = canvas.toDataURL("image/png");
        const img = new Image();
        img.src = url;
        img.onload = () => {
            this.setState({
                previewImg: img
            });
        };
    }

    onSort = (event, property) => {
        if (property === "selected") return;
        const orderBy = property;
        let order = "desc";
        if (this.props.orderBy === property && this.props.order === "desc") {
            order = "asc";
        }
        // Update redux store
        const { setOrderDirection, setOrderBy } = this.props;
        setOrderDirection(order);
        setOrderBy(orderBy);
    };

    handleClickNeedUnit = needUnitName => event => {
        const {
            setClusterSelected,
            setSelectedProducts,
            clusterSelected
        } = this.props;
        event.preventDefault();
        return (
            clusterSelected !== needUnitName &&
            (setClusterSelected(needUnitName), setSelectedProducts([]))
        );
    };

    handleClickEditNeedUnit = needUnitNode => event => {
        this.setState({
            currentEditNode: needUnitNode,
            anchorEl: event.currentTarget
        });
        event.stopPropagation();
    };

    handleChangeNeedUnitName = event => {
        this.setState({
            currentEditNodeName: event.target.value
        });
    };

    handleClickCancelEditNameDialog = event => {
        this.closeEditDialog();
    };

    handleClickSaveEditNameDialog = event => {
        const { updateNodeName } = this.props;
        const { currentEditNode, currentEditNodeName } = this.state;
        updateNodeName(currentEditNode, currentEditNodeName);
        this.closeEditDialog();
    };

    deleteCurrentNeedUnit() {
        const { currentEditNode } = this.state;
        const {
            savedSetNeedUnitsWithUserDefined,
            updateNeedUnits
        } = this.props;
        const needUnits = savedSetNeedUnitsWithUserDefined.filter(
            el => el.node !== currentEditNode
        );
        updateNeedUnits(needUnits);
    }

    handleClickDeleteNeedUnit = event => {
        this.deleteCurrentNeedUnit();
        this.closeNeedUnitOptions();
    };

    handleClickRenameNeedUnit = event => {
        this.openEditDialog();
        this.closeNeedUnitOptions();
    };

    closeNeedUnitOptions() {
        this.setState({
            anchorEl: null
        });
    }

    handleCloseNeedUnitOptions = () => {
        this.closeNeedUnitOptions();
    };

    openEditSortLabelDialog() {
        this.setState({
            isEditSortLabelDialogOpen: true
        });
    }

    closeEditSortLabelDialog() {
        this.setState({
            isEditSortLabelDialogOpen: false
        });
    }

    handleClickCloseEditSortLabelDialog = () => {
        this.closeEditSortLabelDialog();
    };

    handleClickEditSortLabel = (e, sortLabelId) => {
        e.stopPropagation();
        this.setState({
            currentSortLabel: sortLabelId
        });
        this.openEditSortLabelDialog();
    };

    handleSelectSortLabel = label => {
        const { headerData, currentSortLabel } = this.state;
        const newHeaderData = headerData.map(el => {
            if (el.id === currentSortLabel) {
                return {
                    ...el,
                    id: label,
                    label: label
                };
            } else return el;
        });

        const { orderBy, setOrderBy } = this.props;

        if (currentSortLabel === orderBy) {
            setOrderBy(label);
        }

        this.setState({
            currentSortLabel: null,
            headerData: newHeaderData,
            isEditSortLabelDialogOpen: false
        });
    };

    setActiveSortLabel(sortLabelId) {
        this.setState({
            [`activeSortLabel-${sortLabelId}`]: true
        });
    }

    clearActiveSortLabel(sortLabelId) {
        this.setState({
            [`activeSortLabel-${sortLabelId}`]: false
        });
    }

    handleMouseEnterSortLabel = (sortLabelId, index) => event => {
        if (index === 0) return;
        const enterDelay = 500;
        clearTimeout(this.enterTimer);
        this.enterTimer = setTimeout(() => {
            this.setActiveSortLabel(sortLabelId);
        }, enterDelay);
    };

    handleMouseLeaveSortLabel = (rowId, index) => event => {
        if (index === 0) return;
        clearTimeout(this.enterTimer);
        this.clearActiveSortLabel(rowId);
    };

    handleReassignToTarget = node => {
        this.props.reassignToTarget(node);
    };

    render() {
        const {
            order,
            orderBy,
            selectedProducts,
            savedSetNodeNames,
            currentProductDict,
            allNeedUnits,
            clusterSelected,
            draggingTaskId,
            classes,
            // hasDragged,
            reassignDragEnd,
            reassignDragStart,
            t
        } = this.props;
        const {
            anchorEl,
            headerData,
            currentEditNode,
            currentEditNodeName,
            isEditNameDialogOpen,
            isEditSortLabelDialogOpen,
            previewImg
        } = this.state;

        const columnKeys = headerData.map(el => el.id);
        const currentProducts = currentProductDict[clusterSelected] || [];

        return (
            <div className={classes.root}>
                <Typography
                    className={classes.titleInfo}
                    variant="h6"
                    gutterBottom
                    component="h3"
                    align="left"
                >
                    {t("tree.tree_reassigned_title")}
                    {/*With new mode of assigning its better not to emphasise on the drag and drop feature*/}
                    {/*{!hasDragged && <DragRightIcon />}*/}
                </Typography>
                <TreeReassignToolbar
                    options={allNeedUnits.slice(1)}
                    onReassignToTarget={this.handleReassignToTarget}
                    targetDisabled={selectedProducts.length === 0}
                    clusterSelected={clusterSelected}
                />
                <div className={classes.reassignContainer}>
                    <div className={classes.reassignWrapper}>
                        <div className={classes.reassignProductListContainer}>
                            <div className={classes.tableHead}>
                                {headerData.map((obj, index) => {
                                    return (
                                        <div
                                            style={{
                                                width: obj.width
                                            }}
                                            key={obj.id}
                                            className={classes.tableCell}
                                        >
                                            {index < headerData.length - 1 && (
                                                <StyledTableSortLabel
                                                    key={obj.id}
                                                    active={orderBy === obj.id}
                                                    direction={order}
                                                    onClick={event =>
                                                        this.onSort(
                                                            event,
                                                            obj.id
                                                        )
                                                    }
                                                    onMouseEnter={this.handleMouseEnterSortLabel(
                                                        obj.id,
                                                        index
                                                    )}
                                                    onMouseLeave={this.handleMouseLeaveSortLabel(
                                                        obj.id,
                                                        index
                                                    )}
                                                >
                                                    <TreeReassignEditSortLabelButton
                                                        handleClick={e => {
                                                            this.handleClickEditSortLabel(
                                                                e,
                                                                obj.id
                                                            );
                                                        }}
                                                        active={
                                                            this.state[
                                                                `activeSortLabel-${obj.id}`
                                                            ]
                                                        }
                                                    />
                                                    {t([
                                                        "common." + obj.label,
                                                        `info_panel.kpi_${obj.label}`,
                                                        renderLabel(obj.label)
                                                    ])}
                                                </StyledTableSortLabel>
                                            )}
                                            {index === headerData.length - 1 &&
                                                t([
                                                    "common." + obj.label,
                                                    `info_panel.kpi_${obj.label}`
                                                ])}
                                        </div>
                                    );
                                })}
                            </div>

                            <ul className={classes.list}>
                                {currentProducts.map((product, index) => (
                                    <ReassignProduct
                                        key={index}
                                        index={index}
                                        product={product}
                                        previewImg={previewImg}
                                        isUserAssigned={product.isUserAssigned}
                                        selectedProducts={selectedProducts}
                                        toggleSelected={this.selectProduct}
                                        draggingTaskId={draggingTaskId}
                                        classes={classes}
                                        reassignDragEnd={reassignDragEnd}
                                        reassignDragStart={reassignDragStart}
                                        t={t}
                                        columnKeys={columnKeys}
                                    />
                                ))}
                            </ul>
                        </div>
                        <div className={classes.productSeparate}>
                            <div className={classes.verticalLine} />
                        </div>

                        <div className={classes.reassignNeedUnitListContainer}>
                            <div className={classes.tableHead}>
                                <div
                                    style={{ width: "50%" }}
                                    className={classes.tableCell}
                                >
                                    {t("tree.tree_reassigned__needs_unit")}
                                </div>
                                <div
                                    style={{ width: "50%" }}
                                    className={classes.tableCell}
                                >
                                    <CreateNeedUnitContainer />
                                </div>
                            </div>

                            <ul className={classes.list}>
                                {allNeedUnits.map((needunit, index) => (
                                    <ReassignNeedUnit
                                        key={index}
                                        classes={classes}
                                        needunit={needunit}
                                        clusterSelected={clusterSelected}
                                        savedSetNodeNames={savedSetNodeNames}
                                        handleClickNeedUnit={
                                            this.handleClickNeedUnit
                                        }
                                        handleClickEditNeedUnit={
                                            this.handleClickEditNeedUnit
                                        }
                                        t={t}
                                    />
                                ))}
                            </ul>
                            <Popover
                                id="simple-popper"
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={this.handleCloseNeedUnitOptions}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                            >
                                <ReassignNeedUnitEditOptions
                                    canDelete={Boolean(
                                        currentEditNode &&
                                            currentEditNode.indexOf(
                                                USER_PREFIX
                                            ) !== -1
                                    )}
                                    handleClickRename={
                                        this.handleClickRenameNeedUnit
                                    }
                                    handleClickDelete={
                                        this.handleClickDeleteNeedUnit
                                    }
                                />
                            </Popover>
                        </div>
                    </div>
                </div>
                <DialogComponent
                    padding
                    isOpen={isEditNameDialogOpen}
                    canSave={currentEditNodeName.length > 0}
                    onSave={this.handleClickSaveEditNameDialog}
                    onClose={this.handleClickCancelEditNameDialog}
                    title={t("tree.edit_dialog_title")}
                    maxWidth="sm"
                    centre
                >
                    <FormControl>
                        <FormLabel className={classes.formLabel}>
                            {t("tree.enter_name")}
                        </FormLabel>
                        <TextField
                            required
                            id="saved-set-name"
                            label={t("common.needunit")}
                            autoFocus
                            value={currentEditNodeName}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.handleChangeNeedUnitName}
                        />
                    </FormControl>
                </DialogComponent>
                <DialogComponent
                    padding
                    isOpen={isEditSortLabelDialogOpen}
                    onClose={this.handleClickCloseEditSortLabelDialog}
                    title={t("tree.edit_column_attr")}
                    maxWidth="sm"
                    centre
                >
                    <TreeReassignSortLabelChips
                        currentKeys={columnKeys}
                        handleClick={this.handleSelectSortLabel}
                    />
                </DialogComponent>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withStyles(styles)(TreeReassign)));
