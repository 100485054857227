import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import numeral from "numeral";
import theme from "../../styles/MuiTheme";

const StyledSVGText = styled.text`
    font-family: ${theme.typography.fontFamily};
    font-weight: 600;
    font-size: ${props => props.scaledFont};
`;

const TreemapContentText = ({
    t,
    x,
    y,
    name,
    size,
    totalSales,
    totalUnits,
    textXOffset,
    textYOffset,
    scaledFont,
    isProduct,
    isClicked,
    isHighlight,
    isNarrow
}) => (
    <StyledSVGText
        scaledFont={scaledFont}
        pointerEvents={"none"}
        x={x + textXOffset}
        y={y + textYOffset}
        textAnchor="left"
        fill={isClicked && !isHighlight ? "#fff" : theme.status.grey.dark}
        stroke="none"
        isProduct={isProduct}
    >
        <tspan>
            {isNarrow && name.length >= 9 ? name.substr(0, 6) + "..." : name}
        </tspan>

        {!isNarrow && (
            <tspan style={{ fontWeight: 400 }} x={x + textXOffset} dy="16px">
                {t("tree.product_size")}
            </tspan>
        )}
        <tspan
            style={{ fontWeight: 600 }}
            x={x + textXOffset}
            dx={isNarrow ? "0" : "90px"}
            dy={isNarrow ? "16px" : "0"}
            textAnchor={isNarrow ? "start" : "end"}
        >
            {numeral(size).format("0,0")}
        </tspan>

        {!isNarrow && (
            <tspan style={{ fontWeight: 400 }} x={x + textXOffset} dy="12px">
                {t("tree.total_sales")}
            </tspan>
        )}
        <tspan
            style={{ fontWeight: 600 }}
            x={x + textXOffset}
            dx={isNarrow ? "0" : "90px"}
            dy={isNarrow ? "12px" : "0"}
            textAnchor={isNarrow ? "start" : "end"}
        >
            {numeral(totalSales).format("$0a")}
        </tspan>

        {!isNarrow && (
            <tspan style={{ fontWeight: 400 }} x={x + textXOffset} dy="12px">
                {t("tree.total_units")}
            </tspan>
        )}
        <tspan
            style={{ fontWeight: 600 }}
            x={x + textXOffset}
            dx={isNarrow ? "0" : "90px"}
            dy={isNarrow ? "12px" : "0"}
            textAnchor={isNarrow ? "start" : "end"}
        >
            {numeral(totalUnits).format("0a")}
        </tspan>
    </StyledSVGText>
);

TreemapContentText.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    name: PropTypes.string,
    size: PropTypes.number,
    totalSales: PropTypes.number,
    totalUnits: PropTypes.number,
    textXOffset: PropTypes.number,
    textYOffset: PropTypes.number,
    scaledFont: PropTypes.string,
    isProduct: PropTypes.bool
};

TreemapContentText.defaultProps = {
    name: "",
    size: 1,
    totalSales: 0,
    totalUnits: 0,
    textXOffset: 8,
    textYOffset: 16,
    isProduct: false
};

export default withTranslation()(TreemapContentText);
