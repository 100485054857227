import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as d3 from "d3";
import { withTranslation } from "react-i18next";
import numeral from "numeral";
import theme from "../../styles/MuiTheme";
import { traverseNodes } from "redux/modules/tree/savedset";
import HierarchicalLink from "components/Tree/HierarchicalLink";

//Images
import gridBackground from "static/img/light_grey_dots.png";

// SVG Constants
const NODE_WIDTH = 130;
const NODE_HEIGHT = 140;
const NODE_SVG_WIDTH = 125;
const NODE_SVG_HEIGHT = 48;
const INITIAL_TX = 450;
const INITIAL_TY = 50;
const INITIAL_VIEWBOX_WIDTH = 900;
const INITIAL_VIEWBOX_HEIGHT = 900;

// Helper functions
const getPointFromEvent = event => ({
    x: event.clientX,
    y: event.clientY
});

// Get point local to SVG object
const getLocalPoint = (globalPoint, svgPoint, svgElement) => {
    // global point is point from click event
    // update svg point (created from svg el)
    svgPoint.x = globalPoint.x;
    svgPoint.y = globalPoint.y;
    // transform to local coordinates
    return svgPoint.matrixTransform(svgElement.getScreenCTM().inverse());
};

export const getTotal = (data = [], attr) =>
    data.reduce(
        (count, node) =>
            node && node.hasOwnProperty(attr) ? count + node[attr] : count,
        0
    );

const getTotalSize = data => getTotal(data, "size");
const getTotalSales = data => getTotal(data, "totalSales");

export const getMaxSize = (data = []) =>
    data.reduce((prev, curr) => Math.max(prev, curr.size), 0);

const getMaxSales = (data = []) =>
    data.reduce((prev, curr) => Math.max(prev, curr.totalSales), 0);

// Styled SVG components
const NodeRect = styled.rect`
    fill: ${theme.status.grey.white};
    stroke: ${props => {
        if (props.isNextCluster) {
            return theme.status.green["100"];
        } else {
            return props.active
                ? theme.status.grey[100]
                : theme.status.grey.greydivs;
        }
    }};
    stroke-width: ${props => (props.isNextCluster ? "1.5px" : "1px")};
    cursor: pointer;
`;

const NodeTitle = styled.text`
    font-size: 0.65rem;
    color: ${theme.status.grey.dark};
    pointer-events: none;
`;

const NodePath = styled.path`
    pointer-events: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #323a51;
    stroke-miterlimit: 10;
    stroke-dashoffset: 0;
`;
const NodeLine = styled.line`
    stroke: ${theme.status.grey.greydivs};
    stroke-width: 1px;
    pointer-events: none;
`;

const NodeBody = styled.text`
    font-size: 0.5rem;
    font-weight: 400;
    color: ${theme.status.grey.dark};
    pointer-events: none;
`;

// Styled components
const StyledHierarchicalTree = styled.div`
    position: relative;
    background-image: url(${gridBackground});
    background-repeat: repeat;
`;

export class HierarchicalTree extends React.Component {
    static propTypes = {
        innerRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.any })
        ]).isRequired,
        data: PropTypes.array.isRequired,
        getProductsByNodeName: PropTypes.func.isRequired,
        categoryTitle: PropTypes.string,
        activeOverlay: PropTypes.bool.isRequired,
        activeClusterName: PropTypes.string,
        isColourByIndex: PropTypes.bool,
        lineOption: PropTypes.string,
        zoom: PropTypes.number.isRequired,
        handleUpdateZoom: PropTypes.func.isRequired,
        hierarchicalLayoutMethod: PropTypes.oneOf(["tree", "cluster"])
    };

    static defaultProps = {
        categoryTitle: "",
        activeClusterName: ""
    };

    constructor(props) {
        super(props);
        this.isMouseDown = false;
        this.transformMatrix = [1, 0, 0, 1, INITIAL_TX, INITIAL_TY];
        this.onSVGMouseDown = this.onSVGMouseDown.bind(this);
        this.onSVGMouseUp = this.onSVGMouseUp.bind(this);
        this.onSVGMouseMove = this.onSVGMouseMove.bind(this);
        this.onSVGWheel = this.onSVGWheel.bind(this);
        this.createNodeRect = this.createNodeRect.bind(this);
        this.onClickNode = this.onClickNode.bind(this);
        this.createLinkPath = this.createLinkPath.bind(this);
    }

    componentDidMount() {
        this.svgPoint = this.svgEl && this.svgEl.createSVGPoint();
        this.svgRect = this.svgEl
            ? this.svgEl.getBoundingClientRect()
            : { x: 0, y: 0 };
        this.svgRect.y += window.scrollY;
        this.svgEl && this.svgEl.addEventListener("wheel", this.onSVGWheel);
        this.viewBox = this.svgEl && this.svgEl.viewBox.baseVal;
        this.updateOverlayPosition();
    }

    componentDidUpdate(prevProps) {
        const { zoom: prevZoom, isFitToScreen: prevFitTosScreen } = prevProps;
        const { zoom, isFitToScreen, isRotate } = this.props;
        if (zoom === 0 && prevZoom !== 0) {
            let viewBox = this.viewBox;
            viewBox.x = 0;
            viewBox.y = 0;
            viewBox.width = INITIAL_VIEWBOX_WIDTH;
            viewBox.height = INITIAL_VIEWBOX_HEIGHT;
            this.transformMatrix = [1, 0, 0, 1, INITIAL_TX, INITIAL_TY];
            this.applyTransform();
        }
        if (isFitToScreen && !prevFitTosScreen) {
            const bbox = this.svgEl.getBBox();
            const groupBBox = this.gEl.getBBox();
            let viewBox = this.viewBox;
            viewBox.x = 0;
            viewBox.y = 0;
            let newSize = bbox.width > bbox.height ? bbox.width : bbox.height;
            newSize += 50;
            viewBox.width = newSize;
            viewBox.height = newSize;
            let tx = groupBBox.x * -1;
            let ty = !isRotate
                ? newSize * 0.5 - groupBBox.height * 0.5
                : newSize * 0.5;
            this.transformMatrix = [1, 0, 0, 1, tx, ty];
            this.applyTransform();
            this.props.handleSetFitToScreen(false);
        }
    }

    componentWillUnmount() {
        this.svgEl && this.svgEl.removeEventListener("wheel", this.onSVGWheel);
    }

    updateOverlayPosition() {
        const { activeOverlay, activeClusterName } = this.props;
        if (activeOverlay) {
            const refId = `node${activeClusterName}El`;
            const nodeRefEl = this[refId];
            if (nodeRefEl) {
                const rect = nodeRefEl.getBoundingClientRect();
                const foundNode = traverseNodes(
                    this.props.data,
                    node => node.name === activeClusterName
                )[0];
                this.updateActiveNode(null, rect, foundNode);
            }
        }
    }

    getPoint(event) {
        return getLocalPoint(
            getPointFromEvent(event),
            this.svgPoint,
            this.svgEl
        );
    }

    applyTransform() {
        this.gEl.setAttribute(
            "transform",
            `matrix(${this.transformMatrix.join(" ")})`
        );
        this.updateOverlayPosition();
    }

    onSVGWheel(event) {
        event.preventDefault();
        let scaleFactor = 1.05;
        let normalized;
        let delta = event.wheelDelta;
        if (delta) {
            normalized = delta % 120 === 0 ? delta / 120 : delta / 12;
        } else {
            delta = event.deltaY || event.detail || 0;
            normalized = -(delta % 3 ? delta * 10 : delta / 3);
        }

        let scaleDelta = normalized > 0 ? 1 / scaleFactor : scaleFactor;

        this.svgPoint.x = event.clientX;
        this.svgPoint.y = event.clientY;
        let eventPoint = this.svgPoint.matrixTransform(
            this.svgEl.getScreenCTM().inverse()
        );

        let viewBox = this.viewBox;
        viewBox.x -= (eventPoint.x - viewBox.x) * (scaleDelta - 1);
        viewBox.y -= (eventPoint.y - viewBox.y) * (scaleDelta - 1);
        viewBox.width *= scaleDelta;
        viewBox.height *= scaleDelta;
        this.props.handleUpdateZoom(scaleDelta);
    }

    onSVGMouseDown(event) {
        this.isMouseDown = true;
        const point = this.getPoint(event);
        const transformPoint = {
            x: this.transformMatrix[4],
            y: this.transformMatrix[5]
        };
        const offset = {
            x: point.x - transformPoint.x,
            y: point.y - transformPoint.y
        };
        this.offset = offset;
    }

    onSVGMouseUp(event) {
        this.isMouseDown = false;
    }

    onSVGMouseMove(event) {
        if (this.isMouseDown) {
            event.preventDefault();
            const point = this.getPoint(event);
            const tx = point.x - this.offset.x;
            const ty = point.y - this.offset.y;
            this.transformMatrix[4] = tx;
            this.transformMatrix[5] = ty;
            this.applyTransform();
            if (this.props.zoom === 0) this.props.handleUpdateZoom(1);
        }
    }

    updateActiveNode(event, rect, data) {
        const cluster = {
            ...data,
            offsetX: rect.x - this.svgRect.x + rect.width / 2,
            offsetY: rect.y - this.svgRect.y + window.scrollY + rect.height + 16
        };
        this.props.onClickCluster(event, cluster);
    }

    onClickNode(event, node) {
        const rect = event.target.getBoundingClientRect();
        const { exportToDMTNodes } = this.props;
        this.updateActiveNode(event, rect, node.data);
        let nodeParent = node.parent;
        let parentNodeSelected = false;
        while(nodeParent === undefined || nodeParent !== null) {
            if(exportToDMTNodes.indexOf(nodeParent.data.name) !== -1) {
                parentNodeSelected = true;
                break;
            }
            nodeParent = nodeParent.parent;
        }
        this.props.updateParentNodeSelectedForDmtExport(parentNodeSelected)
    }

    createNodeRect(t, node, rootSize, rootSales, isRotate, isNextCluster, showCheckbox) {
        const isRoot = node.depth === 0;
        const items = isRoot ? rootSize : node.data.size;
        const sales = isRoot ? rootSales : node.data.totalSales;
        const titleMaxLen = 13;
        const title =
            node.data.nodeName && node.data.nodeName.length > titleMaxLen
                ? node.data.nodeName.substr(0, titleMaxLen - 2) + "..."
                : node.data.nodeName;
        const bodyText = t("tree.hierarchical_node_body", {
            items,
            itemsPc: numeral((items / rootSize) * 100).format("0"),
            sales: numeral(sales).format("$0.0a"),
            salesPc: numeral((sales / rootSales) * 100).format("0")
        });
        const refId = `node${node.data.name}El`;
        const transformStyle = `translate(${isRotate ? node.y : node.x}, ${
            isRotate ? node.x : node.y
        })`;
        return (
            <g key={node.data.name} transform={transformStyle}>
                <title>{node.data.nodeName}</title>

                <NodeRect
                    ref={el => (this[refId] = el)}
                    active={this.props.activeClusterName === node.data.name}
                    rx="2"
                    ry="2"
                    x={(NODE_SVG_WIDTH / 2) * -1}
                    y={(NODE_SVG_HEIGHT / 2) * -1}
                    width={NODE_SVG_WIDTH}
                    height={NODE_SVG_HEIGHT}
                    isNextCluster={isNextCluster}
                    onClick={event =>
                        !isRoot && this.onClickNode(event, node, node.y, node.x)
                    }
                />
                {!isRoot && showCheckbox && <NodePath  fill="none" d="M45 -10.8l3.1 3.2 8.7-8.8" />}
                <NodeTitle x="-50" y="-6" textAnchor="start">
                    {title}
                </NodeTitle>
                <NodeLine x1="-50" y1="1" x2="50" y2="1" />
                <NodeBody x="-50" y="15" textAnchor="start">
                    {bodyText}
                </NodeBody>
            </g>
        );
    }

    createLinkPath(link, index) {
        const {
            lineOption,
            isColourByIndex,
            highlightBrand,
            getProductsByNodeName,
            isRotate
        } = this.props;
        return (
            <HierarchicalLink
                key={index}
                link={link}
                maxSize={this.maxSize}
                maxSales={this.maxSales}
                lineOption={lineOption}
                isColourByIndex={isColourByIndex}
                rootSize={this.rootSize}
                rootSales={this.rootSales}
                highlightBrand={highlightBrand}
                getProductsByNodeName={getProductsByNodeName}
                isRotate={isRotate}
            />
        );
    }

    getDataWithRoot() {
        const { t, data, categoryTitle: category } = this.props;
        return {
            name: "root",
            nodeName: t("tree.total_category", { category }),
            children: data
        };
    }

    render() {
        const {
            t,
            data,
            isRotate,
            hierarchicalLayoutMethod,
            nextCluster,
            exportToDMTNodes
        } = this.props;
        if (data == null) {
            return <div></div>;
        }

        this.rootSize = getTotalSize(data);
        this.rootSales = getTotalSales(data);
        this.maxSize = getMaxSize(data);
        this.maxSales = getMaxSales(data);

        // d3 requires a root node, append to data
        const d3data = this.getDataWithRoot(data);
        // get layout positioning from d3
        function separation(a, b) {
                return a.parent === b.parent ? 1 : 1.1;
        }
        const nodeWidth = isRotate ? NODE_HEIGHT : NODE_WIDTH;
        const nodeHeight = isRotate ? NODE_WIDTH * 2 : NODE_HEIGHT;

        let layout;
        if (hierarchicalLayoutMethod === "tree") {
            layout = d3
                .tree()
                .nodeSize([nodeWidth, nodeHeight])
                .separation(separation);
        } else {
            layout = d3
                .cluster()
                .nodeSize([nodeWidth, nodeHeight])
                .separation(separation);
        }
        const root = layout(d3.hierarchy(d3data));
        const nodes = root.descendants();

        const links = root.links();

        return (
            <StyledHierarchicalTree ref={this.props.innerRef}>
                <svg
                    ref={el => (this.svgEl = el)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={`0 0 ${INITIAL_VIEWBOX_WIDTH} ${INITIAL_VIEWBOX_HEIGHT}`}
                    preserveAspectRatio="xMidYMid slice"
                    onMouseDown={this.onSVGMouseDown}
                    onMouseUp={this.onSVGMouseUp}
                    onMouseMove={this.onSVGMouseMove}
                >
                    <g
                        ref={el => (this.gEl = el)}
                        transform={`matrix(1 0 0 1 ${INITIAL_TX} ${INITIAL_TY})`}
                    >
                        <rect x="0" y="0" width="10" height="10" />
                        {links.map(this.createLinkPath)}
                        {nodes.map(node =>
                            this.createNodeRect(
                                t,
                                node,
                                this.rootSize,
                                this.rootSales,
                                isRotate,
                                node.data.name === nextCluster,
                                exportToDMTNodes.indexOf(node.data.name) !== -1
                            )
                        )}
                    </g>
                </svg>
            </StyledHierarchicalTree>
        );
    }
}

export default withTranslation()(HierarchicalTree);
