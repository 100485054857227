// redux-saga
import { takeLatest } from "redux-saga/effects";

// Redux saga helper
import { fetchEntity } from "../../store/sagaHelpers";

// Api configuration
import api from "api";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "importFromAssorter";
const { reducer, actions, actionTypes, selectors } = createFetchRedux(
    reducerName
);

export default reducer;

export const FETCH_PRODUCTS_FROM_ASSORTER = actionTypes.FETCH;
export const fetchProductsFromAssorter = actions.fetch;
export const resetProductsFromAssorter = actions.reset;
export const selectImportAssorterLoading = selectors.selectReduxLoading;
export const selectImportAssorterData = selectors.selectReduxData;

export const importFromAssorter = actions;

// Sagas
const fetchProductsFromAssorterEntity = fetchEntity.bind(
    null,
    importFromAssorter,
    api["products.import.from.assorter"]
);

export function* watchImportFromAssorter() {
    yield takeLatest(
        FETCH_PRODUCTS_FROM_ASSORTER,
        fetchProductsFromAssorterEntity
    );
}
