class FindPermissions {
    static isAdmin(currentUserRoles) {
        if (currentUserRoles !== undefined) {
            return currentUserRoles.some(role => role.name === "ranger-admin")
        }
        return false;
    }

    static hasAccessToAssorter(allowedApplications) {
        let hasAccessToAssorter = false;
        if (allowedApplications !== undefined) {
            allowedApplications.forEach(application => {
                if (application.clientId === "assorter") {
                    hasAccessToAssorter = true;
                }
            });
        }
        return hasAccessToAssorter;
    }

    static isOwnerOfThisProject(userName, loginUserName) {
        if (
            userName !== undefined &&
            loginUserName !== undefined &&
            userName === loginUserName
        ) {
            return true;
        }
        return false;
    }
}

export default FindPermissions;
