import React, { Component } from "react";
import PropTypes from "prop-types";

import TreeOptimalSplitButton from "components/Tree/TreeOptimalSplitButton";
import TreeOptimalSplitDialogConfirm from "components/Tree/TreeOptimalSplitDialogConfirm";

class TreeOptimalSplitButtonContainer extends Component {
    static propTypes = {
        onConfirmOptimalSplit: PropTypes.func.isRequired,
        savedSet: PropTypes.string,
        isUnsavedChanges: PropTypes.bool,
        isOptimal: PropTypes.bool.isRequired,
        canUndo: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isUnsavedChanges: false,
        savedSet: ""
    };

    state = {
        isConfirmDialogOpen: false
    };

    constructor(props) {
        super(props);
        this.handleClickOptimalSplit = this.handleClickOptimalSplit.bind(this);
        this.handleAcceptSplit = this.handleAcceptSplit.bind(this);
        this.handleCancelSplit = this.handleCancelSplit.bind(this);
    }

    openDialog() {
        this.setState({ isConfirmDialogOpen: true });
    }

    closeDialog() {
        this.setState({ isConfirmDialogOpen: false });
    }

    handleClickOptimalSplit() {
        const { isUnsavedChanges, onConfirmOptimalSplit } = this.props;

        if (isUnsavedChanges) {
            this.openDialog();
        } else {
            onConfirmOptimalSplit();
        }
    }

    handleChangeOptimalSplit = event => {
        const {
            isOptimal,
            onUndoOptimalSplit,
            onConfirmOptimalSplit
        } = this.props;
        if (isOptimal) {
            onUndoOptimalSplit();
        } else {
            onConfirmOptimalSplit();
        }
    };

    handleAcceptSplit() {
        this.props.onConfirmOptimalSplit();
        this.closeDialog();
    }

    handleCancelSplit() {
        this.closeDialog();
    }

    render() {
        const { isOptimal, canUndo } = this.props;
        const isDisabled = isOptimal && !canUndo;
        return (
            <React.Fragment>
                <TreeOptimalSplitButton
                    isDisabled={isDisabled}
                    handleChangeOptimalSplit={this.handleChangeOptimalSplit}
                    isOptimal={isOptimal}
                />
                <TreeOptimalSplitDialogConfirm
                    handleAccept={this.handleAcceptSplit}
                    handleClose={this.handleCancelSplit}
                    isOpen={this.state.isConfirmDialogOpen}
                />
            </React.Fragment>
        );
    }
}

export default TreeOptimalSplitButtonContainer;
