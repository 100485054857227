import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import TreeInformation from "components/Version/TreeInformation";
import Carousel from "components/Carousel/Carousel";

const styles = theme => ({
    container: {
        backgroundColor: theme.status.clay[20],
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    }
});

export class TreeInformationContainer extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        versionNumber: PropTypes.number.isRequired,
        trees: PropTypes.array.isRequired,
        onClickShare: PropTypes.func.isRequired
    };

    state = {
        isHeightLimit: true,
        selectedSavedSetId: "",
        selectedTreeId: ""
    };

    setSelectedTree = selectedTreeId => {
        this.setState({
            selectedTreeId
        });
    };

    setSelectedSavedSet = selectedSavedSetId => {
        this.setState({
            selectedSavedSetId
        });
    };

    handleToggleExpand = event => {
        this.setState({
            isHeightLimit: false
        });
        event.preventDefault();
    };

    render() {
        const { trees, versionNumber, onClickShare, classes } = this.props;
        const {
            isHeightLimit,
            selectedSavedSetId,
            selectedTreeId
        } = this.state;
        return (
            <div className={classes.container}>
                <Carousel numItems={trees.length}>
                    {trees
                        .sort((a, b) => a.id - b.id)
                        .map((tree, idx) => (
                            <TreeInformation
                                key={tree.id}
                                isFirst={idx === 0}
                                tree={tree}
                                isHeightLimit={isHeightLimit}
                                versionNumber={versionNumber}
                                onToggleExpand={this.handleToggleExpand}
                                onClickShare={onClickShare}
                                selectedSavedSetId={selectedSavedSetId}
                                selectedTreeId={selectedTreeId}
                                setSelectedSavedSet={this.setSelectedSavedSet}
                                setSelectedTree={this.setSelectedTree}
                            />
                        ))}
                </Carousel>
            </div>
        );
    }
}

export default withStyles(styles)(TreeInformationContainer);
