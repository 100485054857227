export const OPEN_SNACKBAR = "modules/snackbar/OPEN_SNACKBAR";

export default (state = null, action) => {
    switch (action.type) {
        case OPEN_SNACKBAR:
            return action.payload;
        default:
            return state;
    }
};

export function openSnackbar(message, variant = "success") {
    return {
        type: OPEN_SNACKBAR,
        payload: {
            message,
            variant
        }
    };
}

export function selectSnackbar(state) {
    return state.snackbar;
}
