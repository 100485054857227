// React
import React, { Component } from "react";
// MUI
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ConfigContext } from "utils/ConfigProvider";
// Third parties libs
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { ImportPanelState } from "./ProductImportDialog";

import ProductImportSelectCategory from "./ProductImportSelectCategory";
import ProductImportConfirm from "./ProductImportConfirm";
import { Typography } from "@material-ui/core";

const styles = theme => ({
    loadingPaper: {
        padding: 30,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
    },
    header: {
        width: 600,
        height: 70,
        backgroundColor: theme.status.grey.greybox,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 30px"
    },
    headerText: {
        fontWeight: 600,
        textAlign: "center",
        width: "100%"
    },
    addImportContainer: {
        display: "flex",
        flexDirection: "column"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-around",
        padding: 30
    },
    unlinked: {
        padding: "0 0px 30px 30px",
        fontSize: 14,
        textAlign: "left"
    },
    hidden: {
        visibility: "hidden",
        width: 0
    },
    importSummary: {
        padding: 30,
        textAlign: "center"
    },
    importSummaryHeader: {
        fontWeight: 600
    },
    importRow: {
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${theme.status.grey.greydivs}`
    },
    importRowMissing: {
        textAlign: "left",
        borderBottom: "none"
    },
    importColumn: {
        display: "inline-block",
        width: "50%",
        padding: 12,
        boxSizing: "border-box"
    },
    missingTitle: {
        padding: "12px 0 0"
    },
    missingList: {
        margin: 0,
        padding: "8px 0 0",
        listStyle: "none",
        whiteSpace: "normal"
    },
    missingListItem: {
        display: "inline-block",
        margin: 0,
        padding: "0 4px 0 0",
        fontSize: 14,
        color: theme.status.grey.dark,
        lineHeight: 1.6
    },
    selectFormControl: {
        padding: 30
    }
});

class ProductImport extends Component {
    static propTypes = {
        project: PropTypes.object,
        projectId: PropTypes.string.isRequired,
        // Current subcategory index
        selectedCategoryId: PropTypes.array.isRequired,
        // Object with verified products, missing products
        importFromFileResult: PropTypes.object,
        // List of products
        importFromAssorterResult: PropTypes.array,
        onClickImportFromAssorter: PropTypes.func.isRequired,
        onChangeImportFromFile: PropTypes.func.isRequired,
        onClickDownloadProductTemplate: PropTypes.func.isRequired
    };

    renderPanelSelectCategory() {
        const {
            onChangeCategory,
            selectedCategoryId,
            importFromFileResult,
            realizationResult,
            categoryKey,
            importMode
        } = this.props;
        const result =
            importMode === "file" ? importFromFileResult : realizationResult;
        return (
            <ProductImportSelectCategory
                categoryKey={categoryKey}
                onChangeCategory={onChangeCategory}
                selectedCategoryId={selectedCategoryId}
                verifiedProducts={result.verifiedProducts}
            />
        );
    }

    renderPanelFileUpload() {
        const { classes } = this.props;
        return (
            <div className={classes.addImportContainer}>
                <div className={classes.buttonContainer}>
                    <CircularProgress size={18} />
                </div>
            </div>
        );
    }

    renderPanelLoadingRealization() {
        const { classes } = this.props;
        return (
            <Paper elevation={0} className={classes.loadingPaper}>
                <Typography variant="subtitle1">Loading realization</Typography>
                <CircularProgress size={18} />
            </Paper>
        );
    }

    renderPanelLoadingSaveProducts() {
        const { classes } = this.props;
        return (
            <Paper elevation={0} className={classes.loadingPaper}>
                <Typography variant="subtitle1">Saving products...</Typography>
                <CircularProgress size={18} />
            </Paper>
        );
    }

    renderPanelConfirm() {
        const {
            categoryKey,
            selectedCategoryId,
            importFromFileResult,
            importFromAssorterResult,
            realizationResult,
            importMode
        } = this.props;

        let verifiedProducts = [];
        let missingProductsIds = [];

        if (importMode === "file" && importFromFileResult) {
            verifiedProducts = importFromFileResult.verifiedProducts;
            missingProductsIds = importFromFileResult.missingProductsIds;
        }
        let subcategoryCountKey = "subcategoryCount";
        if (importMode === "realization" && realizationResult) {
            verifiedProducts = realizationResult.verifiedProducts;
        }
        return (
            <ProductImportConfirm
                selectedCategoryId={selectedCategoryId}
                verifiedProducts={verifiedProducts}
                missingProductsIds={missingProductsIds}
                assorterResult={importFromAssorterResult}
                categoryKey={categoryKey}
                subcategoryCountKey={subcategoryCountKey}
            />
        );
    }

    renderPanel(step) {
        let panel = null;
        switch (step) {
            case ImportPanelState.ADD_IMPORT:
                panel = this.renderPanelFileUpload();
                break;
            case ImportPanelState.SELECT_IMPORT:
                panel = this.renderPanelSelectCategory();
                break;
            case ImportPanelState.CONFIRM_IMPORT:
                panel = this.renderPanelConfirm();
                break;
            case ImportPanelState.LOADING_REALIZATION:
                panel = this.renderPanelLoadingRealization();
                break;
            case ImportPanelState.LOADING_SAVE_PRODUCTS:
                panel = this.renderPanelLoadingSaveProducts();
                break;
            default:
                panel = null;
        }
        return panel;
    }

    render() {
        const { classes, step } = this.props;
        return (
            <div className={classes.container}>{this.renderPanel(step)}</div>
        );
    }
}

ProductImport.contextType = ConfigContext;

export default withTranslation()(withStyles(styles)(ProductImport));
