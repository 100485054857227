import { createSelector } from "reselect";
// redux-saga
import { all, fork, call, take, select, put } from "redux-saga/effects";
// List helpers from ordering wizard components
import {
    normalizeTree,
    appendNormalizedTree
} from "@emnos-legacy/ordering-wizard-components";

// Api config
import api from "api";
import { fetchDMT } from "services/fetch";

import { selectProjects } from "redux/modules/projects/projects";

const reducerName = "dmtProductTree";

// Action types
export const FETCH_DMT_PRODUCT_TREE = "dmtProductTree/FETCH_DMT_PRODUCT_TREE";
export const FETCH_DMT_PRODUCT_TREE_NODE =
    "dmtProductTree/FETCH_DMT_PRODUCT_TREE_NODE";
export const EXPAND_DMT_PRODUCT_TREE = "dmtProductTree/EXPAND_DMT_PRODUCT_TREE";
export const COLLAPSE_DMT_PRODUCT_TREE =
    "dmtProductTree/COLLAPSE_DMT_PRODUCT_TREE";
export const SELECT_DMT_PRODUCT_TREE_NODE =
    "dmtProductTree/SELECT_DMT_PRODUCT_TREE";
export const DESELECT_DMT_PRODUCT_TREE_NODE =
    "dmtProductTree/DESELECT_DMT_PRODUCT_TREE";
export const DESELECT_ALL_DMT_PRODUCT_TREE_NODES =
    "dmtProductTree/DESELECT_ALL_DMT_PRODUCT_TREE_NODES";
export const INIT_DMT_PRODUCT_TREE_FROM_PROJECT = `${reducerName}/INIT_DMT_PRODUCT_TREE_FROM_PROJECT`;

const REQUEST_ONE = "dmtProductTree/REQUEST_ONE";
const REQUEST_INIT = "dmtProductTree/REQUEST_INIT";
const REQUEST = "dmtProductTree/REQUEST";
const RECEIVE = "dmtProductTree/RECEIVE";
const ERROR = "dmtProductTree/ERROR";
const RESET = "dmtProductTree/RESET";
const RESET_REQUEST = "dmtProductTree/RESET_REQUEST";

// Actions
export function fetchDMTProductTree(projectId) {
    return {
        type: FETCH_DMT_PRODUCT_TREE,
        payload: projectId
    };
}

export function initDMTProductTreeFromProject(projectId) {
    return {
        type: INIT_DMT_PRODUCT_TREE_FROM_PROJECT,
        payload: projectId
    };
}

export function resetDMTProductTree() {
    return {
        type: RESET
    };
}

export function resetDMTProductTreeRequest() {
    return {
        type: RESET_REQUEST
    };
}

export function fetchNode(id) {
    return {
        type: FETCH_DMT_PRODUCT_TREE_NODE,
        payload: id
    };
}

export function expandNode(id) {
    return {
        type: EXPAND_DMT_PRODUCT_TREE,
        payload: id
    };
}

export function collapseNode(id) {
    return {
        type: COLLAPSE_DMT_PRODUCT_TREE,
        payload: id
    };
}

export function selectNode(id) {
    return {
        type: SELECT_DMT_PRODUCT_TREE_NODE,
        payload: id
    };
}

export function deselectNode(id) {
    return {
        type: DESELECT_DMT_PRODUCT_TREE_NODE,
        payload: id
    };
}

export function deselectAllNodes() {
    return {
        type: DESELECT_ALL_DMT_PRODUCT_TREE_NODES
    };
}
// Status of fetch for rendering
export const STATUS = {
    PENDING: "pending",
    LOADING: "loading",
    COMPLETE: "complete",
    ERROR: "error"
};

function findSelectedNodes(tree) {
    return Object.values(tree).filter(el => el.selected);
}

const initialState = {
    requestId: null,
    status: STATUS.PENDING,
    error: null,
    data: {
        root: {
            id: "root",
            childrenIds: []
        }
    }
};

export default function dmtProductTreeReducer(state = initialState, action) {
    switch (action.type) {
        case RESET: {
            return initialState;
        }
        case RESET_REQUEST: {
            return {
                ...state,
                requestId: null,
                status: STATUS.PENDING
            };
        }
        case REQUEST_INIT:
            return {
                ...state,
                requestId: action.payload,
                status: STATUS.LOADING
            };
        case REQUEST:
        case REQUEST_ONE:
            return {
                ...state,
                status: STATUS.LOADING
            };
        case RECEIVE:
            return {
                ...state,
                data: action.payload,
                status: STATUS.COMPLETE
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
                status: STATUS.ERROR
            };
        default:
            return state;
    }
}

export const actions = {
    requestInit(requestId) {
        return {
            type: REQUEST_INIT,
            payload: requestId
        };
    },
    request() {
        return {
            type: REQUEST
        };
    },
    receive(data) {
        return {
            type: RECEIVE,
            payload: data
        };
    },

    error() {
        return {
            type: ERROR
        };
    }
};

// Selectors
export const selectDMTProductTree = state => state[reducerName].data;
export const selectDMTProductTreeStatus = state => state[reducerName].status;
export const selectDMTProductTreeError = state => state[reducerName].error;
export const selectDMTProductTreeRequestId = state =>
    state[reducerName].requestId;

export const selectDMTProductTreeSelectedNodes = createSelector(
    [selectDMTProductTree],
    tree => findSelectedNodes(tree)
);
function updateTreeFromProject(tree, project) {
    if (
        project &&
        project.productStoreHierarchySelection &&
        project.productStoreHierarchySelection.products &&
        project.productStoreHierarchySelection.products.hierarchies
    ) {
        const newTree = Object.keys(tree).reduce((prev, curr) => {
            const currNode = tree[curr];
            prev[curr] = {
                ...currNode,
                selected: false
            };
            return prev;
        }, {});
        const hierarchies =
            project.productStoreHierarchySelection.products.hierarchies;
        let node;
        for (let i = 0; i < hierarchies.length; ++i) {
            node = hierarchies[i];
            if (newTree[node.id]) {
                newTree[node.id] = {
                    ...newTree[node.id],
                    selected: true
                };
            } else {
                newTree[node.id] = {
                    ...node,
                    selected: true
                };
            }
        }
        return newTree;
    }
    return tree;
}
// Sagas
export function* watchDMTProductTreeInitFromProject() {
    while (true) {
        const { payload: projectId } = yield take(
            INIT_DMT_PRODUCT_TREE_FROM_PROJECT
        );
        // update with any previous selection in project
        const projects = yield select(selectProjects);
        const project = projects.find(el => String(el.id) === projectId);
        const tree = yield select(selectDMTProductTree);
        if (Object.keys(tree).length === 0) {
            return;
        } else {
            const reinitTree = updateTreeFromProject(tree, project);
            yield put(actions.receive(reinitTree));
        }
    }
}

export function* watchDMTProductTree() {
    while (true) {
        const { payload: projectId } = yield take(FETCH_DMT_PRODUCT_TREE);
        const { url, type, error } = api["dmt.product.tree.get"]();
        yield put(actions.requestInit(projectId));
        try {
            const result = yield call(fetchDMT, url, type);
            let normalizedResult = normalizeTree(result);
            // update with any previous selection in project
            const projects = yield select(selectProjects);
            const project = projects.find(el => String(el.id) === projectId);
            normalizedResult = updateTreeFromProject(normalizedResult, project);
            yield put(actions.receive(normalizedResult));
        } catch (e) {
            yield put(actions.error(error));
            console.error(e);
        }
    }
}

export function* watchDMTProductTreeNode() {
    while (true) {
        const { payload: id } = yield take(FETCH_DMT_PRODUCT_TREE_NODE);
        const { url, type, error } = api["dmt.product.tree.node.get"](id);
        yield put(actions.request());
        try {
            const result = yield call(fetchDMT, url, type);
            const tree = yield select(selectDMTProductTree);
            const newTree = appendNormalizedTree(id, result, tree);
            newTree[id].expanded = true;
            newTree[id].requested = true;
            yield put(actions.receive(newTree));
        } catch (e) {
            yield put(actions.error(error));
            console.error(e);
        }
    }
}

export function* watchDMTProductTreeCollapse() {
    while (true) {
        const { payload: id } = yield take(COLLAPSE_DMT_PRODUCT_TREE);
        const tree = yield select(selectDMTProductTree);
        const node = tree[id];
        const newTree = {
            ...tree,
            [id]: {
                ...node,
                expanded: false
            }
        };
        yield put(actions.receive(newTree));
    }
}

export function findParentNode(id, tree) {
    let parentNode;
    Object.values(tree).forEach(node => {
        if (Array.isArray(node.childrenIds) && node.childrenIds.includes(id)) {
            parentNode = node;
        }
    });
    return parentNode;
}

function canSelectNode(id, tree) {
    let parentNode = findParentNode(id, tree);
    if (!parentNode) return true;
    if (parentNode.selected) return false;
    return canSelectNode(parentNode.id, tree);
}

export function selectNodeInTree(id, tree) {
    if (!canSelectNode(id, tree)) {
        return tree;
    }
    const newTree = {
        ...tree,
        [id]: {
            ...tree[id],
            selected: true
        }
    };
    const parentNode = findParentNode(id, tree);
    if (parentNode && parentNode.id !== "root" && parentNode.selectable) {
        const allSelected = parentNode.childrenIds.every(
            pid => pid === id || tree[pid].selected
        );
        // select parent node instead
        if (allSelected) {
            // unselect descendants
            for (let i = 0; i < parentNode.childrenIds.length; ++i) {
                const pid = parentNode.childrenIds[i];
                newTree[pid].selected = false;
            }
            return selectNodeInTree(parentNode.id, newTree);
        }
    }
    return newTree;
}

export function* watchDMTProductTreeSelect() {
    while (true) {
        const { payload: id } = yield take(SELECT_DMT_PRODUCT_TREE_NODE);
        const tree = yield select(selectDMTProductTree);
        const newTree = selectNodeInTree(id, tree);
        yield put(actions.receive(newTree));
    }
}

export function* watchDMTProductTreeDeselect() {
    while (true) {
        const { payload: id } = yield take(DESELECT_DMT_PRODUCT_TREE_NODE);
        const tree = yield select(selectDMTProductTree);
        const node = tree[id];
        const newTree = {
            ...tree,
            [id]: {
                ...node,
                selected: false
            }
        };
        yield put(actions.receive(newTree));
    }
}

export function* watchDMTProductTreeDeselectAll() {
    while (true) {
        yield take(DESELECT_ALL_DMT_PRODUCT_TREE_NODES);
        const tree = yield select(selectDMTProductTree);
        const newTree = Object.keys(tree).reduce((prev, id) => {
            const node = tree[id];
            prev[id] = {
                ...node,
                selected: false
            };
            return prev;
        }, {});
        yield put(actions.receive(newTree));
    }
}

export function* watchDMTProductTreeExpand() {
    while (true) {
        const { payload: id } = yield take(EXPAND_DMT_PRODUCT_TREE);
        const tree = yield select(selectDMTProductTree);
        const node = tree[id];

        if (!node || !node.requested) {
            yield put(fetchNode(id));
        } else {
            const newTree = {
                ...tree,
                [id]: {
                    ...node,
                    expanded: true
                }
            };
            yield put(actions.receive(newTree));
        }
    }
}

export function* watchDMTProduct() {
    yield all([
        fork(watchDMTProductTree),
        fork(watchDMTProductTreeExpand),
        fork(watchDMTProductTreeCollapse),
        fork(watchDMTProductTreeNode),
        fork(watchDMTProductTreeSelect),
        fork(watchDMTProductTreeDeselect),
        fork(watchDMTProductTreeDeselectAll),
        fork(watchDMTProductTreeInitFromProject)
    ]);
}
