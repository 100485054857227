import React from "react";
import PropTypes from "prop-types";

// Material UI components
import { withStyles } from "@material-ui/core/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = theme => ({
    root: {
        margin: "0 60px",
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    scrollButtons: {
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
            opacity: 0.5
        }
    },
    tabsRoot: {
        borderBottom: "1px solid",
        borderBottomColor: theme.status.clay[20]
    },
    tabRoot: {
        fontSize: 14
    }
});

export const TreeInfoPanelTabs = ({ tab, tabs, handleTabChange, classes }) => (
    <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        classes={{
            root: classes.tabsRoot,
            scrollButtons: classes.scrollButtons
        }}
    >
        {tabs.map((el, index) => (
            <Tab
                key={index}
                classes={{
                    root: classes.tabRoot
                }}
                label={el.name}
            />
        ))}
    </Tabs>
);

TreeInfoPanelTabs.propTypes = {
    tab: PropTypes.number.isRequired,
    tabs: PropTypes.array.isRequired,
    handleTabChange: PropTypes.func.isRequired
};

export default withStyles(styles)(TreeInfoPanelTabs);
