import orderBy from "lodash.orderby";
// redux-saga
import {
    call,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
    delay
} from "redux-saga/effects";
// Redux factory
import createFetchRedux from "../createFetchRedux";
// Redux saga helper
import { fetchEntity, fetchEntityData } from "../../store/sagaHelpers";
// Fetch service
import fetch from "services/fetch";
// Api configuration
import api from "api";
// Redux modules
import { openSnackbar } from "../snackbar/open";
import { TAB_VERSIONS, updateTab } from "../tab/change";
import { FETCH_VERSIONS } from "../versions/versions";

// Reducer name
const reducerName = "projects";
const { reducer, actions, actionTypes, selectors } = createFetchRedux(
    reducerName
);

export default reducer;

// Action types
export const FETCH_PROJECTS = actionTypes.FETCH;
export const FETCH_PROJECT = actionTypes.FETCH_ONE;
export const ADD_PROJECT = `${reducerName}/ADD_PROJECT`;
export const EDIT_PROJECT = `${reducerName}/EDIT_PROJECT`;
export const DELETE_PROJECT = `${reducerName}/DELETE_PROJECT`;
export const UPDATE_CONSTRAINTS = `${reducerName}/UPDATE_CONSTRAINTS`;
export const UPDATE_HIERARCHY_SELECTION = `${reducerName}/UPDATE_HIERARCHY_SELECTION`;
export const RUN_SIMULATION = `${reducerName}/RUN_SIMULATION`;

// Actions
export const fetchProjects = actions.fetch;
export const fetchProject = actions.fetchOne;
export const resetProjects = actions.reset;

export function editProject(project) {
    return {
        type: EDIT_PROJECT,
        payload: project
    };
}

export function addProject(project) {
    return {
        type: ADD_PROJECT,
        payload: project
    };
}

export function deleteProject(id) {
    return {
        type: DELETE_PROJECT,
        payload: id
    };
}

export function updateHierarchySelection(id, data) {
    return {
        type: UPDATE_HIERARCHY_SELECTION,
        payload: {
            id,
            data
        }
    };
}

export function updateConstraints(id, data) {
    return {
        type: UPDATE_CONSTRAINTS,
        payload: {
            id,
            data
        }
    };
}

export function runSimulation(id, data, history) {
    return {
        type: RUN_SIMULATION,
        payload: {
            id,
            data,
            history
        }
    };
}

// Technically its a project update :)
export function updateAnalysisLevel(updatedProject) {
    return {
        type: EDIT_PROJECT,
        payload: updatedProject
    };
}

export const projectsMany = {
    ...actions
};

export const projectsOne = {
    ...actions,
    receive: actions.receiveOne
};

export const projectsEmpty = {
    ...actions,
    receive: actions.receiveEmpty
};

// Selectors
export const selectProjects = state => {
    let res = selectors.selectReduxData(state) || [];
    return orderBy(res, ["createdOn"], ["desc"]);
};

export const selectProjectId = selectors.selectReduxId;

export const selectProjectById = (state, ownProps) => {
    const projects = selectProjects(state);
    let projectId = null;
    if (!ownProps || !ownProps.match) {
        projectId = selectProjectId(state);
    } else {
        projectId = parseInt(ownProps.match.params.projectId, 10);
    }
    return projects.find(el => el.id === projectId);
};

export const selectStoresFromProject = project => {
    if (!project) {
        return null;
    }
    let stores = project.stores;
    if (Array.isArray(stores)) {
        stores = stores.map(el => {
            if (typeof el === "string") {
                return {
                    storeId: el
                };
            } else return el;
        });
    }
    return stores;
};

export const selectAnalysisLevel = (state, ownProps) => {
    const project = selectProjectById(state, ownProps);
    return project && project.analysisLevel && project.analysisLevel !== null
        ? project.analysisLevel
        : "";
};

export const selectProjectsError = selectors.selectReduxError;

export const selectProjectsLoading = selectors.selectReduxLoading;

// Sagas
const fetchProjectsEntity = fetchEntity.bind(
    null,
    projectsMany,
    api["projects.get.all"]
);

export function* watchProjects() {
    yield takeLatest(FETCH_PROJECTS, fetchProjectsEntity);
}

export function* watchAddEditProject() {
    while (true) {
        // Destructure payload (project) from edit project action
        const { payload } = yield take([ADD_PROJECT, EDIT_PROJECT]);
        const { id } = payload;
        // Request action
        yield put(projectsMany.request());
        // API call options (edit if we have project id)
        const { url, type, successful, error, data: defaultData } = id
            ? api["project.edit"](id)
            : api["project.add"];
        const data = id ? payload : { ...defaultData, ...payload };
        // Attempt to make the request
        try {
            // Result of api call
            const result = yield call(fetch, url, type, data);
            // Update projects list with updated project
            yield put(projectsMany.receiveOne(result));
            // Put to snackbar
            yield put(openSnackbar(successful, "success"));
        } catch (e) {
            console.error(e);
            yield put(projectsMany.error(error));
            // Put to snackbar
            yield put(openSnackbar(error, "error"));
        }
    }
}

const fetchProjectEntity = fetchEntity.bind(
    null,
    projectsOne,
    api["project.get.id"]
);

export function* watchProject() {
    yield takeEvery(FETCH_PROJECT, fetchProjectEntity);
}

export function* watchDeleteProject() {
    while (true) {
        const { payload: id } = yield take(DELETE_PROJECT);
        const { url, type, error } = api["project.delete"](id);
        try {
            yield call(fetch, url, type);
            let projectList = yield select(selectProjects);
            projectList = projectList.filter(el => el.id !== id);
            yield put(projectsMany.receive(projectList));
        } catch (e) {
            yield put(projectsMany.error(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}

export function* watchProjectUpdateConstraints() {
    while (true) {
        const { payload } = yield take(UPDATE_CONSTRAINTS);
        const { id, data } = payload;
        yield call(
            fetchEntityData,
            projectsOne,
            api["project.constraints"](id),
            data
        );
    }
}

export function* watchProjectHierarchySelection() {
    while (true) {
        const { payload } = yield take(UPDATE_HIERARCHY_SELECTION);
        const { id, data } = payload;
        const { url, type, error } = api[
            "project.productStoreHierarchySelection"
        ](id);
        yield put(actions.request());
        try {
            const result = yield call(fetch, url, type, data);
            const projects = yield select(selectProjects);
            const currentProject = projects.find(el => String(el.id) === id);
            const updatedProject = {
                ...currentProject,
                productStoreHierarchySelection: result
            };
            yield put(actions.receiveOne(updatedProject));
        } catch (e) {
            yield put(actions.error(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}

export function* watchProjectRunSimulation() {
    while (true) {
        const { payload } = yield take(RUN_SIMULATION);
        const { id, data, history } = payload;
        yield call(
            fetchEntityData,
            projectsEmpty,
            api["project.submit"](id),
            data
        );
        yield put(updateTab(TAB_VERSIONS));
        history.push(`/project/${id}/versions`);
        yield delay(1000);
        yield put({ type: FETCH_VERSIONS, payload: id });
    }
}
