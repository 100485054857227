import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import IconButtonA11y from "../../uiComponents/Button/IconButtonA11y";
import SettingsIcon from "../../styles/icons/settings";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";

import { TAB_PROJECT } from "redux/modules/tab/change";

import FindPermissions from "helpers/FindPermissions";
import Content from "helpers/Content";
import { ConfigContext } from "utils/ConfigProvider";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    containerButton: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 30
    },
    row: {
        flexBasis: "20%"
    },
    rowMiddle: {
        flexBasis: "35%",
        marginLeft: 15,
        marginRight: 15
    },
    alignRight: {
        textAlign: "right"
    },
    textSecondary: {
        color: theme.status.grey.greydivs
    },
    textSubheading: {
        marginBottom: 30,
        // textTransform: "capitalize"
    },
    chip: {
        marginRight: 10,
        marginTop: 10
    },
    button: {
        float: "right"
    },
    icon: {
        fontSize: 18
    },
    paper: {
        padding: 30,
        marginBottom: 30,
        "&:hover $deleteButton": {
            opacity: 1,
            pointerEvents: "auto"
        }
    },
    deleteButton: {
        opacity: 0,
        pointerEvents: "none",
        transition: "opacity 200ms"
    }
});

class ProjectListItem extends Component {
    static propTypes = {
        project: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            source: PropTypes.string,
            sourceId: PropTypes.number,
            collaborators: PropTypes.array,
            byProject: PropTypes.bool
        }).isRequired,
        collaboratorsNameMap: PropTypes.object.isRequired,
        handleClickEdit: PropTypes.func.isRequired,
        handleClickDelete: PropTypes.func.isRequired,
        updateTab: PropTypes.func.isRequired
    };

    render() {
        const {
            project,
            collaboratorsNameMap,
            classes,
            t,
            handleClickEdit,
            handleClickDelete,
            updateTab
        } = this.props;
        const {
            name,
            description,
            createdOn,
            collaborators,
            owner,
            id
        } = project;
        const headings = [
            "project.name",
            "common.description",
            "project.collaborators",
            "common.date_created"
        ];
        const content = [
            { primary: name },
            { primary: description },
            null,
            {
                primary: Content.formatDate(createdOn),
                secondary: Content.formatTime(createdOn, "hh:mm:ss a")
            }
        ];
        return (
            <Paper className={classes.paper}>
                <div className={classes.container}>
                    {headings.map((elem, idx) => (
                        <div
                            key={`projectRow${idx}`}
                            className={classnames(
                                idx === 1 ? classes.rowMiddle : classes.row,
                                idx === 3 ? classes.alignRight : null
                            )}
                        >
                            <Typography
                                variant="h4"
                                className={classes.textSubheading}
                            >
                                {t(elem)}
                            </Typography>
                            {idx !== 2 && (
                                <Typography>{content[idx].primary}</Typography>
                            )}
                            {idx !== 2 && content[idx].secondary && (
                                <Typography className={classes.textSecondary}>
                                    {content[idx].secondary}
                                </Typography>
                            )}
                            {idx === 2 &&
                                collaborators &&
                                collaborators.map((name, index) => (
                                    <Chip
                                        key={index}
                                        label={
                                            collaboratorsNameMap[name]
                                                ? collaboratorsNameMap[name]
                                                : name
                                        }
                                        className={classes.chip}
                                    />
                                ))}
                        </div>
                    ))}
                </div>
                <div className={classes.containerButton}>
                    <div>
                        {FindPermissions.isOwnerOfThisProject(
                            owner,
                            this.context.userInfo.screenName
                        ) && (
                            <Fragment>
                                <IconButtonA11y
                                    ariaLabel={t("projects.settings_link")}
                                    onClick={handleClickEdit}
                                >
                                    <SettingsIcon className={classes.icon} />
                                </IconButtonA11y>
                                <IconButtonA11y
                                    ariaLabel={t("projects.delete")}
                                    className={classes.deleteButton}
                                    onClick={handleClickDelete}
                                >
                                    <DeleteIcon />
                                </IconButtonA11y>
                            </Fragment>
                        )}
                    </div>
                    <Link
                        onClick={() => {
                            updateTab(TAB_PROJECT);
                        }}
                        to={"/project/" + id}
                    >
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                        >
                            {t("project.view_project")}
                        </Button>
                    </Link>
                </div>
            </Paper>
        );
    }
}

ProjectListItem.contextType = ConfigContext;

export default withTranslation()(withStyles(styles)(ProjectListItem));
