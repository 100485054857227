import React from "react";
import classNames from "classnames";
import { DropTarget } from "react-dnd";

// MUI
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { ItemTypes } from "./ReassignDragConstants";
import { UNASSIGNED } from "constants/static";
import { USER_PREFIX } from "../../redux/modules/tree/savedset";

// Dnd type
const itemType = ItemTypes.PRODUCT_ITEM;

// DnD drop specification for needunit
const itemSpec = {
    canDrop(props, monitor) {
        const item = monitor.getItem();
        const { needunit } = props;
        const { node, products } = needunit;
        // product must not exist in needunit already
        const hasItem = Boolean(products.find(el => el === item.id));
        // we cant drop into unassigned
        const canDrop = !hasItem && node !== UNASSIGNED;
        return canDrop;
    },
    drop(props) {
        return {
            id: props.needunit.node
        };
    }
};

// DnD drop collect function
function itemCollect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}

const ReassignNeedUnit = ({
    needunit,
    handleClickNeedUnit,
    handleClickEditNeedUnit,
    classes,
    clusterSelected,
    savedSetNodeNames,
    connectDropTarget,
    isOver,
    canDrop,
    t
}) =>
    connectDropTarget(
        <li
            onClick={handleClickNeedUnit(needunit.node)}
            className={classNames(classes.listItem, classes.listItemNeedUnit, {
                [classes.listItemTarget]: needunit.node !== UNASSIGNED,
                [classes.listItemSelected]: needunit.node === clusterSelected,
                [classes.listItemOver]: isOver && canDrop,
                [classes.listItemUserDefined]:
                    needunit.node.indexOf(USER_PREFIX) !== -1
            })}
        >
            <span
                className={classNames(
                    classes.column,
                    classes.columnFirst,
                    classes.overflow,
                    classes.needUnitName
                )}
            >
                {needunit.node !== UNASSIGNED ? savedSetNodeNames[needunit.node] || needunit.node : t("tree.unassigned")}
            </span>
            <span className={classNames(classes.column, classes.columnSm)}>
                <IconButton
                    aria-label="Edit"
                    aria-haspopup="true"
                    className={classNames(classes.editNeedUnitIcon, {
                        [classes.editNeedUnitIconActive]:
                            needunit.node !== UNASSIGNED
                    })}
                    onClick={handleClickEditNeedUnit(needunit.node)}
                >
                    <MoreVertIcon />
                </IconButton>
            </span>

            <span
                className={classNames(
                    classes.column,
                    classes.columnSm,
                    classes.textAlignRight,
                    classes.productCount
                )}
            >
                {needunit.products.length}
            </span>
        </li>
    );

export default DropTarget(itemType, itemSpec, itemCollect)(ReassignNeedUnit);
