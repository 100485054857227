import { takeLatest, select, put } from "redux-saga/effects";

import { UNASSIGNED } from "constants/static";
import {
    updateNodeProducts,
    selectSavedSetNeedUnitsWithUserDefined
} from "../tree/savedset";

// Action types
const SET_ORDER_BY = "reassign/SET_ORDER_BY";
const SET_DIRECTION = "reassign/SET_DIRECTION";
const SET_SELECTED_PRODUCTS = "reassign/SET_SELECTED_PRODUCTS";
const SET_DRAGGING_TASK_ID = "reassign/SET_DRAGGING_TASK_ID";
export const SET_CLUSTER_SELECTED = "reassign/SET_CLUSTER_SELECTED";
const REASSIGN_DRAG_END = "reassign/DRAG_END";
const REASSIGN_DRAG_START = "reassign/DRAG_START";
const REASSIGN_TO_TARGET = "reassign/REASSIGN_TO_TARGET";
const SET_LAST_SELECTED_INDEX = "reassign/SET_LAST_SELECTED_INDEX";
const SET_HAS_DRAGGED = "reassign/SET_HAS_DRAGGED";

// Actions
export function setOrderBy(orderBy) {
    return {
        type: SET_ORDER_BY,
        payload: orderBy
    };
}

export function setOrderDirection(direction) {
    return {
        type: SET_DIRECTION,
        payload: direction
    };
}

export function setSelectedProducts(selectedProducts) {
    return {
        type: SET_SELECTED_PRODUCTS,
        payload: selectedProducts
    };
}

export function setLastSelectedIndex(index) {
    return {
        type: SET_LAST_SELECTED_INDEX,
        payload: index
    };
}

export function setDraggingTaskId(id) {
    return {
        type: SET_DRAGGING_TASK_ID,
        payload: id
    };
}

export function setClusterSelected(clusterSelected) {
    return {
        type: SET_CLUSTER_SELECTED,
        payload: clusterSelected
    };
}

export function reassignToTarget(targetId) {
    return {
        type: REASSIGN_TO_TARGET,
        payload: {
            targetId
        }
    };
}

export function reassignDragStart(sourceId) {
    return {
        type: REASSIGN_DRAG_START,
        payload: {
            sourceId
        }
    };
}

export function reassignDragEnd(sourceId, targetId) {
    return {
        type: REASSIGN_DRAG_END,
        payload: {
            sourceId,
            targetId
        }
    };
}

export function setHasDragged() {
    return {
        type: SET_HAS_DRAGGED
    };
}

// Selectors
export function selectOrderBy(state) {
    return state.reassign.orderBy;
}
export function selectOrderDirection(state) {
    return state.reassign.order;
}
export function selectReassignSelectedProducts(state) {
    return state.reassign.selectedProducts;
}
export function selectLastSelectedIndex(state) {
    return state.reassign.lastSelectedIndex;
}
export function selectDraggingTaskId(state) {
    return state.reassign.draggingTaskId;
}
export function selectClusterSelected(state) {
    return state.reassign.clusterSelected;
}
export function selectHasDragged(state) {
    return state.reassign.hasDragged;
}

// Initial state
const initialState = {
    order: "asc",
    orderBy: "brand",
    selectedProducts: [],
    draggingTaskId: null,
    clusterSelected: null,
    lastSelectedIndex: 0,
    hasDragged: false
};

// Reassign reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_CLUSTER_SELECTED:
            return {
                ...state,
                clusterSelected: action.payload
            };
        case SET_DRAGGING_TASK_ID:
            return {
                ...state,
                draggingTaskId: action.payload
            };
        case SET_SELECTED_PRODUCTS:
            return {
                ...state,
                selectedProducts: action.payload
            };
        case SET_LAST_SELECTED_INDEX:
            return {
                ...state,
                lastSelectedIndex: action.payload
            };
        case SET_ORDER_BY:
            return {
                ...state,
                orderBy: action.payload
            };
        case SET_DIRECTION:
            return {
                ...state,
                order: action.payload
            };
        case SET_HAS_DRAGGED:
            return {
                ...state,
                hasDragged: true
            };
        default:
            return state;
    }
}

// Sagas
export function* updateAfterDragStart({ payload }) {
    const { sourceId } = payload;
    const selectedProducts = yield select(selectReassignSelectedProducts);
    const selected = selectedProducts.some(el => el === sourceId);

    // if dragging an item that is not selected - unselect all items and replace with current id
    if (!selected) {
        yield put(setSelectedProducts([sourceId]));
    }

    yield put(setDraggingTaskId(sourceId));
}

function* combineProductsWithNeedUnit(id, targetId) {
    // cannot drag to unassigned
    if (targetId === "") return;
    if (targetId === UNASSIGNED) {
        return;
    }
    // Select current selected cluster/needunit
    const clusterSelected = yield select(selectClusterSelected);
    let selectedProducts = yield select(selectReassignSelectedProducts);
    let savedSetNeedUnits = yield select(
        selectSavedSetNeedUnitsWithUserDefined
    );
    let targetProducts = savedSetNeedUnits.reduce(
        (prev, curr) => (curr.node === targetId ? curr.products : prev),
        []
    );

    // If there are no selected products
    // we should just move a single product
    // if no id, we can skip
    if (selectedProducts.length === 0 && id === "") return;
    if (selectedProducts.length === 0) {
        selectedProducts = [id];
    }

    let payload = {
        // Add selected products to the target need unit
        [targetId]: [...targetProducts, ...selectedProducts]
    };

    // remove products from source
    // handle special case for unassigned
    if (clusterSelected !== UNASSIGNED) {
        const sourceProducts = savedSetNeedUnits.reduce(
            (prev, curr) =>
                curr.node === clusterSelected ? curr.products : prev,
            []
        );
        payload = {
            ...payload,
            [clusterSelected]: sourceProducts.filter(
                el => selectedProducts.indexOf(el) === -1
            )
        };
    }

    // Dispatch to update node prodcuts
    yield put(updateNodeProducts(payload));
    // Clear selected products
    yield put(setSelectedProducts([]));
}

export function* updateReassignToTarget({ payload }) {
    const { targetId } = payload;
    yield combineProductsWithNeedUnit("", targetId);
}

export function* watchReassignToTarget() {
    yield takeLatest(REASSIGN_TO_TARGET, updateReassignToTarget);
}

export function* updateAfterDragEnd({ payload }) {
    const { sourceId, targetId } = payload;
    yield put(setHasDragged());
    if (sourceId && targetId) {
        yield combineProductsWithNeedUnit(sourceId, targetId);
    }
    yield put(setDraggingTaskId(null));
}

export function* watchReassignDragStart() {
    yield takeLatest(REASSIGN_DRAG_START, updateAfterDragStart);
}

export function* watchReassignDragEnd() {
    yield takeLatest(REASSIGN_DRAG_END, updateAfterDragEnd);
}
