import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import CircleIcon from "@material-ui/icons/Brightness1Outlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PersonIcon from "@material-ui/icons/Person";
import Tooltip from "@material-ui/core/Tooltip";

const tooltipDelay = 1000;

const ReassignProductItem = ({
    product,
    isSelected,
    isGhosting,
    isUserAssigned,
    columnKeys,
    classes
}) => {
    return (
        <div
            className={classNames(
                {
                    [classes.productGridContainerGhost]: isGhosting
                },
                classes.productGridContainer
            )}
        >
            <Fragment>
                <Tooltip enterDelay={tooltipDelay} title={product.label}>
                    <span
                        className={classes.productGridItem}
                        style={{
                            paddingLeft: 15,
                            width: "55%"
                        }}
                    >
                        {product[columnKeys[0]]}
                    </span>
                </Tooltip>
                <span
                    className={classNames(
                        classes.productGridItem,
                        classes.productGridItemUserAssigned,
                        {
                            [classes.hidden]: !isUserAssigned
                        }
                    )}
                >
                    {"\u00A0"}
                    <PersonIcon
                        className={classNames(classes.icon, classes.personIcon)}
                    />
                </span>
                <span
                    className={classes.productGridItem}
                    style={{
                        width: "15%"
                    }}
                >
                    {product[columnKeys[1]]}
                </span>
                <Tooltip enterDelay={tooltipDelay} title={product.brand}>
                    <span
                        className={classes.productGridItem}
                        style={{ width: "15%" }}
                    >
                        {product[columnKeys[2]]}
                    </span>
                </Tooltip>
            </Fragment>
            <span
                className={classNames(
                    classes.productGridItem,
                    classes.productGridItemLast
                )}
                style={{
                    width: "10%"
                }}
            >
                {!isSelected && <CircleIcon className={classes.icon} />}
                {isSelected && (
                    <CheckCircleIcon
                        className={classNames(classes.icon, classes.iconCheck)}
                    />
                )}
            </span>
        </div>
    );
};

ReassignProductItem.defaultProps = {
    columnKeys: ["label", "productId", "brand"]
};

ReassignProductItem.propTypes = {
    columnKeys: PropTypes.arrayOf(PropTypes.string)
};

export default ReassignProductItem;
