// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

//Styles
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Redux
import { connect } from "react-redux";
import {
    deleteProject,
    resetProjects,
    fetchProjects,
    selectProjects,
    selectProjectsLoading,
    selectProjectsError
} from "redux/modules/projects/projects";
import {
    fetchCollaborators,
    selectCollaborators,
    selectCollaboratorsLoading,
    selectCollaboratorsError
} from "../redux/modules/collaborators/collaborators";
import { resetSubcategories } from "../redux/modules/subcategories/subcategories";
import { resetGroups } from "../redux/modules/subcategories/groups";
import { resetMetrics } from "../redux/modules/subcategories/metrics";
import { resetProducts } from "../redux/modules/products/products";
import { updateTab } from "../redux/modules/tab/change";
import { resetCurrentTree } from "../redux/modules/tree/current";
import { resetCurrentVersion } from "../redux/modules/versions/current";
import { resetDMTProductTree } from "../redux/modules/dmtProductTree/dmtProductTree";
import { resetDMTAttributeList } from "../redux/modules/dmtAttributeList/dmtAttributeList";

import ProjectListItem from "components/Project/ProjectListItem";
import PageTitle from "../components/PageTitle/PageTitle";
import ProjectAddEdit from "components/ProjectAddEdit/ProjectAddEdit";
import DialogComponent from "../uiComponents/Dialog/DialogComponent";
import Paginate from "../helpers/Paginate";

const styles = theme => ({
    button: {
        textTransform: "capitalize",
        textDecoration: "underline",
        fontSize: 12,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    text: {
        marginBottom: 15
    },
    textCentre: {
        textAlign: "center"
    }
});

const mapStateToProps = state => {
    return {
        projects: selectProjects(state),
        isLoadingProjects: selectProjectsLoading(state),
        errorProjects: selectProjectsError(state),
        collaborators: selectCollaborators(state),
        isLoadingCollaborators: selectCollaboratorsLoading(state),
        errorCollaborators: selectCollaboratorsError(state)
    };
};

const mapDispatchToProps = {
    fetchProjects,
    fetchCollaborators,
    deleteProject,
    resetProjects,
    resetProducts,
    resetSubcategories,
    resetGroups,
    resetMetrics,
    resetCurrentTree,
    resetCurrentVersion,
    resetDMTProductTree,
    resetDMTAttributeList,
    updateTab
};

class ProjectListPage extends Component {
    state = {
        isAddModalOpen: false,
        isDeleteModalOpen: false,
        currentProject: null
    };

    static propTypes = {
        projects: PropTypes.array,
        isLoadingProjects: PropTypes.bool,
        errorProjects: PropTypes.string,
        fetchProjects: PropTypes.func.isRequired,
        collaborators: PropTypes.array,
        isLoadingCollaborators: PropTypes.bool,
        errorCollaborators: PropTypes.string,
        fetchCollaborators: PropTypes.func.isRequired,
        deleteProject: PropTypes.func.isRequired,
        resetProjects: PropTypes.func.isRequired,
        resetProducts: PropTypes.func.isRequired,
        resetSubcategories: PropTypes.func.isRequired,
        resetGroups: PropTypes.func.isRequired,
        resetMetrics: PropTypes.func.isRequired,
        resetCurrentTree: PropTypes.func.isRequired,
        resetCurrentVersion: PropTypes.func.isRequired,
        updateTab: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.props.resetDMTAttributeList();
        this.props.resetDMTProductTree();
        this.props.resetProjects();
        this.props.resetProducts();
        this.props.resetGroups();
        this.props.resetMetrics();
        this.props.resetSubcategories();
        this.props.resetCurrentTree();
        this.props.resetCurrentVersion();
        this.props.fetchProjects();
        this.props.fetchCollaborators();
    }

    openProjectModal() {
        this.setState({ isAddModalOpen: true });
    }

    closeProjectModal() {
        this.setState({ isAddModalOpen: false });
    }

    setCurrentProject(currentProject) {
        this.setState({ currentProject });
    }

    handleOpenAddModal = () => {
        this.setCurrentProject(null);
        this.openProjectModal();
    };

    handleClickEdit = project => () => {
        this.setCurrentProject(project);
        this.openProjectModal();
    };

    handleCloseAddModal = () => {
        this.closeProjectModal();
    };

    openDeleteModal() {
        this.setState({
            isDeleteModalOpen: true
        });
    }

    closeDeleteModal() {
        this.setState({
            isDeleteModalOpen: false
        });
    }

    handleClickDelete = project => () => {
        this.setCurrentProject(project);
        this.openDeleteModal();
    };

    handleCloseDeleteModal = () => {
        this.closeDeleteModal();
    };

    handleDelete = () => {
        const { currentProject } = this.state;
        const { deleteProject } = this.props;
        deleteProject(currentProject && currentProject.id);
        this.closeDeleteModal();
    };

    render() {
        const {
            collaborators,
            projects,
            errorProjects,
            classes,
            t,
            updateTab
        } = this.props;
        const {
            isAddModalOpen,
            isDeleteModalOpen,
            currentProject
        } = this.state;

        let collaboratorsNameMap =
            collaborators &&
            collaborators.reduce((obj, item) => {
                obj[item.userName] = item.fullName;
                return obj;
            }, {});

        return (
            <div>
                <PageTitle
                    title={t("projects.projects")}
                    subtitle={t("projects.available_to_you")}
                    addButton={t("projects.add_new_project")}
                    onAddButtonClick={this.handleOpenAddModal}
                />
                {errorProjects && <span>{errorProjects}</span>}
                {projects && collaboratorsNameMap && (
                    <Paginate
                        list={projects}
                        render={paginatedList =>
                            paginatedList.map((item, i) => {
                                return (
                                    <ProjectListItem
                                        key={i}
                                        project={item}
                                        collaboratorsNameMap={
                                            collaboratorsNameMap
                                        }
                                        handleClickEdit={this.handleClickEdit(
                                            item
                                        )}
                                        handleClickDelete={this.handleClickDelete(
                                            item
                                        )}
                                        updateTab={updateTab}
                                    />
                                );
                            })
                        }
                    />
                )}
                {(projects && projects.length > 0) ||
                    (projects && (
                        <Typography
                            variant="body2"
                            align="center"
                            className={classes.body1}
                        >
                            {t("projects.no_projects_found")}
                        </Typography>
                    ))}
                <DialogComponent
                    isOpen={isAddModalOpen}
                    onClose={this.handleCloseAddModal}
                    title={
                        !!currentProject
                            ? t("project_edit_add.edit_title")
                            : t("project_edit_add.add_title")
                    }
                >
                    <ProjectAddEdit
                        isEdit={!!currentProject}
                        project={currentProject}
                        onClose={this.handleCloseAddModal}
                    />
                </DialogComponent>
                <DialogComponent
                    isOpen={isDeleteModalOpen}
                    onClose={this.handleCloseDeleteModal}
                    title={t("projects.delete")}
                    onSave={this.handleDelete}
                    saveLabel={t("common.delete")}
                    padding
                >
                    <Typography variant="body2" className={classes.textCentre}>
                        {t("projects.confirm_delete")}
                    </Typography>
                    {currentProject && currentProject.collaborators.length > 0 && (
                        <Typography
                            variant="body2"
                            className={classes.textCentre}
                        >
                            {t("projects.delete_warning_collaborators", {
                                num: currentProject.collaborators.length
                            })}
                        </Typography>
                    )}
                </DialogComponent>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withStyles(styles)(ProjectListPage)));
