import React from "react";
import PropTypes from "prop-types";
import { List } from "@emnos-legacy/ordering-wizard-components";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import ChipList from "../../components/ChipList/ChipList";
import { STATUS } from "../../redux/modules/dmtProductTree/dmtProductTree";

const styles = theme => ({
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonClear: {
        textTransform: "capitalize",
        textDecoration: "underline",
        fontSize: 12,
        fontWeight: 400,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    subheading: {
        paddingBottom: 15
    },
    paper: {
        position: "relative",
        marginBottom: 30
    },
    section: {
        position: "relative",
        padding: 10,
        minHeight: 60,
        backgroundColor: theme.status.clay[10]
    },
    buttonRow: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 20
    }
});

const DMTProductTree = ({
    t,
    classes,
    status,
    data,
    selectNode,
    deselectNode,
    deselectAllNodes,
    collapseNode,
    expandNode,
    selectedNodes,
    fetchDMTProductTree,
    projectId
}) => (
    <Paper elevation={0} className={classes.paper}>
        <Typography variant="h3" align="center" className={classes.subheading}>
            {t("product_import.select_your_products")}
        </Typography>
        <Paper elevation={0} className={classes.section}>
            {status === STATUS.ERROR && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body1" align="center">
                        {t("product_import.dmt_error")}
                    </Typography>
                    <Button
                        style={{
                            marginTop: 4,
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            fetchDMTProductTree(projectId);
                        }}
                    >
                        Refresh
                    </Button>
                </div>
            )}
            {status === STATUS.LOADING && (
                <div style={{ position: "absolute" }}>
                    <CircularProgress size={18} thickness={1} />
                </div>
            )}
            <List
                data={data}
                nodes={data.root.childrenIds}
                selectNode={selectNode}
                deselectNode={deselectNode}
                collapseNode={collapseNode}
                expandNode={expandNode}
            />
        </Paper>
        {selectedNodes.length > 0 && (
            <>
                <ChipList
                    items={selectedNodes}
                    labelKey="hierarchyName"
                    handleDelete={deselectNode}
                />
                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.buttonClear}
                        onClick={deselectAllNodes}
                    >
                        Clear
                    </Button>
                </div>
            </>
        )}
    </Paper>
);

DMTProductTree.propTypes = {
    selectedNodes: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    selectNode: PropTypes.func.isRequired,
    deselectNode: PropTypes.func.isRequired,
    deselectAllNodes: PropTypes.func.isRequired,
    expandNode: PropTypes.func.isRequired,
    collapseNode: PropTypes.func.isRequired,
    fetchDMTProductTree: PropTypes.func.isRequired
};

export default withTranslation()(withStyles(styles)(DMTProductTree));
