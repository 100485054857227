import i18n from "i18next";

i18n.init({
    whitelist: ["en_US", "de_DE", "ru_RU" ,"fr_FR"],
    fallbackLng: "en_US",
    interpolation: {
        escapeValue: false
    },
    debug: false
});

i18n.addResourceBundle("en_US", "translation", require("locales/en_US.json"));
i18n.addResourceBundle("de_DE", "translation", require("locales/de_DE.json"));
i18n.addResourceBundle("ru_RU", "translation", require("locales/ru_RU.json"));
i18n.addResourceBundle("fr_FR", "translation", require("locales/fr_FR.json"));

export default i18n;
