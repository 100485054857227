import React from "react";
import { connect } from "react-redux";
import {
    selectHierarchicalLineOption,
    selectColourByIndex,
    selectHighlightBrand
} from "redux/modules/tree/current";
import HierarchicalTree from "components/Tree/HierarchicalTree";

const mapStateToProps = state => ({
    hierarchicalLineOption: selectHierarchicalLineOption(state),
    isColourByIndex: selectColourByIndex(state),
    highlightBrand: selectHighlightBrand(state)
});

const HierarchicalTreeContainer = ({
    hierarchicalLineOption,
    isColourByIndex,
    highlightBrand,
    innerRef,
    ...otherProps
}) => (
    <HierarchicalTree
        lineOption={hierarchicalLineOption}
        isColourByIndex={isColourByIndex}
        highlightBrand={highlightBrand}
        innerRef={innerRef}
        {...otherProps}
    />
);

const HierarchalTreeConnected = connect(mapStateToProps)(
    HierarchicalTreeContainer
);

export default React.forwardRef((props, ref) => (
    <HierarchalTreeConnected {...props} innerRef={ref} />
));
