import React, { useState } from "react";
import { withTranslation } from "react-i18next";
// MUI
import { withStyles } from "@material-ui/core/styles";
import IconButtonA11y from "../../uiComponents/Button/IconButtonA11y";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import DialogComponent from "../../uiComponents/Dialog/DialogComponent";
import ParametersDialogContent from "components/Version/ParametersDialogContent";

const styles = theme => ({
    infoIcon: {
        fill: theme.status.grey.dark
    }
});

const ParametersInfo = ({ t, version, classes }) => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    return (
        <>
            <IconButtonA11y
                ariaLabel={t("common.information")}
                onClick={() => setDialogOpen(true)}
            >
                <InfoIcon className={classes.infoIcon} />
            </IconButtonA11y>
            <DialogComponent
                isOpen={isDialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth
                title={t("version.dialog_title")}
            >
                <ParametersDialogContent version={version} />
            </DialogComponent>
        </>
    );
};

ParametersInfo.defaultProps = {
    version: {}
};

export default withStyles(styles)(withTranslation()(ParametersInfo));
