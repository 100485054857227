import React from "react";
import { withTranslation } from "react-i18next";

// MUI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
    root: {
        paddingRight: 0,
        borderRadius: 0,
        textTransform: "none",
        fontWeight: 400,
        fontSize: 13,
        top: -9,
        "&:hover": {
            backgroundColor: "transparent"
        },
        "&:hover $label": {
            textDecoration: "underline"
        }
    },
    label: {
        justifyContent: "flex-end"
    }
};

function CreateNeedUnitButton({ t, classes, handleClick }) {
    return (
        <Button
            onClick={handleClick}
            classes={{
                root: classes.root,
                label: classes.label
            }}
        >
            {t("tree.add_new")}
        </Button>
    );
}

export default withStyles(styles)(withTranslation()(CreateNeedUnitButton));
