import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import HeaderButton from "../../uiComponents/Button/HeaderButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlined";
import WarningIcon from "@material-ui/icons/WarningOutlined";

const styles = theme => ({
    icon: {
        paddingRight: 10,
        fontSize: 20
    }
});

const SetConstraintsButton = props => {
    const { openDateRange, hasConstraints, classes, t } = props;
    return (
        <HeaderButton onClick={openDateRange} className={classes.button}>
            {hasConstraints && <CheckCircleIcon className={classes.icon} />}
            {!hasConstraints && <WarningIcon className={classes.icon} />}
            {t(
                hasConstraints
                    ? "project_details.constraints"
                    : "project_details.set_constraints"
            )}
        </HeaderButton>
    );
};

SetConstraintsButton.propTypes = {
    classes: PropTypes.object.isRequired,
    openDateRange: PropTypes.func.isRequired,
    hasConstraints: PropTypes.bool
};

export default withTranslation()(withStyles(styles)(SetConstraintsButton));
