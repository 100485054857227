import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

// MUI
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import CreateNeedUnitButton from "./CreateNeedUnitButton";
import DialogComponent from "uiComponents/Dialog/DialogComponent";

const styles = {
    formLabel: {
        marginBottom: 15
    }
};

class CreateNeedUnit extends Component {
    static propTypes = {
        handleSave: PropTypes.func.isRequired
    };

    state = {
        isCreateDialogOpen: false,
        needUnitName: ""
    };

    openDialog() {
        this.setState({
            isCreateDialogOpen: true
        });
    }

    closeDialog() {
        this.setState({
            needUnitName: "",
            isCreateDialogOpen: false
        });
    }

    saveNeedUnitName() {
        const { handleSave } = this.props;
        const { needUnitName } = this.state;
        handleSave(needUnitName);
        this.closeDialog();
    }

    handleClickCreate = () => {
        this.openDialog();
    };

    handleClickCancelDialog = () => {
        this.closeDialog();
    };

    handleClickSaveDialog = () => {
        this.saveNeedUnitName();
    };

    handleChangeNeedUnitName = event => {
        this.setState({
            needUnitName: event.target.value
        });
    };

    handleKeyDown = event => {
        const { needUnitName } = this.state;
        if (needUnitName.length > 0 && event.keyCode === 13) {
            this.saveNeedUnitName();
            this.closeDialog();
            event.preventDefault();
        }
    };

    render() {
        const { t, classes } = this.props;
        const { isCreateDialogOpen, needUnitName } = this.state;
        return (
            <Fragment>
                <CreateNeedUnitButton handleClick={this.handleClickCreate} />
                <DialogComponent
                    padding
                    isOpen={isCreateDialogOpen}
                    canSave={needUnitName.length > 0}
                    onSave={this.handleClickSaveDialog}
                    onClose={this.handleClickCancelDialog}
                    title={t("tree.add_new_dialog_title")}
                    maxWidth="sm"
                    centre
                >
                    <FormControl>
                        <FormLabel className={classes.formLabel}>
                            {t("tree.enter_name")}
                        </FormLabel>
                        <TextField
                            required
                            id="saved-set-name"
                            label={t("common.needunit")}
                            autoFocus
                            value={needUnitName}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={this.handleChangeNeedUnitName}
                            onKeyDown={this.handleKeyDown}
                        />
                    </FormControl>
                </DialogComponent>
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(CreateNeedUnit));
