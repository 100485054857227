const reducerName = "alerts";

// actions
const OPEN = `${alert}/OPEN`;
const CLOSE = `${alert}/CLOSE`;

// reducer
const initialState = {
    open: false,
    title: "",
    message: ""
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN:
            return {
                ...state,
                title: action.payload.title,
                message: action.payload.message,
                open: true
            };
        case CLOSE:
            return {
                ...state,
                open: false
            };
        default:
            return state;
    }
}

// actions
export function closeAlert() {
    return {
        type: CLOSE
    };
}

export function openAlert(title, message) {
    return {
        type: OPEN,
        payload: {
            title,
            message
        }
    };
}

// Selectors
export const selectAlertTitle = state => state[reducerName].title;
export const selectAlertMessage = state => state[reducerName].message;
export const selectAlertOpen = state => state[reducerName].open;
