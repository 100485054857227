import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectVersionById } from "redux/modules/versions/versions";
import { getStepsByVersion } from "redux/modules/jobs/jobs";

import VersionProgress from "./VersionProgress";

const mapStateToProps = (state, ownProps) => {
    const version = selectVersionById(state, ownProps.versionId);
    const steps = getStepsByVersion(state, version);
    return {
        inProgress: version && version.status === "IN_PROGRESS" ? true : false,
        steps
    };
};

const VersionProgressContainer = ({
    projectId,
    versionId,
    ...remainingProps
}) => <VersionProgress {...remainingProps} />;

VersionProgressContainer.propTypes = {
    projectId: PropTypes.string.isRequired,
    versionId: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(VersionProgressContainer);
