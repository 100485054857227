import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
//import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import { selectDMTStoreTreeSelectedNodes } from "redux/modules/dmtStoreTree/dmtStoreTree";
import DMTStoreTreeContainer from "components/DMTStoreTree/DMTStoreTreeContainer";
//import StoreAttributeListContainer from "components/AttributeList/StoreAttributeListContainer";

const StyledBackButton = withStyles({
    root: {
        textTransform: "capitalize",
        textDecoration: "underline",
        fontSize: 12,
        fontWeight: 400,
        "&:hover": {
            textDecoration: "underline"
        }
    }
})(Button);

const styles = theme => ({
    paperHierarchy: {
        padding: 30,
        marginBottom: 30
    },
    dmtButton: {
        marginTop: 30,
        display: "flex",
        justifyContent: "flex-end"
    }
});

class StoreImportHierarchyContainer extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        handleSave: PropTypes.func.isRequired,
        hasStores: PropTypes.bool.isRequired
    };

    render() {
        const {
            t,
            projectId,
            classes,
            selectedNodes,
            hasStores,
            handleSave,
            handleClickGoBack
        } = this.props;
        return (
            <>
                {hasStores && (
                    <StyledBackButton onClick={handleClickGoBack}>
                        {t("product_import.go_back")}
                    </StyledBackButton>
                )}
                <Paper className={classes.paperHierarchy}>
                    <DMTStoreTreeContainer projectId={projectId} />
                    {/*<Divider /><StoreAttributeListContainer projectId={projectId} />*/}
                    <div className={classes.dmtButton}>
                        <Button
                            disabled={
                                !selectedNodes || selectedNodes.length === 0
                            }
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        >
                            {t("common.continue")}
                        </Button>
                    </div>
                </Paper>
            </>
        );
    }
}

const mapStateToProps = state => ({
    selectedNodes: selectDMTStoreTreeSelectedNodes(state)
});

export default withTranslation()(
    connect(mapStateToProps)(withStyles(styles)(StoreImportHierarchyContainer))
);
