import superagent from "superagent";
import { config } from "config/dev.json";

/**
 * Extends session of the portal by calling the Liferay Global Object.
 * The ajax calls that are made from the assorter will not go via the portal stack resulting no information
 * to the portal if the user is active or not.
 * Liferay.Session._elapsed give the elapsed time from when the last user action.
 * Liferay.Session._getWarningTime is the warning limit defined.
 * When the ratio is about 80% (no logic in deriving this value), we call an extend session call which extends the Portal Session.
 */
export const extendSessionIfRequired = () => {
    if (window.self !== window.top) {
        // Confirms you are in iframe
        const session = parent.Liferay.Session; // eslint-disable-line no-restricted-globals
        if (session) {
            const warningTime = session._getWarningTime();
            const elapsedTime = session._elapsed;
            if ((elapsedTime / warningTime) * 100 > 80) {
                parent.Liferay.Session.extend(); // eslint-disable-line no-restricted-globals
                return true;
            }
        }
    }
    return false;
};

const _fetch = (url, type, data, attachment, args, responseType) => {
    extendSessionIfRequired();
    if (attachment) {
        return superagent[type](url)
            .attach(args.fileFieldName, attachment.file, attachment.file.name)
            .then(res => res.body);
    }

    if (responseType === "binary") {
        return superagent[type](url)
            .responseType("blob")
            .send(data)
            .then(res => res.body);
    }
    return superagent[type](url)
        .send(data)
        .then(res => res.body || res.text);
};

const fetch = (url, type, data, attachment, args, responseType) => {
    const prefixedUrl = `${config.api}${url}`;
    return _fetch(prefixedUrl, type, data, attachment, args, responseType);
};

export const fetchDMT = (url, type, data, attachment, args, responseType) => {
    const prefixedUrl = `${config.apiDMT}${url}`;
    return _fetch(prefixedUrl, type, data, attachment, args, responseType);
};

export default fetch;
