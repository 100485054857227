import React from "react";
import { connect } from "react-redux";

import { selectReassignProductAttributes } from "redux/modules/reassign/viewAttributes";

import TreeReassignProductAttributes from "./TreeReassignProductAttributes";

const mapStateToProps = state => ({
    data: selectReassignProductAttributes(state)
});

function TreeReassignProductAttributesContainer(props) {
    return <TreeReassignProductAttributes {...props} />;
}

export default connect(mapStateToProps)(TreeReassignProductAttributesContainer);
