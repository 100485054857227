// redux-saga
import {call, put} from "redux-saga/effects";

// Services
import fetch, {fetchDMT} from "services/fetch";

// Redux modules
import {openSnackbar} from "../modules/snackbar/open";

function getErrorMessage(errorKey, e) {
    if (e && e.hasOwnProperty("response") && e.response.statusCode === 401) {
        return "snack_bar_messages.invalid_session"
    }
    return errorKey;
}

function getApiOptions(options, params) {
    return typeof options === "function"
        ? Array.isArray(params)
            ? options(...params)
            : options(params)
        : options;
}

export function* fetchDMTEntity(entity, options, action, data) {
    const params = action && action.payload;
    const {url, type, error} = getApiOptions(options, params);
    yield put(entity.request());
    try {
        const result = yield call(fetchDMT, url, type, data);
        yield put(entity.receive(result, params));
    } catch (e) {
        yield put(entity.error(getErrorMessage(error, e)));
        console.error(e);
    }
}

export function* fetchEntity(entity, options, action, data) {
    const params = action && action.payload;
    const {url, type, error} = getApiOptions(options, params);
    yield put(entity.request());
    try {
        const result = yield call(fetch, url, type, data);
        yield put(entity.receive(result));
    } catch (e) {
        yield put(entity.error(getErrorMessage(error, e)));
        console.error(e);
    }
}

export function* fetchEntityList(entity, {url, type, error}) {
    yield put(entity.request());
    try {
        const result = yield call(fetch, url, type);
        yield put(entity.receive(result));
    } catch (e) {
        yield put(entity.error(getErrorMessage(error, e)));
        yield put(openSnackbar(getErrorMessage(error, e), "error"));
    }
}

export function* fetchEntityData(
    entity,
    {url, type, successful, error},
    data
) {
    yield put(entity.request());
    try {
        const result = yield call(fetch, url, type, data);
        yield put(entity.receive(result || data));
        yield put(openSnackbar(successful, "success"));
    } catch (e) {
        yield put(entity.error(getErrorMessage(error, e)));
        yield put(openSnackbar(getErrorMessage(error, e), "error"));
        console.error(e);
    }
}
