import { combineReducers } from "redux";

import collaborators from "redux/modules/collaborators/collaborators";
import retailerDates from "redux/modules/retailer/getDates";
import userInfoGet from "redux/modules/user/get";
import projects from "redux/modules/projects/projects";

import versions from "redux/modules/versions/versions";
import products from "redux/modules/products/products";
import importFromAssorter from "redux/modules/products/importFromAssorter";
import importFromFile from "redux/modules/products/importFromFile";
import versionCurrent from "redux/modules/versions/current";
import jobs from "redux/modules/jobs/jobs";

import snackbar from "redux/modules/snackbar/open";
import tab from "redux/modules/tab/change";
import tree from "redux/modules/tree/current";
import savedset from "redux/modules/tree/savedset";
import subcategories from "redux/modules/subcategories/subcategories";
import subcategoryGroups from "redux/modules/subcategories/groups";
import subcategoryMetrics from "redux/modules/subcategories/metrics";
import saveSubcategoryGroups from "redux/modules/subcategories/save";
import reassign from "redux/modules/reassign/reassign";
import config from "redux/modules/config";
import exportData from "redux/modules/tree/exportData";
import verifyStores from "redux/modules/stores/verify";
import saveStores from "redux/modules/stores/save";
import importProductTemplate from "redux/modules/products/importProductTemplate";
import importStoreTemplate from "redux/modules/stores/importStoreTemplate";
import dmtProductTree from "redux/modules/dmtProductTree/dmtProductTree";
import dmtStoreTree from "redux/modules/dmtStoreTree/dmtStoreTree";
import dmtAttributeList from "redux/modules/dmtAttributeList/dmtAttributeList";
import dmtStoreAttributeList from "redux/modules/dmtAttributeList/dmtStoreAttributeList";
import dmtRealization from "redux/modules/dmtProductTree/dmtRealization";
import dmtStoreRealization from "redux/modules/dmtStoreTree/dmtStoreRealization";
import exportProducts from "redux/modules/products/exportProducts";
import alerts from "redux/modules/alerts";

import feedbackQuestions from "redux/modules/feedbackQuestions";
import feedbackAnswers from "redux/modules/feedbackAnswers";

import dmtUserDefinedAttributeList from "redux/modules/dmtAttributeList/dmtUserDefinedAttributeList";
import dmtAttributeExport from "redux/modules/dmtAttributeExport/dmtAttributeExport";
import nodeProducts from "redux/modules/products/nodeProducts";

export default combineReducers({
    projects,
    subcategories,
    subcategoryGroups,
    subcategoryMetrics,
    saveSubcategoryGroups,
    collaborators,
    products,
    importFromAssorter,
    importFromFile,
    versions,
    versionCurrent,
    jobs,
    retailerDates,
    userInfoGet,
    snackbar,
    tab,
    tree,
    savedset,
    reassign,
    config,
    exportData,
    verifyStores,
    saveStores,
    importProductTemplate,
    importStoreTemplate,
    dmtProductTree,
    dmtStoreTree,
    dmtAttributeList,
    dmtStoreAttributeList,
    dmtRealization,
    dmtStoreRealization,
    exportProducts,
    alerts,
    feedbackQuestions,
    feedbackAnswers,
    dmtUserDefinedAttributeList,
    dmtAttributeExport,
    nodeProducts
});
