import React from "react";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.status.clay[20]
    },
    clickable: {
        cursor: "pointer",
        "&:focus": {
            backgroundColor: theme.status.clay[20]
        },
        "&:hover": {
            backgroundColor: theme.status.clay[40]
        },
        "&:active": {
            backgroundColor: theme.status.clay[40]
        }
    },
    rootActive: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.status.yellow[100],
        "&$clickable": {
            "&:focus": {
                backgroundColor: theme.status.yellow.dark
            },
            "&:hover": {
                backgroundColor: theme.status.yellow.dark
            },
            "&:active": {
                backgroundColor: theme.status.yellow.dark
            }
        }
    }
});

const CollaboratorChip = ({ classes, isActive, ...props }) => (
    <Chip
        classes={{
            root: isActive ? classes.rootActive : classes.root,
            clickable: classes.clickable
        }}
        {...props}
    />
);

export default withStyles(styles)(CollaboratorChip);
