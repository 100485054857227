export default function undo(reducer, reducerName, resetActions = [], historySize = 10 ) {
    const initialState = {
        previous: [],
        current: reducer(undefined, {})
    };
    const UNDO = `${reducerName}/UNDO`;
    return function (state = initialState, action) {
        const {current, previous} = state;
        switch (action.type) {
            case UNDO: {
                if (previous.length === 0) return state;
                const newPrevious = previous.slice(0, previous.length - 1);
                const newCurrent = previous[previous.length - 1];
                return {
                    previous: newPrevious,
                    current: newCurrent
                };
            }
            default: {
                const newCurrent = reducer(current, action);
                if (newCurrent === current) return state;
                let newPrevious = resetActions.includes(action.type) ? [] : [...previous, current]
                if (newPrevious.length > historySize ){
                    newPrevious = newPrevious.splice( newPrevious.length - historySize, newPrevious.length - 1);
                }
                return {
                    previous: newPrevious,
                    current: newCurrent
                };
            }
        }
    };
}
