import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";

const styles = {
    root: {
        position: "absolute",
        left: -16,
        top: -1,
        cursor: "pointer",
        opacity: 0,
        transition: "opacity 160ms linear"
    },
    rootActive: {
        opacity: 1,
        "&:hover": {
            opacity: 0.75,
            transition: "none"
        }
    },
    icon: {
        width: 16,
        height: 16
    }
};

const TreeReassignEditSortLabelButton = ({ handleClick, active, classes }) => {
    return (
        <div
            onClick={handleClick}
            className={classNames(classes.root, {
                [classes.rootActive]: active
            })}
        >
            <CreateIcon className={classes.icon} />
        </div>
    );
};

export default withStyles(styles)(TreeReassignEditSortLabelButton);
