import { createSelector } from "reselect";

import {
    selectOrderBy,
    selectOrderDirection
} from "redux/modules/reassign/reassign";
import { selectAllVersionsProductsDict } from "redux/modules/versions/versions";
import {
    selectUnassignedData,
    selectSavedSetNeedUnitsWithUserDefined,
    selectProductDict,
    selectProductsPerNode,
    getUnassignedNode
} from "./savedset";

import { UNASSIGNED } from "constants/static";

export const selectAllNeedUnits = createSelector(
    [selectUnassignedData, selectSavedSetNeedUnitsWithUserDefined],
    (unassignedData, savedSetNeedUnitsWithUserDefined) => {
        return [
            getUnassignedNode(unassignedData, savedSetNeedUnitsWithUserDefined),
            ...savedSetNeedUnitsWithUserDefined
        ].sort((a, b) => a.node - b.node);
    }
);

export function getUserAssigned(id, productsPerNode, node) {
    const list = productsPerNode[node];
    const hasTreeProducts = Array.isArray(list);
    const isUserDefinedNeedUnit = node !== UNASSIGNED && !hasTreeProducts;
    const isUserAssigned = hasTreeProducts && list.indexOf(id) === -1;
    return isUserDefinedNeedUnit || isUserAssigned;
}

export const createEnhancedProducts = (
    productData,
    allVersionProductsDict,
    productsPerNode
) => (productIds, node) => {
    return productIds.map(id => ({
        ...allVersionProductsDict[id],
        ...productData[id],
        isUserAssigned: getUserAssigned(id, productsPerNode, node)
    }));
};

// Selector for view of reassign product list
// Build a dictionary indexed by need unit name
// That contains list of enhanced products
export const selectCurrentProductDictUnsorted = createSelector(
    [
        selectAllNeedUnits,
        selectProductsPerNode,
        selectProductDict,
        selectAllVersionsProductsDict
    ],
    (allNeedUnits, productsPerNode, productData, allVersionsProductsDict) => {
        const getEnhancedProducts = createEnhancedProducts(
            productData,
            allVersionsProductsDict,
            productsPerNode
        );
        return allNeedUnits.reduce((prev, curr) => {
            prev[curr.node] = getEnhancedProducts(curr.products, curr.node);
            return prev;
        }, {});
    }
);

export const selectSavedSetProductList = createSelector(
    [selectCurrentProductDictUnsorted],
    currentProductDict =>
        Object.values(currentProductDict).reduce((prev, curr) => {
            prev = prev.concat(curr);
            return prev;
        }, [])
);

export function getProductValue(product, key, order) {
    const hasValue = product.hasOwnProperty(key);
    if (hasValue) {
        return product[key];
    } else {
        return order === "asc"
            ? Number.POSITIVE_INFINITY
            : Number.NEGATIVE_INFINITY;
    }
}

export const selectCurrentProductDict = createSelector(
    [selectCurrentProductDictUnsorted, selectOrderDirection, selectOrderBy],
    (currentProductDict, order, orderBy) => {
        for (let node in currentProductDict) {
            currentProductDict[node]
                .sort((a, b) =>
                    order === "desc"
                        ? getProductValue(b, orderBy, order) <
                          getProductValue(a, orderBy, order)
                            ? -1
                            : 1
                        : getProductValue(a, orderBy, order) <
                          getProductValue(b, orderBy, order)
                        ? -1
                        : 1
                )
                .sort();
        }
        return currentProductDict;
    }
);
