// Reselect
import { createSelector } from "reselect";
// Lodash
import orderBy from "lodash.orderby";
// redux-saga
import { call, put, select, take, takeLatest } from "redux-saga/effects";
// Redux saga helper
import { fetchEntity, fetchEntityData } from "../../store/sagaHelpers";
// Fetch service
import fetch from "services/fetch";
// Api configuration
import api from "api";
// Other redux selectors
import { selectCurrentVersionNum } from "./current";
import { initSavedSet, selectProductsPerNode } from "../tree/savedset";
// Redux modules
import { openSnackbar } from "../snackbar/open";
import {
    selectActiveTab,
    TAB_PROJECT,
    TAB_VERSIONS,
    updateTab
} from "redux/modules/tab/change";
// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "versions";
const { reducer, actions, actionTypes, selectors } = createFetchRedux(
    reducerName
);

export default reducer;

export const FETCH_VERSIONS = actionTypes.FETCH;
export const FETCH_VERSION = actionTypes.FETCH_ONE;
export const UPDATE_VERSION = `${reducerName}/UPDATE_VERSION`;
export const FETCH_UPDATE_VERSION = `${reducerName}/FETCH_UPDATE_VERSION`;
export const DELETE_VERSION = `${reducerName}/DELETE_VERSION`;
export const UPDATE_SAVED_SET = `${reducerName}/UPDATE_SAVED_SET`;
export const SAVE_SAVED_SET = `${reducerName}/SAVE_SAVED_SET`;

// For testing
export const RECEIVE_ONE = actionTypes.RECEIVE_ONE;

// Actions
export const UPDATE_CURRENT_TREE = "modules/tree/UPDATE_CURRENT_TREE";
// TODO: Duplicating this here as importing causes circular dependency issue
const UPDATE_CURRENT_SAVED_SET =
    "modules/tree/saved.set/UPDATE_CURRENT_SAVED_SET";

const updateCurrentSavedSet = savedSetId => ({
    type: UPDATE_CURRENT_SAVED_SET,
    payload: savedSetId
});

export const fetchVersions = actions.fetch;
export const fetchVersion = actions.fetchOne;

export function updateVersionDescription(id, version, data) {
    return {
        type: FETCH_UPDATE_VERSION,
        payload: {
            id,
            version,
            data
        }
    };
}

export function saveSavedSet(projectId, version, rangingSetId, data) {
    return {
        type: SAVE_SAVED_SET,
        payload: {
            id: projectId,
            version,
            rangingSetId,
            data
        }
    };
}

export function updateSavedSet(savedSetId, rangingSetId, data) {
    return {
        type: UPDATE_SAVED_SET,
        payload: {
            savedSetId,
            rangingSetId,
            data
        }
    };
}

export function deleteVersion(id, version) {
    return {
        type: DELETE_VERSION,
        payload: {
            id,
            version
        }
    };
}

export const versions = actions;
export const versionOne = {
    ...actions,
    receive: actions.receiveOne
};
export const versionPut = {
    ...actions,
    receive: actions.receiveEmpty
};

export const selectVersions = createSelector(
    [selectors.selectReduxData],
    data => orderBy(data, ["createdOn"], ["desc"])
);

export const selectVersionsLoading = selectors.selectReduxLoading;
export const selectVersionsError = selectors.selectReduxError;

export function selectVersion(state) {
    const versions = selectVersions(state);
    const versionNum = selectCurrentVersionNum(state);
    const res = versions.find(el => el.version === versionNum);
    return res;
}

export function selectVersionById(state, versionId) {
    const versions = selectVersions(state);
    return versions && versions.filter(el => el.id === versionId)[0];
}

export const selectTrees = createSelector(
    [selectVersion],
    version => version && version.trees
);

export const selectAllVersionsProductsDict = createSelector(
    [selectVersion],
    version => {
        if (
            version &&
            version.versionRequestInfo &&
            Array.isArray(version.versionRequestInfo.products)
        ) {
            const analysisLevel = version.versionRequestInfo.analysis_level;
            if (analysisLevel && analysisLevel.length > 0) {
                const first = version.versionRequestInfo.products[0];
                let isAdditionalProperty = false;
                if (
                    Object.keys(first["additionalAttributes"]).includes(
                        analysisLevel
                    )
                ) {
                    isAdditionalProperty = true;
                }
                // Compute the new products
                let reduce = version.versionRequestInfo.products.reduce(
                    (dict, product) => {
                        let analysisLevelValue = isAdditionalProperty
                            ? product["additionalAttributes"][analysisLevel]
                            : product[analysisLevel];
                        dict[analysisLevelValue] = {
                            productId: analysisLevelValue,
                            label: product["label"],
                            subcategory: product["subcategory"],
                            brand: product["brand"],
                            additionalAttributes: {
                                ...product["additionalAttributes"]
                            }
                        };
                        return dict;
                    },
                    {}
                );
                return reduce;
            } else {
                return version.versionRequestInfo.products.reduce(
                    (dict, product) => {
                        dict[product.productId] = product;
                        return dict;
                    },
                    {}
                );
            }
        } else {
            return null;
        }
    }
);

export function selectEnhancedProductsDict(state) {
    return selectAllVersionsProductsDict(state);
}

// Sagas
export const fetchVersionsEntity = fetchEntity.bind(
    null,
    versions,
    api["project.versions.get"]
);

export const fetchVersionEntity = fetchEntity.bind(
    null,
    versionOne,
    api["version.get"]
);

export function* watchVersions() {
    yield takeLatest(FETCH_VERSIONS, fetchVersionsEntity);
}

const defaultSavedSet = {
    name: "",
    needUnits: [],
    nodeNames: {}
};

function selectCurrentSavedSetId(state) {
    return parseInt(state.tree.savedSetId, 10);
}

function selectCurrentTreeId(state) {
    return state.tree.treeId;
}

export function selectSavedSetFromTree(state) {
    const treeId = selectCurrentTreeId(state);
    const savedSetId = selectCurrentSavedSetId(state);
    const version = selectVersion(state);
    if (!version || !Array.isArray(version.trees)) return defaultSavedSet;
    let result = version.trees.reduce((prev, curr) => {
        if (!curr) return prev;
        let isCurrentTree = curr.id === treeId;
        let hasSavedSets = Array.isArray(curr.saved_sets);
        if (hasSavedSets && isCurrentTree) {
            let savedSet = curr.saved_sets.find(
                el => el && el.id === savedSetId
            );
            if (savedSet) {
                prev = {
                    name: savedSet.name,
                    needUnits: savedSet.need_units,
                    nodeNames: savedSet.node_names || {}
                };
            }
        }
        return prev;
    }, defaultSavedSet);
    return result;
}

export function* watchSavedSet() {
    while (true) {
        yield take([UPDATE_CURRENT_TREE, UPDATE_CURRENT_SAVED_SET]);
        const version = yield select(selectVersion);
        if (version) {
            try {
                yield* createSavedSet(version);
            } catch (e) {
                console.error(e);
            }
        }
    }
}

export function* createSavedSet(result) {
    // now do saved set!
    const treeId = yield select(selectCurrentTreeId);
    if (typeof treeId === "undefined" || treeId === null) return false;
    const savedSetId = yield select(selectCurrentSavedSetId);
    const productsPerNode = yield select(selectProductsPerNode);
    // if no saved set, default to firsrt two children in tree
    const savedSet = result.trees.reduce((prev, curr) => {
        if (curr.id === treeId && curr.tree) {
            let savedSetName = defaultSavedSet.name;
            let needUnits = curr.tree.children.map(el => ({
                name: el.name,
                node: el.name,
                products: productsPerNode[el.name]
            }));
            let nodeNames = {};

            if (savedSetId) {
                let savedSet = curr.saved_sets.find(
                    el => el && el.id === savedSetId
                );
                if (savedSet) {
                    needUnits = savedSet.need_units.map(el => ({
                        name: el.name,
                        node: el.node,
                        products: el.products
                    }));
                    nodeNames = savedSet.node_names;
                    savedSetName = savedSet.name;
                }
            }
            return {
                ...prev,
                name: savedSetName,
                needUnits,
                nodeNames
            };
        }
        return prev;
    }, defaultSavedSet);
    yield put(initSavedSet(savedSet, result));
}

export function* watchVersion() {
    while (true) {
        const { payload } = yield take(FETCH_VERSION);
        const [id, version] = payload;
        const { url, type, error } = api["version.get"](id, version);
        yield put(versionOne.request());
        try {
            const result = yield call(fetch, url, type);
            yield put(versionOne.receive(result));
            yield* createSavedSet(result);
        } catch (e) {
            yield put(versionOne.error(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}

export function* watchUpdateVersion() {
    while (true) {
        const { payload } = yield take(FETCH_UPDATE_VERSION);
        const { id, version, data } = payload;
        yield call(
            fetchEntityData,
            versionPut,
            api["version.edit.description"](id, version),
            data
        );
    }
}

export function* watchDeleteVersion() {
    while (true) {
        const { payload } = yield take(DELETE_VERSION);
        const { id, version } = payload;
        const { url, type, error } = api["version.delete"](id, version);
        try {
            yield call(fetch, url, type);
            const versionList = yield select(selectVersions);
            const updatedList = versionList.filter(
                el => el.version !== version
            );
            if (updatedList.length === 0) {
                // if no versions remain after delete,
                // switch back to the project tab
                const currentTab = yield select(selectActiveTab);
                if (currentTab === TAB_VERSIONS) {
                    yield put(updateTab(TAB_PROJECT));
                }
            }
            yield put(versions.receive(updatedList));
        } catch (e) {
            yield put(versions.error(error));
            yield put(openSnackbar(error, "error"));
            console.error(e);
        }
    }
}

const appendSavedSet = (tree, savedSet, needUnits) => {
    if (tree && tree.saved_sets) {
        // Need units returned from save is an array
        // of node names only, so use the need units
        // we originally sent instead
        savedSet.need_units = needUnits.slice(0);
        return (tree.saved_sets = tree.saved_sets.concat([savedSet]));
    }
};

const replaceSavedSet = (tree, savedSet, needUnits, savedSetId) => {
    if (tree && tree.saved_sets) {
        // Get array of savedSets except the current one
        const savedSetWithoutCurrent = tree.saved_sets.filter(
            savedSet => savedSet && savedSet.id !== savedSetId
        );
        savedSet.need_units = needUnits.slice(0);
        return (tree.saved_sets = savedSetWithoutCurrent.concat([savedSet]));
    }
};

function* fetchUpdateSavedSet(
    url,
    type,
    data,
    successful,
    error,
    rangingSetId,
    currentSavedSetId
) {
    yield put(versionOne.request());
    try {
        const res = yield call(fetch, url, type, data);
        const currentVersion = yield select(selectVersion);
        const treeId = rangingSetId;
        // Get array without the current tree
        const treesWithoutCurrent = currentVersion.trees.filter(
            tree => tree.id !== treeId
        );
        // Current tree
        const currentTree = currentVersion.trees.filter(
            tree => tree.id === treeId
        )[0];
        // Create new tree from current tree
        const newTree = Object.assign({}, currentTree);
        // Append saved set to tree.saved_sets
        if (currentSavedSetId) {
            replaceSavedSet(newTree, res, data.need_units, currentSavedSetId);
        } else {
            appendSavedSet(newTree, res, data.need_units);
        }
        const updatedVersion = {
            ...currentVersion,
            trees: [...treesWithoutCurrent, newTree]
        };
        yield put(versionOne.receive(updatedVersion));
        const savedSetId = res.id;
        yield put(updateCurrentSavedSet(savedSetId));
        yield put(openSnackbar(successful, "success"));
    } catch (e) {
        // Put to snackbar
        console.error(e);
        yield put(versionOne.error(error));
        yield put(openSnackbar(error, "error"));
    }
}

export function* watchUpdateSavedSet() {
    while (true) {
        const { payload } = yield take(UPDATE_SAVED_SET);
        const { savedSetId, rangingSetId, data: payloadData } = payload;
        const { url, type, successful, error, data: defaultData } = api[
            "savedset.update"
        ](savedSetId);
        const data = { ...defaultData, ...payloadData };
        yield call(
            fetchUpdateSavedSet,
            url,
            type,
            data,
            successful,
            error,
            rangingSetId,
            savedSetId
        );
    }
}

export function* watchSaveSavedSet() {
    while (true) {
        const { payload } = yield take(SAVE_SAVED_SET);
        const { id, version, rangingSetId, data: payloadData } = payload;
        const { url, type, successful, error, data: defaultData } = api[
            "savedset.save"
        ](id, version, rangingSetId);
        const data = { ...defaultData, ...payloadData };
        yield call(
            fetchUpdateSavedSet,
            url,
            type,
            data,
            successful,
            error,
            rangingSetId
        );
    }
}
