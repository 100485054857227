import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { TAB_TREE, selectActiveTab, updateTab } from "redux/modules/tab/change";
import { fetchProject, selectProjectId } from "redux/modules/projects/projects";
import { fetchVersions, selectVersions } from "redux/modules/versions/versions";
import { fetchProducts } from "redux/modules/products/products";
import { resetSavedSet } from "redux/modules/tree/savedset";
import {
    selectCurrentVersionNum,
    updateCurrentVersion
} from "redux/modules/versions/current";
import {
    selectCurrentTreeId,
    selectCurrentSavedSetId,
    updateCurrentTree,
    updateCurrentSavedSet
} from "redux/modules/tree/current";

import TreeViewContainer from "components/Tree/TreeViewContainer";

const mapStateToProps = state => ({
    lastProjectId: String(selectProjectId(state)),
    versions: selectVersions(state),
    activeTab: selectActiveTab(state),
    // tree, version,  savedset
    currentVersionNum: selectCurrentVersionNum(state),
    currentTreeId: selectCurrentTreeId(state),
    currentSavedSetId: selectCurrentSavedSetId(state)
});

const mapDispatchToProps = {
    resetSavedSet,
    updateCurrentVersion,
    updateCurrentTree,
    updateCurrentSavedSet,
    updateTab,
    fetchProducts,
    fetchProject,
    fetchVersions
};

export class TreeViewPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        updateCurrentVersion: PropTypes.func.isRequired,
        updateCurrentTree: PropTypes.func.isRequired,
        updateCurrentSavedSet: PropTypes.func.isRequired,
        updateTab: PropTypes.func.isRequired,
        fetchProducts: PropTypes.func.isRequired,
        fetchProject: PropTypes.func.isRequired,
        fetchVersions: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.shouldUpdateCurrentTreeData() && this.updateCurrentTreeData();
        this.shouldFetch() && this.fetchData();
        this.shouldUpdateTab() && this.updateTab();
    }

    updateCurrentTreeData() {
        const {
            resetSavedSet,
            updateCurrentSavedSet,
            updateCurrentTree,
            updateCurrentVersion
        } = this.props;
        const { version, treeId, savedSetId } = this.props.match.params;
        resetSavedSet();
        updateCurrentVersion(parseInt(version, 10));
        updateCurrentTree(parseInt(treeId, 10));
        updateCurrentSavedSet(savedSetId);
    }

    shouldUpdateCurrentTreeData() {
        const { version, treeId, savedSetId } = this.props.match.params;
        const {
            currentVersionNum,
            currentTreeId,
            currentSavedSetId
        } = this.props;
        if (
            !currentSavedSetId ||
            !currentTreeId ||
            !currentSavedSetId ||
            currentSavedSetId !== savedSetId ||
            version !== String(currentVersionNum) ||
            treeId !== String(currentTreeId)
        ) {
            return true;
        }
        return false;
    }

    updateTab() {
        this.props.updateTab(TAB_TREE);
    }

    shouldUpdateTab() {
        const { activeTab } = this.props;
        return activeTab !== TAB_TREE;
    }

    fetchData() {
        const { fetchProject, fetchProducts, fetchVersions } = this.props;
        const { projectId } = this.props.match.params;
        fetchProject(projectId);
        fetchProducts(projectId);
        fetchVersions(projectId);
    }

    shouldFetch() {
        const { lastProjectId } = this.props;
        const { projectId } = this.props.match.params;
        const hasNeverFetched =
            typeof lastProjectId === "undefined" || lastProjectId === null;
        const hasChangedProjectId = projectId !== lastProjectId;
        return hasNeverFetched || hasChangedProjectId;
    }

    render() {
        const { projectId } = this.props.match.params;
        const {
            versions,
            currentVersionNum,
            currentTreeId,
            currentSavedSetId
        } = this.props;
        return (
            Array.isArray(versions) &&
            versions.length > 0 &&
            currentVersionNum &&
            currentTreeId &&
            currentSavedSetId && <TreeViewContainer projectId={projectId} />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TreeViewPage);
