import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
    resetDMTProductTreeRequest,
    fetchDMTProductTree,
    expandNode,
    collapseNode,
    selectNode,
    deselectNode,
    deselectAllNodes,
    selectDMTProductTree,
    selectDMTProductTreeError,
    selectDMTProductTreeStatus,
    selectDMTProductTreeSelectedNodes,
    selectDMTProductTreeRequestId
} from "redux/modules/dmtProductTree/dmtProductTree";

import DMTProductTree from "./DMTProductTree";

function DMTProductTreeContainer({
    fetchDMTProductTree,
    resetDMTProductTreeRequest,
    projectId,
    requestId,
    ...remainingProps
}) {
    useEffect(() => {
        return () => {
            resetDMTProductTreeRequest();
        };
    }, [resetDMTProductTreeRequest]);
    useEffect(() => {
        if (String(requestId) !== String(projectId)) {
            fetchDMTProductTree(projectId);
        }
    }, [projectId, requestId, fetchDMTProductTree]);

    return (
        <DMTProductTree
            fetchDMTProductTree={fetchDMTProductTree}
            projectId={projectId}
            {...remainingProps}
        />
    );
}

const mapStateToProps = state => ({
    data: selectDMTProductTree(state),
    selectedNodes: selectDMTProductTreeSelectedNodes(state),
    status: selectDMTProductTreeStatus(state),
    error: selectDMTProductTreeError(state),
    requestId: selectDMTProductTreeRequestId(state)
});

const mapDispatchToProps = {
    resetDMTProductTreeRequest,
    fetchDMTProductTree,
    expandNode,
    collapseNode,
    selectNode,
    deselectNode,
    deselectAllNodes
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DMTProductTreeContainer);
