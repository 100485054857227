import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
    fetchDMTStoreTree,
    expandNode,
    collapseNode,
    selectNode,
    deselectNode,
    deselectAllNodes,
    selectDMTStoreTree,
    selectDMTStoreTreeError,
    selectDMTStoreTreeStatus,
    selectDMTStoreTreeSelectedNodes,
    selectDMTStoreTreeRequestId
} from "redux/modules/dmtStoreTree/dmtStoreTree";

import DMTStoreTree from "./DMTStoreTree";

function DMTStoreTreeContainer({
    fetchDMTStoreTree,
    projectId,
    requestId,
    ...remainingProps
}) {
    useEffect(() => {
        if (String(requestId) !== String(projectId)) {
            fetchDMTStoreTree(projectId);
        }
    }, [projectId, requestId, fetchDMTStoreTree]);

    return <DMTStoreTree {...remainingProps} />;
}

const mapStateToProps = state => ({
    data: selectDMTStoreTree(state),
    selectedNodes: selectDMTStoreTreeSelectedNodes(state),
    status: selectDMTStoreTreeStatus(state),
    error: selectDMTStoreTreeError(state),
    requestId: selectDMTStoreTreeRequestId(state)
});

const mapDispatchToProps = {
    fetchDMTStoreTree,
    expandNode,
    collapseNode,
    selectNode,
    deselectNode,
    deselectAllNodes
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DMTStoreTreeContainer);
