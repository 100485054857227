import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";

import {
    selectColourByIndex,
    selectHierarchicalLineOption,
    setHierarchicalLineOption,
    setColourByIndex
} from "redux/modules/tree/current";

import HierarchicalOptionsPanel from "components/Tree/HierarchicalOptionsPanel";

const mapStateToProps = state => ({
    currentOptionId: selectHierarchicalLineOption(state),
    isColourByIndex: selectColourByIndex(state)
});

const mapDispatchToProps = {
    setHierarchicalLineOption,
    setColourByIndex
};

const styles = theme => ({
    icon: {
        color: theme.status.grey.dark
    }
});

export class TreeIconDots extends Component {
    static propTypes = {
        currentOptionId: PropTypes.string.isRequired,
        brands: PropTypes.object,
        version: PropTypes.object
    };

    state = {
        anchorEl: null,
        radioValue: "line.size.product",
        checkboxValue: false
    };

    componentDidMount() {
        this.setState({
            radioValue: this.props.currentOptionId,
            checkboxValue: this.props.isColourByIndex
        });
    }

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleRadioChange = event => {
        this.setState({ radioValue: event.target.value });
        this.props.setHierarchicalLineOption(event.target.value);
    };

    handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
        this.props.setColourByIndex(event.target.checked);
    };

    render() {
        const { brands, classes, t, version, exportToDMTNodes, data} = this.props;
        const { anchorEl, radioValue, checkboxValue } = this.state;
        const open = Boolean(anchorEl);
        return (
            <Fragment>
                <IconButton
                    onClick={this.handleClick}
                    aria-owns={open ? "simple-popper" : null}
                    aria-haspopup="true"
                    aria-label={t("common.more_options")}
                >
                    <MoreVertIcon className={classes.icon} />
                </IconButton>
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    <HierarchicalOptionsPanel
                        radioValue={radioValue}
                        checkboxValue={checkboxValue}
                        handleRadioChange={this.handleRadioChange}
                        handleCheckboxChange={this.handleCheckboxChange}
                        brands={brands}
                        version={version}
                        exportToDMTNodes={exportToDMTNodes}
                        data={data}
                    />
                </Popover>
            </Fragment>
        );
    }
}

TreeIconDots = withStyles(styles)(TreeIconDots);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(TreeIconDots));
