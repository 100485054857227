// redux-saga
import { take, call } from "redux-saga/effects";

// Api configuration
import api from "api";

// Redux saga helper
import { fetchEntityData } from "../../store/sagaHelpers";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Reducer name
const reducerName = "saveSubcategoryGroups";
const { reducer, actions, selectors } = createFetchRedux(reducerName);

export default reducer;

export const FETCH_SAVE_GROUPS = `${reducerName}/FETCH_SAVE_GROUPS`;

export function fetchSaveGroups(projectId, groups) {
    return {
        type: FETCH_SAVE_GROUPS,
        payload: {
            id: projectId,
            data: groups
        }
    };
}

export const saveSubcategoryGroups = actions;
export const selectSaveGroupsLoading = selectors.selectReduxLoading;

// Sagas
export function* watchSaveSubcategoryGroups() {
    while (true) {
        const { payload } = yield take(FETCH_SAVE_GROUPS);
        const { id, data } = payload;
        yield call(
            fetchEntityData,
            saveSubcategoryGroups,
            api["project.subcategories.group.save"](id),
            data
        );
    }
}
