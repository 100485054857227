import moment from "moment-timezone";

class Content {
    // specificFormat can passed as string if you want overwrite default format of date
    // @param timezone is not required to pass as moment.tz.guess() does the job guessing it from browser timezone.
    // @param timezone is used for unit testing.
    static formatDate(dateReceived, specificFormat, timezone) {
        const defaultDateFormat = "L";
        const getDate = () => {
            const momentDate = moment.tz(
                dateReceived,
                timezone !== undefined ? timezone : moment.tz.guess()
            );
            return momentDate.format(
                specificFormat !== undefined
                    ? specificFormat
                    : defaultDateFormat
            );
        };
        return dateReceived !== undefined ? getDate() : "";
    }
    static formatTime(dateReceived, specificFormat, timezone) {
        // specificFormat can passed as string if you want overwrite default format of time
        const defaultTimeFormat = "LT";
        const getTime = () => {
            return this.formatDate(
                dateReceived,
                specificFormat !== undefined
                    ? specificFormat
                    : defaultTimeFormat,
                timezone
            );
        };
        return dateReceived !== undefined ? getTime() : "";
    }
}

export default Content;
