import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

// MUI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
    container: {
        display: "flex",
        flexDirection: "column"
    },
    button: {
        borderRadius: 0,
        justifyContent: "flex-start",
        textTransform: "none"
    },
    buttonLabel: {
        fontWeight: 400
    }
};

const ReassignNeedUnitEditOptions = ({
    canDelete,
    handleClickDelete,
    handleClickRename,
    t,
    classes
}) => (
    <div className={classes.container}>
        <Button
            classes={{
                root: classes.button,
                label: classes.buttonLabel
            }}
            onClick={handleClickRename}
        >
            {t("common.rename")}
        </Button>
        {canDelete && (
            <Button
                classes={{
                    root: classes.button,
                    label: classes.buttonLabel
                }}
                onClick={handleClickDelete}
            >
                {t("common.delete")}
            </Button>
        )}
    </div>
);

ReassignNeedUnitEditOptions.propTypes = {
    canDelete: PropTypes.bool.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    handleClickRename: PropTypes.func.isRequired
};

export default withStyles(styles)(withTranslation()(ReassignNeedUnitEditOptions));
