import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ConfigContext } from "utils/ConfigProvider";
import { PAGE_STATE } from "./StoreImportContainer";
import { downloadStoreTemplate } from "../../redux/modules/stores/importStoreTemplate";

const styles = {
    button: {
        float: "right"
    },
    list: {
        padding: 15
    },
    hidden: {
        visibility: "hidden",
        width: 0
    }
};

export class StoreImportMenu extends Component {
    state = {
        anchorEl: null,
        isNewProdDialogOpen: false
    };

    handlePopoverClick = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleClickHierarchy = () => {
        this.handlePopoverClose();
        this.props.handleClickHierarchyImport();
    };

    handleClickTemplate = () => {
        this.handlePopoverClose();
        this.props.downloadStoreTemplate();
    };

    handleClickDelete = () => {
        this.handlePopoverClose();
        this.props.handleDeleteStores();
    };

    render() {
        const {
            isTemplateLoading,
            hierarchySelectionEnabled,
            isFileLoading,
            handleChangeFile,
            classes,
            hasStores,
            pageState,
            projectHasHierarchySelection,
            t
        } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <Fragment>
                <IconButton
                    aria-owns={open ? "projectAction-popper" : null}
                    aria-haspopup="true"
                    onClick={this.handlePopoverClick}
                    className={classes.button}
                >
                    <MoreVertIcon />
                </IconButton>
                <Popover
                    id="projectAction-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <List component="nav" className={classes.list}>
                        <Fragment>
                            {hierarchySelectionEnabled && (
                                <>
                                    <ListItem
                                        button
                                        dense
                                        divider
                                        disabled={
                                            pageState ===
                                            PAGE_STATE.HIERARCHY_IMPORT
                                        }
                                        onClick={this.handleClickHierarchy}
                                    >
                                        <ListItemText
                                            primary={
                                                hasStores &&
                                                projectHasHierarchySelection
                                                    ? t(
                                                          "product_import.hierarchy_reimport"
                                                      )
                                                    : t(
                                                          "product_import.hierarchy"
                                                      )
                                            }
                                        ></ListItemText>
                                    </ListItem>
                                </>
                            )}
                            <ListItem
                                style={{ width: 170 }}
                                button
                                dense
                                divider
                                disabled={isFileLoading}
                            >
                                <input
                                    className={classes.hidden}
                                    id="button-stores-file-selector"
                                    multiple
                                    onChange={handleChangeFile}
                                    type="file"
                                />

                                <label htmlFor="button-stores-file-selector">
                                    <ListItemText
                                        primary={t(
                                            "product_import.import_file"
                                        )}
                                    />
                                </label>
                            </ListItem>
                            <ListItem
                                button
                                dense
                                divider
                                disabled={!hasStores}
                                onClick={this.handleClickDelete}
                            >
                                <ListItemText primary={t("common.delete")} />
                            </ListItem>
                            <ListItem
                                button
                                dense
                                onClick={this.handleClickTemplate}
                            >
                                {isTemplateLoading ? (
                                    <CircularProgress size={18} thickness={1} />
                                ) : (
                                    <ListItemText
                                        primary={t(
                                            "product_import.download_template"
                                        )}
                                    />
                                )}
                            </ListItem>
                        </Fragment>
                    </List>
                </Popover>
            </Fragment>
        );
    }
}

StoreImportMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClickHierarchyImport: PropTypes.func.isRequired,
    handleChangeFile: PropTypes.func.isRequired,
    handleDeleteStores: PropTypes.func.isRequired,
    hasStores: PropTypes.bool.isRequired,
    hierarchySelectionEnabled: PropTypes.bool.isRequired,
    projectHasHierarchySelection: PropTypes.bool.isRequired,
    // from redux
    downloadStoreTemplate: PropTypes.func.isRequired
};

StoreImportMenu.contextType = ConfigContext;

const mapDispatchToProps = {
    downloadStoreTemplate
};
export default withTranslation()(
    withStyles(styles)(connect(null, mapDispatchToProps)(StoreImportMenu))
);
