import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../styles/MuiTheme";

const StyledVersionTrees = styled.div`
    position: relative;
    width: 100%;
    padding: 6px 0 6px 0;
    overflow: hidden;
    text-align: center;
    height: 100%;
`;

const StyledTrackWrapper = styled.div`
    position: relative;
    margin: 0;
    overflow: hidden;
    height: 100%;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }
`;

const trackWidth = (len, perPage) => (len / perPage) * 100;

const xOffset = (offset = 0, len = 1) => {
    const x = (offset / len) * 100 * -1;
    return `translateX(${x}%)`;
};

const StyledTrack = styled.div`
    position: relative;
    display: flex;
    width: ${props => trackWidth(props.len, props.perPage)}%;
    transform: ${props => xOffset(props.offset, props.len)};
    transition: transform 0.5s ease-in-out;
    height: 100%;
    > div {
        width: 100%;
        overflow: hidden;
    }
`;

export const StyledArrowButton = styled.div`
    position: absolute;
    top: 10px;
    left: ${props => (props.right ? "auto" : "10px")};
    right: ${props => (props.right ? "10px" : "auto")};
    width: 20px;
    height: 20px;
    background-color: ${theme.status.grey.white};
    border-radius: 50%;
    box-shadow: ${theme.shadows[4]};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    visibility: ${props => (props.visible ? "visible" : "hidden")};
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    z-index: 20;

    &::after {
        content: "";
        position: absolute;
        top: 5px;
        left: 8px;
        width: 0;
        height: 0;
        border-left: ${props =>
            props.right ? `5px solid ${theme.status.grey.dark}` : "none"};
        border-right: ${props =>
            props.right ? "none" : `5px solid ${theme.status.grey.dark}`};
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
    }
    &:hover {
        background-color: ${theme.status.yellow.dark};
    }
`;

class TreeInfoPanelCarousel extends Component {
    static propTypes = {
        perPage: PropTypes.number
    };

    static defaultProps = {
        perPage: 1
    };

    state = {
        offset: 0
    };

    clampOffset(offset) {
        const { perPage, children } = this.props;
        const maxOffset = children.length - perPage;
        if (offset < 0) return 0;
        if (offset >= maxOffset) return maxOffset;
        return offset;
    }

    updateOffset(newOffset) {
        const offset = this.clampOffset(newOffset);
        this.setState({ offset });
    }

    go(dir) {
        const { perPage } = this.props;
        const { offset } = this.state;
        this.updateOffset(offset + perPage * dir);
    }

    goLeft = () => {
        this.go(-1);
    };

    goRight = () => {
        this.go(1);
    };

    goTo = index => {
        const { perPage } = this.props;
        this.updateOffset(index * perPage);
    };

    render() {
        const { perPage, children, isOpenPanel } = this.props;
        const { offset } = this.state;
        return (
            <StyledVersionTrees>
                <StyledArrowButton
                    visible={!isOpenPanel}
                    disabled={offset === 0}
                    onClick={this.goLeft}
                />
                <StyledTrackWrapper>
                    <StyledTrack
                        len={children.length}
                        offset={offset}
                        perPage={isOpenPanel ? 3 : 1}
                    >
                        {children}
                    </StyledTrack>
                </StyledTrackWrapper>
                <StyledArrowButton
                    right
                    visible={!isOpenPanel}
                    disabled={offset === children.length - perPage}
                    onClick={this.goRight}
                />
            </StyledVersionTrees>
        );
    }
}

export default TreeInfoPanelCarousel;
