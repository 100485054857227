// redux-saga
import { call, take } from "redux-saga/effects";

// Redux factory
import createFetchRedux from "../createFetchRedux";

// Redux saga helper
import { fetchEntity } from "../../store/sagaHelpers";



// Api configuration
import api from "api";

// Reducer name
const configReducerName = "userInfoGet";
const { reducer, actions, selectors } = createFetchRedux(configReducerName);

export default reducer;

// Action types
export const FETCH_USER_INFO = "userInfoGet/FETCH_USER_INFO";

// Actions
export function fetchUserInfo() {
    return {
        type: FETCH_USER_INFO
    };
}

export const userInfo = actions;

// Selectors
export const selectUserInfo = selectors.selectReduxData;
export const selectErrorUserInfo = selectors.selectReduxError;
export const selectLoadingUserInfo = selectors.selectReduxLoading;


// Sagas
export const fetchEntityUserInfo = fetchEntity.bind(
    null,
    userInfo,
    api["user.info.get"]
);

export function* watchUserInfo() {
    yield take(FETCH_USER_INFO);
    yield call(fetchEntityUserInfo);
}
