import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Content from "helpers/Content";

const styles = theme => ({
    container: {
        padding: 45,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center"
    },
    padding: {
        paddingBottom: 15
    },
    diagonalLine: {
        width: 50,
        height: 50,
        position: "relative",
        overflow: "hidden",
        "&:before": {
            borderTop: "1px solid",
            borderTopColor: theme.status.grey.greydivs,
            content: "''",
            position: "absolute",
            left: "-50%",
            right: 0,
            transform: "rotate(-45deg)",
            transformOrigin: "100% 0"
        }
    },
    display: {
        fontWeight: 300
    }
});

function ParametersTimePeriod(props) {
    const { version, t, classes } = props;
    return (
        <div className={classes.container}>
            <div>
                <Typography className={classes.padding}>
                    {t("common.startDate")}
                </Typography>
                <Typography variant="h4" className={classes.display}>
                    {Content.formatDate(version.versionRequestInfo.start_date)}
                </Typography>
            </div>
            <div className={classes.diagonalLine} />
            <div>
                <Typography className={classes.padding}>
                    {t("common.endDate")}
                </Typography>
                <Typography variant="h4" className={classes.display}>
                    {Content.formatDate(version.versionRequestInfo.end_date)}
                </Typography>
            </div>
        </div>
    );
}

ParametersTimePeriod.propTypes = {
    classes: PropTypes.object.isRequired,
    version: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(ParametersTimePeriod));
