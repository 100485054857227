import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragSource } from "react-dnd";

import { ItemTypes } from "./ReassignDragConstants";

import ReassignProductItem from "./ReassignProductItem";

// The DragSource higher-order component accepts three parameters: type, spec, and collect

// Dnd type
const itemType = ItemTypes.PRODUCT_ITEM;
// DnD source specification
const itemSpec = {
    beginDrag(props) {
        const { product } = props;
        const { productId: id } = product;
        props.reassignDragStart(id);
        return { id };
    },
    endDrag(props, monitor) {
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();
        props.reassignDragEnd(item && item.id, dropResult && dropResult.id);
    }
};
// DnD collect function
function itemCollect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging()
    };
}

class ReassignProduct extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        isUserAssigned: PropTypes.bool.isRequired,
        selectedProducts: PropTypes.array.isRequired,
        toggleSelected: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        draggingTaskId: PropTypes.string,
        isDragging: PropTypes.bool,
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.updatePreview();
    }

    componentDidUpdate(prevProps) {
        const { previewImg } = this.props;
        const { previewImg: prevPreviewImg } = prevProps;

        if (previewImg !== prevPreviewImg) {
            this.updatePreview();
        }
    }

    updatePreview() {
        const { previewImg, connectDragPreview } = this.props;
        previewImg && connectDragPreview(previewImg);
    }

    handleClickItem = event => {
        if (event.defaultPrevented) return;
        const { product, toggleSelected } = this.props;
        event.preventDefault();
        if (event.button === 0) {
            toggleSelected(product.productId, event.shiftKey);
        }
    };

    render() {
        const {
            classes,
            product,
            selectedProducts,
            draggingTaskId,
            connectDragSource,
            isUserAssigned,
            columnKeys
        } = this.props;
        const count = selectedProducts.length;
        const isSelected = selectedProducts.some(
            el => el === product.productId
        );
        const isDragging = Boolean(draggingTaskId);
        const isGhosting = isSelected && isDragging;
        return connectDragSource(
            <li className={classes.listItem} onClick={this.handleClickItem}>
                <ReassignProductItem
                    product={product}
                    count={count}
                    isSelected={isSelected}
                    isGhosting={isGhosting}
                    isUserAssigned={isUserAssigned}
                    classes={classes}
                    columnKeys={columnKeys}
                />
            </li>
        );
    }
}

export default DragSource(itemType, itemSpec, itemCollect)(ReassignProduct);
