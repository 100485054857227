import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import DialogComponent from "../../uiComponents/Dialog/DialogComponent";

const UnSavedChangesDialogCheck = ({
                                       isOpen,
                                       handleClose,
                                       handleDiscard,
                                       t
                                   }) => (
    <DialogComponent
        onClose={handleClose}
        onDiscard={handleDiscard}
        isOpen={isOpen}
        padding
        maxWidth="sm"
        centre
        title={t("tree.save_saved_set_dialog_title")}
    >
        <div>
            {(
                <div>{t("tree.saved_set_unsaved_changes_warning")}</div>
            )}
        </div>
    </DialogComponent>
);

UnSavedChangesDialogCheck.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleDiscard: PropTypes.func.isRequired
};

UnSavedChangesDialogCheck.defaultProps = {
    isOpen: false,
};

export default withTranslation()(UnSavedChangesDialogCheck);
